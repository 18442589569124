import {FormikValues} from 'formik';
import _ from 'lodash';

const validateEmpty = (values: FormikValues) => {
  const errors = {};
  _.each(values, (v, k) => {
    if (_.isObject(v)) {
      const o = {};
      _.each(v, (sv, sk) => {
        if (!_.trim(sv) || _.isEqual(sv, 0)) {
          _.assign(o, {[sk]: `Empty Field`});
        }
      });
      _.assign(errors, {[k]: o});
    } else {
      if (!_.trim(v) || _.isEqual(v, 0)) {
        _.assign(errors, {[k]: `Empty Field`});
      }
    }
  });
  if (!_.isEmpty(errors)) {
    return errors;
  } else {
    return;
  }
};

export default validateEmpty;
