import {Toggle} from '@avalara/skylab-react';
import classNames from 'classnames';
import React from 'react';
import { TOOLTIP_SECTIONS } from '../../enums';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';

import styles from './styles.module.scss';

interface Props {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  classnames?: string;
  disabled?: boolean;
  tooltipText?: string;
}

/** Custom checkbox, it receice label & value & onChange and a optional parameter classnames */
function FieldToggleCheckbox(props: Props) {
  const {classnames, label, value, onChange, disabled, tooltipText} = props;
  return (
    <div
      className={classNames(
        styles.toggleCheckbox,
        disabled && styles.disabled,
        classnames,
      )}
    >
      <label>
        {label ? (
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.Common}
            tooltipKey={tooltipText || label}
          >
            <span>{label}</span>
          </TooltipWrapper>
        ) : (
          label
        )}

        <Toggle checked={value} onSToggle={(e) => onChange(e.detail.checked)} />
      </label>
    </div>
  );
}

export default FieldToggleCheckbox;
