import { AxiosResponse } from "axios";
import getFileName from "./getFileName";

export const responseToFile = (res: AxiosResponse<ArrayBuffer>) =>
  new File([res.data], getFileName(res.headers["content-disposition"]), {
    type: res.headers["content-type"],
  });

export const exportFile = (
  target: AxiosResponse | Promise<AxiosResponse>,
  type: string,
  finish: () => void
) => {
  return Promise.resolve(target).then((d) => {
    const blob = new Blob([d.data], {
      type,
    });
    const href = URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = href;
    tempLink.setAttribute(
      "download",
      getFileName(d.headers["content-disposition"])
    );
    tempLink.click();
    finish();
  }).catch(() => {
    finish();
  });
};

export const exportPdf = (target: AxiosResponse | Promise<AxiosResponse>, finish: () => void) => {
  return exportFile(target, "application/pdf", finish);
};

const exportXLSX = (target: AxiosResponse | Promise<AxiosResponse>, finish: () => void) => {
  exportFile(
    target,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    finish,
  );
};

export default exportXLSX;
