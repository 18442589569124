import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { UserModal } from '../../interface';
import { getUserProfile as getUser } from '../../services';

interface IAuthSlice {
	user: UserModal | null;
}

const initialState: IAuthSlice = {
	user: null
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.user = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.user = action.payload;
    })
  }
});

export const getUserProfile = createAsyncThunk(
  'getUserProile',
  async () => (await getUser()).data
);

export const {setUserProfile} = authSlice.actions;


export default authSlice;
