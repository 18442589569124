import {AxiosResponse} from 'axios';
import classNames from 'classnames';
import _, {
  camelCase,
  Dictionary,
  first,
  get,
  isEqual,
  keys,
  map,
  some,
  values,
} from 'lodash';
import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ASSETFILETYPE, ASSETORDERKEYS} from '../../constants';
import ModalContext from '../../context/ModalContext';
import {
  ASSET_STATUS,
  FIXEDASSETFILETOFIELD,
  INVENTORYFILETOFIELD,
  LEASEDEQUIPMENTFILETOFIELD,
  LEASEDVEHICLEFILETOFIELD,
  PROPERTIESFILETOFIELD,
  TOOLTIP_SECTIONS,
  VEHICLEFILETOFIELD,
} from '../../enums';
import {
  Assessor,
  AssessorAccount,
  AssetField,
  IErrorBase,
  ReduxState,
  State,
} from '../../interface';
import {
  deleteAsset,
  deleteAssets,
  getAllAssetClass,
  getAllAssets,
  getAssessorsByAccount,
  getCompanies,
  getErrorMessage,
  getLegalEntities,
  getProperties,
  setRollover,
  bulkUpdateAssets,
  getAllAssessors,
} from '../../services';
import Loader, {LoaderIndicator} from '../Loader/Loader';
import Modal from '../Modal/Modal';
import {InputType} from '../Table/components/Editor/Editor';
import InitTable, {Query} from '../Table/Table';
import useUserProfile from '../../hook/useUserProfile';
import style from './AssetsTable.module.scss';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import {ToastMessageRefType} from '../ToastMessage/ToastMessage';
import {useAppDispatch} from '../../redux/store';
import {getDashboardInfo} from '../../redux/features/dashboard-info-slice';
import useSelectedSystemTaxYear from '../../hook/useSelectedSystemTaxYear';
import FieldToggleCheckbox from '../FieldToggleCheckbox/FieldToggleCheckbox';
import { updateLoadingObjectByIds } from '../../utils/reactState';

const {Table, Column, TopBar} = InitTable<AssetField>();

type TypeColumnsKeys =
  | typeof FIXEDASSETFILETOFIELD
  | typeof INVENTORYFILETOFIELD
  | typeof VEHICLEFILETOFIELD
  | typeof LEASEDVEHICLEFILETOFIELD
  | typeof LEASEDEQUIPMENTFILETOFIELD
  | typeof PROPERTIESFILETOFIELD;

interface Props {
  standardColumns: string[];
  checkedColumns: string[];
  additionColumn: string[];
  mappedKeys: TypeColumnsKeys;
  type: string;
  disabledAdd?: boolean;
  onError: (value: string) => void;
  openModal: () => void;
  states: State[];
  taxYear: number;
  matchRollover?: boolean;
  toastRef: MutableRefObject<ToastMessageRefType | null>;
}

function AssetsTable(props: Props) {
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    standardColumns,
    additionColumn,
    mappedKeys,
    type,
    disabledAdd,
    onError,
    openModal,
    checkedColumns,
    states,
    matchRollover,
    toastRef,
  } = props;
  const selectedSystemTaxYear = useSelectedSystemTaxYear();
  const accountId = useUserProfile()?.userAccountId;
  const [assets, setAssets] = useState<AssetField[]>([]);
  const [selected, setSelected] = useState<AssetField[]>([]);
  const [deleteItem, setDeleteItem] = useState<AssetField>();
  const [assetsLength, setAssetsLength] = useState<number>(0);
  const [query, setQuery] = useState<Dictionary<Query>>({});
  const {state, dispatch} = useContext(ModalContext);
  const [options, setOptions] = useState<Dictionary<unknown[]>>({
    statuses: ['In progress', 'Approved', 'Filed'],
    rolloverStatuses: ['Ready', 'Missing Assessor Account'],
  });
  const lastQueryRef = useRef<Dictionary<unknown>>();
  const [fetchingData, setFetchingData] = useState(false);
  const [deletingAsset, setDeletingAsset] = useState(false);
  const [fetchingCompanies, setFetchingCompanies] = useState(false);
  const [fetchingEntities, setFetchingEntities] = useState(false);
  const [fetchingProperties, setFetchingProperties] = useState(false);

  const responseToOptions = (res: AxiosResponse) =>
    map(res.data.items, (x: {id: unknown; name: string; number: string}) => ({
      value: x.id,
      label: x.name ? x.name : '-',
      number: x.number,
    }));

  const addResponseOptions = (key: string, res: AxiosResponse) =>
    setOptions((current) => ({
      ...current,
      [key]: responseToOptions(res),
    }));

  useEffect(() => {
    setFetchingCompanies(true);
    getCompanies()
      .then((res) => {
        addResponseOptions('companyIds', res);
        setFetchingCompanies(false);
      })
      .catch(() => {
        setFetchingCompanies(false);
      });

    setFetchingEntities(true);
    getLegalEntities()
      .then((res) => {
        addResponseOptions('legalEntityIds', res);
        setFetchingEntities(false);
      })
      .catch(() => {
        setFetchingEntities(false);
      });

    setFetchingProperties(true);
    getProperties()
      .then((res) => {
        addResponseOptions('propertyIds', res);
        setFetchingProperties(false);
      })
      .catch(() => {
        setFetchingProperties(false);
      });
  }, []);

  const searchClassOptions = useCallback(
    (text: string) => {
      if (matchRollover) {
        return getAllAssetClass({
          page: 1,
          perPage: 20,
          text,
          taxYear: (+(selectedSystemTaxYear.taxYear || 1)- 1)?.toString(),
        }).then(responseToOptions);
      } else {
        return getAllAssetClass({
          page: 1,
          perPage: 20,
          text,
          taxYear: selectedSystemTaxYear.taxYear,
        }).then(responseToOptions);
      }
    },
    [selectedSystemTaxYear.taxYear, matchRollover],
  );

  const columns = useMemo(
    () => [...additionColumn, ...standardColumns],
    [standardColumns, additionColumn],
  );

  const defaultColumns = useMemo(
    () => new Set(checkedColumns),
    [checkedColumns],
  );

  const disableBulkActions = useMemo(
    () =>
      some(selected, (x) => x.status === 'Approved' || x.status === 'Filed'),
    [selected],
  );

  const [assessors, setAssessors] = useState([]);
  const [fetchingAllAssessors, setFetchingAllAssessors] = useState(false);
  useEffect(() => {
    if (accountId) {
      setFetchingAllAssessors(true);
      getAssessorsByAccount({userAccountId: _.toString(accountId)})
        .then((d) => {
          setAssessors(
            d.data.items.map((item: Assessor) => ({
              id: item.id,
              label: item.name,
              name: item.name,
              value: item.id,
            })),
          );
          setFetchingAllAssessors(false);
        })
        .catch(() => {
          setFetchingAllAssessors(false);
        });
    }
  }, [accountId]);

  const [assessorAccounts, setAssessorAccounts] = useState([]);
  const [fetchingAllAssessorAccounts, setFetchingAllAssessorAccounts] = useState(false);
  useEffect(() => {
    if (accountId && matchRollover) {
      setFetchingAllAssessorAccounts(true);
      getAllAssessors((+(selectedSystemTaxYear.taxYear || 1)- 1)?.toString())
        .then((d) => {
          setAssessorAccounts(
            d.data.items.map((item: AssessorAccount) => ({
              id: item.id,
              label: `${item.assessor.name} | ${item.number}`,
              name: `${item.assessor.name} | ${item.number}`,
              value: item.id,
            })),
          );
          setFetchingAllAssessorAccounts(false);
        })
        .catch(() => {
          setFetchingAllAssessorAccounts(false);
        });
    }
  }, [accountId]);

  const fetchData = (finish: () => void) => {
    const selectedTaxYears: string[] = [];
    if (matchRollover) {
      selectedTaxYears.push(selectedSystemTaxYear?.taxYear ? (+selectedSystemTaxYear.taxYear - 1).toString() : '');
    }
    else {
      selectedTaxYears.push(selectedSystemTaxYear?.taxYear ?? '');
    }

    const formattedQuery = {
      page: query[type]?.pagination?.page || 1,
      perPage: query[type]?.pagination?.pageSize ?? 10,
      sortBy: first(keys(query[type]?.order)),
      sortDirection: first(values(query[type]?.order)),
      text: query[type]?.search,
      types: type,
      taxYears: selectedTaxYears.join(','),
      statuses: (query[type]?.filter?.statuses as string[])?.join(','),
      rolloverStatuses: (query[type]?.filter?.rolloverStatuses as string[])?.join(','),
      companyIds: (query[type]?.filter?.companyIds as number[])?.join(','),
      legalEntityIds: (query[type]?.filter?.legalEntityIds as number[])?.join(
        ',',
      ),
      stateIds: (query[type]?.filter?.stateIds as number[])?.join(','),
      assessorIds: (query[type]?.filter?.assessorIds as number[])?.join(','),
      assessorAccountIds: (query[type]?.filter?.assessorAccountIds as number[])?.join(','),
      propertyIds: (query[type]?.filter?.propertyIds as string[])?.join(','),
      assetClassIds: (query[type]?.filter?.assetClassIds as number[])?.join(
        ',',
      ),
      additionStatuses: (query[type]?.filter?.addition as boolean[])?.join(',') ?? '',
      excludeDisposals: matchRollover,
      excludeRolledOver: matchRollover
    };

    if (!isEqual(formattedQuery, lastQueryRef.current)) {
      lastQueryRef.current = formattedQuery;
      setFetchingData(true);
      getAllAssets(formattedQuery)
        .then((res) => {
          setAssets(res.data.items);
          setAssetsLength(res.data.total);
          setSelected([]);
          setFetchingData(false);
          finish();
        })
        .catch(() => {
          setFetchingData(false);
          finish();
        });
    } else {
      finish();
    }
  };

  useEffect(() => {
    if ((!matchRollover || !_.isUndefined(selectedSystemTaxYear.taxYear)) && query[type]) {
      fetchData(_.noop);
    }
  }, [type, query[type], matchRollover, selectedSystemTaxYear.taxYear]);

  const confirmDelete = () => {
    dispatch({type: 'OPEN', href: 'comfirm'});
  };

  const rolloverItems = () => {
    dispatch({type: 'OPEN', href: 'rollover'});
  };

  const confirm = (e: React.MouseEvent) => {
    setDeletingAsset(true);

    (deleteItem
      ? deleteAsset(deleteItem.id)
      : deleteAssets(selected.map((x) => x.id).join(','))
    )
      .then((d) => {
        setDeletingAsset(false);
        dispatch({type: 'CLOSE'});
        const errorMessage = getErrorMessage(d);
        if (errorMessage) {
          toastRef.current?.showErrorToast(errorMessage);
        } else {
          toastRef.current?.showSuccessToast('Delete successfully');
          appDispatch(getDashboardInfo());
          lastQueryRef.current = undefined;
          return fetchData(_.noop);
        }
      })
      .catch((err) => {
        toastRef.current?.showErrorToast(
          err?.data?.errors[0]
            ? err?.data?.errors[0]
            : `Error occurs, please try again later.`,
        );
        setDeletingAsset(false);
      });

    e.stopPropagation();
  };

  const rollover = (e: React.MouseEvent) => {
    setFetchingData(true);
    setRollover(selected.map((x) => x.id).join(','))
      .then((res: AxiosResponse<IErrorBase>) => {
        if (res.data?.errors?.length) {
          onError(res.data?.errors[0]);
          setFetchingData(false);
        } else {
          const formattedQuery = {
            page: query[type]?.pagination?.page || 1,
            perPage: query[type]?.pagination?.pageSize ?? 10,
            sortBy: first(keys(query[type]?.order)),
            sortDirection: first(values(query[type]?.order)),
            text: query[type]?.search,
            types: type,
            taxYears: selectedSystemTaxYear.taxYear && matchRollover ? `${+selectedSystemTaxYear.taxYear - 1}` : '',
            statuses: (query[type]?.filter?.statuses as string[])?.join(','),
            rolloverStatuses: (query[type]?.filter?.rolloverStatuses as string[])?.join(','),
            companyIds: (query[type]?.filter?.companyIds as number[])?.join(
              ',',
            ),
            legalEntityIds: (
              query[type]?.filter?.legalEntityIds as number[]
            )?.join(','),
            stateIds: (query[type]?.filter?.stateIds as number[])?.join(','),
            assessorIds: (query[type]?.filter?.assessorIds as number[])?.join(
              ',',
            ),
            assessorAccountIds: (query[type]?.filter?.assessorAccountIds as number[])?.join(','),
            propertyIds: (query[type]?.filter?.propertyIds as string[])?.join(
              ',',
            ),
            assetClassIds: (
              query[type]?.filter?.assetClassIds as number[]
            )?.join(','),
            additionStatuses: (query[type]?.filter?.addition as boolean[])?.join(',') ?? '',
            excludeDisposals: matchRollover
          };

          getAllAssets(formattedQuery)
            .then((assetRes) => {
              setAssets(assetRes.data.items);
              setAssetsLength(assetRes.data.total);
              setSelected([]);
              setFetchingData(false);
            })
            .catch(() => {
              setFetchingData(false);
            });
        }
      })
      .catch((err) => {
        toastRef.current?.showErrorToast(err.data.errors[0]);
        setFetchingData(false);
      });

    dispatch({type: 'CLOSE'});
    e.stopPropagation();
  };

  const [mapAssetOrderkeys, setMapAssetOrderkeys] = useState({});
  useEffect(() => {
    const MAPASSETORDERKEYS = {};
    for (const col in ASSETORDERKEYS) {
      if (_.isEqual(col, 'Original Cost')) {
        _.assign(MAPASSETORDERKEYS, {
          'Original Cost': `${_.camelCase(type)}.${_.get(ASSETORDERKEYS, col)}`,
        });
      } else {
        _.assign(MAPASSETORDERKEYS, {[col]: _.get(ASSETORDERKEYS, col)});
      }
    }
    setMapAssetOrderkeys(MAPASSETORDERKEYS);
  }, [ASSETORDERKEYS, type]);

  const ref = useRef<HTMLButtonElement>(null);
  const rollRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    ref.current && ref.current.blur();
    rollRef.current && rollRef.current.blur();
  }, [state.isOpen]);

  useEffect(() => {
    const result = {};
    _.forEach(ASSETFILETYPE, (value) => {
      _.assign(result, {[value]: null});
    });
    setQuery(result);
  }, []);

  const setQueryHanele = (x: Query) => {
    const res = _.mapValues(query, () => _.omit(x, 'order'));
    setQuery({...res, [type]: x});
  };

  // Bulk Actions
  const selectedIds = useMemo(
    () => (_.compact(selected).length ? selected.map((item) => item?.id) : []),
    [selected],
  );
  const canSaveSelected = useMemo(
    () =>
      selected.length > 0 &&
      !some(selected, (x) =>
        [
          ASSET_STATUS.Approved,
          ASSET_STATUS.Filed,
          ASSET_STATUS.Mapping,
        ].includes(x?.status as ASSET_STATUS),
      ),
    [selected],
  );
  const canRolloverSelected = useMemo(
    () =>
      selected.length > 0 &&
      _.every(selected, (x) => x?.rolloverStatus === 'Ready'),
    [selected],
  );

  const [batchIsAddition, setBatchIsAddition] = useState<boolean>(false);
  const [batchIsDisposal, setBatchIsDisposal] = useState<boolean>(false);

  const [approvingAssets, setApprovingAssets] = useState<{ [key: number]: boolean; }>({});
  const isSavingAssets = (ids: number[]) => {
    return ids.length > 0 && _.every(ids, (id) => !!approvingAssets[id]);
  };
  const [deletingAssets, setDeletingAssets] = useState<{
    [key: number]: boolean;
  }>({});

  const updateSavingAssets = (ids: number[], deleting: boolean) => {
    updateLoadingObjectByIds(
      ids,
      deleting,
      approvingAssets,
      setApprovingAssets,
    );
  };

  const bulkSaveAssets = () => {
    updateSavingAssets(
      selectedIds,
      true,
    );
    bulkUpdateAssets(selectedIds.join(','), batchIsAddition, batchIsDisposal)
      .then(() => {
          toastRef.current?.showSuccessToast('Assets saved successfully');
          resetBatchSelections();
          lastQueryRef.current = undefined;
          fetchData(_.noop);
          updateSavingAssets( selectedIds, false);
        },
        (err) => {
          const message = err.data.errors?.join(', ');
          toastRef.current?.showErrorToast(message || 'Failed to save Assets');
          updateSavingAssets(selectedIds, false);
        },
      )
      .catch((e) => {
        toastRef.current?.showErrorToast(getErrorMessage(e));
        updateSavingAssets(selectedIds, false);
      });
  };

  const resetBatchSelections = () => {
    setBatchIsAddition(false);
    setBatchIsDisposal(false);
    setSelected([]);
  };

  return (
    <>
      <Table
        id={
          matchRollover
            ? `rollover_${camelCase(type)}`
            : `assets_${camelCase(type)}`
        }
        rows={assets}
        onQueryChanged={(x) => setQueryHanele(x)}
        paginate
        selectable={{selected, onChange: setSelected}}
        searchable='Search assets...'
        totalRows={assetsLength}
        dynamicColumns
        loading={fetchingData}
        showAddButton={!matchRollover ? 'Add Asset' : ''}
        onAddButtonClick={openModal}
      >
        <TopBar>
          <div className={style.operate}>
            {selected.length > 0 && (
              <>
                {matchRollover ? (
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.TableAction}
                    tooltipKey='Rollover'
                  >
                    <button
                      className='button secondary'
                      onClick={rolloverItems}
                      disabled={
                        !canRolloverSelected
                        || fetchingData
                      }
                    >
                      Rollover ({selected.length})
                    </button>
                  </TooltipWrapper>
                ) : (
                  <div className={style['selected-items-action']}>
                    <div className={style['selected-items']}>
                      <TooltipWrapper
                        tooltipSection={TOOLTIP_SECTIONS.Common}
                        tooltipKey='Selected Assets'
                      >
                        <span>Selected Assets ({selected.length})</span>
                      </TooltipWrapper>
                    </div>
                    <FieldToggleCheckbox
                      classnames={style['group-field-toggle']}
                      label='Addtion'
                      value={batchIsAddition}
                      onChange={(newVal) => {
                        setBatchIsAddition(newVal);
                      }}
                      disabled={disableBulkActions}
                    />
                    <FieldToggleCheckbox
                      classnames={style['group-field-toggle']}
                      label='Disposal'
                      value={batchIsDisposal}
                      onChange={(newVal) => {
                        setBatchIsDisposal(newVal);
                      }}
                      disabled={disableBulkActions}
                    />
                    <div className={style['batch-action-buttons']}>
                      <TooltipWrapper
                        tooltipSection={TOOLTIP_SECTIONS.TableAction}
                        tooltipKey='Save'
                      >
                        <button
                          className='default-button'
                          onClick={bulkSaveAssets}
                          disabled={disableBulkActions || isSavingAssets(selectedIds) || !canSaveSelected}
                        >
                          Save
                        </button>
                      </TooltipWrapper>
                      <TooltipWrapper
                        tooltipSection={TOOLTIP_SECTIONS.TableAction}
                        tooltipKey='Cancel'
                      >
                        <button
                          className='default-button'
                          onClick={resetBatchSelections}
                          disabled={disableBulkActions}
                        >
                          Cancel
                        </button>
                      </TooltipWrapper>
                      <TooltipWrapper
                        tooltipSection={TOOLTIP_SECTIONS.TableAction}
                        tooltipKey='Delete'
                      >
                        <button
                          className='button secondary'
                          onClick={confirmDelete}
                          disabled={disableBulkActions}
                        >
                          Delete ({selected.length})
                        </button>
                      </TooltipWrapper>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </TopBar>

        {columns.map((col, idx) => (
          <Column
            key={idx}
            hidden={!defaultColumns.has(col)}
            sortable={col in ASSETORDERKEYS}
            id={get(mapAssetOrderkeys, col)}
            label={col}
            accessor={(asset) => {
              switch (col) {
                case 'Asset Status':
                  return asset.status ?? null;
                case 'Original Cost':
                  return get(
                    asset,
                    `${_.camelCase(type)}.${_.get(ASSETORDERKEYS, col)}`,
                    null,
                  );
                case 'Rollover Status':
                  return asset.rolloverStatus ?? null;
                default:
                  return _.get(asset, _.get(mappedKeys, col), null);
              }
            }}
          >
            {(value: string | number) =>
              col === 'Asset Status' || col === 'Rollover Status' ? (
                <span
                  className={classNames(
                    'badge-status',
                    _.kebabCase(value as string),
                  )}
                >
                  {value}
                </span>
              ) : (
                value?.toString()
              )
            }
          </Column>
        ))}
        {!matchRollover && (
          <Column id='actions' label='Actions'>
            {(asset: AssetField) => (
              <div className={style.operate}>
                <button
                  role='button'
                  className='edit'
                  disabled={asset.status !== 'In progress'}
                  onClick={() =>
                    navigate(
                      get(
                        {
                          'Fixed Asset': `/assets/editFixedAssetSetup/${asset.id}`,
                          Inventory: `/assets/editInventorySetup/${asset.id}`,
                          Vehicle: `/assets/editVehicleSetup/${asset.id}`,
                          'Leased Vehicle': `/assets/editLeasedVehicleSetup/${asset.id}`,
                          'Leased Equipment': `/assets/editLeasedEquipmentSetup/${asset.id}`,
                        },
                        type,
                        `/assets/editFixedAssetSetup/${asset.id}`,
                      ),
                    )
                  }
                ></button>
                <button
                  role='button'
                  className='delete'
                  disabled={asset.status !== 'In progress'}
                  onClick={() => {
                    setDeleteItem(asset);
                    confirmDelete();
                  }}
                ></button>
              </div>
            )}
          </Column>
        )}

        {/* Status filter */}
        {
          !matchRollover &&
          <Column
            id='statuses'
            hidden='always'
            label={['Status', 'Select asset status']}
            filterable
            editor={{type: InputType.Checkbox, options: options['statuses']}}
          />
        }
        
        {/* Rollover Status filter */}
        {
          matchRollover &&
          <Column
            id='rolloverStatuses'
            hidden='always'
            label={['Rollover Status', 'Select asset rollover status']}
            filterable
            editor={{type: InputType.Checkbox, options: options['rolloverStatuses']}}
          />
        }

        {/* Company name filter */}
        <Column
          id='companyIds'
          hidden='always'
          label={['Company', 'Select one or more companies']}
          filterable
          editor={{
            type: InputType.Select,
            options: options['companyIds'],
            getLabel: (x) => `${_.get(x, 'label')} | ${_.get(x, 'number')}`,
            multi: true,
          }}
        />

        {/* Legal Entity filter */}
        <Column
          id='legalEntityIds'
          hidden='always'
          label={['Legal Entity', 'Select one or more legal entities']}
          filterable
          editor={{
            type: InputType.Select,
            options: options['legalEntityIds'],
            getLabel: (x) => `${_.get(x, 'label')} | ${_.get(x, 'number')}`,
            multi: true,
          }}
        />

        {/* Property Number filter */}
        <Column
          id='propertyIds'
          hidden='always'
          label={['Property', 'Select one or more properties']}
          filterable
          editor={{
            type: InputType.Select,
            options: options['propertyIds'],
            getLabel: (x) => `${_.get(x, 'label')} | ${_.get(x, 'number')}`,
            multi: true,
          }}
        />

        {/* Assessor Account filter */}
        {
          matchRollover &&
          <Column
            id='assessorAccountIds'
            hidden='always'
            label={['Assessor Account', 'Select one or more assessor accounts']}
            filterable
            editor={{
              type: InputType.Select,
              options: assessorAccounts,
              multi: true,
            }}
          />
        }

        {/* State filter */}
        <Column
          id='stateIds'
          hidden='always'
          label={['State', 'Select one or more states']}
          filterable
          editor={{
            type: InputType.Select,
            options: states,
            getLabel: (x) => (x as State).abbreviation,
            getValue: (x) => (x as State).id,
            multi: true,
          }}
        />

        {/* Assessor filter */}
        {
          !matchRollover &&
          <Column
            id='assessorIds'
            hidden='always'
            label={['Assessor', 'Select one or more assessor names']}
            filterable
            editor={{
              type: InputType.Select,
              options: assessors,
              multi: true,
            }}
          />
        }

        {/* Asset Class filter */}
        <Column
          id='assetClassIds'
          hidden='always'
          label={['Asset Class', 'Select one or more asset classes']}
          filterable
          editor={{
            type: InputType.Select,
            options: searchClassOptions,
            multi: true,
          }}
        />

        {/* Addition and Disposal filters */}
        {
          matchRollover &&
          <Column
            id='addition'
            label='Addition'
            filterable
            hidden='always'
            editor={{
              type: InputType.Select,
              options: [
                {
                  name: 'Addition',
                  value: true,
                },
                {
                  name: 'Non - Addition',
                  value: false,
                },
              ],
              multi: true,
              getLabel: (option) => {
                return (option as {name: string; value: boolean}).name;
              },
              getValue: (option) => {
                return (option as {name: string; value: boolean}).value;
              },
            }}
          />
        }
      </Table>

      <Modal
        title='Confirmation'
        body={<p>Are you sure you want to delete?</p>}
        isOpen={state.isOpen && state.href === 'comfirm'}
        onClose={() => {
          setDeletingAsset(false);
        }}
        footer={
          <div className='buttons'>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Confirmation Confirm'
            >
              <button
                className='primary'
                onClick={(e) => confirm(e)}
                disabled={deletingAsset}
                ref={ref}
              >
                Confirm
                {deletingAsset ? (
                  <LoaderIndicator className='button-loading' loading={true} />
                ) : null}
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Confirmation Cancel'
            >
              <button
                className='secondary'
                onClick={() => {
                  setDeletingAsset(false);
                  dispatch({type: 'CLOSE'});
                }}
              >
                Cancel
              </button>
            </TooltipWrapper>
          </div>
        }
      />
      <Modal
        title={`Rollover ${selected.length} Assets`}
        tooltipText='Rollover Assets'
        body={
          <div>
            <div className={style['rollover']}>
            <span className={style['block']}>
              Tax year<small>{_.toString(+(selectedSystemTaxYear.taxYear || 1) - 1)}</small>
            </span>
            <span className={style['block']}>
              Tax year<small>{_.toString(selectedSystemTaxYear.taxYear)}</small>
            </span>
            </div>
            <div className={style['disposal-note']}>
              Disposed assets will not be rolled over
            </div>
          </div>
        }
        isOpen={state.isOpen && state.href === 'rollover'}
        footer={
          <div className='buttons'>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Rollover fixed assets'
            >
              <button
                className='primary'
                onClick={(e) => rollover(e)}
                ref={rollRef}
              >
                {`Rollover fixed assets (${selected.length})`}
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Cancel'
            >
              <button
                className='secondary'
                onClick={() => dispatch({type: 'CLOSE'})}
              >
                Cancel
              </button>
            </TooltipWrapper>
          </div>
        }
      />

      <Loader
        isOpen={
          fetchingCompanies ||
          fetchingEntities ||
          fetchingProperties ||
          fetchingAllAssessors ||
          fetchingAllAssessorAccounts
        }
      />
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  states: state.states,
  taxYear: state.dashboardInfo?.taxYear,
});

export default connect(mapStateToProps)(AssetsTable);
