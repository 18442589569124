import React, { useState } from 'react';
import classNames from 'classnames';
import style from './AssessorManagement.module.scss';
import Divider from '../../../components/Divider/Divider';
import AssessorManagementTable from '../../../components/AssessorManagementTable/AssessorManagementTable';
import { IToastMessage } from '../../../interface';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import { getErrorMessage, syncContentStudioData } from "../../../services";

interface Props {
  className?: string;
}

function AssessorManagement({ className }: Props) {
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [
    confirmationSyncAssessorAddressesOpen,
    setConfirmationSyncAssessorAddressesOpen,
  ] = useState<boolean>(false);
  const [isSyncingFromContentStudio, setIsSyncingFromContentStudio] =
    useState<boolean>(false);

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'AssessorManagement d-flex flex-column')
    }>
      {toastMessage?.visible && (
        <div className={style.toast}>
          <ToastMessage
            className='successful'
            status={toastMessage}
            visiableHandler={(value) =>
              setToastMessage({ ...toastMessage, visible: value })
            }
          />
        </div>
      )}

      <div className={style.operate}>
        <button
          className={classNames('primary sync', style['sync-button'])}
          title="Sync Assessor Address from Content Studio"
          onClick={() => {
            setConfirmationSyncAssessorAddressesOpen(true);
          }}
        >
          <i></i> Sync Assessor Address
        </button>
      </div>

      <Divider className={style.divider1} />

      <AssessorManagementTable
        setToastMessage={setToastMessage}
      />

      <ConfirmModal
        isOpen={!!confirmationSyncAssessorAddressesOpen}
        onRequestClose={() => setConfirmationSyncAssessorAddressesOpen(false)}
        title="Confirmation"
        content="This will sync Depreciation Collections, Index Collections, and Assessor Addresses from Content Studio. Are you sure you want to sync this data?"
        confirming={isSyncingFromContentStudio}
        onConfirm={() => {
          setIsSyncingFromContentStudio(true);
          syncContentStudioData()
            .then(() => {
              setConfirmationSyncAssessorAddressesOpen(false);
              setToastMessage?.({
                message: "Assessors synchronized successfully.",
                visible: true,
                type: "success",
              });
              setIsSyncingFromContentStudio(false);
            })
            .catch((e) => {
              setConfirmationSyncAssessorAddressesOpen(false);
              setToastMessage?.({
                message:getErrorMessage(e),
                visible: true,
                type: "error",
              });
              setIsSyncingFromContentStudio(false);
            });
        }}
      />

    </div>
  );
}

export default AssessorManagement;