import React, {useReducer} from 'react';
import { INITIALMODAL } from '../../constants';
import { ModalAction, ModalState } from '../../interface';
import ModalContext from '../../context/ModalContext';

const reducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case 'OPEN':
      return {...state, ...{isOpen: true, href: action.href}};
    case 'CLOSE':
      return {...state, ...{isOpen: false}};
    default:
      return state;
  }
};

interface Props {
  children: JSX.Element;
}

export default function ModalContextProvider(props: Props) {
  const {children} = props;
  const [state, dispatch] = useReducer(reducer, INITIALMODAL);
  return (
    <ModalContext.Provider value={{state, dispatch}}>
      {children}
    </ModalContext.Provider>
  );
}
