import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import {Company} from '../../interface';
import InitTable, {Query} from '../../components/Table/Table';
import TableMenu, {MenuItem} from '../../components/TableMenu/TableMenu';
import TooltipWrapper from '../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../enums';
import style from './CompanyTable.module.scss';

interface Props {
  data: Company[];
  loading: boolean;
}

const {Table, Column, TopBar} = InitTable<Company>();

function CompanyTable(props: Props) {
  const {data, loading} = props;
  const navigate = useNavigate();
  const [query, setQuery] = useState<Query>();

  function formatNameAndNumber(name?: string, number?: string) {
    return _.compact([name, number && `${number}`]).join(' | ');
  }

  function getMenuItems(company: Company) {
    const editCompany = company.id && {
      'Edit Company': () => {
        navigate(`/properties/editCompany/${company.id}`);
      },
    };
    const actions = [_.assign({}, editCompany)];
    return [
      _.map(
        _.assign({}, ..._.compact(actions)),
        (onClick, label) =>
          ({
            variant: 'default',
            tooltip: {
              section: TOOLTIP_SECTIONS.TableRowAction,
              keyword: label,
            },
            label,
            onClick,
          } as MenuItem),
      ),
    ];
  }

  function fetchData(query?: Query) {
    setQuery(query);
  }

  function searchData(data: Company[], value: string | undefined) {
    if (value) {
      const escaped = _.escapeRegExp(value);
      return _.filter(
        data,
        (item) =>
          new RegExp(escaped, 'i').test(item.name) ||
          new RegExp(escaped, 'i').test(item.number)
      );
    } else {
      return data;
    }
  }

  return (
    <div className={style['table-layout']}>
      <Table
        paginate
        id='manageProperties'
        rows={
          _.nth(
            _.chunk(
              searchData(
                _.orderBy(
                  data,
                  _.last(_.keys(query?.order)),
                  _.last(_.values(query?.order)),
                ),
                query?.search,
              ),
              query?.pagination?.pageSize,
            ),
            (query?.pagination?.page as number) - 1,
          ) as Company[]
        }
        onQueryChanged={fetchData}
        loading={loading}
        sortable
        searchable='Search companies...'
        totalRows={searchData(data, query?.search).length}
      >
        <TopBar>
          <div className={style['operate']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.TableAction}
              tooltipKey='Add Company'
            >
              <Link
                className='button primary add-button'
                to='/properties/companySetup'
              >
                <i></i>Add Company
              </Link>
            </TooltipWrapper>
          </div>
        </TopBar>
        <Column id='number' label='Company Name & Number'>
          {(property: Company) =>
            formatNameAndNumber(property.name, property.number)
          }
        </Column>
        <Column id='active' label='Status'>
          {(property: Company) => (
            <span
              className={classNames(
                'badge-status',
                _.kebabCase(property.active ? 'Active' : 'Inactive'),
              )}
            >
              {property.active ? 'Active' : 'Inactive'}
            </span>
          )}
        </Column>
        <Column id='actions' label='Actions' sortable={false}>
          {(property: Company) => (
            <div className={style['actions-column-cell']}>
              <TableMenu
                toggle={
                  <button type='button'>
                    <i className={style['icon-3-dots']} />
                  </button>
                }
                menuItems={getMenuItems(property)}
              />
            </div>
          )}
        </Column>
      </Table>
    </div>
  );
}

export default CompanyTable;
