export interface IAssessorNotTaxYearFormData {
    name?: string;
    id?: number | null;
    state?: number | null;
    title?: string;
    mailingAddressLine1?: string;
    mailingAddressLine2?: string;
    mailingAddressCity?: string;
    mailingAddressState?: number | null;
    mailingAddressZip?: string;
    websiteLink?: string;
  }
  
  export const createEmptyIAssessorNotTaxYearFormData = (): IAssessorNotTaxYearFormData => {
    return {
      name: '',
      id: null,
      state: null,
      title: '',
      mailingAddressLine1: '',
      mailingAddressLine2: '',
      mailingAddressCity: '',
      mailingAddressState: null,
      mailingAddressZip: '',
      websiteLink: '',
    } as IAssessorNotTaxYearFormData;
  }