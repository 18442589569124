import _ from 'lodash';
import {FormikValues} from 'formik';
const removeAssetsEmptyFields = (source: FormikValues) => {
  const {
    fixedAsset,
    inventory,
    vehicle,
    leasedVehicle,
    leasedEquipment,
    ...rest
  } = source;
  const result = _.omitBy(rest, (v) => !_.trim(v));
  const fixedAssetsResult = _.omitBy(
    fixedAsset,
    (v) => !_.trim(v),
  );
  const inventoryResult = _.omitBy(
    inventory,
    (v) => !_.trim(v),
  );
  const vehicleResult = _.omitBy(vehicle, (v) => !_.trim(v));
  const leasedVehicleResult = _.omitBy(
    leasedVehicle,
    (v) => !_.trim(v),
  );
  const leasedEquipmentResult = _.omitBy(
    leasedEquipment,
    (v) => !_.trim(v),
  );
  return _.omitBy(
    _.assign(
      result,
      {fixedAsset: _.isEmpty(fixedAssetsResult) ? {} : fixedAssetsResult},
      {inventory: _.isEmpty(inventoryResult) ? {} : inventoryResult},
      {vehicle: _.isEmpty(vehicleResult) ? {} : vehicleResult},
      {leasedVehicle: _.isEmpty(leasedVehicleResult) ? {} : leasedVehicleResult },
      {leasedEquipment: _.isEmpty(leasedEquipmentResult) ? {} : leasedEquipmentResult },
    ),
    (v) => !_.trim(v),
  );
};

export default removeAssetsEmptyFields;
