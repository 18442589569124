import _ from 'lodash';

export interface Obj {
  [key: string]: string | number | boolean;
}

const generateQueryString = (obj: Obj) => {
  const strPart = [];
  for (const p in obj)
    if (
      _.has(obj, p) &&
      ((typeof obj[p] === 'number' && obj[p] !== null) || !!obj[p])
    ) {
      strPart.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return strPart.join('&');
};

export {generateQueryString};
