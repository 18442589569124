import _ from 'lodash';
import {ExtProperty} from '../interface';

interface Query {
  [key: string]: unknown | undefined;
}

const filterQuery = (data: ExtProperty[], query: Query | undefined) =>
  _.reduce(
    query,
    (result, value, key) => {
      if (_.isArray(value)) {
        if (!_.isEmpty(value)) {
          return _.reduce(
            value as Array<string>,
            (r, n) => {
              r = _.concat(r, _.filter(result, [key, n]));
              return _.unionBy(r, "id");
            },
            [] as ExtProperty[]
          );
        } else {
          return result;
        }
      } else {
        if (_.isEqual(key, "address.stateId")) {
          return _.filter(result, [key, value]);
        } else {
          return _.filter(result, (item) =>
            _.includes(
              _.lowerCase(_.get(item, key)),
              _.lowerCase(value as string)
            )
          );
        }
      }
    },
    data
  );

export default filterQuery;
