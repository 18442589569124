import React from 'react';
import classNames from 'classnames';
import style from './FieldReadOnly.module.scss';
import { TOOLTIP_SECTIONS } from '../../enums';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';

interface Props {
  className?: string;
  labelText: string;
  required?: boolean;
  tooltipText?: string;
  value: string | number;
  isLink?: boolean;
}

function FieldReadonly({
  className,
  labelText,
  required,
  tooltipText,
  value,
  isLink = false
}: Props) {
  return (
    <div
      className={
        classNames(
          className,
          style['main-content'],
          'FieldReadonly d-flex flex-column')
      }
    >
      <TooltipWrapper
        tooltipSection={TOOLTIP_SECTIONS.Common}
        tooltipKey={tooltipText ?? labelText}
      >
        <span className={style.textTitle}>
          {labelText}
          {required && <i className={style['required']}>*</i>}
        </span>
      </TooltipWrapper>
      {isLink ?
        (<a className={style.textLink} href={value as string}  rel='noreferrer' target='_blank' >{value}</a>) :
        (<span className={style.textValue}>{value || '-'}</span>)}
    </div>
  );
}

export default FieldReadonly;