import React from "react";
import classNames from "classnames";
import style from "./FieldRichText.module.scss";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

interface Props {
  labelText?: string;
  value: string;
  onChange: (value: string) => void;
  classnames?: string;
  required?: boolean;
  tooltipText?: string;
  disabled?: boolean;
  error?: string;
  name?: string;
}

function FieldRichText(props: Props) {
  const {
    labelText,
    classnames,
    required,
    tooltipText,
    value,
    onChange,
    error,
  } = props;

  const editorConfiguration = {
    fontFamily: {
      options: [
          'default',
          'Arial, sans-serif',
          'Courier New, Courier, monospace'
      ]
    },
    toolbar: [
      'fontFamily', 'fontSize', '|',
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'fontColor', 'fontBackgroundColor', '|',
      'heading', 'blockQuote', '|',
      'numberedList', 'bulletedList', '|',
      'indent', 'outdent', '|',
      'link',
    ]
  }

  return (
    <div
      className={classNames(style["form-control"], classnames, {
        error: error,
      })}
    >
      {labelText && (
        <div className={style["label"]}>
          {labelText}
          {required && <i className={style["required"]}>*</i>}
          {tooltipText && (
            <i className={style["tooltip"]}>
              <span className={style["tip"]}>{tooltipText}</span>
            </i>
          )}
        </div>
      )}

      <div className={classNames(style["input-control"])}>
        <CKEditor
          config={editorConfiguration}
          // eslint-disable-next-line
          onReady={ (editor: any) => {
            editor.ui.getEditableElement().parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
            );
          } }
          // eslint-disable-next-line
          onChange={ (_, editor: any ) => {
            onChange(editor.getData());
          }}
          editor={ DecoupledEditor }
          data={value}
        />
      </div>
      {error && (
        <span
          className={classNames(style["error-message"], {
            [style["long-message"]]: !/Empty/.test(error),
          })}
        >
          {error}
        </span>
      )}
    </div>
  );
}

export default FieldRichText;
