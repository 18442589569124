import React, {KeyboardEventHandler} from 'react';
import classNames from 'classnames';
import style from './FieldInput.module.scss';
import _, {isNil} from 'lodash';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import { TOOLTIP_SECTIONS } from '../../enums';
import FieldReadonly from '../FieldReadOnly/FieldReadOnly';

interface Props {
  labelClassName?: string;
  labelText: string;
  value: string | number;
  onChange: (value: string) => void;
  classnames?: string;
  required?: boolean;
  tooltipText?: string;
  disabled?: boolean;
  error?: string;
  name?: string;
  icon?: React.ReactNode;
  maxLength?: number;
  type?: 'text' | 'number';
  recommended?: boolean;
  decimal?: boolean;
  placeholder?: string;
  yearFormat?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  isInReadOnlyMode?: boolean;
}

function FieldInput(props: Props) {
  const {
    labelClassName,
    labelText,
    classnames,
    required,
    tooltipText,
    value,
    onChange,
    disabled,
    error,
    name,
    icon,
    maxLength,
    type = 'text',
    recommended,
    decimal,
    placeholder,
    yearFormat,
    onKeyDown,
    isInReadOnlyMode = false,
  } = props;
  const inputId = _.uniqueId(`label-`);
  const messageId = _.uniqueId(`msg-`);
  if (isInReadOnlyMode) {
    return (
      <FieldReadonly
        className={classnames}
        labelText={labelText}
        required={required}
        tooltipText={tooltipText}
        value={value}
      />
    )
  }
  return (
    // If validate false, pass the error to here to show error style
    <label
      htmlFor={inputId}
      className={
        classNames(
          'FieldInput',
          style['form-control'],
          classnames,
          {error: !!error}
        )
      }
    >
      {/* Lable of input,accept required and tooltip parameters */}
      {labelText && (
        <div
          className={classNames(
            style['label'],
            {
              [style['label-recommended']]: recommended,
            },
            labelClassName,
          )}
        >
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.Common}
            tooltipKey={tooltipText ? tooltipText : labelText}
          >
            <span>
              {labelText}
              {required && <i className={style['required']}>*</i>}
            </span>
          </TooltipWrapper>
          {recommended && <small>Recommended</small>}
        </div>
      )}
      {/* If disabled the input, add disabled parameter here */}
      <div
        className={classNames(style['input-control'], {
          disabled: disabled,
          [style['with-icon']]: !isNil(icon),
        })}
      >
        {icon && <span className={style.icon}>{icon}</span>}
        <input
          aria-label={messageId}
          id={inputId}
          type={type}
          name={name}
          value={value || (decimal ? 0 : '')}
          className={classNames({decimal: decimal}, {error: error})}
          onChange={(evt) => {
            const updated = evt.target.value;

            if (
              updated &&
              ((decimal && !/^\d+(\.\d*)?$/.test(updated)) ||
                (yearFormat && !/^\d{0,4}$/.test(updated)))
            ) {
              return;
            }

            onChange(evt.target.value);
          }}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
        />
      </div>
      {/* Error message if do not enter any text and if field is required */}
      {error && (
        <div
          id={messageId}
          className={classNames(style['error-message'], {
            [style['long-message']]: !/Empty/.test(error),
          })}
        >
          {error}
        </div>
      )}
    </label>
  );
}

export default FieldInput;
