import React, {useState, useEffect, useRef} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import _ from 'lodash';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import StepsBar from '../../../components/StepsBar/StepsBar';
import ToastMessage, {
  ToastMessageRefType,
} from '../../../components/ToastMessage/ToastMessage';
import {
  ExtProperty,
  IErrorBase,
  IToastMessage,
  OriginalFileField,
  SelectOption,
} from '../../../interface';
import style from './PropertyReview.module.scss';
import {
  cancelUploadFile,
  getErrorMessage,
  getUploadFile,
  updateUploadFile,
  uploadProperty,
  validateProperties,
} from '../../../services';
import clearProperties from '../../../utils/clearProperties';
import removeEmptyField from '../../../utils/removeEmptyField';
import {AxiosResponse} from 'axios';
import {PROPERTYFILECOLUMNS} from '../../../constants';
import {PROPERTIESFILETOFIELD, TOOLTIP_SECTIONS} from '../../../enums';
import usePrevious from '../../../hook/usePrevious';
import ValidatePropertiesTable from '../../../components/ValidatePropertiesTable/ValidatePropertiesTable';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {FormikValues} from 'formik';
import actions from '../../../redux/actions';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import Loader, {LoaderIndicator} from '../../../components/Loader/Loader';
import {useAppDispatch} from '../../../redux/store';
import {getDashboardInfo} from '../../../redux/features/dashboard-info-slice';

interface ExtFile {
  status: string;
  mappingPriority: string;
  propertyData: string;
  expectData: string;
  mapFileColumn: SelectOption[];
  fileColumnPreview?: string[];
  selectedColumn: string;
}

interface Props {
  setStoreProperties: (value: FormikValues | undefined) => void;
}

function PropertyReview(props: Props) {
  const appDispatch = useAppDispatch();
  const {setStoreProperties} = props;
  const navigate = useNavigate();
  const {id} = useParams();
  const [fileColumns, setFileColumns] = useState();
  const [returnJSON, setReturnJSON] = useState<OriginalFileField[]>([]);
  const [properties, setProperties] = useState<ExtProperty[]>([]);
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [fileName, setFileName] = useState<string>('');
  const [propertiesWithStatus, setPropertiesWithStatus] = useState<
    ExtProperty[]
  >([]);
  const [file, setFile] = useState<ExtFile[]>([]);
  const [validate, setValidate] = useState<boolean>(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [validatingData, setValidatingData] = useState(false);
  const [uploadingData, setUploadingData] = useState(false);
  const [deletingItem, setDeletingItem] = useState(false);
  const [cancellingUploadFile, setCancellingUploadFile] =
    useState<boolean>(false);
  const toastRef = useRef<ToastMessageRefType | null>(null);

  // Breadcrumb, dashboard depend on user skip or not, property link depend where is the page from
  const breadcrumb = [
    {
      value: 'Properties',
      href: '/properties?tab=Properties+and+Assessor+Accounts',
    },
    {value: 'Property Setup', href: '/properties/propertyUpload'},
    {value: 'File Mapping', href: `/properties/propertyMapFile/${id}`},
    {value: 'File Review'},
  ];
  const ref = usePrevious(properties);
  useEffect(() => {
    if (!_.isEmpty(properties)) {
      if (!_.isEqual(ref, properties)) {
        if (validate) {
          setValidate(false);
          setValidatingData(true);
          validateProperties(
            clearProperties(removeEmptyField(properties) as ExtProperty[]),
          )
            .then((d) => {
              const errorMessage = getErrorMessage(d);
              if (_.find(d.data, ['status', 'Action Required'])) {
                setToastMessage({
                  visible: true,
                  message:
                    'All errors must be resolved before the assets can be imported!',
                  type: 'error',
                });
              } else if (_.find(d.data, ['status', 'Duplicate'])) {
                setToastMessage({
                  visible: true,
                  message: 'Duplicate records will not be imported.',
                  type: 'warning',
                });
              } else if (errorMessage) {
                setToastMessage({
                  visible: true,
                  message: errorMessage,
                  type: 'error',
                });
              }
              setPropertiesWithStatus(
                _.map(properties, (property, index) => ({
                  ...property,
                  ...d.data[index],
                })),
              );
              setValidatingData(false);
            })
            .catch(() => {
              setValidatingData(false);
            });
        }
      }
    } else {
      setPropertiesWithStatus(properties);
    }
  }, [properties, validate]);

  useEffect(() => {
    if (id) {
      setFetchingData(true);
      getUploadFile(id)
        .then((d) => {
          setFileColumns(d.data.columnMappings[0]);
          setReturnJSON(d.data.columnMappings[1]);
          setFile(d.data.columnMappings[2]);
          setFileName(d.data.document.name);
          setProperties(d.data.items);
          setValidate(true);
          setFetchingData(false);
        })
        .catch(() => {
          setFetchingData(false);
        });
    }
  }, [id]);

  const deleteItem = (values: ExtProperty[]) => {
    if (id) {
      setDeletingItem(true);
      updateUploadFile(id, {
        columnMappings: [
          fileColumns,
          _.intersectionBy(returnJSON, values, 'id'),
          file,
        ],
        items: values,
      })
        .then((d) => {
          const errorMessage = getErrorMessage(d);
          if (errorMessage) {
            toastRef.current?.showErrorToast(errorMessage);
          } else {
            if (
              _.isEmpty(d.data.items) ||
              _.every(d.data.items, (o) => _.isEqual(o['status'], 'Ready'))
            ) {
              toastRef.current?.showSuccessToast('Delete successfully');
            }
            setProperties(d.data.items);
            setReturnJSON(d.data.columnMappings[1]);
            setValidate(true);
          }
          setDeletingItem(false);
        })
        .catch((e) => {
          toastRef.current?.showErrorToast(getErrorMessage(e));
          setDeletingItem(false);
        });
    }
  };

  useEffect(() => {
    setStoreProperties(undefined);
  }, []);

  return (
    <div className={style['main-content']}>
      {/* Braedcrumb */}
      <Breadcrumb items={breadcrumb} />
      <ToastMessage
        status={toastMessage}
        ref={toastRef}
        className={style['toast']}
      />
      <div className={style['main']}>
        {/* Steps */}
        <StepsBar step={3} steps={['Upload', 'Map', 'Review']} />
        {/* Add property section */}
        <div className={style['top-section']}>
          <h2>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageTitle}
              tooltipKey='Review & Complete Import'
            >
              <span>Review & Complete Import</span>
            </TooltipWrapper>
          </h2>
          <div className={style['results']}>
            <div className='mappedfile-result'>
              <ul>
                <li className={style['mappedFileColumn1']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Uploaded file'
                  >
                    <span>Uploaded file</span>
                  </TooltipWrapper>
                  <p>
                    <i className={'icon'}></i>
                    <span className={style['mappedFileName']}>{fileName}</span>
                  </p>
                </li>
                <li className={style['mappedFileColumn2']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Errors to address'
                  >
                    <span>Errors to address</span>
                  </TooltipWrapper>
                  <span className='block'>
                    {
                      _.filter(propertiesWithStatus, [
                        'status',
                        'Action Required',
                      ]).length
                    }
                  </span>
                </li>
                <li className={style['mappedFileColumn3']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Mapped columns'
                  >
                    <span>Mapped columns</span>
                  </TooltipWrapper>
                  <span className='block'>
                    {_.filter(file, ['status', 'Mapped']).length}
                  </span>
                </li>
                <li className={style['mappedFileColumn4']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Total file columns'
                  >
                    <span>Total file columns</span>
                  </TooltipWrapper>
                  <span className='block'>{PROPERTYFILECOLUMNS.length}</span>
                </li>
                <li className={style['mappedFileColumn5']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Total file rows'
                  >
                    <span>Total file rows</span>
                  </TooltipWrapper>
                  <span className='block'>{returnJSON.length}</span>
                </li>
              </ul>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Remove & Replace file'
              >
                <button
                  onClick={() => {
                    if (id) {
                      setCancellingUploadFile(true);
                      cancelUploadFile(id)
                        .then((d) => {
                          const errorMessage = getErrorMessage(d);
                          if (errorMessage) {
                            toastRef.current?.showErrorToast(errorMessage);
                          } else {
                            toastRef.current?.showSuccessToast(
                              'Remove & Replace file successfully',
                            );
                            navigate('/properties/propertyUpload');
                          }
                        })
                        .catch((e) => {
                          toastRef.current?.showErrorToast(getErrorMessage(e));
                          setCancellingUploadFile(false);
                        });
                    }
                  }}
                  className='primary-link'
                  disabled={cancellingUploadFile}
                >
                  Remove & Replace file
                  {cancellingUploadFile ? (
                    <LoaderIndicator
                      className='button-loading'
                      loading={true}
                    />
                  ) : null}
                </button>
              </TooltipWrapper>
            </div>
          </div>
        </div>
        {/* Added Properties */}
        <div className={style['section']}>
          <ValidatePropertiesTable
            dataList={_.orderBy(propertiesWithStatus, 'id')}
            standardColumns={_.map(PROPERTYFILECOLUMNS, 'propertyData')}
            checkedColumns={[
              'Import status',
              'Company Name',
              'Company Number',
              'Legal Entity Name',
              'Legal Entity Number',
              'Property Number',
              'State',
              'Account Number',
              'Assessor',
            ]}
            additionColumn={['Import status']}
            mappedKeys={PROPERTIESFILETOFIELD}
            type='property'
            url={`editUploadProperty/${id}`}
            onDeleteItem={deleteItem}
            loading={fetchingData || validatingData}
          />
          <div className={style['buttons']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Import properties'
            >
              <button
                className='primary'
                onClick={() => {
                  if (
                    _.isEqual(
                      _.filter(propertiesWithStatus, [
                        'status',
                        'Action Required',
                      ]).length,
                      0,
                    ) &&
                    !_.isEqual(
                      _.filter(propertiesWithStatus, ['status', 'Ready'])
                        .length,
                      0,
                    )
                  ) {
                    const values = {
                      items: clearProperties(propertiesWithStatus),
                      uploadedFileId: _.toNumber(id),
                    };
                    setUploadingData(true);
                    uploadProperty(values)
                      .then((e: AxiosResponse<IErrorBase>) => {
                        const errorMessage = getErrorMessage(e);
                        if (errorMessage) {
                          setToastMessage({
                            visible: true,
                            message: errorMessage,
                            type: 'error',
                          });
                        } else {
                          appDispatch(getDashboardInfo());
                          navigate('/properties?tab=Properties+and+Assessor+Accounts');
                        }
                        setUploadingData(false);
                      })
                      .catch((e) => {
                        toastRef.current?.showErrorToast(getErrorMessage(e));
                        setUploadingData(false);
                      });
                  } else {
                    setToastMessage({
                      ...(toastMessage as IToastMessage),
                      visible: true,
                    });
                  }
                }}
                disabled={uploadingData || !properties.length}
              >
                Import properties
                {uploadingData ? (
                  <LoaderIndicator className='button-loading' loading={true} />
                ) : null}
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Back'
            >
              <button
                className='secondary'
                onClick={() => navigate(`/properties/propertyMapFile/${id}`)}
              >
                Back
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Cancel import'
            >
              <button
                className='secondary'
                onClick={() => {
                  if (id) {
                    setCancellingUploadFile(true);
                    cancelUploadFile(id)
                      .then(() => {
                        navigate('/properties?tab=Properties+and+Assessor+Accounts');
                      })
                      .then(() => {
                        setCancellingUploadFile(false);
                      });
                  }
                }}
                disabled={cancellingUploadFile}
              >
                Cancel import
                {cancellingUploadFile ? (
                  <LoaderIndicator className='button-loading' loading={true} />
                ) : null}
              </button>
            </TooltipWrapper>
          </div>
        </div>
      </div>

      <Loader isOpen={deletingItem} />
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setStoreProperties: (value: FormikValues | undefined) =>
    dispatch(actions.setProperties(value)),
});

export default connect(null, mapDispatchToProps)(PropertyReview);
