import React, {useMemo} from 'react';
import _ from 'lodash';
import {IBreadCrumb} from '../../interface';
import {Breadcrumbs, BreadcrumbItem} from '@avalara/skylab-react';
import style from './Breadcrumb.module.scss';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

interface Props {
  items: IBreadCrumb[];
  className?: string;
}

/* Breadcrumb of company & property & owner setup page */
function Breadcrumb(props: Props) {
  const navigate = useNavigate();
  const navtems: BreadcrumbItem[] = useMemo(
    () =>
      _.map(props.items, (item, i) => ({
        label: item.value,
        url: item.href,
        dataRef: i.toString(),
        emitNavEvent: true,
      })),
    [props.items],
  );

  return (
    <div className={classNames(style['breadcrumb'], props.className)}>
      <Breadcrumbs
        navItems={navtems}
        onSNavigate={(e: CustomEvent<BreadcrumbItem>) => navigate(e.detail.url as string)}
      ></Breadcrumbs>
    </div>
  );
}

export default Breadcrumb;
