import classNames from 'classnames';
import React, {useState} from 'react';
import {Outlet} from 'react-router-dom';
import Header from '../../components/Header/Header';
import Navigation from '../../components/Navigation/Navigation';
import {EUserRoles} from '../../enums';
import useUserProfile from '../../hook/useUserProfile';
import useTooltips from '../../hook/useTooltips';
import useStates from '../../hook/useStates';
import useDashboardInfo from '../../hook/useDashboardInfo';
import Loader from '../../components/Loader/Loader';
import { MaintenanceNotificationResponse, ReduxState } from '../../interface';
import useMaintenanceNotifications from '../../hook/useMaintenanceNotifications';
import { connect } from 'react-redux';

interface Props {
  maintenanceNotifications: MaintenanceNotificationResponse;
}

function Landing(props: Props) {

  const {dashboardInfoLoading} = useDashboardInfo();
  const [collapsed, setcollapsed] = useState<boolean>(false);
  const profile = useUserProfile();
  const userRoles = profile?.roles;
  const isOnlySystemAdmin =
    userRoles &&
    userRoles.length === 1 &&
    userRoles[0] === EUserRoles.SystemAdministrator;

  // Get tooltips once at app loading
  useTooltips();
  // Get states once at app loading
  useStates();
  // Get states once at app loading
  useMaintenanceNotifications();

  return (
      <div
        className={classNames('main-layout', {
          collapsed: collapsed,
          ['no-nav']: isOnlySystemAdmin,
          ['impersonate']: !!sessionStorage.getItem('user-account-id'),
          ['not-completed']: !profile?.userAccount?.setupCompleted,
          ['has-notifications']: props.maintenanceNotifications?.items?.length > 0
        })}
      >
        <Header profile={profile} maintenanceNotifications={props.maintenanceNotifications} />
        {!isOnlySystemAdmin && (
          <Navigation clickHandle={() => setcollapsed(!collapsed)} />
        )}
        <Outlet />

        <Loader isOpen={!!dashboardInfoLoading} />
      </div>
  );

}

const mapStateToProps = (state: ReduxState) => ({
  maintenanceNotifications: state.maintenanceNotifications,
});

export default connect(mapStateToProps)(Landing);
