import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { EUserRoles, TOOLTIP_SECTIONS } from '../../enums';
import useUserProfile from '../../hook/useUserProfile';
import { TaxReturnProcess } from '../../interface';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import style from './PrimaryPanels.module.scss';

interface Props {
  data: TaxReturnProcess | undefined;
}

function PrimaryPanels(props: Props) {
  const {data} = props;
  const navigate = useNavigate();
  const userRoles = useUserProfile()?.roles || [];

  return (
    <div className={style['blocks']}>
      <TooltipWrapper
        tooltipSection={TOOLTIP_SECTIONS.Card}
        tooltipKey='Properties'
      >
        <button
          role='button'
          disabled={!_.includes(userRoles, EUserRoles.AccountPropertyModerator)}
          className={classNames(style['block'])}
          onClick={() => navigate('/properties?tab=Companies')}
        >
          <i className={classNames(style['properties'])}>Properties</i>
        </button>
      </TooltipWrapper>
      <TooltipWrapper
        tooltipSection={TOOLTIP_SECTIONS.Card}
        tooltipKey='Assets'
      >
        <button
          role='button'
          disabled={
            !_.includes(userRoles, EUserRoles.AccountAssetModerator) ||
            !data?.companies ||
            !data.properties
          }
          className={classNames(style['block'])}
          onClick={() => navigate('/assets')}
        >
          <i className={classNames(style['assets'])}>Assets</i>
        </button>
      </TooltipWrapper>
      <TooltipWrapper tooltipSection={TOOLTIP_SECTIONS.Card} tooltipKey='Forms'>
        <button
          role='button'
          disabled={
            !_.includes(userRoles, EUserRoles.AccountFormModerator) ||
            !data?.companies ||
            !data?.properties ||
            !data?.assets
          }
          className={classNames(style['block'])}
          onClick={() => navigate('/forms')}
        >
          <i className={classNames(style['forms'])}>Forms</i>
        </button>
      </TooltipWrapper>
      <TooltipWrapper
        tooltipSection={TOOLTIP_SECTIONS.Card}
        tooltipKey='Returns'
      >
        <button
          role='button'
          disabled={
            !_.includes(userRoles, EUserRoles.AccountReturnModerator) ||
            !data?.companies ||
            !data?.properties ||
            !data?.assets ||
            !data?.mapAssetClasses
          }
          className={classNames(style['block'])}
          onClick={() => navigate('/returns')}
        >
          <i className={classNames(style['returns'])}>Returns</i>
        </button>
      </TooltipWrapper>
    </div>
  );
}

export default PrimaryPanels;
