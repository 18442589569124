import {createSlice, createAsyncThunk, isAnyOf} from '@reduxjs/toolkit';

import {TaxtInfor} from '../../interface';
import {getDashboardInfor} from '../../services';

interface IDashboardInfoSlice extends TaxtInfor {
  dashboardInfoLoading: boolean;
}

const initialState: Partial<IDashboardInfoSlice> = {
  dashboardInfoLoading: false,
};

const dashboardInfoSlice = createSlice({
  name: 'dashboardInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboardInfo.pending, (state) => {
      state.dashboardInfoLoading = true;
    });
    builder.addMatcher(
      isAnyOf(getDashboardInfo.fulfilled, getDashboardInfo.rejected),
      (state, action) => {
        state = {...state, ...(action.payload || {})};
        state.dashboardInfoLoading = false;
        return state;
      },
    );
  },
});

export const getDashboardInfo = createAsyncThunk(
  'getDashboardInfo',
  async () => {
    const selectedTaxYear = sessionStorage.getItem('selected-tax-year');
    return (await getDashboardInfor(selectedTaxYear || '')).data;
  },
  {
    condition: (_a, {getState}) => {
      const {dashboardInfoLoading} = getState() as IDashboardInfoSlice;
      return !dashboardInfoLoading;
    },
  },
);

export default dashboardInfoSlice;
