import classNames from 'classnames';
import React from 'react';
import style from './SearchInput.module.scss';

interface Props {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

//Search input from properties page
function SearchInput(props: Props) {
  const {placeholder, value, onChange, className} = props;
  return (
    <div className={classNames(className, style['form-control'])}>
      <i></i>
      <input
        type='search'
        placeholder={placeholder}
        value={value}
        onChange={(evt) => onChange(evt.target.value)}
      />
    </div>
  );
}

export default SearchInput;
