import _ from 'lodash';
import {PICKACCOUNTFILED, PICKPROPERTYFIELD} from '../constants';
import {ExtProperty} from '../interface';

function clearProperties(properties: ExtProperty[]): ExtProperty[] {
  return properties.map((value) => {
    const withoutAccount = _.pick(value, PICKPROPERTYFIELD);
    const assessorAccounts = value.assessorAccounts && value.assessorAccounts.map((account) =>
      _.pick(account, PICKACCOUNTFILED),
    );
    const result = {...withoutAccount, assessorAccounts};
    return result as ExtProperty;
  });
}

export default clearProperties;
