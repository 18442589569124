import React, {useState, useEffect} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import _ from 'lodash';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import {
  IToastMessage,
  Property,
  AssessorAccount,
  Company,
  ILegalEntity,
  Tab,
} from '../../../interface';
import style from './Properties.module.scss';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../enums';
import Tabs from '../../../components/Tabs/Tabs';
import {PROPERTIES} from '../../../constants';
import CompanyTable from '../../../components/CompanyTable/CompanyTable';
import {getAccountSummary} from '../../../services';
import LegalEntityTable from '../../../components/LegalEntityTable/LegalEntityTable';
import PropertyTable from '../../../components/PropertyTable/PropertyTable';
import useSelectedSystemTaxYear from '../../../hook/useSelectedSystemTaxYear';

interface Entity extends Property {
  assessorAccount: AssessorAccount;
}

interface LegalEntity extends ILegalEntity {
  company: Company;
}

function Properties() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [type, setType] = useState<string>(searchParams.get('tab') as string);
  const [properties, setProperties] = useState<Entity[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [legalEntities, setLegalEntities] = useState<LegalEntity[]>([]);
  const [companyCount, setCompanyCount] = useState<number>(0);
  const [fetchingData, setFetchingData] = useState(false);
  const selectedSystemTaxYear = useSelectedSystemTaxYear();

  function initialAccountSummary() {
    setFetchingData(true);
    getAccountSummary()
      .then((d) => {
        const groupByProperties = _.map(
          _.map(d.data.properties, (_property) => ({
            ..._property,
            company: _.find(d.data.companies, ['id', _property.companyId]),
            legalEntity: _.find(d.data.legalEntities, [
              'id',
              _property.legalEntityId,
            ]),
            // CR-7389: only display assessor accounts for selected tax year
            assessorAccounts: _.filter(d.data.assessorAccounts, (assessorAccount) => {
              return assessorAccount.propertyId === _property.id
                     && assessorAccount.taxYear == selectedSystemTaxYear.taxYear;
            }),
          })),
          (property) =>
            property.assessorAccounts.length
              ? _.map(property.assessorAccounts, (assessorAccount) => ({
                  ...property,
                  assessorAccount: assessorAccount,
                }))
              : [{...property, assessorAccount: []}],
        );
        const groupByLegalEntities = _.map(
          d.data.legalEntities,
          (legalEntity) => ({
            ...legalEntity,
            company: _.find(d.data.companies, ['id', legalEntity.companyId]),
          }),
        );
        setProperties(_.flatMap(groupByProperties));
        setCompanies(d.data.companies);
        setLegalEntities(groupByLegalEntities);
        setCompanyCount(d.data.companies.length);
        setFetchingData(false);
      })
      .catch(() => {
        setFetchingData(false);
      });
  }

  useEffect(() => {
    initialAccountSummary();
  }, []);

  useEffect(() => {
    companyCount
      ? setTabs(_.map(PROPERTIES, (tab) => ({name: tab, disabled: false})))
      : setTabs(
          _.map(PROPERTIES, (tab) =>
            tab === 'Companies'
              ? {name: tab, disabled: false}
              : {name: tab, disabled: true},
          ),
        );
  }, [companyCount]);

  useEffect(() => {
    switch (searchParams.get('tab')) {
      case 'Legal Entities':
        setType(PROPERTIES[1]);
        break;
      case 'Properties and Assessor Accounts':
        setType(PROPERTIES[2]);
        break;
      default:
        setType(PROPERTIES[0]);
        break;
    }
  }, []);

  useEffect(() => {
    setSearchParams(`?tab=${type}`, {replace: true});
  }, [type]);

  // Breadcrumb, dashboard depend on user skip or not, property link depend where is the page from
  const breadcrumb = [
    {
      value: 'Properties',
      href: '/properties',
    },
    {value: 'Property Setup', href: '/properties/propertyUpload'},
    {value: 'Manage Properties'},
  ];

  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  return (
    <>
      <div className={style['main-content']}>
        {/* Braedcrumb */}
        <Breadcrumb items={breadcrumb} />
        {
          // ignore in coverage report because toastMessage is always undefined
          /* istanbul ignore next */ toastMessage?.visible && (
            <div className={style['toast']}>
              <ToastMessage
                status={toastMessage}
                className='validate-error'
                visiableHandler={(value) =>
                  setToastMessage({ ...toastMessage, visible: value })
                }
              />
            </div>
          )
        }
        <h2>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageTitle}
            tooltipKey='Manage Properties'
          >
            <span>Manage Properties</span>
          </TooltipWrapper>
        </h2>
        <div className={style['main']}>
          <Tabs tabs={tabs} selected={type} onSelect={setType}>
            {_.isEqual(type, PROPERTIES[0]) ? (
              <CompanyTable data={companies} loading={fetchingData} />
            ) : _.isEqual(type, PROPERTIES[1]) ? (
              <LegalEntityTable data={legalEntities} loading={fetchingData} />
            ) : (
              <PropertyTable data={properties} loading={fetchingData} />
            )}
          </Tabs>
          <div className={style['buttons']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Return to dashboard'
            >
              <Link className='button primary' to='/dashboard'>
                Return to dashboard
              </Link>
            </TooltipWrapper>
          </div>
        </div>
      </div>
    </>
  );
}

export default Properties;
