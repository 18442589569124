/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import { useLocation, Link } from "react-router-dom";
import {TOOLTIP_SECTIONS} from '../../enums';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import style from './Selection.module.scss';

export interface SelectionItem {
  id?: number | string;
  name: string;
  to?: string;
  startsWith?: string;
  callback?: (id: number | string) => void;
}

interface Props {
  title: string;
  selectionList: SelectionItem[];
  headerClass?: string;
  selectedItem?: SelectionItem;
}

function Selection({title, selectionList, headerClass, selectedItem}: Props) {
  const location = useLocation();

  const [selected, setSelected] = useState(location.pathname);

  useEffect(() => {
    if (location.pathname.startsWith('/admin/tooltip')) {
      setSelected('/admin');
    } else {
      setSelected(location.pathname)
    }
  }, [location.pathname])

  return (
    <div className={style['selection']}>
      <div className={classNames(style['title'], headerClass)}>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.Common}
          tooltipKey={title}
        >
          <span>{title}</span>
        </TooltipWrapper>
      </div>
      <div className={style['links']}>
        {selectionList?.length > 0 &&
          selectionList.map((item, index) => (
            <Link
              key={index}
              to={item.to || ''}
              className={classNames(
                style['item'],
                item.to &&
                selected &&
                (
                  (selected === item.to) ||
                  (item.startsWith && selected.startsWith(item.startsWith))
                ) &&
                style['selected'],
                item.id && item.id === selectedItem?.id && style['selected'],
              )}
              onClick={(e) => {
                if (item.to) {
                  setSelected(item.to);
                } else if (item.callback && item.id) {
                  e.preventDefault();
                  item.callback(item.id);
                }
              }}
            >
              {item.name}
            </Link>
          ))}
      </div>
    </div>
  );
}

export default Selection;
