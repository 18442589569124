import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import {AssessorAccount, Property} from '../../interface';
import InitTable, {Query} from '../Table/Table';
import TableMenu, {MenuItem} from '../TableMenu/TableMenu';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../enums';
import style from './PropertyTable.module.scss';

interface Entity extends Property {
  assessorAccount: AssessorAccount;
}

interface Props {
  data: Entity[];
  loading: boolean;
}

const {Table, Column, TopBar} = InitTable<Entity>();

function PropertyTable(props: Props) {
  const {data, loading} = props;
  const navigate = useNavigate();
  const [query, setQuery] = useState<Query>();
 
  function formatNameAndNumber(name?: string, number?: string) {
    return _.compact([name, number && `${number}`]).join(' | ');
  }

  function getMenuItems(property: Entity) {
    const editCompany = property.companyId && {
      'Edit Company': () => {
        navigate(`/properties/editCompany/${property.companyId}`);
      },
    };
    const editLegalEntity = property.legalEntityId && {
      'Edit Legal Entity': () => {
        navigate(`/properties/editLegalEntity/${property.legalEntityId}`, {
          state: location.pathname + '?tab=Legal+Entities',
        });
      },
    };
    const editProperty = property.id && {
      'Edit Property': () => {
        navigate(`/properties/editProperty/${property.id}`);
      },
    };
    const actions = [_.assign({}, editCompany, editLegalEntity, editProperty)];
    return [
      _.map(
        _.assign({}, ..._.compact(actions)),
        (onClick, label) =>
          ({
            variant: 'default',
            tooltip: {
              section: TOOLTIP_SECTIONS.TableRowAction,
              keyword: label,
            },
            label,
            onClick,
          } as MenuItem),
      ),
    ];
  }

  function fetchData(query?: Query) {
    setQuery(query);
  }

  function searchData(data: Entity[], value: string | undefined) {
    if (value) {
      const escaped = _.escapeRegExp(value);
      return _.filter(
        data,
        (item) =>
          new RegExp(escaped, 'i').test(item.name as string) ||
          new RegExp(escaped, 'i').test(item.number as string) ||
          new RegExp(escaped, 'i').test(item.assessorAccount?.name as string) ||
          new RegExp(escaped, 'i').test(item.assessorAccount?.number as string) ||
          new RegExp(escaped, 'i').test(item.company?.name as string) ||
          new RegExp(escaped, 'i').test(item.company?.number as string) ||
          new RegExp(escaped, 'i').test(item.legalEntity?.name as string) ||
          new RegExp(escaped, 'i').test(item.legalEntity?.number as string) ||
          new RegExp(escaped, 'i').test(item.address.state?.abbreviation as string)
      );
    } else {
      return data;
    }
  }
  return (
    <div className={style['table-layout']}>
      <Table
        paginate
        id='manageProperties'
        rows={
          _.nth(
            _.chunk(
              searchData(
                _.orderBy(
                  data,
                  _.last(_.keys(query?.order)),
                  _.last(_.values(query?.order)),
                ),
                query?.search,
              ),
              query?.pagination?.pageSize,
            ),
            (query?.pagination?.page as number) - 1,
          ) as Entity[]
        }
        loading={loading}
        onQueryChanged={fetchData}
        sortable
        searchable='Search properties...'
        totalRows={searchData(data, query?.search).length}
      >
        <TopBar>
          <div className={style['operate']}>
          <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.TableAction}
              tooltipKey='Add Property'
            >
              <Link
                className='button primary add-button'
                to='/properties/propertyUpload'
              >
                <i></i>Add Property
              </Link>
            </TooltipWrapper>
          </div>
        </TopBar>
        <Column id='number' label='Property Name & Number'>
          {(property: Entity) =>
            formatNameAndNumber(property.name, property.number)
          }
        </Column>
        <Column id='assessorAccount.number' label='Assessor & Assessor Account Number'>
          {(property: Entity) =>
            formatNameAndNumber(
              property.assessorAccount?.assessor?.name,
              property.assessorAccount?.number,
            )
          }
        </Column>
        <Column id='company.number' label='Company Name & Number'>
          {(property: Entity) =>
            formatNameAndNumber(
              property.company?.name,
              property.company?.number,
            )
          }
        </Column>
        <Column id='legalEntity.number' label='Legal Entity Name & Number'>
          {(property: Entity) =>
            formatNameAndNumber(
              property.legalEntity?.name,
              property.legalEntity?.number,
            )
          }
        </Column>
        <Column id='address.state.abbreviation' label='State'>
          {(property: Entity) =>
            formatNameAndNumber(property.address.state?.abbreviation)
          }
        </Column>
        <Column id='active' label='Status'>
          {(property: Entity) => (
            <span
              className={classNames(
                'badge-status',
                _.kebabCase(property.active ? 'Active' : 'Inactive'),
              )}
            >
              {property.active ? 'Active' : 'Inactive'}
            </span>
          )}
        </Column>
        <Column id='actions' label='Actions' sortable={false}>
          {(property: Entity) => (
            <div className={style['actions-column-cell']}>
              <TableMenu
                toggle={
                  <button type='button'>
                    <i className={style['icon-3-dots']} />
                  </button>
                }
                menuItems={getMenuItems(property)}
              />
            </div>
          )}
        </Column>
      </Table>
    </div>
  );
}

export default PropertyTable;
