import _, { entries, last } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import BaseModal from '../../../components/BaseModal/BaseModal';
import Loader from '../../../components/Loader/Loader';
import Modal from '../../../components/Modal/Modal';
import InitTable, { Query } from '../../../components/Table/Table';
import ToastMessage, {
    ToastMessageRefType,
} from '../../../components/ToastMessage/ToastMessage';
import { MaintenanceNotification, MaintenanceNotificationResponse } from '../../../interface';
import {
    AsyncStatus, getMaintenanceNotificationList,
} from '../../../redux/features/admin-slice';
import { RootState, useAppDispatch } from '../../../redux/store';
import {
    addMaintenanceNotifications,
    deleteMaintenanceNotifications,
    getErrorMessage,
    updateMaintenanceNotifications,
} from '../../../services';
import style from './MaintenanceNotifications.module.scss';
import moment from 'moment';
import MaintenanceNotificationForm from '../../../components/MaintenanceNotificationForm/MaintenanceNotificationForm';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import { TOOLTIP_SECTIONS } from '../../../enums';
import DOMPurify from 'dompurify';
import classNames from 'classnames';

const { Table, Column, TopBar } = InitTable<MaintenanceNotification>();

interface Props {
    maintenanceNotifications: MaintenanceNotificationResponse;
    fetchStatus?: AsyncStatus;
}

function MaintenanceNotifications(props: Props) {
    const { maintenanceNotifications, fetchStatus } = props;
    const toastRef = useRef<ToastMessageRefType | null>(null);

    const dashboardDispatch = useAppDispatch();

    const [toBeDeleted, setToBeDeleted] = useState<MaintenanceNotification>();
    const [toBeEdited, setToBeEdited] = useState<MaintenanceNotification>();
    const [toBeAdded, setToBeAdded] = useState<MaintenanceNotification>();
    const [query, setQuery] = useState<Query>();

    const newInit: MaintenanceNotification = {
        id: undefined,
        startDate: undefined,
        endDate: undefined,
        isActive: false,
        content: ''
    }

    const handleDelete = (value: MaintenanceNotification) => {
        setToBeDeleted(value);
    };

    const datetimeFormat = 'MM/DD/YYYY hh:mm A';

    const queryMaintenanceNotificationList = (queryVal: Query | undefined) => {
        if (queryVal) {
            const sort = last(entries(queryVal?.order));

            dashboardDispatch(
                getMaintenanceNotificationList({
                    page: queryVal.pagination?.page ?? 1,
                    perPage: queryVal.pagination?.pageSize ?? 10,
                    sortBy: sort ? sort[0] : '',
                    sortDirection: sort ? sort[1] : '',
                    text: queryVal.search ?? '',
                    onlyActive: false
                }),
            );
        }
    };

    useEffect(() => {
        queryMaintenanceNotificationList(query)
    }, [query]);

    const data = useMemo(
        () => maintenanceNotifications.items,
        [maintenanceNotifications, query?.search, query?.filter, query?.order],
    );
    const [submitting, setSubmitting] = useState(false);
    const [updateErrorMessage, setUpdateErrorMessage] = useState('');

    const handleSubmit = (notification: MaintenanceNotification) => {
        setSubmitting(true);
        setUpdateErrorMessage('');
        if (notification.id) {
            updateMaintenanceNotifications(notification.id || 0, {
                startDate: notification.startDate,
                endDate: notification.endDate,
                content: notification.content
            })
                .then((resp) => {
                    const errorMessage = getErrorMessage(resp);
                    if (errorMessage) {
                        toastRef.current?.showErrorToast(errorMessage);
                    } else {
                        toastRef.current?.showSuccessToast('Update successfully');
                        queryMaintenanceNotificationList(query);
                    }
                    setSubmitting(false);
                    setToBeEdited(undefined);
                })
                .catch((err) => {
                    setSubmitting(false);
                    const errMessage = getErrorMessage(err);
                    if (errMessage) {
                        setUpdateErrorMessage(errMessage);
                    } else {
                        setToBeEdited(undefined);
                    }
                });
        }
        else {
            addMaintenanceNotifications(notification)
                .then((resp) => {
                    const errorMessage = getErrorMessage(resp);
                    if (errorMessage) {
                        toastRef.current?.showErrorToast(errorMessage);
                    } else {
                        toastRef.current?.showSuccessToast('Update successfully');
                        queryMaintenanceNotificationList(query);
                    }
                    setSubmitting(false);
                    setToBeAdded(undefined);
                })
                .catch((err) => {
                    setSubmitting(false);
                    const errMessage = getErrorMessage(err);
                    if (errMessage) {
                        setUpdateErrorMessage(errMessage);
                    } else {
                        setToBeAdded(undefined);
                    }
                });
        }
    };

    return (
        <>
            <div className={style['main-content']}>
                {/* Breadcrumb */}
                <h3>Maintenance Notifications</h3>
                <ToastMessage ref={toastRef} className={style['toast']} />

                <Table
                    id='maintenanceNotifications'
                    rows={data}
                    onQueryChanged={setQuery}
                    paginate
                    searchable='Search maintenance notifications...'
                    totalRows={maintenanceNotifications.total}
                    loading={false}
                >
                    <TopBar>
                        <div className={style.operate}>
                            <TooltipWrapper
                                tooltipSection={TOOLTIP_SECTIONS.TableAction}
                                tooltipKey='Add Notification'
                            >
                                <button
                                    className='primary add-button'
                                    onClick={() => setToBeAdded(_.cloneDeep(newInit))}
                                >
                                    <i></i>Add Notification
                                </button>
                            </TooltipWrapper>
                        </div>
                    </TopBar>
                    <Column label='' id='isActive' sortable>
                        {(notification: MaintenanceNotification) => (
                            <span className={classNames('badge-status', (notification.isActive ? 'active' : 'default'))}>
                                {notification.isActive ? 'Active' : 'Inactive'}
                            </span>
                        )}
                    </Column>
                    <Column label='Notification Start' id='startDate' sortable>
                        {(notification: MaintenanceNotification) => (
                            <div>
                                {moment(notification.startDate).local().format(datetimeFormat)}
                            </div>
                        )}
                    </Column>
                    <Column label='Notification End' id='endDate' sortable>
                        {(notification: MaintenanceNotification) => (
                            <div>
                                {moment(notification.endDate).local().format(datetimeFormat)}
                            </div>
                        )}
                    </Column>
                    <Column label='Text Content' id='shortContent' sortable>
                        {(notification: MaintenanceNotification) => (
                            <div
                                className={style['content']}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(notification.shortContent || ''),
                                }}
                            ></div>
                        )}
                    </Column>
                    

                    <Column id='actions' label='Actions'>
                        {(item: MaintenanceNotification) => (
                            <div className='operate'>
                                <i
                                    role='button'
                                    className='edit'
                                    onClick={async () => {
                                        const editable = maintenanceNotifications.items.find(
                                            (x) => x.id === item?.id,
                                        );
                                        if (editable) {
                                            setToBeEdited({ ...editable });
                                        }
                                    }}
                                ></i>
                                <i
                                    role='button'
                                    className='delete'
                                    onClick={() => handleDelete(item)}
                                ></i>
                            </div>
                        )}
                    </Column>
                </Table>
            </div>

            <Modal
                title='Confirmation'
                body={<p>Are you sure you want to delete?</p>}
                isOpen={!!toBeDeleted}
                footer={
                    <div className='buttons'>
                        <button
                            className='primary-button'
                            onClick={() => {
                                const item = maintenanceNotifications.items.find(
                                    (x) => x.id === toBeDeleted?.id,
                                );
                                if (item && item.id) {
                                    deleteMaintenanceNotifications(item.id).then((e) => {
                                        const errorMessage = getErrorMessage(e);
                                        if (errorMessage) {
                                            toastRef.current?.showErrorToast(errorMessage);
                                        } else {
                                            toastRef.current?.showSuccessToast('Successfully deleted');
                                        }
                                        queryMaintenanceNotificationList(query);
                                    });
                                }
                                setToBeDeleted(undefined);
                            }}
                        >
                            Confirm
                        </button>
                        <button
                            className='default-button'
                            onClick={() => setToBeDeleted(undefined)}
                        >
                            Cancel
                        </button>
                    </div>
                }
            />
            {/* Edit notification modal */}
            <BaseModal isOpen={!!toBeEdited} className={style['modal']}>
                <div className={style['modal-wrapper']}>
                    <h3>Edit Notification</h3>
                    {toBeEdited && (
                        <MaintenanceNotificationForm
                            inEditMode
                            data={toBeEdited}
                            onSubmit={handleSubmit}
                            submitting={submitting}
                            sectionClassName={style['section']}
                            formClassName={style['form']}
                            onClear={() => {
                                setToBeEdited(undefined);
                            }}
                            innerModal={true}
                            errorMessage={updateErrorMessage}
                        />
                    )}
                </div>
            </BaseModal>

            {/* Add notification modal */}
            <BaseModal isOpen={!!toBeAdded} className={style['modal']}>
                <div className={style['modal-wrapper']}>
                    <h3>Add Notification</h3>
                    {toBeAdded && (
                        <MaintenanceNotificationForm
                            inEditMode
                            data={_.cloneDeep(newInit)}
                            onSubmit={handleSubmit}
                            submitting={submitting}
                            sectionClassName={style['section']}
                            formClassName={style['form']}
                            onClear={() => {
                                setToBeAdded(undefined);
                            }}
                            innerModal={true}
                            errorMessage={updateErrorMessage}
                        />
                    )}
                </div>
            </BaseModal>

            <Loader isOpen={fetchStatus === 'pending'} />
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    fetchStatus: state.admin?.fetchStatus,
    maintenanceNotifications: state.admin?.maintenanceNotifications,
});

export default connect(mapStateToProps)(MaintenanceNotifications);
