import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DepreciationScheduleCollection, IBackendDepreciationScheduleCollectionUpdate, IToastMessage, SelectOption, State } from '../../../interface';
import { createDepreciationScheduleCollection, getDepreciationScheduleCollection, getErrorMessage, getSystemTaxYears, updateDepreciationScheduleCollection,  } from '../../../services';
import { DepreciationScheduleType, TOOLTIP_SECTIONS } from '../../../enums';
import _ from 'lodash';
import { FormikProps, FormikValues, useFormik } from 'formik';
import validateEmpty from '../../../utils/validateEmpty';
import Loader from '../../../components/Loader/Loader';
import DiscardChangeConfirmModal from '../../../components/DiscardChangeConfirmModal/DiscardChangeConfirmModal';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import Divider from '../../../components/Divider/Divider';
import style from './DepreciationCollectionManagementDetails.module.scss';
import classNames from 'classnames';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import FieldInput from '../../../components/FieldInput/FieldInput';
import FieldSelect from '../../../components/FieldSelect/FieldSelect';
import { useAppSelector } from '../../../redux/store';
import DepreciationScheduleManagementTable from '../../../components/DepreciationScheduleManagementTable/DepreciationScheduleManagementTable';

interface Props {
  className?: string;
  isAddNew?: boolean;
}

interface DepreciationCollectionFormData {
  id: number | null;
  name: string;
  stateId: number | null;
  type: DepreciationScheduleType;
  taxYear: number | null;
}

const newInit: DepreciationCollectionFormData = {
  id: null,
  name: '',
  stateId: null,
  type: DepreciationScheduleType.Composite,
  taxYear: null,
};

function DepreciationCollectionManagementDetails({
  className,
  isAddNew
}: Props) {
  const navigate = useNavigate();
  const [backendDepreciationCollection, setBackendDepreciationCollection] = useState<DepreciationScheduleCollection | null>(null);
  const [initialDepreciationCollectionValues, setInitialDepreciationCollectionValues] = useState<IBackendDepreciationScheduleCollectionUpdate>(newInit);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [activeTaxYear, setActiveTaxYear] = useState<number | null>(null);
  const [taxYearOptions, setTaxYearOptions] = useState<SelectOption[]>([]);
  const stateOptions = useAppSelector(state => (state.states ?? []).map((o: State) => ({
    value: o.id,
    label: o.abbreviation,
    name: o.name,
  })));
  const depreciationTypeOptions = Object.values(DepreciationScheduleType).map(depreciationType => ({
    value: depreciationType.toString(),
    label: depreciationType.toString(),
    name: depreciationType.toString(),
  }));

  const params = useParams();
  const [fetchingDepreciationScheduleCollectionInfo, setFetchingDepreciationScheduleCollectionInfo] = useState(false);
  const [fetchingAllTaxYears, setFetchingAllTaxYears] = useState(false);
  const [savingDepreciationCollection, setSavingDepreciationCollection] = useState(false);
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const shouldShowError = useMemo(() => isSubmitted, [isSubmitted]);
  const getFormErrorMessage = (key: keyof IBackendDepreciationScheduleCollectionUpdate) => {
    return shouldShowError ? formikDepreciationCollectionForm?.errors?.[key] : undefined;
  }

  const showSuccess = () => {
    window.scrollTo(0, 0);
    setToastMessage({
      message: 'Depreciation Collection details saved successfully',
      visible: true,
      type: 'success',
    });
  }
  const showErrorMessage = (message: string) => {
    window.scrollTo(0, 0);
    setToastMessage({
      message,
      visible: true,
      type: 'error',
    });
  }

  useEffect(() => {
    if (
      params.id &&
      !fetchingDepreciationScheduleCollectionInfo &&
      activeTaxYear !== null
    ) {
      setFetchingDepreciationScheduleCollectionInfo(true);
      getDepreciationScheduleCollection(_.toNumber(params.id))
        .then((d) => {
          const depreciationCollectionData = d.data;
          if (!_.isEmpty(depreciationCollectionData)) {
            setInitialDepreciationCollectionValues(depreciationCollectionData);
            setBackendDepreciationCollection(depreciationCollectionData);
            formikDepreciationCollectionForm.validateForm();
          }
          setFetchingDepreciationScheduleCollectionInfo(false);
        })
        .catch(() => {
          setFetchingDepreciationScheduleCollectionInfo(false);
        });
    }
  }, [
    params,
    activeTaxYear
  ]);

  useEffect(() => {
    setFetchingAllTaxYears(true);
    getSystemTaxYears()
      .then((res) => {
        setFetchingAllTaxYears(false);
        const allActiveTaxYears = _.orderBy(
          res.data,
          ['taxYear'],
          ['desc'],
        );

        let currentTaxYearIndex = _.findIndex(allActiveTaxYears, {
          isCurrent: true,
        });
        if (currentTaxYearIndex < 0) {
          currentTaxYearIndex = 0;
        }
        setActiveTaxYear(allActiveTaxYears[currentTaxYearIndex].taxYear);
        setTaxYearOptions(allActiveTaxYears.map(item => ({
          value: item.taxYear.toString(),
          label: item.taxYear.toString(),
          name: item.taxYear.toString(),
        })));

        if (isAddNew) {
          setInitialDepreciationCollectionValues(newInit);
        } 
      })
      .catch(() => {
        setFetchingAllTaxYears(false);
      });
  }, [isAddNew]);


  const formikDepreciationCollectionForm: FormikProps<IBackendDepreciationScheduleCollectionUpdate> = useFormik<FormikValues>({
    initialValues: initialDepreciationCollectionValues,
    enableReinitialize: true,
    validate: (values) => {
      const requiredErrors = validateEmpty(_.pick({
        ...values,
      }, [
        'name',
        'type',
        'stateId',
        'taxYear',
      ]));
      return requiredErrors;
    },
    onSubmit: (values) => {
      if (!isDepreciationCollectionFormChanged) {
        return;
      }
      setSavingDepreciationCollection(true);

      if (isAddNew) {
        createDepreciationScheduleCollection(values)
          .then(() => {
            showSuccess();
            setInitialDepreciationCollectionValues(formikDepreciationCollectionForm.values);
            setSavingDepreciationCollection(false);
            setIsSubmitted(false);
            setTimeout(() => {
              navigate('/admin/schedule-collection-management');
            }, 100);
          })
          .catch((e) => {
            showErrorMessage(getErrorMessage(e));
            setSavingDepreciationCollection(false);
            setIsSubmitted(false);
          });
      }
      else {
        updateDepreciationScheduleCollection(backendDepreciationCollection?.id ?? 0, formikDepreciationCollectionForm.values)
        .then(() => {
          setInitialDepreciationCollectionValues(values);
          setSavingDepreciationCollection(false);
          showSuccess();
        })
        .catch((e) => {
          showErrorMessage(getErrorMessage(e));
          setSavingDepreciationCollection(false);
        });
      }
    },
  });

  const isDepreciationCollectionFormChanged = useMemo(() => !_.isEqual(
    initialDepreciationCollectionValues,
    formikDepreciationCollectionForm.values
  ), [
    formikDepreciationCollectionForm.values,
    initialDepreciationCollectionValues
  ]);

  const pageTitle = useMemo(() => {
    if (isAddNew) {
      return 'New Depreciation Schedule Collection';
    }
    return 'Depreciation Schedule Collection Details';
  }, [isAddNew])

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'AssessorManagementDetails d-flex')
    }>
      <div className={classNames('d-flex flex-column flex-1', style.blockLeft)}>
        <Breadcrumb
          items={[
            {
              value: 'Depreciation Schedule Collection Management',
              href: '/admin/schedule-collection-management',
            },
            { value: pageTitle },
          ]}
          className={style.Breadcrumb}
        />
        {toastMessage?.visible && (
          <div className={style.toast}>
            <ToastMessage
              className='successful'
              status={toastMessage}
              visiableHandler={(value) =>
                setToastMessage({ ...toastMessage, visible: value })
              }
            />
          </div>
        )}
        <span className={style.textTitle}>{pageTitle}</span>

        <Divider className={style.divider1} />

        <div className='d-grid grid-4-columns gap-column-25 gap-row-20'>
          <FieldInput
            labelText='Depreciation Collection Name'
            name='name'
            value={formikDepreciationCollectionForm?.values.name ?? ''}
            onChange={(value) => {
              formikDepreciationCollectionForm?.setFieldValue('name', value);
            }}
            error={getFormErrorMessage('name')}
            isInReadOnlyMode={true}
          />
          <FieldSelect
            labelText='State'
            options={stateOptions}
            selectId={formikDepreciationCollectionForm?.values.stateId}
            onSelect={(value) => {
              formikDepreciationCollectionForm?.setFieldValue(
                'stateId',
                value?.value,
              )
            }}
            hideNullOption
            error={getFormErrorMessage('stateId')}
            isInReadOnlyMode={true}
          />
          <FieldSelect
            labelText='Type'
            options={depreciationTypeOptions}
            selectId={formikDepreciationCollectionForm?.values.type}
            onSelect={(value) => {
              formikDepreciationCollectionForm?.setFieldValue(
                'type',
                value?.value,
              )
            }}
            hideNullOption
            error={getFormErrorMessage('type')}
            isInReadOnlyMode={true}
          />
          <FieldSelect
            labelText='Tax Year'
            options={taxYearOptions}
            selectId={taxYearOptions.find(taxYear => taxYear.value == formikDepreciationCollectionForm?.values.taxYear)?.value}
            onSelect={(value) => {
              formikDepreciationCollectionForm?.setFieldValue(
                'taxYear',
                value?.value,
              )
            }}
            hideNullOption
            error={getFormErrorMessage('taxYear')}
            isInReadOnlyMode={true}
          />
        </div>

        {
          !isAddNew && (
            <div>
              <Divider className={style.divider1} strokeWidth={1} />

              <DepreciationScheduleManagementTable setToastMessage={setToastMessage}/>
            </div>
          )
        }
        

        <Divider className={style.divider1} strokeWidth={2} />

        <div className='d-flex gap-20'>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageAction}
            tooltipKey='Back'
          >
            <button
              className='secondary'
              onClick={() => {
                navigate("/admin/schedule-collection-management");
              }}
            >
              Back
            </button>
          </TooltipWrapper>
        </div>
      </div>

      <DiscardChangeConfirmModal
        initialData={1}
        inputData={isDepreciationCollectionFormChanged ? 2 : 1}
      />

      <Loader
        isOpen={
          fetchingAllTaxYears ||
          fetchingDepreciationScheduleCollectionInfo ||
          savingDepreciationCollection
        }
      />
    </div>
  );
}

export default DepreciationCollectionManagementDetails;