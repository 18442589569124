import {useEffect} from 'react';
import _ from 'lodash';
import {getStates} from '../services';
import {useDispatch, useStore} from 'react-redux';
import { fetchAsyncState } from '../redux/reducer';
import { ReduxState } from '../interface';

function useStates() {
  const dispatch = useDispatch();
  const store = useStore();
  useEffect(() => {
    if(_.isEmpty((store.getState() as ReduxState).states)) {
      // eslint-disable-next-line
      dispatch(fetchAsyncState(getStates()) as any);
    }
  }, [fetchAsyncState]);
}

export default useStates;
