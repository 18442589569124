import type { Identifier } from "dnd-core";
import React, { useRef, PropsWithChildren, useEffect } from "react";
import { DragSourceMonitor, useDrag, useDrop } from "react-dnd";

export interface CardProps {
  id: string;
  index: number;
  onMove?: (dragIndex: number, hoverIndex: number) => void;
  onDrop?: (dragIndex: number, hoverIndex: number) => void;
  onCancel?: () => void;
  type: string;
  className?: string;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
  parent: string;
}

function SortableCard({
  id,
  index,
  onMove,
  onDrop,
  onCancel,
  type,
  className,
  children,
}: PropsWithChildren<CardProps>) {
  const ref = useRef<HTMLDivElement>(null);
  const dragIndexRef = useRef(-1);
  const hoverIndexRef = useRef(-1);
  const [{ handlerId, isOver, canDrop}, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null, isOver: boolean, canDrop: boolean }
  >({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      };
    },
    hover(item: DragItem) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      dragIndexRef.current = dragIndex;
      hoverIndexRef.current = hoverIndex;
    },
    // eslint-disable-next-line
    drop(item: DragItem) {
      if (!ref.current) {
        return;
      }
      if (id == item.parent) {
        return
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      onDrop && onDrop(dragIndex, hoverIndex);
    }
  });

  useEffect(() => {
    if (!isOver || !canDrop) {
      dragIndexRef.current = -1;
      hoverIndexRef.current = -1;
      onCancel && onCancel();
    } else if (dragIndexRef.current >= 0 && hoverIndexRef.current >= 0) {
      onMove && onMove(dragIndexRef.current, hoverIndexRef.current);
    }
  }, [isOver, canDrop])


  const [{ isDragging }, drag] = useDrag({
    type: type,
    item: () => {
      return { id, index };
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    // eslint-disable-next-line
    end: (_, monitor: DragSourceMonitor) => {
      if (!monitor.didDrop()) {
        onCancel && onCancel();
      }
    },
  });

  const opacity = isDragging ? 0.3 : 1;
  drag(drop(ref));
  return (
    <div className={className} ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      {children}
    </div>
  );
}

export default SortableCard;
