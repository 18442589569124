import axios, {AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import {LOGOUT} from '../config';
import getBaseURL from '../utils/getBaseURL';
import envConfig from '../envConfig';

//Define global parameter of axios
const axiosInstance = axios.create({
  baseURL: envConfig.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const reqInterceptor = (req: InternalAxiosRequestConfig) => {
  if (sessionStorage.getItem(`user-account-id`)) {
    req.headers.set('user-account-id', sessionStorage.getItem(`user-account-id`) as string);
  }
  return req;
};

const resInterceptor = (res: AxiosResponse) => {
  return res;
};

axiosInstance.interceptors.request.use(reqInterceptor, (err) =>
  Promise.reject(err),
);

axiosInstance.interceptors.response.use(resInterceptor, (err) => {
  if (err?.response?.status === 401) {
    window.location.href = getBaseURL() + LOGOUT;
  }
  return Promise.reject(err?.response);
});

export const apiInstance = axiosInstance;
