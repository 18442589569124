import { Dispatch } from "redux";
import { Action, State } from "./../interface/index";
import actions, { actionTypes } from "./actions";

export const mappedFileReducer = (state = {}, action: Action) => {
  if (action.type === actionTypes.SET_UPLOADFILE) {
    return action.uploadFile ? action.uploadFile : {};
  }
  return state;
};

export const statesReducer = (state: State[] = [], action: Action) => {
  return action.type === actionTypes.SET_STATES ? action.states : state;
};

// eslint-disable-next-line
export const fetchAsyncState = (promise: Promise<any>) => {
  return async (dispatch: Dispatch) => {
    const response = await promise;
    dispatch(actions.setStates(response.data));
  };
};

export const maintenanceNotificationsReducer = (state = [], action: Action) => {
  if (action.type === actionTypes.SET_MAINTENANCENOTIFICATIONS) {
    return action.maintenanceNotifications ? action.maintenanceNotifications : {}
  }

  return state;
};

// eslint-disable-next-line
export const fetchAsyncMaintenanceNotifications = (promise: Promise<any>) => {
  return async (dispatch: Dispatch) => {
    const response = await promise;
    dispatch(actions.setMaintenanceNotifications(response.data));
  };
};

// export const setMaintenanceNotifications = (state = [], action: { payload: any; type: string; }) => {
//   return Object.assign({}, state, action.payload);
// }

export const mappedAssetsFileReducer = (state = {}, action: Action) => {
  if (action.type === actionTypes.SET_UPLOADASSETSFILE) {
    return action.uploadAssetsFile ? action.uploadAssetsFile : {};
  }

  return state;
};

export const propertiesReducer = (state = {}, action: Action) => {
  if (action.type === actionTypes.SET_PROPERTIES) {
    return action.properties ? action.properties : {};
  }

  return state;
};
