import React, {useEffect, useState} from 'react';
import style from './TaxYearSelection.module.scss';
import {IToastMessage, SystemTaxYear} from '../../../../interface';
import ToastMessage from '../../../../components/ToastMessage/ToastMessage';
import cn from 'classnames';
import {useNavigate} from 'react-router-dom';
import TooltipWrapper from '../../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../../enums';
import { getSystemTaxYears } from '../../../../services';
import useSelectedSystemTaxYear from '../../../../hook/useSelectedSystemTaxYear';
import { setSelectedTaxYear } from '../../../../redux/features/selected-tax-year-slice';
import { useAppDispatch } from '../../../../redux/store';
import FieldSelect from '../../../../components/FieldSelect/FieldSelect';
import _ from 'lodash';

function TaxYearSelection() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //Toast message
  const [toastMessage] = useState<IToastMessage>();

  const [systemTaxYears, setSystemTaxYears] = useState<SystemTaxYear[]>([]);
  const selectedSystemTaxYear = useSelectedSystemTaxYear();

  // Component initialization
  useEffect(() => {
    getSystemTaxYears()
      .then(result => {
        if (result.data.length === 0) return;

        // Set options for tax year dropdown
        const activeTaxYears = result.data
          .filter((taxYear: SystemTaxYear) => taxYear.isActive)
          .sort((taxYear1: SystemTaxYear, taxYear2: SystemTaxYear) => {
            if (taxYear1.taxYear < taxYear2.taxYear) {
              return 1;
            }
            if (taxYear1.taxYear > taxYear2.taxYear) {
              return -1;
            }
            return 0;
          });

        setSystemTaxYears(activeTaxYears);

        // Set selected option for tax year dropdown
        if (!selectedSystemTaxYear.taxYear) {
          const currentTaxYears = activeTaxYears.filter((taxYear: SystemTaxYear) => taxYear.isCurrent);
          if (currentTaxYears.length === 0) {
            currentTaxYears.push(activeTaxYears[0]);
          }
          
          dispatch(setSelectedTaxYear({ taxYear: currentTaxYears[0].taxYear }));
        }
      });
  }, []);

  // Update session variable when new tax year is selected
  useEffect(() => {
    if (!selectedSystemTaxYear) return;
    
    sessionStorage.setItem('selected-tax-year', selectedSystemTaxYear?.taxYear?.toString() || '');
  }, [selectedSystemTaxYear])

  return (
    <div className={style['main-content']}>
      <ToastMessage status={toastMessage} />
      <div className={style['main']}>
        <h2>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageTitle}
            tooltipKey='Account Settings'
          >
            <span>Account Settings</span>
          </TooltipWrapper>
        </h2>
        <div className={style['content']}>
          <div className={style['bar']}>
            <div className={style['bar-item-container']}>
              <div className={style['barHeader']}>
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.Common}
                  tooltipKey='Manage Settings'
                >
                  <span>Manage Settings</span>
                </TooltipWrapper>
              </div>
              <div
                className={style['barItem']}
                onClick={() => navigate('/users')}
              >
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.Common}
                  tooltipKey='User Management'
                >
                  <span>User Management</span>
                </TooltipWrapper>
              </div>
              <div className={style['barItem']}
                onClick={() => navigate('/plans')}
              >
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.Common}
                  tooltipKey='Plan & Usage'
                >
                  <span>Plan &amp; Usage</span>
                </TooltipWrapper>
              </div>
              <div className={cn(style['barItem'], style.active)}>
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.Common}
                  tooltipKey='Tax Year Selection'
                >
                  <span>Tax Year Selection</span>
                </TooltipWrapper>
              </div>
            </div>
          </div>
          <div className={style['rightPart']}>
            <div className={style['section']}>
              <div className={style['taxyear-container']}>
                <label>Select a Tax Year:&nbsp;&nbsp;</label>
                <FieldSelect
                  labelText=''
                  selectText={selectedSystemTaxYear?.taxYear?.toString()}
                  options={_.map(
                      systemTaxYears,
                      (systemTaxYear) => ({
                        value: systemTaxYear.taxYear,
                        label: systemTaxYear.taxYear.toString(),
                        name: systemTaxYear.taxYear.toString(),
                      })
                    )
                  }
                  onSelect={selectedTaxYear => {
                    dispatch(setSelectedTaxYear({ taxYear: selectedTaxYear?.value }));
                  }}
                  hideNullOption
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaxYearSelection;