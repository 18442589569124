import _ from "lodash";
import { AssetField } from "../interface";

interface Query {
  [key: string]: unknown | undefined;
}

const filterAssetsQuery = (data: AssetField[], query: Query | undefined) =>
  _.reduce(
    query,
    (_result, value, key) => {
      if (_.isArray(value)) {
        if (!_.isEmpty(value)) {
          return _.reduce(
            value as Array<string>,
            (r, n) => {
              r = _.concat(r, _.filter(_result, [key, n]));
              return _.unionBy(r, 'id');
            },
            [] as AssetField[]
          );
        } else {
          return _result;
        }
      } else {
        if (_.isEqual(key, 'stateId')) {
          return _.filter(_result, [key, value]);
        } else {
          return _.filter(_result, (item) =>
            _.includes(
              _.lowerCase(_.get(item, key)),
              _.lowerCase(value as string)
            )
          );
        }
      }
    },
    data
  );

export default filterAssetsQuery;
