import {useEffect, useState} from 'react';
import _ from 'lodash';
import {read, utils} from 'xlsx';

function useMappedResult(file: File) {
  const [totalProperties, setTotalProperties] = useState<number>(0);
  const [fileColumns, setFileColumns] = useState<string[]>([]);
  const [fileRows, setFileRows] = useState<string[][]>([[]]);
  const handleFile = async (targetFile: File) => {
    // ignore in coverage report because targetFile.name can not be null
    /* istanbul ignore else */
    if (targetFile.name) {
      const fileBuffer = await targetFile.arrayBuffer();
      const workbook = read(fileBuffer);
      const firstSheet = workbook.SheetNames[0];
      const excelRows = utils.sheet_to_json<string[]>(workbook.Sheets[firstSheet], {
        header: 1,
      });
      setTotalProperties(
        utils.sheet_to_json(workbook.Sheets[firstSheet]).length,
      );
      setFileColumns(_.map(_.map(excelRows[0], _.toString), _.trim));
      setFileRows(_.tail(excelRows));
    }
  };
  useEffect(() => {
    !_.isEmpty(file?.name) && handleFile(file);
  }, [file]);
  return {totalProperties, fileColumns, setFileColumns, fileRows, setFileRows};
}

export default useMappedResult;
