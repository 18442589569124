import classNames from "classnames";
import { kebabCase, keys, last, map, values, orderBy } from "lodash";
import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InputType } from "../../../../components/Table/components/Editor/Editor";
import InitTable, { Query } from "../../../../components/Table/Table";
import { FORM_STATUSES } from "../../../../enums";
import useSelectedSystemTaxYear from "../../../../hook/useSelectedSystemTaxYear";
import { ExtCustomForm, State } from "../../../../interface";
import { getFormList } from "../../../../services";
import style from "./FormsFormDetails.module.scss";

const { Table, Column } = InitTable<ExtCustomForm>();

export interface Props {
  variant: "form-details" | "package-content";
  states: State[];
}

function FormsFormDetails({ variant, states }: Props) {
  const navigate = useNavigate();
  const [formList, setFormList] = useState<ExtCustomForm[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [fetchingData, setFetchingData] = useState(false);
  const [query, setQuery] = useState<Query>({});
  const selectedSystemTaxYear = useSelectedSystemTaxYear();

  const fetchData = (query: Query) => {
    setFetchingData(true);
    getFormList({
      page: query.pagination?.page ?? 1,
      perPage: query.pagination?.pageSize ?? 10,
      sortBy: last(keys(query.order)),
      sortDirection: last(values(query.order)),
      text: query.search,
      status: query.filter?.status as string,
      taxYear: selectedSystemTaxYear.taxYear,
      stateId: query.filter?.["form.state.abbreviation"] as string,
    }).then((d) => {
      setFormList(map(d.data.items, (item) => ({ ...item, checked: false })));
      setTotal(d.data.total);
      setFetchingData(false);
    }).catch(() => {
      setFetchingData(false);
    });
  };

  const stateOptions = useMemo(
    () =>
      orderBy(
        states.map(({id, abbreviation}) => ({ label: abbreviation, value: id })),
        (x) => x.label
      ),
    [states]
  );

  // Refetch data when selected tax year changes or filters (query) change
  useEffect(() => {
    fetchData(query);
  }, [selectedSystemTaxYear, query]);

  return (
    <div className={style.main}>
      <Table
        id="formDetails"
        rows={formList}
        onQueryChanged={(query) => setQuery(query)}
        paginate
        sortable
        searchable="Search forms..."
        totalRows={total}
        loading={fetchingData}
      >
        <Column
          label="Status"
          prop="status"
          filterable
          editor={{ type: InputType.Select, options: FORM_STATUSES }}
        >
          {(status: string) => (
            <span className={classNames("badge-status", kebabCase(status))}>
              {status}
            </span>
          )}
        </Column>

        <Column
          label="State"
          id="form.state.abbreviation"
          accessor={(item) => item.form?.state?.abbreviation}
          filterable
          editor={{
            type: InputType.Select,
            options: stateOptions,
          }}
        />

        <Column label="Tax Year" prop="taxYear" />
        <Column
          label="Form Name"
          id="form.name"
          accessor={(item) => item.form?.name}
        />

        <Column label="Number of accounts" prop="numberOfAccounts" />

        <Column id="actions" label="Actions" sortable={false}>
          {(item: ExtCustomForm) => (
            <i
              role="button"
              className={classNames("edit", style["edit"])}
              onClick={() => {
                if (variant === "form-details") {
                  navigate(`./formDetails/${item.id}`);
                } else {
                  navigate(`/forms/packageContents/${item.id}`, {
                    state: { backTrack: true },
                  });
                }
              }}
            ></i>
          )}
        </Column>
      </Table>
    </div>
  );
}

export default FormsFormDetails;
