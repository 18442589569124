import moment from 'moment';

export const formatDate = (date: string | Date | undefined): string => {
  if (date) {
    return moment(date).format('MM/DD/YYYY');
  }

  return '';
}

export const formatHumanDate = (date: string | Date | undefined): string => {
  if (date) {
    return moment(date).format('LL');
  }

  return '';
}

export const formatMoney = (num: string | number | undefined): string => {
  if (typeof num === 'number') {
    return num.toFixed(2);
  } else if (typeof num === 'string') {
    return parseFloat(num).toFixed(2);
  }

  return '';
}
