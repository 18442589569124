import {AxiosResponse} from 'axios';

function readUploadedFile(d: AxiosResponse) {
  const blob = new Blob([d.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  let filename = d.headers['content-disposition'];
  if (
    d.headers['content-disposition'] &&
    d.headers['content-disposition'].indexOf('attachment') !== -1
  ) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(d.headers['content-disposition']);
    if (matches != null && matches[1]) {
      filename = decodeURI(matches[1].replace(/['"]/g, ''));
    }
  }
  const file = new File([blob], filename, {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  return file;
}

export default readUploadedFile;
