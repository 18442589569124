import React, {useState, useEffect} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import _ from 'lodash';
import moment from 'moment';
import 'swiper/css';
import style from './EventsBoard.module.scss';
import {Deadlines, ReduxState, State} from '../../interface';
import {connect} from 'react-redux';
import {Tooltip} from '@avalara/skylab-react';
import { useNavigate } from 'react-router-dom';

interface Props {
  data: Deadlines[] | undefined;
  states: State[];
}

function EventsBoard(props: Props) {
  const {data, states} = props;
  const [deadlines, setDeadlines] = useState<Deadlines[]>();
  const [stateSuccess, setStateSuccess] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if(data && data.length > 0 && states && states.length > 0) {

      setDeadlines(
        _.map(data, (item) => ({
          ...item,
          byStates: _.map(item.byStates, (state) => ({
            ...state,
            state: _.find(states, ['id', state.stateId])?.abbreviation,
          })),
        }))
      );
      setTimeout(() => {
        setStateSuccess(true)
      }, 100);
      setTimeout(() => {
        _.forEach(document.querySelectorAll('s-tooltip.tooltip'), (item) => {
          item.setAttribute('placement', 'top');
        });
      }, 300);
    }
  }, [states, data]);

  return (
    <div className={style['boards']}>
      {data && (
        <Swiper
          cssMode={true}
          navigation={true}
          modules={[Navigation]}
          slidesPerView={'auto'}
          className='mySwiper'
        >
          {/* Swiper items, they are sorted by date if data from BE is des-sorted */}
          {deadlines && _.sortBy(deadlines, ['deadline']).map((item, index) => {
            const deadlineDate = moment(item.deadline);
            const isPastDue = deadlineDate.isBefore(moment());
            return (
              <SwiperSlide
                key={index}
                className={isPastDue ? style['past-due'] : style['upcoming']}
                onClick={() => navigate(`/returns?deadline=${item.deadline}`)}
              >
                <div
                  className={style['inner']}
                  id={`tooltip-trigger-event-${index}`}
                  aria-describedby={`tooltip-event-${index}`}
                >
                  <i></i>
                  <span className={style['date']}>
                    {moment(item.deadline).format('MMM DD YYYY')}
                    <small>
                      {/* Judge the date is before or after today to show different message */}
                      {moment(item.deadline).diff(moment(), 'days') > 0
                        ? `IN ${Math.abs(
                            moment(item.deadline).diff(moment(), 'days'),
                          )} DAYS`
                        : `${Math.abs(
                            moment(item.deadline).diff(moment(), 'days'),
                          )} DAYS AGO`}
                    </small>
                  </span>
                  <div className={style['details']}>
                    {/* If only 1 item returns,show it directly, if more than 1 item,show the count */}
                    <p>{item.numberOfReturnsToFile} Returns</p>
                  </div>
                </div>
                {stateSuccess && (
                  <Tooltip
                    tooltipId={`tooltip-event-${index}`}
                    triggerId={`tooltip-trigger-event-${index}`}
                    className='tooltip'
                  >
                    {_.map(item.byStates, (obj, idx) => (
                      <li key={idx}>
                        <span>{_.get(obj, 'state')} </span>
                        {obj.numberOfReturnsToFile}
                        {!_.isEqual(item.byStates.length, idx + 1) && ', '}
                      </li>
                    ))}
                  </Tooltip>
                )}
              </SwiperSlide>
            )}
          )}
        </Swiper>
      )}
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  states: state.states,
});

export default connect(mapStateToProps)(EventsBoard);
