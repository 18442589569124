import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IBackendIndexTableCollectionUpdate, IndexTableCollection, IToastMessage, SelectOption, State } from '../../../interface';
import { createIndexTableCollection, getErrorMessage, getIndexTableCollection, getSystemTaxYears, updateIndexTableCollection,  } from '../../../services';
import { TOOLTIP_SECTIONS } from '../../../enums';
import _ from 'lodash';
import { FormikProps, FormikValues, useFormik } from 'formik';
import validateEmpty from '../../../utils/validateEmpty';
import Loader from '../../../components/Loader/Loader';
import DiscardChangeConfirmModal from '../../../components/DiscardChangeConfirmModal/DiscardChangeConfirmModal';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import Divider from '../../../components/Divider/Divider';
import style from './IndexCollectionManagementDetails.module.scss';
import classNames from 'classnames';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import FieldInput from '../../../components/FieldInput/FieldInput';
import FieldSelect from '../../../components/FieldSelect/FieldSelect';
import { useAppSelector } from '../../../redux/store';
import IndexTableManagementTable from '../../../components/IndexTableManagementTable/IndexTableManagementTable';

interface Props {
  className?: string;
  isAddNew?: boolean;
}

interface IndexCollectionFormData {
  id: number | null;
  name: string;
  stateId: number | null;
  taxYear: number | null;
}

const newInit: IndexCollectionFormData = {
  id: null,
  name: '',
  stateId: null,
  taxYear: null,
};

function IndexCollectionManagementDetails({
  className,
  isAddNew
}: Props) {
  const navigate = useNavigate();
  const [backendIndexCollection, setBackendIndexCollection] = useState<IndexTableCollection | null>(null);
  const [initialIndexCollectionValues, setInitialIndexCollectionValues] = useState<IBackendIndexTableCollectionUpdate>(newInit);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [activeTaxYear, setActiveTaxYear] = useState<number | null>(null);
  const [taxYearOptions, setTaxYearOptions] = useState<SelectOption[]>([]);
  const stateOptions = useAppSelector(state => (state.states ?? []).map((o: State) => ({
    value: o.id,
    label: o.abbreviation,
    name: o.name,
  })));

  const params = useParams();
  const [fetchingIndexTableCollectionInfo, setFetchingIndexTableCollectionInfo] = useState(false);
  const [fetchingAllTaxYears, setFetchingAllTaxYears] = useState(false);
  const [savingIndexCollection, setSavingIndexCollection] = useState(false);
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const shouldShowError = useMemo(() => isSubmitted, [isSubmitted]);
  const getFormErrorMessage = (key: keyof IBackendIndexTableCollectionUpdate) => {
    return shouldShowError ? formikIndexCollectionForm?.errors?.[key] : undefined;
  }

  const showSuccess = () => {
    window.scrollTo(0, 0);
    setToastMessage({
      message: 'Index Collection details saved successfully',
      visible: true,
      type: 'success',
    });
  }
  const showErrorMessage = (message: string) => {
    window.scrollTo(0, 0);
    setToastMessage({
      message,
      visible: true,
      type: 'error',
    });
  }

  useEffect(() => {
    if (
      params.id &&
      !fetchingIndexTableCollectionInfo &&
      activeTaxYear !== null
    ) {
      setFetchingIndexTableCollectionInfo(true);
      getIndexTableCollection(_.toNumber(params.id))
        .then((d) => {
          const indexCollectionData = d.data;
          if (!_.isEmpty(indexCollectionData)) {
            setInitialIndexCollectionValues(indexCollectionData);
            setBackendIndexCollection(indexCollectionData);
            formikIndexCollectionForm.validateForm();
          }
          setFetchingIndexTableCollectionInfo(false);
        })
        .catch(() => {
          setFetchingIndexTableCollectionInfo(false);
        });
    }
  }, [
    params,
    activeTaxYear
  ]);

  useEffect(() => {
    setFetchingAllTaxYears(true);
    getSystemTaxYears()
      .then((res) => {
        setFetchingAllTaxYears(false);
        const allActiveTaxYears = _.orderBy(
          res.data,
          ['taxYear'],
          ['desc'],
        );

        let currentTaxYearIndex = _.findIndex(allActiveTaxYears, {
          isCurrent: true,
        });
        if (currentTaxYearIndex < 0) {
          currentTaxYearIndex = 0;
        }
        setActiveTaxYear(allActiveTaxYears[currentTaxYearIndex].taxYear);
        setTaxYearOptions(allActiveTaxYears.map(item => ({
          value: item.taxYear.toString(),
          label: item.taxYear.toString(),
          name: item.taxYear.toString(),
        })));

        if (isAddNew) {
          setInitialIndexCollectionValues(newInit);
        } 
      })
      .catch(() => {
        setFetchingAllTaxYears(false);
      });
  }, [isAddNew]);


  const formikIndexCollectionForm: FormikProps<IBackendIndexTableCollectionUpdate> = useFormik<FormikValues>({
    initialValues: initialIndexCollectionValues,
    enableReinitialize: true,
    validate: (values) => {
      const requiredErrors = validateEmpty(_.pick({
        ...values,
      }, [
        'name',
        'stateId',
        'taxYear',
      ]));
      return requiredErrors;
    },
    onSubmit: (values) => {
      if (!isIndexCollectionFormChanged) {
        return;
      }
      setSavingIndexCollection(true);

      if (isAddNew) {
        createIndexTableCollection(values)
          .then(() => {
            showSuccess();
            setInitialIndexCollectionValues(formikIndexCollectionForm.values);
            setSavingIndexCollection(false);
            setIsSubmitted(false);
            setTimeout(() => {
              navigate('/admin/schedule-collection-management?tab=Index+Table+Collections');
            }, 100);
          })
          .catch((e) => {
            showErrorMessage(getErrorMessage(e));
            setSavingIndexCollection(false);
            setIsSubmitted(false);
          });
      }
      else {
        updateIndexTableCollection(backendIndexCollection?.id ?? 0, formikIndexCollectionForm.values)
        .then(() => {
          setInitialIndexCollectionValues(values);
          setSavingIndexCollection(false);
          showSuccess();
        })
        .catch((e) => {
          showErrorMessage(getErrorMessage(e));
          setSavingIndexCollection(false);
        });
      }
    },
  });

  const isIndexCollectionFormChanged = useMemo(() => !_.isEqual(
    initialIndexCollectionValues,
    formikIndexCollectionForm.values
  ), [
    formikIndexCollectionForm.values,
    initialIndexCollectionValues
  ]);

  const pageTitle = useMemo(() => {
    if (isAddNew) {
      return 'New Index Table Collection';
    }
    return 'Index Table Collection Details';
  }, [isAddNew])

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'IndexCollectionManagementDetails d-flex')
    }>
      <div className={classNames('d-flex flex-column flex-1', style.blockLeft)}>
        <Breadcrumb
          items={[
            {
              value: 'Index Tabl Collection Management',
              href: '/admin/schedule-collection-management?tab=Index+Table+Collections',
            },
            { value: pageTitle },
          ]}
          className={style.Breadcrumb}
        />
        {toastMessage?.visible && (
          <div className={style.toast}>
            <ToastMessage
              className='successful'
              status={toastMessage}
              visiableHandler={(value) =>
                setToastMessage({ ...toastMessage, visible: value })
              }
            />
          </div>
        )}
        <span className={style.textTitle}>{pageTitle}</span>

        <Divider className={style.divider1} />

        <div className='d-grid grid-4-columns gap-column-25 gap-row-20'>
          <FieldInput
            labelText='Index Collection Name'
            name='name'
            value={formikIndexCollectionForm?.values.name ?? ''}
            onChange={(value) => {
              formikIndexCollectionForm?.setFieldValue('name', value);
            }}
            error={getFormErrorMessage('name')}
            isInReadOnlyMode={true}
          />
          <FieldSelect
            labelText='State'
            options={stateOptions}
            selectId={formikIndexCollectionForm?.values.stateId}
            onSelect={(value) => {
              formikIndexCollectionForm?.setFieldValue(
                'stateId',
                value?.value,
              )
            }}
            hideNullOption
            error={getFormErrorMessage('stateId')}
            isInReadOnlyMode={true}
          />
          <FieldSelect
            labelText='Tax Year'
            options={taxYearOptions}
            selectId={taxYearOptions.find(taxYear => taxYear.value == formikIndexCollectionForm?.values.taxYear)?.value}
            onSelect={(value) => {
              formikIndexCollectionForm?.setFieldValue(
                'taxYear',
                value?.value,
              )
            }}
            hideNullOption
            error={getFormErrorMessage('taxYear')}
            isInReadOnlyMode={true}
          />
        </div>

        {
          !isAddNew && (
            <div>
              <Divider className={style.divider1} strokeWidth={1} />

              <IndexTableManagementTable setToastMessage={setToastMessage}/>
            </div>
          )
        }
        

        <Divider className={style.divider1} strokeWidth={2} />

        <div className='d-flex gap-20'>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageAction}
            tooltipKey='Cancel'
          >
            <button
              className='secondary'
              onClick={() => {
                navigate("/admin/schedule-collection-management?tab=Index+Table+Collections");
              }}
            >
              Back
            </button>
          </TooltipWrapper>
        </div>
      </div>

      <DiscardChangeConfirmModal
        initialData={1}
        inputData={isIndexCollectionFormChanged ? 2 : 1}
      />

      <Loader
        isOpen={
          fetchingAllTaxYears ||
          fetchingIndexTableCollectionInfo ||
          savingIndexCollection
        }
      />
    </div>
  );
}

export default IndexCollectionManagementDetails;