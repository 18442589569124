import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import {FormikValues, useFormik} from 'formik';
import FieldInput from '../../../components/FieldInput/FieldInput';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import FieldRelatedSelect from '../../../components/FieldRelatedSelect/FieldRelatedSelect';
import validateEmpty from '../../../utils/validateEmpty';
import {
  AssessorAccount,
  IErrorBase,
  IToastMessage,
  ReduxState,
  SelectOption,
} from '../../../interface';
import style from './GenerateReport.module.scss';
import {
  createGeneralReport,
  getAllAssessors,
  getAllLegalEntities,
  getAllProperties,
  getCompanies,
} from '../../../services';
import {AxiosResponse} from 'axios';
import {connect} from 'react-redux';
import FieldSelect from '../../../components/FieldSelect/FieldSelect';
import Loader, {LoaderIndicator} from '../../../components/Loader/Loader';

import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../enums';
import useSelectedSystemTaxYear from '../../../hook/useSelectedSystemTaxYear';

const reportTypes: SelectOption[] = [
  {
    value: 1,
    label: 'Property Report',
    name: 'Property Report',
  },
  {
    value: 2,
    label: 'Asset Report',
    name: 'Asset Report',
  },
  {
    value: 3,
    label: 'Return Tracking Report',
    name: 'Return Tracking Report',
  },
  {
    value: 4,
    label: 'Value Tracking for Filed Returns Report',
    name: 'Value Tracking for Filed Returns Report',
  },
];

// Init formik values
const newInit = {
  companyId: null,
  legalEntityId: null,
  propertyId: null,
  assessorId: null,
  taxYear: null,
  assessorAccountId: null,
  reportName: reportTypes[0].value,
};

// Define the required fields
const requiredField = ['taxYear'];

const decimalField = ['taxYear'];

function GenerateReport() {
  // Breadcrumb, dashboard depend on user skip or not
  const breadcrumb = [
    {
      value: 'Reports',
      href: '/reports',
    },
    {value: 'Generate Report'},
  ];

  // Options of select fields
  const [companies, setCompanies] = useState<SelectOption[]>([]);
  const [legalEntities, setlegalEntities] = useState<SelectOption[]>([]);
  const [properties, setProperties] = useState<SelectOption[]>([]);
  const [assessors, setAssessors] = useState<SelectOption[]>([]);
  const [accountNumbers, setAccountNumbers] = useState<SelectOption[]>([]);
  const [fetchingCompanies, setFetchingCompanies] = useState(false);
  const [fetchingAllLegalEntities, setFetchingAllLegalEntities] =
    useState(false);
  const [fetchingAllProperties, setFetchingAllProperties] = useState(false);
  const [fetchingAllAssessors, setFetchingAllAssessors] = useState(false);
  const selectedSystemTaxYear = useSelectedSystemTaxYear();

  function getOptions(d: AxiosResponse) {
    return _.map(d.data.items, (item) => ({
      value: item.id,
      label: item.name,
      name: item.number,
      item: item,
    }));
  }
  // Get options of select from JSON
  useEffect(() => {
    setFetchingCompanies(true);
    getCompanies()
      .then((d) => {
        setCompanies([
          ...[
            {
              value: null,
              label: 'All',
              name: '',
              item: {},
            },
          ],
          ...getOptions(d),
        ]);
        setFetchingCompanies(false);
      })
      .catch(() => {
        setFetchingCompanies(false);
      });

    setFetchingAllLegalEntities(true);
    getAllLegalEntities()
      .then((d) => {
        setlegalEntities([
          ...[
            {
              value: null,
              label: 'All',
              name: '',
              item: {},
            },
          ],
          ...getOptions(d),
        ]);
        setFetchingAllLegalEntities(false);
      })
      .catch(() => {
        setFetchingAllLegalEntities(false);
      });

    setFetchingAllProperties(true);
    getAllProperties()
      .then((d) => {
        setProperties([
          ...[
            {
              value: null,
              label: 'All',
              name: '',
              item: {},
            },
          ],
          ...getOptions(d),
        ]);
        setFetchingAllProperties(false);
      })
      .catch(() => {
        setFetchingAllProperties(false);
      });

    setFetchingAllAssessors(true);
    getAllAssessors(selectedSystemTaxYear?.taxYear || '')
      .then((d) => {
        setAssessors(
          _.map(d.data.items, (item) => ({
            value: item.assessor.id,
            label: item.assessor.name,
            name: item.number,
            item: item.assessor,
          })),
        );
        setAccountNumbers(
          _.map(d.data.items, (item) => ({
            value: item.id,
            label: item.number,
            name: item.number,
            item: item,
          })),
        );
        setFetchingAllAssessors(false);
      })
      .catch(() => {
        setFetchingAllAssessors(false);
      });
  }, []);

  const navigate = useNavigate();

  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const formik = useFormik<FormikValues>({
    initialValues: newInit,
    enableReinitialize: true,
    validate: (values) => {
      // Validate form
      if (isSubmit) {
        const errors =
          values.reportName === reportTypes[0].value
            ? {}
            : validateEmpty(_.pick(values, requiredField));
        const result = _.every(_.values(errors), (e: string | []) => {
          return _.every(e, _.isEmpty);
        });
        if (!result) {
          setIsSubmit(false);
          setToastMessage({
            visible: true,
            message:
              'The Report you save had some errors that need to be fixed. Please address these to move forward.',
            type: 'error',
          });
          return errors;
        }
      }
    },
    onSubmit: () => {
      const result = _.cloneDeep(formik.values);
      _.map(decimalField, (item) => {
        return _.update(result, item, (v) => (v ? _.toNumber(v) : v));
      });
      const newReport = {
        reportName: (
          _.find(reportTypes, {value: result.reportName}) as SelectOption
        )?.name,
        companyId: result.companyId,
        legalEntityId: result.legalEntityId,
        propertyId: result.propertyId,
        taxYear: result.taxYear,
        assessorId: result.assessorId,
        assessorAccountId: result.assessorAccountId,
      };
      if (newReport.reportName === reportTypes[0].name) {
        delete newReport.taxYear;
        delete newReport.assessorId;
        delete newReport.assessorAccountId;
      }

      setSubmitting(true);
      createGeneralReport(newReport)
        .then((e: AxiosResponse<IErrorBase>) => {
          if (e.data?.errors?.length) {
            setToastMessage({
              visible: true,
              message: e?.data.errors[0].replace(/items\.\d\./, ''),
              type: 'error',
            });
          } else {
            if (_.inRange(e.status, 200, 299)) {
              navigate('/reports');
            } else {
              setToastMessage({
                visible: true,
                message: `Error occurs, please try again later.`,
                type: 'error',
              });
            }
          }
          setSubmitting(false);
        })
        .catch(() => {
          setToastMessage({
            visible: true,
            message: `Error occurs, please try again later.`,
            type: 'error',
          });
          setSubmitting(false);
        });
    },
  });

  // reset valid by click the close icon of error message box
  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  //Auto populate
  const [currentSelected, setCurrentSelected] = useState<string>('');
  useEffect(() => {
    formik.setFieldValue('assessorId', null);
    formik.setFieldValue('propertyId', null);
    formik.setFieldValue('legalEntityId', null);
    formik.setFieldValue('companyId', null);
  }, [formik.values.reportName]);

  const changeLegalEntityId = (newLegalEntityId: number) => {
    formik.setFieldValue('legalEntityId', newLegalEntityId);
    setCurrentSelected('owner');

    if (newLegalEntityId) {
      const find = _.find(legalEntities, ['value', newLegalEntityId]);
      formik.setFieldValue('companyId', _.get(find, 'item.companyId'));
      const findProperties = _.filter(properties, [
        'item.legalEntityId',
        newLegalEntityId,
      ]);
      if (_.isEqual(findProperties.length, 1)) {
        formik.setFieldValue('propertyId', findProperties[0].value);
      }
      if (_.isEqual(findProperties.length, 0)) {
        formik.setFieldValue('propertyId', null);
      }
      if (_.gt(findProperties.length, 1)) {
        if (!_.find(findProperties, ['value', formik.values.propertyId])) {
          formik.setFieldValue('propertyId', null);
        }
      }
    }
  };

  const changeCompanyId = (newCompanyId: number) => {
    formik.setFieldValue('companyId', newCompanyId);
    if (newCompanyId && _.isEqual(currentSelected, 'company')) {
      const findOwners = _.filter(legalEntities, [
        'item.companyId',
        newCompanyId,
      ]);
      if (_.isEqual(findOwners.length, 1)) {
        formik.setFieldValue('legalEntityId', findOwners[0].value);
      }
      if (_.isEqual(findOwners.length, 0)) {
        formik.setFieldValue('legalEntityId', null);
      }
      if (_.gt(findOwners.length, 1)) {
        if (!_.find(findOwners, ['value', formik.values.legalEntityId])) {
          formik.setFieldValue('legalEntityId', null);
        }
      }
      formik.setFieldValue('propertyId', null);
    }
  };

  const changeAccountId = (newAssessorAccountId: number) => {
    formik.setFieldValue('assessorAccountId', newAssessorAccountId);
    const accountNumber = _.find(accountNumbers, {value: newAssessorAccountId});
    if (newAssessorAccountId && accountNumber) {
      const account = accountNumber.item as AssessorAccount;
      formik.setFieldValue('companyId', _.get(account, 'property.companyId'));
      formik.setFieldValue(
        'legalEntityId',
        _.get(account, 'property.legalEntityId'),
      );
      formik.setFieldValue('propertyId', _.get(account, 'property.id'));
      formik.setFieldValue('assessorId', _.get(account, 'assessorId'));
    }
  };

  return (
    <>
      <div className={style['main-content']}>
        {/* Braedcrumb */}
        <Breadcrumb items={breadcrumb} />
        {/* Error message box,if there are errors message */}
        {toastMessage?.visible && (
          <div className={style['toast']}>
            <ToastMessage
              status={toastMessage}
              className={toastMessage?.type ? toastMessage?.type : ''}
            />
          </div>
        )}
        <div className={style['header']}>
          <h2>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageTitle}
              tooltipKey='Generate Report'
            >
              <span>Generate Report</span>
            </TooltipWrapper>
          </h2>
        </div>
        <form className={style['main']} onSubmit={formik.handleSubmit}>
          <div className={classNames(style['asset-section'])}>
            <div className={style['section']}>
              <div className={style['form']}>
                <fieldset>
                  <div className={style['row']}>
                    <div className={classNames(style['column'])}>
                      <FieldSelect
                        labelText='Report'
                        options={reportTypes}
                        force
                        selectId={formik.values.reportName}
                        onSelect={(value) =>
                          formik.setFieldValue(
                            `reportName`,
                            value?.value ? value?.value : null,
                          )
                        }
                        error={
                          formik.errors &&
                          (formik.errors as FormikValues).reportName
                        }
                      />
                    </div>
                  </div>

                  {formik.values.reportName !== reportTypes[0].value ? (
                    <div className={style['row']}>
                      <div className={classNames(style['column'])}>
                        <FieldInput
                          labelText='Tax Year'
                          value={formik.values.taxYear}
                          onChange={(value) =>
                            formik.setFieldValue(`taxYear`, value)
                          }
                          placeholder='Enter'
                          type='number'
                          yearFormat
                          required
                          error={
                            formik.errors &&
                            (formik.errors as FormikValues).taxYear
                          }
                          maxLength={4}
                        />
                      </div>
                    </div>
                  ) : null}
                </fieldset>
                <fieldset>
                  <legend>
                    <TooltipWrapper
                      tooltipSection={TOOLTIP_SECTIONS.Common}
                      tooltipKey='Customer Information'
                    >
                      <span>Customer Information</span>
                    </TooltipWrapper>
                  </legend>
                  <div className={style['row']}>
                    <div className={style['column']}>
                      <FieldRelatedSelect
                        value={formik.values.companyId}
                        labelText='Company Name & Number'
                        options={companies}
                        shouldAllowNull
                        onChange={(id) => {
                          changeCompanyId(id as number);
                        }}
                        error={
                          formik.errors &&
                          (formik.errors as FormikValues).companyId
                        }
                      />
                    </div>
                    <div className={style['column']}>
                      <FieldRelatedSelect
                        value={formik.values.legalEntityId}
                        labelText='Legal Entity Name & Number'
                        options={legalEntities}
                        shouldAllowNull
                        onChange={(id) => {
                          changeLegalEntityId(id as number);
                        }}
                        error={
                          formik.errors &&
                          (formik.errors as FormikValues).legalEntityId
                        }
                      />
                    </div>
                    <div className={style['column']}>
                      <FieldRelatedSelect
                        labelText='Property Name & Number'
                        value={formik.values.propertyId}
                        shouldAllowNull
                        options={
                          formik.values.legalEntityId
                            ? _.filter(properties, (property) => {
                                return (
                                  property.item.legalEntityId ===
                                    formik.values.legalEntityId ||
                                  property.value === null ||
                                  property.value === formik.values.propertyId
                                );
                              })
                            : _.filter(properties, (property) => {
                                return (
                                  property.value === null ||
                                  property.value === formik.values.propertyId
                                );
                              })
                        }
                        onChange={(id) => {
                          formik.setFieldValue('propertyId', id);
                        }}
                        error={
                          formik.errors &&
                          (formik.errors as FormikValues).propertyId
                        }
                      />
                    </div>
                  </div>
                  {formik.values.reportName !== reportTypes[0].value ? (
                    <div className={style['row']}>
                      <div className={style['column']}>
                        <FieldSelect
                          labelText='Assessor'
                          options={assessors}
                          selectId={formik.values.assessorId}
                          onSelect={(value) => {
                            const assessorId = value?.value
                              ? value?.value
                              : null;
                            formik.setFieldValue('assessorId', assessorId);
                          }}
                          error={
                            formik.errors &&
                            (formik.errors as FormikValues).assessorId
                          }
                        />
                      </div>

                      <div className={style['column']}>
                        <FieldSelect
                          labelText='Assessor Account Number'
                          options={accountNumbers}
                          selectId={formik.values.assessorAccountId}
                          onSelect={(value) => {
                            const assessorAccountId = value?.value
                              ? value?.value
                              : null;
                            changeAccountId(assessorAccountId as number);
                          }}
                          error={
                            formik.errors &&
                            (formik.errors as FormikValues).assessorAccountId
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                </fieldset>
              </div>
            </div>
            {/* Add company link */}
            <div className={style['bottom-bar']}>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.Common}
                tooltipKey='Required field'
              >
                <span className={style['required']}>* Required field</span>
              </TooltipWrapper>
            </div>
          </div>
          <div className={style['footer']}>
            {/* Submit link,it change the flag of submit */}
            <div className={style['buttons']}>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Generate and exit'
              >
                <button
                  type='submit'
                  className='primary'
                  onClick={() => setIsSubmit(true)}
                  disabled={submitting}
                >
                  Generate and exit
                  {submitting ? (
                    <LoaderIndicator
                      className='button-loading'
                      loading={true}
                    />
                  ) : null}
                </button>
              </TooltipWrapper>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Cancel'
              >
                <button
                  disabled={submitting}
                  className='button secondary'
                  onClick={() => window.history.go(-1)}
                >
                  Cancel
                </button>
              </TooltipWrapper>
            </div>
          </div>
        </form>
      </div>

      <Loader
        isOpen={
          fetchingCompanies ||
          fetchingAllLegalEntities ||
          fetchingAllProperties ||
          fetchingAllAssessors
        }
      />
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  states: state.states,
});

export default connect(mapStateToProps)(GenerateReport);
