import {Dictionary, map} from 'lodash';
import React, {ReactNode} from 'react';
import {TOOLTIP_SECTIONS} from '../../enums';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import style from './PropertiesPanel.module.scss';
import classNames from 'classnames';

interface Props {
  values: Dictionary<ReactNode>;
  classname?: string;
}

function PropertiesPanel(props: Props) {
  const {values, classname} = props;

  return (
    <div className={classNames(style['fieldset'], style[(classname ?? '')])}>
      {map(values, (value, label) => (
        <div key={label}>
          <div className={style['field-label']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Common}
              tooltipKey={label}
            >
              <span>{label}</span>
            </TooltipWrapper>
          </div>
          <div className={style['field-value']}>{value}</div>
        </div>
      ))}
    </div>
  );
}

export default PropertiesPanel;
