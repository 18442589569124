import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import style from './Help.module.scss';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import { TOOLTIP_SECTIONS } from '../../enums';

interface Topic {
  id?: number;
  title: string;
}

interface HelpEntry {
  title: string;
  type?: string;
  topics: Topic[];
}

//Help section on dashboard page
function Help() {
  //Get data from JSON
  const helps: HelpEntry[] = [
    {
      title: 'Lean how to use your new product',
      topics: [
        {title: 'Go to Avalara University'},
        {title: 'Expore the Help center'},
      ],
      type: '',
    },
    {
      title: 'Still need help?',
      topics: [{title: 'Contact a support agent'}],
      type: 'help',
    },
  ];
  
  return (
    <div className={style['section']}>
      <h3>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.Help}
          tooltipKey='What can we do for you'
        >
          <span>What can we do for you?</span>
        </TooltipWrapper>
      </h3>
      {/* Help list */}
      {helps.map((help, index) => (
        <div
          key={index}
          className={classNames(style['block'], style[`${help.type}`])}
        >
          <p>{help.title}</p>
          <ul>
            {help.topics.map((topic, idx) => (
              <li key={idx}>
                <Link to='#' className='link'>
                  {topic.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default Help;
