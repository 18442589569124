import classNames from "classnames";
import React from "react";
import style from "./Chips.module.scss";

interface Props {
  values: string[];
  onRemove?: (value: string, idx: number) => void;
  onRemoveAll?: () => void;
  removeAllLabel?: string;
  className?: string;
}

function Chips(props: Props) {
  const { values, onRemove, removeAllLabel, onRemoveAll, className } = props;

  return (
    <>
      <ul className={classNames(style.chips, className)}>
        {values.map((value, idx) => (
          <li key={idx} className={style.chip}>
            {value}
            {onRemove && (
              <button
                type="button"
                className={style.btnRemove}
                onClick={() => onRemove(value, idx)}
              ></button>
            )}
          </li>
        ))}

        {onRemove && (
          <li>
            <button
              type="button"
              className={style.btnRemoveAll}
              onClick={onRemoveAll}
            >
              {removeAllLabel ?? "Remove All"}
            </button>
          </li>
        )}
      </ul>
    </>
  );
}

export default Chips;
