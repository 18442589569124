import _ from 'lodash';

export const updateLoadingObjectByIds = (
  ids: number[],
  loading: boolean,
  oldLoadingObject: { [key: number]: boolean },
  updateLoadingObjectFunction: (newObject: { [key: number]: boolean }) => void
) => {
  const newResults = _.reduce(ids , (obj,param) => {
    obj[param] = loading
    return obj;
   }, {} as {[key: number]: boolean});
   updateLoadingObjectFunction({
    ...oldLoadingObject,
    ...newResults,
   });
};
