import React from 'react';
import { useNavigate } from 'react-router-dom';
import BaseModal from '../BaseModal/BaseModal';
import style from './GeneratePackageSuccessModal.module.scss';

export interface Props {
  isOpen: boolean;
  onRequestClose?: () => void;
  packageId: number;
  packageName: string;
  includedItems: string[];
}

function GeneratePackageSuccessModal({
  isOpen,
  onRequestClose,
  packageId,
  packageName,
  includedItems,
}: Props) {
  const navigate = useNavigate();

  return (
    <BaseModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className={style['modal']}>
        <div className={style['modal-header']}>
          <i className={style['modal-header-icon']} />
          Successfully Generated Package
        </div>
        <div className={style['modal-content']}>
          <div className={style['status-panel']}>
            <div className={style['status-title']}>{packageName}</div>
            <div className={style['status-content']}>
              {includedItems.map((item) => (
                <div className={style['status-item']} key={item}>
                  <div className={style['status-item-name']}>{item}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={style['modal-footer']}>
          <button
            type="button"
            className="primary"
            onClick={() => {
              navigate(`/returns/previewPackage/${packageId}`);
            }}
          >
            Preview Package
          </button>
          <button
            type="button"
            className="secondary"
            onClick={() => {
              navigate('/returns');
            }}
          >
            Return to My Tax Returns
          </button>
        </div>
      </div>
    </BaseModal>
  );
}

export default GeneratePackageSuccessModal;
