import React, { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { apiInstance } from "../../api/fetchData";
import { Outlet } from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import { getUserProfile } from "../../redux/features/auth-slice";

function RequireAuth() {
  const auth = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

	const dispatch = useAppDispatch();

  useEffect(() => {
    if (!auth.isLoading && !auth.isAuthenticated) {
      auth.signinRedirect();
    }

    if (auth.user?.access_token) {
      apiInstance.defaults.headers.common.Authorization = `Bearer ${auth.user?.access_token}`;
      setIsLoggedIn(true);

      // also call the get user api
      dispatch(getUserProfile());
    }
  }, [auth]);

  return isLoggedIn ? <Outlet /> : <></>;
}

export default RequireAuth;
