function getFileName(value: string, fallback = "default.xlsx") {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(value);
  const filename =
    matches != null && matches[1] ? matches[1].replace(/['"]/g, "") : fallback;

  return decodeURI(filename);
}

export default getFileName;
