import classNames from 'classnames';
import {useFormik} from 'formik';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import GeneratePackageSuccessModal from '../../../components/GeneratePackageSuccessModal/GeneratePackageSuccessModal';
import SearchInput from '../../../components/SearchInput/SearchInput';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import {IToastMessage, ReturnsItem} from '../../../interface';
import style from './ManagePackageContent.module.scss';
import {
  getFromDetail,
  downloadImage,
  downloadDocument,
  saveFromAttachments,
  getFromAttachments,
  getReturnDetail,
  getCustomerReturnForm,
} from '../../../services';
import {Buffer} from 'buffer';
import {cloneDeep, compact, Dictionary, isNil, at} from 'lodash';
import PropertiesPanel from '../../../components/PropertyPanel/PropertiesPanel';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../enums';
import Loader, { LoaderIndicator } from '../../../components/Loader/Loader';
import DiscardChangeConfirmModal from '../../../components/DiscardChangeConfirmModal/DiscardChangeConfirmModal';

interface FormDocData {
  formDocumentId: number;
  name: string;
  selected: boolean;
  thumbnailDocId: number;
  templateDocumentId: number;
  locked: boolean;
  imageData?: string;
}

interface FormDocDataToSubmit {
  formAttachmentId: number;
  selected: boolean;
}

function ManagePackageContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const {formId, returnId} = useParams();

  const [search, setSearch] = useState<string>('');

  const [generatedSuccessOpen, setGeneratedSuccessOpen] =
    useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<IToastMessage>({
    message: '',
    visible: false,
  });

  const [formName, setFormName] = useState('');
  const [formDocData, setFormDocData] = useState<FormDocData[]>([]);
  const [filteredFormDocData, setFilteredFormDocData] = useState<FormDocData[]>(
    [],
  );
  const [initialValues, setInitialValues] = useState<FormDocDataToSubmit[]>([]);
  const [navBlock, setNavBlock] = useState<boolean>();

  const [returnDetail, setReturnDetail] = useState<ReturnsItem>();

  const encode = (str: string): string =>
    Buffer.from(str, 'binary').toString('base64');
  const [fetchingReturnDetail, setFetchingReturnDetail] = useState(false);

  const [viewingPdf, setviewingPdf] = useState<{[key: number]: boolean}>({})
  const viewPdf = (docId: number) => {
    setviewingPdf({
      ...viewingPdf,
      [docId]: true,
    });
    downloadDocument('' + docId).then((response) => {
      //Build a URL from the file
      const file = new Blob([response.data], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
      setviewingPdf({
        ...viewingPdf,
        [docId]: false,
      });
    }).catch(() => {
      setviewingPdf({
        ...viewingPdf,
        [docId]: false,
      });
    });
  };

  useEffect(() => {
    if (search) {
      const delayDebounceFn = setTimeout(() => {
        const filteredData = formDocData.filter((item) => {
          return new RegExp(search, 'i').test(item.name);
        });
        setFilteredFormDocData(filteredData);
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setFilteredFormDocData(formDocData);
    }
  }, [search]);

  useEffect(() => {
    if (returnId) {
      setFetchingReturnDetail(true);
      getReturnDetail(returnId).then((res) => {
        setReturnDetail(res.data);
        setFetchingReturnDetail(false);
      }).catch(() => {
        setFetchingReturnDetail(false);
      });
    } else {
      setReturnDetail(undefined);
    }
  }, [returnId]);

  const [fetchingData, setFetchingData] = useState(false)

  useEffect(() => {
    if (formId) {
      setFetchingData(true);
      (isNil(returnId)
        ? getFromDetail(formId)
        : getCustomerReturnForm(Number(returnId))
      ).then((res) => {
        setFormName(res.data.form?.name || '');

        const ids = new Set(
          res.data.customerFormAttachments?.map(
            (item) => item.formAttachmentId,
          ),
        );

        getFromAttachments(res.data.formId).then((res2) => {
          const data: FormDocData[] =
            res2.data.map((item) => ({
              formDocumentId: item.id,
              name: item.name,
              selected: ids.has(item.id) || item.required,
              thumbnailDocId: item.thumbnailDocumentId,
              templateDocumentId: item.templateDocumentId,
              locked: item.required,
            })) ||
            // ignore in coverage report because res2.data.map() is always return
            // valid data
            /* istanbul ignore next */[];

          setFormDocData(data);
          setFilteredFormDocData(data);

          const toSubmitData = data.map((item) => ({
            formAttachmentId: item.formDocumentId,
            selected: item.selected,
          }));

          setInitialValues(toSubmitData);
          formik.setValues(toSubmitData);

          // eslint-disable-next-line
          const promiseArray: Promise<any>[] = [];
          data.forEach((item) => {
            promiseArray.push(downloadImage('' + item.thumbnailDocId));
          });

          const newData = cloneDeep(data);

          Promise.all(promiseArray).then((_res) => {
            _res.forEach((resData, idx) => {
              const image = encode(resData.data);
              newData[idx].imageData = image;
            });

            setFormDocData(newData);
            setFilteredFormDocData(newData);
            setFetchingData(false);
          }).catch(() => {
            setFetchingData(false);
          });
        }).catch(() => {
          setFetchingData(false);
        });
      }).catch(() => {
        setFetchingData(false);
      });
    }
  }, []);

  const breadcrumb = useMemo(
    () =>
      compact([
        {
          value: 'Forms',
          href: '/forms',
        },
        {
          value: 'Manage Forms',
          href: '/forms',
        },
        {
          value: `Manage ${formName} Package`,
          href: returnDetail ? `/forms/packageContents/${formId}` : undefined,
        },
        returnDetail && {
          value: `Assessor Account ${returnDetail.assessorAccountData.number}`,
        },
      ]),
    [formName],
  );

  const [submitting, setSubmitting] = useState(false)

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      const saveData = {
        items: values
          .filter((value) => value.selected)
          .map((value) => ({
            formAttachmentId: value.formAttachmentId,
            returnId: Number(returnId) || null,
          })),
      };
      setSubmitting(true);
      saveFromAttachments('' + formId, saveData).then(
        () => {
          setSubmitting(false);
          setNavBlock(false);
          if (!isNil(returnId)) {
            setGeneratedSuccessOpen(true);
          } else {
            setToastMessage({
              type: 'success',
              message: 'The documents are saved in package successfully',
              visible: true,
            });
            setTimeout(() => {
              navigate('/forms?tab=Package Contents');
            }, 2000);
          }
        },
        (err) => {
          setSubmitting(false);
          const message = err.data.errors?.join(', ');
          setToastMessage({
            type: 'error',
            message: message || 'Saving From Document failed',
            visible: true,
          });
        },
      ).catch(() => {
        setSubmitting(false);
      });
    },
  });

  const includedItems = useMemo(() => {
    if (formik.values && !Array.isArray(formik.values)) {
      formik.values = []
    }
    return compact(
      formik.values
        .filter((x) => x.selected)
        .map(
          ({formAttachmentId}) =>
            formDocData.find(
              (entry) => entry.formDocumentId === formAttachmentId,
            )?.name,
        ),
    );
  }, [formik.values, formDocData]);


  const goBack = () => {
    (location.state as Dictionary<unknown>)?.backTrack
      ? navigate(-1)
      : navigate('/forms?tab=Package Contents');
  };

  const returnProperties = useMemo(
    () =>
      returnDetail && {
        'Assessor Name': returnDetail.assessorAccountData.assessor.name,
        'Account Number': returnDetail.assessorAccountData.number,
        'Property Number': returnDetail.assessorAccountData.property.number,
        'Property Name': returnDetail.assessorAccountData.property.name,
        'Property location': compact(
          at(
            returnDetail.assessorAccountData.property.address,
            'line1',
            'line2',
            'state.abbreviation',
            'postalCode',
          ),
        ).join(', '),
      },
    [returnDetail],
  );

  function loading(item: FormDocData) {
    if(viewingPdf[item.templateDocumentId]) {
      return (<LoaderIndicator className='button-loading' loading={true} />);
    } else {
      return null;
    }
  }

  return (
    <div className={style['content']}>
      <div className={style['main-content']}>
        {/* Braedcrumb */}
        <Breadcrumb items={breadcrumb} />
        {toastMessage?.visible && (
          <div className={style['toast']}>
            <ToastMessage
              status={toastMessage}
              className={toastMessage.type}
              visiableHandler={(value) =>
                setToastMessage({...toastMessage, visible: value})
              }
            />
          </div>
        )}
        <h2>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageTitle}
            tooltipKey='Manage Package Content'
          >
            <span>Manage {formName} Package</span>
          </TooltipWrapper>
        </h2>
        {returnProperties && <PropertiesPanel values={returnProperties} />}

        <form className={style['main']} onSubmit={formik.handleSubmit}>
          <div>
            {/* Search input */}
            <SearchInput
              placeholder='Search templates/ documents…'
              value={search}
              onChange={(value) => setSearch(value)}
            />
          </div>
          <div className={style['items-container']}>
            <div className={style['items-container-wrapper']}>
              {filteredFormDocData.map((item, index) => (
                <div
                  key={index}
                  className={classNames(
                    style['form-item'],
                    item.locked && style['form-item-locked'],
                  )}
                >
                  <div className={style['form-item-preview']}>
                    {item.imageData && item.imageData.length > 0 ? (
                      <img
                        className={style['preview-image']}
                        alt=''
                        src={'data:image;base64,' + item.imageData}
                      />
                    ) : (
                      <img className={style['preview-image']} alt='' src='' />
                    )}
                    {item.locked ? (
                      <div className={style['preview-mask']}>
                        <button
                          type='button'
                          className='primary'
                          onClick={() => viewPdf(item.templateDocumentId)}
                          disabled={!!viewingPdf[item.templateDocumentId]}
                        >
                          View{loading(item)}
                        </button>
                      </div>
                    ) : (
                      <CustomCheckbox
                        classnames={style['checkbox']}
                        label=''
                        value={formik.values[index].selected}
                        onChange={(val) =>
                          formik.setFieldValue(`${[index]}.selected`, val)
                        }
                      />
                    )}
                  </div>
                  <div className={style['form-item-name']}>{item.name}</div>
                </div>
              ))}
            </div>
          </div>
          <div className={style['buttons']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Save and complete'
            >
              <button
                className='primary'
                type='button'
                onClick={formik.submitForm}
                disabled={submitting}
              >
                Save and complete{submitting ? (<LoaderIndicator className='button-loading' loading={true} />) : null}
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Cancel'
            >
              <button
                className='secondary'
                type='button'
                onClick={goBack}
                disabled={submitting}
              >
                Cancel
              </button>
            </TooltipWrapper>
          </div>
        </form>
      </div>
      <div className={style['status-panel']}>
        <div className={style['status-title']}>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.Common}
            tooltipKey='Included forms'
          >
            <span>Included forms</span>
          </TooltipWrapper>
        </div>
        <div className={style['status-content']}>
          {formDocData.map((item, index) => (
            <div
              className={classNames(
                style['status-item'],
                formik.values[index] && formik.values[index].selected && style['status-item-checked'],
              )}
              key={item.formDocumentId}
            >
              <div
                className={classNames(
                  style['status-item-name'],
                  item.locked && style['form-item-locked'],
                )}
              >
                {item.name}
              </div>
            </div>
          ))}
        </div>
      </div>
      <GeneratePackageSuccessModal
        isOpen={generatedSuccessOpen}
        onRequestClose={() => setGeneratedSuccessOpen(false)}
        packageId={Number(returnId)}
        packageName={formName}
        includedItems={includedItems}
      />
      <DiscardChangeConfirmModal
        initialData={initialValues}
        inputData={formik.values}
        navBlock={navBlock}
      />

      <Loader isOpen={fetchingData || fetchingReturnDetail} />
    </div>
  );
}

export default ManagePackageContent;
