import React, { useEffect } from 'react';
import { Link, useMatch } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';

import style from './Navigation.module.scss';
import useUserProfile from '../../hook/useUserProfile';
import { EUserRoles, TOOLTIP_SECTIONS } from '../../enums';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import useDashboardInfo from '../../hook/useDashboardInfo';
import { getDashboardInfo } from '../../redux/features/dashboard-info-slice';
import { useAppDispatch } from '../../redux/store';
import useSelectedSystemTaxYear from '../../hook/useSelectedSystemTaxYear';

interface Props {
  clickHandle: () => void;
}

function Navigation(props: Props) {
	const dispatch = useAppDispatch();
  const userRoles = useUserProfile()?.roles;
  const { taxReturnProcess } = useDashboardInfo();
  const selectedSystemTaxYear = useSelectedSystemTaxYear();
  const { clickHandle } = props;
  //Get pathname
  const home = useMatch('/dashboard/*');
  const properties = useMatch('/properties/*');
  const assets = useMatch('/assets/*');
  const forms = useMatch('/forms/*');
  const returns = useMatch('/returns/*');
  const reports = useMatch('/reports/*');

  useEffect(() => {
    dispatch(getDashboardInfo());
  }, []);

  // Refetch data when selected tax year changes
  useEffect(() => {
    dispatch(getDashboardInfo());
  }, [selectedSystemTaxYear]);

  return (
    <div className={classNames('navigation', style['navigation'])}>
      <nav>
        <ul>
          {/* Home navigation,it redirect to Onboarding Dashboard or Primary Dashboard */}
          <li
            className={classNames({[style['active']]: home})}
          >
            <Link to='/dashboard' className={classNames('link', style['home'])}>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.NavMenu}
                tooltipKey='Dashboard'
              >
                <span>Dashboard</span>
              </TooltipWrapper>
            </Link>
          </li>
          {/* Properties navigation */}
          <li
            className={classNames({
              [style['active']]: properties,
              [style['disabled']]: !_.includes(
                userRoles,
                EUserRoles.AccountPropertyModerator,
              ),
            })}
          >
            <Link
              to='/properties?tab=Companies'
              className={classNames('link', style['properties'])}
            >
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.NavMenu}
                tooltipKey='Properties'
              >
                <span>Properties</span>
              </TooltipWrapper>
            </Link>
          </li>
          {/* Assets navigation */}
          <li
            className={classNames({
              [style['active']]: assets,
              [style['disabled']]:
                !_.includes(userRoles, EUserRoles.AccountAssetModerator) ||
                !taxReturnProcess?.properties ||
                !taxReturnProcess?.companies,
            })}
          >
            <Link
              to={!taxReturnProcess?.properties ? '#' : '/assets'}
              className={classNames('link', style['assets'])}
            >
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.NavMenu}
                tooltipKey='Assets'
              >
                <span>Assets</span>
              </TooltipWrapper>
            </Link>
          </li>
          {/* Forms navigation */}
          <li
            className={classNames({
              [style['active']]: forms,
              [style['disabled']]:
                !_.includes(userRoles, EUserRoles.AccountFormModerator) ||
                !taxReturnProcess?.companies ||
                !taxReturnProcess.properties ||
                !taxReturnProcess.assets,
            })}
          >
            <Link
              to={
                !taxReturnProcess?.companies ||
                !taxReturnProcess.properties ||
                !taxReturnProcess.assets
                  ? '#'
                  : '/forms'
              }
              className={classNames('link', style['forms'])}
            >
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.NavMenu}
                tooltipKey='Forms'
              >
                <span>Forms</span>
              </TooltipWrapper>
            </Link>
          </li>
          {/* Returns navigation */}
          <li
            className={classNames({
              [style['active']]: returns,
              [style['disabled']]:
                !_.includes(userRoles, EUserRoles.AccountReturnModerator) ||
                !taxReturnProcess?.companies ||
                !taxReturnProcess.properties ||
                !taxReturnProcess.assets ||
                !taxReturnProcess.mapAssetClasses,
            })}
          >
            <Link
              to={
                !taxReturnProcess?.companies ||
                !taxReturnProcess.properties ||
                !taxReturnProcess.assets ||
                !taxReturnProcess.mapAssetClasses
                  ? '#'
                  : '/returns'
              }
              className={classNames('link', style['returns'])}
            >
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.NavMenu}
                tooltipKey='Returns'
              >
                <span>Returns</span>
              </TooltipWrapper>
            </Link>
          </li>
          {/* Reports navigation */}
          <li
            className={classNames({
              [style['active']]: reports,
              [style['disabled']]: !_.includes(
                userRoles,
                EUserRoles.AccountReportModerator,
              ),
            })}
          >
            <Link
              to='/reports'
              className={classNames('link', style['reports'])}
            >
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.NavMenu}
                tooltipKey='Reports'
              >
                <span>Reports</span>
              </TooltipWrapper>
            </Link>
          </li>
        </ul>
      </nav>
      {/* Bottom link group */}
      <div className={style['bottom-links']}>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.Footer}
          tooltipKey='Contact'
        >
          <a href='mailto:support@crowdreason.com' className='link'>
            Contact
          </a>
        </TooltipWrapper>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.Footer}
          tooltipKey='Terms'
        >
          <a
            href='https://www.avalara.com/terms'
            className='link'
            target='_blank'
            rel='noreferrer'
          >
            Terms
          </a>
        </TooltipWrapper>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.Footer}
          tooltipKey='Privacy'
        >
          <a
            href='https://www.avalara.com/us/en/legal/privacy-policy.html'
            className='link'
            target='_blank'
            rel='noreferrer'
          >
            Privacy
          </a>
        </TooltipWrapper>
      </div>
      {/* Accordion icon */}
      <i onClick={clickHandle}></i>
    </div>
  );
}

export default Navigation;
