import {keys, last, values} from 'lodash';
import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';
import {TOOLTIP_SECTIONS} from '../../enums';
import {PurchaseModal, ReduxState} from '../../interface';
import {getUserPurchases} from '../../services';
import {formatDate, formatHumanDate} from '../../utils/stringUtil';
import InitTable, {Query} from '../Table/Table';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import style from './UsageTable.module.scss';

const {Table, Column} = InitTable<PurchaseModal>();

function UsageTable() {
  const [usageList, setUsageList] = useState<PurchaseModal[]>([]);
  const [currentPurchase, setCurrentPurchase] = useState<PurchaseModal>();
  const [fetchingData, setFetchingData] = useState(false);

  const fetchData = useCallback((query: Query) => {
    setFetchingData(true);
    return getUserPurchases({
      sortBy: last(keys(query.order)),
      sortDirection: last(values(query.order)),
    })
      .then((d) => {
        setUsageList(d.data.items);
        setCurrentPurchase(d.data.current);
        setFetchingData(false);
      })
      .catch(() => {
        setFetchingData(false);
      });
  }, []);

  return (
    <>
      <h4 className={style.title}>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.Common}
          tooltipKey='Current Plan'
        >
          <span>Current Plan</span>
        </TooltipWrapper>
      </h4>
      <div className={style.plan}>
        <div>
          <div className={style.taxavaHeader}>
            <img src="/assets/images/Avalara-PropTaxPro-Stacked-RGB.png" alt="Property Tax Pro" className={style['app-logo']} />
          </div>
          <div>
            Annual Plan | {currentPurchase?.returnsPurchased} Total Returns
          </div>
        </div>
        <div className={style.perYear}>
          <div className={style.renewDate}>
            Renews on {formatHumanDate(currentPurchase?.renewalDate)}
          </div>
        </div>
      </div>
      <h4 className={style['title']}>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.Common}
          tooltipKey='Usage'
        >
          <span>Usage</span>
        </TooltipWrapper>
      </h4>
      <div className={style.totalRow}>
        <div className={style.availableReturns}>
          <div className={style.label}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Common}
              tooltipKey='Available Returns'
            >
              <span>Available Returns</span>
            </TooltipWrapper>
          </div>
          <div className={style.value}>
            {(currentPurchase?.returnsPurchased || 0) -
              (currentPurchase?.returnsUsed || 0) >
            0
              ? (currentPurchase?.returnsPurchased || 0) -
                (currentPurchase?.returnsUsed || 0)
              : 0}
          </div>
        </div>
        <div className={style.operate}></div>
      </div>

      {/* Main table */}
      <Table
        id='usageTable'
        rows={usageList}
        onQueryChanged={fetchData}
        sortable
        loading={fetchingData}
      >
        <Column label='Purchase Date' prop='createdAt'>
          {formatDate}
        </Column>

        <Column label='TaxYear' prop='taxYear' />

        <Column label='Returns Purchased' prop='returnsPurchased' />

        <Column label='Returns Used' prop='returnsUsed' />

        <Column label='Renewal Date' prop='renewalDate'>
          {formatDate}
        </Column>
      </Table>

      <div className={style.buttons}>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.Common}
          tooltipKey='Need more returns'
        >
          <div>Need more returns? </div>
        </TooltipWrapper>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.PageAction}
          tooltipKey='Contact Us'
        >
          <a href='mailto:support@crowdreason.com'>Contact Us</a>
        </TooltipWrapper>
      </div>
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  states: state.states,
});

export default connect(mapStateToProps)(UsageTable);
