import {useEffect} from 'react';
import _ from 'lodash';
import {getMaintenanceNotifications} from '../services';
import {useDispatch, useStore} from 'react-redux';
import { fetchAsyncMaintenanceNotifications } from '../redux/reducer';
import { ReduxState } from '../interface';

function useMaintenanceNotifications() {
  const dispatch = useDispatch();
  const store = useStore();
  useEffect(() => {
    if(_.isEmpty((store.getState() as ReduxState).maintenanceNotifications)) {
      // eslint-disable-next-line
      dispatch(fetchAsyncMaintenanceNotifications(getMaintenanceNotifications()) as any);
    }
  }, [fetchAsyncMaintenanceNotifications]);
}

export default useMaintenanceNotifications;
