import {useRef, useEffect} from 'react';

const usePrevious = <T>(value: T): T => {
  // eslint-disable-next-line
  const ref: any = useRef<T>();

  useEffect(() => {
    ref.current = JSON.parse(JSON.stringify(value));
  }, [value]);

  return ref.current;
};

export default usePrevious;
