import {Limit} from '../interface';

export const XLSXHEADER = {
  responseType: 'arraybuffer',
  headers: {
    'Content-Type': 'blob',
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
};

export const INCORPORATIONTYPE = [
  {
    value: 1,
    name: 'LLC',
    label: 'LLC',
  },
  {
    value: 2,
    name: 'LLP',
    label: 'LLP',
  },
  {
    value: 3,
    name: 'LP',
    label: 'LP',
  },
  {
    value: 4,
    name: 'INC',
    label: 'INC',
  },
  {
    value: 5,
    name: 'INC, Sub S',
    label: 'INC, Sub S',
  },
  {
    value: 6,
    name: 'GP',
    label: 'GP',
  },
  {
    value: 7,
    name: 'Corp',
    label: 'Corp',
  },
  {
    value: 8,
    name: 'Non-stock Corp',
    label: 'Non-stock Corp',
  },
  {
    value: 9,
    name: 'REIT',
    label: 'REIT',
  },
  {
    value: 10,
    name: 'Trust',
    label: 'Trust',
  },
  {
    value: 11,
    name: 'Other',
    label: 'Other',
  },
  {
    value: 12,
    name: 'Sole Proprietor',
    label: 'Sole Proprietor',
  },
];

export const PROPERTYLIMIT: Limit = {
  'Company Name': 75,
  'Company Number': 50,
  'Legal Entity Name': 75,
  'Legal Entity Number': 50,
  'Property Number': 50,
  State: 100,
  'Account Number': 100,
  Assessor: 100,
  'Property Name': 100,
  'Doing Business As': 100,
  'Property Cost Center': 50,
  'Open Date': 10,
  'Closed Date': 10,
  'Property GL Account': 50,
  'Property Description': 100,
  'Address 1': 100,
  'Address 2': 100,
  City: 50,
  'Zip Code': 10,
  'Account Name': 100,
  'Alternate Reference': undefined,
  'GL Account Number': 50,
  'Account Cost Center': 50,
  'Legal Description': 100,
  'Rendition Notes': 255,
  'Asset Number': 50,
  'Asset Acquisition Date': 50,
  'Asset Class': 100,
  'Original Cost': 20,
  'Tax Year': 4,
  'Assessor Account Number': 100,
  'Asset Description': 100,
  'Alternate Asset ID': 50,
  'Asset Description 2': 200,
  'Asset GL Code / Alternate Account Number': 50,
  'Asset Life': 4,
  'Asset Location': 100,
  'Asset Rebook Cost': 20,
  'Asset Rebook Date': 10,
  'Company Cost Center': 50,
  'Historical Asset Number': 50,
  'LTD Depreciation': 20,
  Notes: 255,
  Obsolescence: 255,
  'Original Assessor': 100,
  'Original Assessor Account Number': 100,
  'Rendered Market Value': 20,
  'Asset origin': 20,
  'Assessment Date': 10,
  'Acquisition Date': 10,
  'Vehicle Make': 50,
  'Vehicle Model': 50,
  'Vehicle VIN': 50,
  'Vehicle Year': 4,
  'Lessor Address': 100,
  'Lessor Name': 50,
};

export const PICKCOMPANYFIELD = [
  'name',
  'number',
  'naicsCode',
  'mailingAddress.line1',
  'mailingAddress.line2',
  'mailingAddress.city',
  'mailingAddress.stateId',
  'mailingAddress.postalCode',
  'packageReturnSameAsMailingAddress',
  'packageReturnAddress.line1',
  'packageReturnAddress.line2',
  'packageReturnAddress.city',
  'packageReturnAddress.stateId',
  'packageReturnAddress.postalCode',
  'packageReturnAddress.postalCode',
  'primaryContactFirstName',
  'primaryContactLastName',
  'primaryContactTitle',
  'primaryContactEmailAddress',
  'primaryContactPhoneNumber',
  'federalTaxId',
  'incorporationType',
  'incorporationStateId',
  'incorporateDate',
  'fiscalYearStartDate',
  'active',
];

export const PICKLEGALENTITYFIELD = [
  'companyId',
  'name',
  'number',
  'naicsCode',
  'sameAsCompany',
  'mailingAddress.line1',
  'mailingAddress.line2',
  'mailingAddress.city',
  'mailingAddress.stateId',
  'mailingAddress.postalCode',
  'packageReturnSameAsMailingAddress',
  'packageReturnAddress.line1',
  'packageReturnAddress.line2',
  'packageReturnAddress.city',
  'packageReturnAddress.stateId',
  'packageReturnAddress.postalCode',
  'primaryContactFirstName',
  'primaryContactLastName',
  'primaryContactTitle',
  'primaryContactEmailAddress',
  'primaryContactPhoneNumber',
  'federalTaxId',
  'incorporationType',
  'incorporationStateId',
  'incorporateDate',
  'fiscalYearStartDate',
  'active',
];

export const PICKACCOUNTFILED = [
  'number',
  'assessorId',
  'name',
  'alternateReference',
  'glAccountNumberInheritFromProperty',
  'glAccountNumber',
  'costCenterInheritFromProperty',
  'costCenter',
  'legalDescription',
  'renditionNotes',
  'taxYear',
  'active',
];

export const PICKPROPERTYFIELD = [
  'companyId',
  'legalEntityId',
  'number',
  'address.line1',
  'address.line2',
  'address.city',
  'address.stateId',
  'address.postalCode',
  'name',
  'doingBusinessAs',
  'costCenter',
  'openDate',
  'closedDate',
  'glAccountNumber',
  'description',
  'active',
];

export const INITIALMODAL = {
  isOpen: false,
  href: '',
};

export const ASSETFILETYPE = [
  'Fixed Asset',
  'Inventory',
  'Vehicle',
  'Leased Vehicle',
  'Leased Equipment',
];

export const ASSETFILETYPEINFORMATION = {
  'Fixed Asset':
    'Items capitalized for accounting purposes that are not sold or consumed during normal business operations. Includes assets such as land, buildings, machinery and equipment, leasehold improvements, etc.',
  'Inventory':
    'Item of tangible property that is held for sale in the normal course of business, in the process of being produced for such purpose, or is to be used in the production of such an item.',
  'Vehicle':
    'A thing used for transporting people or goods, especially on land, such as a car, truck, or cart.',
  'Leased Vehicle':
    'Vehicle leasing or car leasing is the leasing (or the use) of a motor vehicle for a fixed period of time at an agreed amount of money for the lease. It is commonly offered by dealers as an alternative to vehicle purchase but is widely used by businesses as a method of acquiring (or having the use of) vehicles for business, without the usually needed cash outlay. The key difference in a lease is that after the primary term (usually 2, 3 or 4 years) the vehicle has to either be returned to the leasing company or purchased for the residual value.',
  'Leased Equipment':
    'Items of a particular purpose that are allowed to be used by someone other than the owner through periodic lease payments.',
};

export const PROPERTYFILECOLUMNS = [
  {
    propertyData: 'Company Name',
    mappingPriority: 'Required',
    expectData: 'My Company',
  },
  {
    propertyData: 'Company Number',
    mappingPriority: 'Required',
    expectData: 'Co1',
  },
  {
    propertyData: 'Legal Entity Name',
    mappingPriority: 'Required',
    expectData: 'My Legal Entity',
  },
  {
    propertyData: 'Legal Entity Number',
    mappingPriority: 'Required',
    expectData: 'LE1',
  },
  {
    propertyData: 'Property Number',
    mappingPriority: 'Required',
    expectData: 'Prop1',
  },
  {
    propertyData: 'State',
    mappingPriority: 'Required',
    expectData: 'TX, two character state abbreviation',
  },
  {
    propertyData: 'Account Number',
    mappingPriority: 'Required',
    expectData: '123456789A',
  },
  {
    propertyData: 'Assessor',
    mappingPriority: 'Required',
    expectData: 'Bexar CAD',
  },
  {
    propertyData: 'Property Name',
    mappingPriority: 'Required',
    expectData: 'My Property',
  },
  {
    propertyData: 'Doing Business As',
    mappingPriority: 'Optional',
    expectData: 'My Business',
  },
  {
    propertyData: 'Property Cost Center',
    mappingPriority: 'Optional',
    expectData: '30-0000',
  },
  {
    propertyData: 'Open Date',
    mappingPriority: 'Optional',
    expectData: '11/05/2019',
  },
  {
    propertyData: 'Closed Date',
    mappingPriority: 'Optional',
    expectData: '02/22/2021',
  },
  {
    propertyData: 'Property GL Account',
    mappingPriority: 'Optional',
    expectData: '17300',
  },
  {
    propertyData: 'Property Description',
    mappingPriority: 'Optional',
    expectData: 'Office Equipment',
  },
  {
    propertyData: 'Address 1',
    mappingPriority: 'Optional',
    expectData: '123 Main Street',
  },
  {
    propertyData: 'Address 2',
    mappingPriority: 'Optional',
    expectData: 'Room 205',
  },
  {
    propertyData: 'City',
    mappingPriority: 'Optional',
    expectData: 'Houston',
  },
  {
    propertyData: 'Zip Code',
    mappingPriority: 'Optional',
    expectData: '77006',
  },
  {
    propertyData: 'Account Name',
    mappingPriority: 'Optional',
    expectData: 'My Account',
  },
  {
    propertyData: 'Alternate Reference',
    mappingPriority: 'Optional',
    expectData: '123456789-A',
  },
  {
    propertyData: 'GL Account Number',
    mappingPriority: 'Optional',
    expectData: '17000',
  },
  {
    propertyData: 'Account Cost Center',
    mappingPriority: 'Optional',
    expectData: 'ACC-1000',
  },
  {
    propertyData: 'Legal Description',
    mappingPriority: 'Optional',
    expectData: 'Lot 1, Block 12',
  },
  {
    propertyData: 'Rendition Notes',
    mappingPriority: 'Optional',
    expectData: 'My Notes',
  },
];

export const FIXEDASSETFILECOLUMNS = [
  {
    propertyData: 'Assessor Account Number',
    mappingPriority: 'Required',
    expectData: '123456789A',
  },
  {
    propertyData: 'Legal Entity Name',
    mappingPriority: 'Required',
    expectData: 'My Legal Entity',
  },
  {
    propertyData: 'State',
    mappingPriority: 'Required',
    expectData: 'TX, two character state abbreviation',
  },
  {
    propertyData: 'Property Number',
    mappingPriority: 'Required',
    expectData: 'Prop1',
  },
  {
    propertyData: 'Asset Number',
    mappingPriority: 'Required',
    expectData: '123456',
  },
  {
    propertyData: 'Acquisition Date',
    mappingPriority: 'Required',
    expectData: '03/11/2020',
  },
  {
    propertyData: 'Asset Class',
    mappingPriority: 'Required',
    expectData: 'Machinery',
  },
  {
    propertyData: 'Original Cost',
    mappingPriority: 'Required',
    expectData: '7777.25',
  },
  {
    propertyData: 'Tax Year',
    mappingPriority: 'Required',
    expectData: '2023',
  },
  {
    propertyData: 'Company Name',
    mappingPriority: 'Recomended',
    expectData: 'My Company',
  },
  {
    propertyData: 'Assessor',
    mappingPriority: 'Recomended',
    expectData: 'Bexar CAD',
  },
  {
    propertyData: 'Asset Description',
    mappingPriority: 'Recomended',
    expectData: 'My Fixed Asset Description',
  },
  {
    propertyData: 'Property Name',
    mappingPriority: 'Recomended',
    expectData: 'My Property',
  },
  {
    propertyData: 'Alternate Asset ID',
    mappingPriority: 'Optional',
    expectData: '123456-Alt',
  },
  {
    propertyData: 'Asset Description 2',
    mappingPriority: 'Optional',
    expectData: 'My Asset Description 2',
  },
  {
    propertyData: 'Asset GL Code / Alternate Account Number',
    mappingPriority: 'Optional',
    expectData: '65000',
  },
  {
    propertyData: 'Asset Life',
    mappingPriority: 'Optional',
    expectData: '10',
  },
  {
    propertyData: 'Asset Location',
    mappingPriority: 'Optional',
    expectData: 'My Asset Location',
  },
  {
    propertyData: 'Asset Rebook Cost',
    mappingPriority: 'Optional',
    expectData: '555.00',
  },
  {
    propertyData: 'Asset Rebook Date',
    mappingPriority: 'Optional',
    expectData: '01/01/2022',
  },
  {
    propertyData: 'Company Cost Center',
    mappingPriority: 'Optional',
    expectData: '55000',
  },
  {
    propertyData: 'Company Number',
    mappingPriority: 'Optional',
    expectData: 'Co1',
  },
  {
    propertyData: 'Historical Asset Number',
    mappingPriority: 'Optional',
    expectData: '654321',
  },
  {
    propertyData: 'LTD Depreciation',
    mappingPriority: 'Optional',
    expectData: '222.22',
  },
  {
    propertyData: 'Notes',
    mappingPriority: 'Optional',
    expectData: 'My Notes',
  },
  {
    propertyData: 'Obsolescence',
    mappingPriority: 'Optional',
    expectData: '0.1234',
  },
  {
    propertyData: 'Original Assessor',
    mappingPriority: 'Optional',
    expectData: 'Travis CAD',
  },
  {
    propertyData: 'Original Assessor Account Number',
    mappingPriority: 'Optional',
    expectData: 'X45606',
  },
  {
    propertyData: 'Legal Entity Number',
    mappingPriority: 'Optional',
    expectData: 'LE1',
  },
  {
    propertyData: 'Rendered Market Value',
    mappingPriority: 'Optional',
    expectData: '1000.99',
  },
  {
    propertyData: 'Disposed',
    mappingPriority: 'Optional',
    expectData: 'TRUE, FALSE, Yes, No',
  },
  {
    propertyData: 'Addition',
    mappingPriority: 'Optional',
    expectData: 'TRUE, FALSE, Yes, No',
  },
];

export const INVENTORYFILECOLUMNS = [
  {
    propertyData: 'Assessor Account Number',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Legal Entity Name',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Property Number',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'State',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset Number',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset Class',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Tax Year',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Company Name',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Assessor',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset Description',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Property Name',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'January',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'February',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'March',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'April',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'May',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'June',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'July',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'August',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'September',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'October',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'November',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'December',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Alternate Asset ID',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Notes',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Legal Entity Number',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Disposed',
    mappingPriority: 'Optional',
    expectData: 'TRUE, FALSE, Yes, No',
  },
  {
    propertyData: 'Addition',
    mappingPriority: 'Optional',
    expectData: 'TRUE, FALSE, Yes, No',
  },
];

export const VEHICLEFILECOLUMNS = [
  {
    propertyData: 'Assessor Account Number',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Legal Entity Name',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Property Number',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'State',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset Number',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Acquisition Date',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset Class',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Original Cost',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Tax Year',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Company Name',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Assessor',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset Description',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Property Name',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Vehicle Make',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Vehicle Model',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Vehicle VIN',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Vehicle Year',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Alternate Asset ID',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset GL Code / Alternate Account Number',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset Life',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Company Cost Center',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Historical Asset Number',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'LTD Depreciation',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Notes',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Legal Entity Number',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Rebook Cost',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Rebook Date',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Rendered Market Value',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Disposed',
    mappingPriority: 'Optional',
    expectData: 'TRUE, FALSE, Yes, No',
  },
  {
    propertyData: 'Addition',
    mappingPriority: 'Optional',
    expectData: 'TRUE, FALSE, Yes, No',
  },
];

export const LEASEVEHICLEFILECOLUMNS = [
  {
    propertyData: 'Assessor Account Number',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Legal Entity Name',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Property Number',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'State',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset Number',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Acquisition Date',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset Class',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Tax Year',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Company Name',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Assessor',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset Description',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Lease End Date',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Lease Start Date',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Lessor Address',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Lessor Name',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Original Cost',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Property Name',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Vehicle Make',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Vehicle Model',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Vehicle VIN',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Vehicle Year',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Alternate Asset ID',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset GL Code / Alternate Account Number',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset Life',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Company Cost Center',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'LTD Depreciation',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Monthly Rent',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Notes',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Legal Entity Number',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Rebook Cost',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Rebook Date',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Rendered Market Value',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Disposed',
    mappingPriority: 'Optional',
    expectData: 'TRUE, FALSE, Yes, No',
  },
  {
    propertyData: 'Addition',
    mappingPriority: 'Optional',
    expectData: 'TRUE, FALSE, Yes, No',
  },
];

export const LEASEEQUIPMENTFILECOLUMNS = [
  {
    propertyData: 'Assessor Account Number',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Legal Entity Name',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Property Number',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'State',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset Number',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Acquisition Date',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset Class',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Tax Year',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Company Name',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Assessor',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset Description',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Lease End Date',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Lease Start Date',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Lessor Address',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Lessor Name',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Original Cost',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Property Name',
    mappingPriority: 'Recomended',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Alternate Asset ID',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset GL Code / Alternate Account Number',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Asset Life',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Company Cost Center',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'LTD Depreciation',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Monthly Rent',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Notes',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Legal Entity Number',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Rebook Cost',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Rebook Date',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Rendered Market Value',
    mappingPriority: 'Optional',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Disposed',
    mappingPriority: 'Optional',
    expectData: 'TRUE, FALSE, Yes, No',
  },
  {
    propertyData: 'Addition',
    mappingPriority: 'Optional',
    expectData: 'TRUE, FALSE, Yes, No',
  },
];

export const ROLLOVERASSETFILECOLUMNS = [
  {
    propertyData: 'Company Name',
    mappingPriority: 'Required',
    expectData: 'My Company',
  },
  {
    propertyData: 'Legal Entity Name',
    mappingPriority: 'Required',
    expectData: 'My Legal Entity',
  },
  {
    propertyData: 'Property Number',
    mappingPriority: 'Required',
    expectData: 'Prop1',
  },
  {
    propertyData: 'Assessor Account Number',
    mappingPriority: 'Required',
    expectData: '123456789A',
  },
  {
    propertyData: 'State',
    mappingPriority: 'Required',
    expectData: 'TX, two character state abbreviation',
  },
  {
    propertyData: 'Assessor',
    mappingPriority: 'Required',
    expectData: 'Bexar CAD',
  },
  {
    propertyData: 'Asset Number',
    mappingPriority: 'Required',
    expectData: '123456',
  },
  {
    propertyData: 'Asset Class',
    mappingPriority: 'Required',
    expectData: 'Machinery',
  },
  {
    propertyData: 'Acquisition Date',
    mappingPriority: 'Required',
    expectData: '03/11/2020',
  },
  {
    propertyData: 'Original Cost',
    mappingPriority: 'Required',
    expectData: '7777.25',
  },
  {
    propertyData: 'Tax Year',
    mappingPriority: 'Optional',
    expectData: '2023',
  },
  {
    propertyData: 'Asset Description',
    mappingPriority: 'Optional',
    expectData: 'My Fixed Asset Description',
  },
  {
    propertyData: 'Property Name',
    mappingPriority: 'Optional',
    expectData: 'My Property',
  },
  {
    propertyData: 'Alternate Asset ID',
    mappingPriority: 'Optional',
    expectData: '123456-Alt',
  },
  {
    propertyData: 'Asset Description 2',
    mappingPriority: 'Optional',
    expectData: 'My Asset Description 2',
  },
  {
    propertyData: 'Asset GL Code / Alternate Account Number',
    mappingPriority: 'Optional',
    expectData: '65000',
  },
  {
    propertyData: 'Asset Life',
    mappingPriority: 'Optional',
    expectData: '10',
  },
  {
    propertyData: 'Asset Location',
    mappingPriority: 'Optional',
    expectData: 'My Asset Location',
  },
  {
    propertyData: 'Asset Rebook Cost',
    mappingPriority: 'Optional',
    expectData: '555.00',
  },
  {
    propertyData: 'Asset Rebook Date',
    mappingPriority: 'Optional',
    expectData: '01/01/2022',
  },
  {
    propertyData: 'Company Cost Center',
    mappingPriority: 'Optional',
    expectData: '55000',
  },
  {
    propertyData: 'Company Number',
    mappingPriority: 'Optional',
    expectData: 'Co1',
  },
  {
    propertyData: 'Historical Asset Number',
    mappingPriority: 'Optional',
    expectData: '654321',
  },
  {
    propertyData: 'LTD Depreciation',
    mappingPriority: 'Optional',
    expectData: '222.22',
  },
  {
    propertyData: 'Notes',
    mappingPriority: 'Optional',
    expectData: 'My Notes',
  },
  {
    propertyData: 'Obsolescence',
    mappingPriority: 'Optional',
    expectData: '0.1234',
  },
  {
    propertyData: 'Original Assessor',
    mappingPriority: 'Optional',
    expectData: 'Travis CAD',
  },
  {
    propertyData: 'Original Assessor Account Number',
    mappingPriority: 'Optional',
    expectData: 'X45606',
  },
  {
    propertyData: 'Legal Entity Number',
    mappingPriority: 'Optional',
    expectData: 'LE1',
  },
  {
    propertyData: 'Rendered Market Value',
    mappingPriority: 'Optional',
    expectData: '1000.99',
  },
  {
    propertyData: 'Disposed',
    mappingPriority: 'Optional',
    expectData: 'TRUE, FALSE, Yes, No',
  },
  {
    propertyData: 'Addition',
    mappingPriority: 'Optional',
    expectData: 'TRUE, FALSE, Yes, No',
  },
];

export const ROLLOVERINVENTORYASSETFILECOLUMNS = [
  {
    propertyData: 'Company Name',
    mappingPriority: 'Required',
    expectData: 'My Company',
  },
  {
    propertyData: 'Legal Entity Name',
    mappingPriority: 'Required',
    expectData: 'My Legal Entity',
  },
  {
    propertyData: 'Property Number',
    mappingPriority: 'Required',
    expectData: 'Prop1',
  },
  {
    propertyData: 'Assessor Account Number',
    mappingPriority: 'Required',
    expectData: '123456789A',
  },
  {
    propertyData: 'State',
    mappingPriority: 'Required',
    expectData: 'TX, two character state abbreviation',
  },
  {
    propertyData: 'Assessor',
    mappingPriority: 'Required',
    expectData: 'Bexar CAD',
  },
  {
    propertyData: 'Asset Number',
    mappingPriority: 'Required',
    expectData: '123456',
  },
  {
    propertyData: 'Asset Class',
    mappingPriority: 'Required',
    expectData: 'Machinery',
  },
  {
    propertyData: 'Tax Year',
    mappingPriority: 'Optional',
    expectData: '2023',
  },
  {
    propertyData: 'Asset Description',
    mappingPriority: 'Optional',
    expectData: 'My Fixed Asset Description',
  },
  {
    propertyData: 'Property Name',
    mappingPriority: 'Optional',
    expectData: 'My Property',
  },
  {
    propertyData: 'Alternate Asset ID',
    mappingPriority: 'Optional',
    expectData: '123456-Alt',
  },
  {
    propertyData: 'Asset Description 2',
    mappingPriority: 'Optional',
    expectData: 'My Asset Description 2',
  },
  {
    propertyData: 'Asset GL Code / Alternate Account Number',
    mappingPriority: 'Optional',
    expectData: '65000',
  },
  {
    propertyData: 'Asset Life',
    mappingPriority: 'Optional',
    expectData: '10',
  },
  {
    propertyData: 'Asset Location',
    mappingPriority: 'Optional',
    expectData: 'My Asset Location',
  },
  {
    propertyData: 'Asset Rebook Cost',
    mappingPriority: 'Optional',
    expectData: '555.00',
  },
  {
    propertyData: 'Asset Rebook Date',
    mappingPriority: 'Optional',
    expectData: '01/01/2022',
  },
  {
    propertyData: 'Company Cost Center',
    mappingPriority: 'Optional',
    expectData: '55000',
  },
  {
    propertyData: 'Company Number',
    mappingPriority: 'Optional',
    expectData: 'Co1',
  },
  {
    propertyData: 'Historical Asset Number',
    mappingPriority: 'Optional',
    expectData: '654321',
  },
  {
    propertyData: 'LTD Depreciation',
    mappingPriority: 'Optional',
    expectData: '222.22',
  },
  {
    propertyData: 'Notes',
    mappingPriority: 'Optional',
    expectData: 'My Notes',
  },
  {
    propertyData: 'Obsolescence',
    mappingPriority: 'Optional',
    expectData: '0.1234',
  },
  {
    propertyData: 'Original Assessor',
    mappingPriority: 'Optional',
    expectData: 'Travis CAD',
  },
  {
    propertyData: 'Original Assessor Account Number',
    mappingPriority: 'Optional',
    expectData: 'X45606',
  },
  {
    propertyData: 'Legal Entity Number',
    mappingPriority: 'Optional',
    expectData: 'LE1',
  },
  {
    propertyData: 'Rendered Market Value',
    mappingPriority: 'Optional',
    expectData: '1000.99',
  },
  {
    propertyData: 'Disposed',
    mappingPriority: 'Optional',
    expectData: 'TRUE, FALSE, Yes, No',
  },
  {
    propertyData: 'Addition',
    mappingPriority: 'Optional',
    expectData: 'TRUE, FALSE, Yes, No',
  },
];

export const ASSETORDERKEYS = {
  'Rollover Status': 'rolloverStatus',
  'Asset Status': 'status',
  'Assessor Account Number': 'assessorAccount.number',
  'Legal Entity Name': 'assessorAccount.property.legalEntity.name',
  State: 'assessorAccount.property.address.state.abbreviation',
  'Property Number': 'assessorAccount.property.number',
  'Asset Number': 'number',
  'Acquisition Date': 'acquisitionDate',
  'Asset Acquisition Date': 'acquisitionDate',
  'Asset Class': 'class',
  'Original Cost': 'originalCost',
  'Tax Year': 'taxYear',
};

export const USER_FIELD_COLUMNS = [
  {
    propertyData: 'Name',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Email',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Role',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
  {
    propertyData: 'Date Added',
    mappingPriority: 'Required',
    expectData: 'Expected format/ data',
  },
];

export const USER_TABLE_ORDER_KEYS = {
  Name: 'name',
  Email: 'email',
  Role: 'role',
};

export const USAGE_TABLE_ORDER_KEYS = {
  'Purchase Date': 'purchaseDate',
  TaxYear: 'taxYear',
  'Returns Purchased': 'purchased',
  'Returns Used': 'used',
  'Renewal Date': 'renewalDate',
};

export const PAGES_NAMES_MAPPING = {
  'Manage Returns': '/returns',
  'Review Account Assets': '/returns/reviewAccountAssets/\\d+',
  'Edit Account Mapping & Taxability':
    '/returns/editAccountMappingAndTaxability/\\d+',
  'Preview Return Form': '/returns/previewReturnForm/\\d+',
  'Preview Package': '/returns/previewPackage/\\d+',
  Dashboard: '/dashboard',
  'Account Summary': '/properties',
  'Company Setup': '(/properties/companySetup)|(/properties/editCompany/\\d+)',
  'Property Upload': '/properties/propertyUpload',
  'Property Map File': '/properties/propertyMapFile/\\d+',
  'Property Review': '/properties/propertyReview/\\d+',
  'Property Setup':
    '(/properties/propertySetup)|(/properties/editProperty/\\d+)|(/properties/editUploadProperty/\\d+)',
  'Legal Entity Setup':
    '(/properties/legalEntitySetup)|(/properties/editLegalEntity/\\d+)',
  'Asset Upload': '/assets/assetsUpload',
  'Asset Map File': '/assets/assetsMapFile/\\d+',
  'Asset Review': '/assets/assetsReview/\\d+',
  'Asset Setup':
    '/assets/(fixedAssetSetup)|(editFixedAssetSetup/\\d+)|(editUploadFixedAssetSetup/\\d+)|(inventorySetup)|(editInventorySetup/\\d+)|(editUploadInventorySetup/\\d+)|(vehicleSetup)|(editVehicleSetup/\\d+)|(editUploadVehicleSetup/\\d+)|(leasedVehicleSetup)|(editLeasedVehicleSetup/\\d+)|(editUploadLeasedVehicleSetup/\\d+)|(leasedEquipmentSetup)|(editLeasedEquipmentSetup/\\d+)|(editUploadLeasedEquipmentSetup/\\d+)',
  'Manage Inventory Asset':
    '((/assets/?)|(/assets/assets/?)|(/assets/rollover/?))\\?assetTab=Inventory',
  'Manage Vehicle Asset':
    '((/assets/?)|(/assets/assets/?)|(/assets/rollover/?))\\?assetTab=Vehicle',
  'Manage Leased Vehicle Asset':
    '((/assets/?)|(/assets/assets/?)|(/assets/rollover/?))\\?assetTab=Leased\\+Vehicle',
  'Manage Leased Equipment Asset':
    '((/assets/?)|(/assets/assets/?)|(/assets/rollover/?))\\?assetTab=Leased\\+Equipment',
  //Please make sure Manage Fixed Asset at the bottom
  'Manage Fixed Asset':
    '((/assets/?)|(/assets/assets/?)|(/assets/rollover/?))(\\?assetTab=Fixed\\+Asset)?',
  'Manage Forms': '/forms',
  'Manage Forms Details':
    '(/forms/formDetails/\\d+)|(/forms/formDetails/\\d+/return/\\d+)',
  'Manage Package Content':
    '(/forms/packageContents/\\d+)|(/forms/return/\\d+)',
  'Manage Reports': '/reports',
  'Generate Report': '/reports/generate',
  Help: '/help',
  Users: '/users',
  Plans: '/plans',
};

export const SEARCHABLELIMIT = 5;

export const PROPERTIES = [
  'Companies',
  'Legal Entities',
  'Properties and Assessor Accounts',
];
