import React, {MutableRefObject, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import {TaxReturnProcess} from '../../interface';
import style from './Steps.module.scss';
import {
  getDownloadTemplate,
  getErrorMessage,
  updateUserProfile,
} from '../../services';
import exportXLSX from '../../utils/exportFile';
import {LoaderIndicator} from '../Loader/Loader';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS, EUserRoles} from '../../enums';
import {ToastMessageRefType} from '../ToastMessage/ToastMessage';
import useUserProfile from '../../hook/useUserProfile';
import {includes} from 'lodash';

interface Props {
  data: TaxReturnProcess | undefined;
  toastRef: MutableRefObject<ToastMessageRefType | null>;
}

function PropertiesButtonComponent(props: {
  data: TaxReturnProcess | undefined;
}) {
  const {data} = props;
  if (!data?.companies || !data?.legalEntities) {
    return (
      <TooltipWrapper
        tooltipSection={TOOLTIP_SECTIONS.GetStarted}
        tooltipKey='Get Started Button'
      >
        <Link to='#' className='button secondary disabled'>
          Get Started
        </Link>
      </TooltipWrapper>
    );
  } else {
    if (!data?.properties) {
      return (
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.GetStarted}
          tooltipKey='Get Started Button'
        >
          <Link
            role='button'
            to='/properties/propertyUpload'
            className='button primary'
          >
            Get Started
          </Link>
        </TooltipWrapper>
      );
    } else {
      return (
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.GetStarted}
          tooltipKey='Edit Properties'
        >
          <Link
            role='button'
            to='/properties?tab=Companies'
            className={classNames(
              'button',
              'secondary',
              style['success-button'],
            )}
          >
            Edit Properties
          </Link>
        </TooltipWrapper>
      );
    }
  }
}

function LegalEntitiesButtonComponent(props: {
  data: TaxReturnProcess | undefined;
}) {
  const {data} = props;
  const navigate = useNavigate();
  if (!data?.companies) {
    return (
      <TooltipWrapper
        tooltipSection={TOOLTIP_SECTIONS.GetStarted}
        tooltipKey='Get Started Button'
      >
        <Link to='#' className='button secondary disabled'>
          Get Started
        </Link>
      </TooltipWrapper>
    );
  } else {
    if (!data?.legalEntities) {
      return (
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.GetStarted}
          tooltipKey='Get Started Button'
        >
          <button
            role='button'
            className='button primary'
            onClick={() =>
              navigate('/properties/legalEntitySetup', {
                state: '/dashboard',
              })
            }
          >
            Get Started
          </button>
        </TooltipWrapper>
      );
    } else {
      return (
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.GetStarted}
          tooltipKey='Edit Legal Entity Info'
        >
          <Link
            role='button'
            to='/properties?tab=Companies'
            className={classNames(
              'button',
              'secondary',
              style['success-button'],
            )}
          >
            Edit Legal Entity Info
          </Link>
        </TooltipWrapper>
      );
    }
  }
}

function CompaniesButtonComponent(props: {
  data: TaxReturnProcess | undefined;
  userRoles: EUserRoles[] | undefined;
}) {
  const {data, userRoles} = props;
  const navigate = useNavigate();
  if (!includes(userRoles, EUserRoles.AccountPropertyModerator)) {
    return (
      <TooltipWrapper
        tooltipSection={TOOLTIP_SECTIONS.GetStarted}
        tooltipKey='Get Started Button'
      >
        <Link to='#' className='button secondary disabled'>
          Get Started
        </Link>
      </TooltipWrapper>
    );
  } else {
    if (!data?.companies) {
      return (
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.GetStarted}
          tooltipKey='Get Started Button'
        >
          <button
            role='button'
            className='button primary'
            onClick={() =>
              navigate('/properties/companySetup', {
                state: '/dashboard',
              })
            }
          >
            Get Started
          </button>
        </TooltipWrapper>
      );
    } else {
      return (
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.GetStarted}
          tooltipKey='Edit Company Info'
        >
          <Link
            role='button'
            to='/properties?tab=Companies'
            className={classNames(
              'button',
              'secondary',
              style['success-button'],
            )}
          >
            Edit Company Info
          </Link>
        </TooltipWrapper>
      );
    }
  }
}

function Steps(props: Props) {
  const {data, toastRef} = props;
  const userProfile = useUserProfile();
  const userRoles = userProfile?.roles;
  const [downloading, setDownloading] = useState(false);
  const download = () => {
    const res = getDownloadTemplate();
    setDownloading(true);
    exportXLSX(res, () => {
      setDownloading(false);
    });
  };
  const [updatingUserProfile, setUpdatingUserProfile] = useState(false);

  const completeSetup = () => {
    setUpdatingUserProfile(true);
    updateUserProfile()
      .then(() => {
        sessionStorage.setItem('skip', 'true');
        setUpdatingUserProfile(false);
        window.location.href = '/dashboard';
      })
      .catch((e) => {
        toastRef.current?.showErrorToast(getErrorMessage(e));
        setUpdatingUserProfile(false);
      });
  }

  return (
    <div className={style['content']}>
      <div className={style['infor']}>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.GetStarted}
          tooltipKey='Section Title'
        >
          <h3>Get Started</h3>
        </TooltipWrapper>
        <div className={style['details']}>
          <p>
            Welcome to Avalara Property Tax Returns Pro! To complete your setup,
            enter your company information by following the simple steps below.
            Once this is done, you will be ready to import your assets and
            prepare for filing your property tax returns.
          </p>
          <div className={style['links']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.GetStarted}
              tooltipKey='Skip Link'
            >
              <button
                role='button'
                className='link'
                onClick={completeSetup}
                disabled={updatingUserProfile}
              >
                I’d like to skip the guided setup
                {updatingUserProfile && (
                  <LoaderIndicator className='button-loading' loading={true} />
                )}
              </button>
            </TooltipWrapper>
          </div>
        </div>
      </div>
      <div
        className={classNames(style['block'], style['company'], {
          [style['disabled']]: !includes(
            userRoles,
            EUserRoles.AccountPropertyModerator,
          ),
          [style['active']]:
            includes(userRoles, EUserRoles.AccountPropertyModerator) &&
            !data?.companies,
          [style['success']]: data?.companies,
        })}
      >
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.GetStarted}
          tooltipKey='Set up Company'
        >
          <strong>Set up Company</strong>
        </TooltipWrapper>

        <p>First things first - enter your Company details.</p>
        <div className={style['buttons']}>
          <CompaniesButtonComponent data={data} userRoles={userRoles} />
        </div>
      </div>
      <div
        className={classNames(style['block'], style['legal-entity'], {
          [style['disabled']]:
            !includes(userRoles, EUserRoles.AccountPropertyModerator) ||
            !data?.companies,
          [style['active']]:
            includes(userRoles, EUserRoles.AccountPropertyModerator) &&
            data?.companies &&
            !data?.legalEntities,
          [style['success']]: data?.legalEntities,
        })}
      >
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.GetStarted}
          tooltipKey='Set up Legal Entity'
        >
          <strong>Set up Legal Entity</strong>
        </TooltipWrapper>

        <p>
          Next up - enter your Legal Entity details. If it is the same as your
          Company, this will be quick!
        </p>
        <div className={style['buttons']}>
          <LegalEntitiesButtonComponent data={data} />
        </div>
      </div>
      <div
        className={classNames(
          style['block'],
          style['properties'],
          {
            [style['disabled']]:
              !includes(userRoles, EUserRoles.AccountPropertyModerator) ||
              !data?.companies ||
              !data.legalEntities,
          },
          {
            [style['active']]:
              includes(userRoles, EUserRoles.AccountPropertyModerator) &&
              data?.companies &&
              data?.legalEntities &&
              !data?.properties,
          },
          {
            [style['success']]: data?.properties,
          },
        )}
      >
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.GetStarted}
          tooltipKey='Set up Properties'
        >
          <strong>Set up Properties</strong>
        </TooltipWrapper>

        <p>
          Almost done - time to enter your Property details. This includes all
          of your physical locations and Assessor Accounts for which you plan to
          file property tax returns.
        </p>
        <div className={style['buttons']}>
          <PropertiesButtonComponent data={data} />
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.GetStarted}
            tooltipKey='Download Link'
          >
            <button
              disabled={downloading || !data?.companies}
              className='link'
              onClick={download}
            >
              Download template and formatting guide
              {downloading ? (
                <LoaderIndicator className='button-loading' loading={true} />
              ) : null}
            </button>
          </TooltipWrapper>
        </div>
      </div>
      <div
        className={classNames(
          style['block'],
          style['setup'],
          {
            [style['disabled']]: !data?.companies || !data?.properties,
          },
          {
            [style['active']]: data?.properties && data?.companies,
          },
        )}
      >
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.GetStarted}
          tooltipKey='Complete setup'
        >
          <strong className={style['last']}>Complete setup</strong>
        </TooltipWrapper>

        <p>
          You did it! You are ready to complete your setup and review your
          information.
        </p>
        <div className={style['buttons']}>
          {!data?.companies || !data?.properties ? (
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.GetStarted}
              tooltipKey='Complete and view properties Button'
            >
              <Link to='#' className='button secondary'>
              Complete and view properties
              </Link>
            </TooltipWrapper>
          ) : (
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.GetStarted}
              tooltipKey='Complete and view properties Button'
            >
              <button
                role='button'
                className='primary'
                onClick={completeSetup}
                disabled={updatingUserProfile}
              >
                Complete and view properties
                {updatingUserProfile && (
                  <LoaderIndicator className='button-loading' loading={true} />
                )}
              </button>
            </TooltipWrapper>
          )}
        </div>
      </div>
    </div>
  );
}

export default Steps;
