import React, { MutableRefObject } from 'react';
import {Link} from 'react-router-dom';
import _, { get } from 'lodash';
import AssetsDropZone from '../AssetsDropzone/AssetsDropzone';
import style from './AddAsset.module.scss';
import {ASSETFILETYPE} from '../../constants';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import { TOOLTIP_SECTIONS } from '../../enums';
import { ToastMessageRefType } from '../ToastMessage/ToastMessage';

interface Props {
  uploadError: (value: string) => void;
  uploadSuccess: () => void;
  toastRef: MutableRefObject<ToastMessageRefType | null>;
}

function AddAsset(props: Props) {
  const {uploadError, toastRef, uploadSuccess} = props;
  return (
    <div className={style['blocks']}>
      <div>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.PageAction}
          tooltipKey='Click here to add asset manually'
        >
          <Link
            role='button'
            className={style['add-property-block']}
            to={get(
              {
                [ASSETFILETYPE[0]]: "/assets/fixedAssetSetup",
                [ASSETFILETYPE[1]]: "/assets/inventorySetup",
                [ASSETFILETYPE[2]]: "/assets/vehicleSetup",
                [ASSETFILETYPE[3]]: "/assets/leasedVehicleSetup",
                [ASSETFILETYPE[4]]: "/assets/leasedEquipmentSetup",
              },
              sessionStorage.getItem("assetType") ?? "",
              "/assets/fixedAssetSetup"
            )}
          >
            {sessionStorage.getItem('assetType')
              ? `Click here to add ${_.lowerCase(
                  sessionStorage.getItem('assetType') as string,
                )} manually`
              : `Click here to add assset manually`}
          </Link>
        </TooltipWrapper>
        <div className={style['rollover']}>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageAction}
            tooltipKey='Rollover assets'
          >
            <Link to='/assets/rollover' className='button primary'>
              Rollover assets
            </Link>
          </TooltipWrapper>
        </div>
      </div>
      <div className={style['column']}>
        <AssetsDropZone
          toastRef={toastRef}
          type={sessionStorage.getItem('assetType')}
          uploadSuccess={uploadSuccess}
          uploadError={uploadError}
        />
      </div>
    </div>
  );
}

export default AddAsset;
