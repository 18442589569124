import _ from 'lodash';
import { TypeColumnsKeys } from "../interface";

const reMatchKeys = (value: TypeColumnsKeys) => {
    return _.mapValues(value, (v, k) => {
        if(_.isEqual(k, 'Company Name')) {
            return 'company.name'
        }
        if(_.isEqual(k, 'Company Number')) {
            return 'company.number'
        }
        if(_.isEqual(k, 'Legal Entity Name')) {
            return 'assessorAccount.property.legalEntity.name'
        }
        if(_.isEqual(k, 'Legal Entity Number')) {
            return 'assessorAccount.property.legalEntity.number'
        }
        if(_.isEqual(k, 'State')) {
            return 'assessorAccount.property.address.state.abbreviation'
        }
        if(_.isEqual(k, 'Property Name')) {
            return 'assessorAccount.property.name'
        }
        if(_.isEqual(k, 'Property Number')) {
            return 'assessorAccount.property.number'
        }
        if(_.isEqual(k, 'Acquisition Date')) {
            return 'acquisitionDate'
        }
        if(_.isEqual(k, 'Assessor Account Number')) {
            return 'assessorAccount.number'
        }
        if(_.isEqual(k, 'Assessor')) {
            return 'assessorAccount.assessor.name'
        }
        return v;
    });
}

export default reMatchKeys;