import React, {useEffect, useRef, useState} from 'react';
import Steps from '../../../components/Steps/Steps';
import Prograss from '../../../components/Prograss/Prograss';
import Help from '../../../components/Help/Help';
import {getErrorMessage, getUserProfile} from '../../../services';
import {IErrorBase, IToastMessage} from '../../../interface';
import style from './Onboarding.module.scss';
import moment from 'moment';
import _ from 'lodash';
import PrimaryPanels from '../../../components/PrimaryPanels/PrimaryPanels';
import classNames from 'classnames';
import EventsBoard from '../../../components/EventsBoard/EventsBoard';
import Resources from '../../../components/Resources/Resources';
import {AxiosResponse} from 'axios';
import ToastMessage, {
  ToastMessageRefType,
} from '../../../components/ToastMessage/ToastMessage';
import {useAuth} from 'react-oidc-context';
import FieldDate from '../../../components/FieldDate/FieldDate';
import {ISO_DATE} from '../../../utils/date';
import Loader from '../../../components/Loader/Loader';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../enums';
import useDashboardInfo from '../../../hook/useDashboardInfo';

function Onboarding() {
  const auth = useAuth();
  const {currentUserPlan, taxYear, deadlines, taxReturnProcess} =
    useDashboardInfo();
  const [skip, setSkip] = useState<boolean>();
  // reset valid by click the close icon of error message box
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [fetchingProfile, setFetchingProfile] = useState(false);
  const toastRef = useRef<ToastMessageRefType | null>(null);

  useEffect(() => {
    if (sessionStorage.getItem('skip')) {
      setSkip(_.isEqual(sessionStorage.getItem('skip'), 'true') ? true : false);
    } else {
      setFetchingProfile(true);
      getUserProfile()
        .then((e: AxiosResponse<IErrorBase>) => {
          if (e.data?.message) {
            setToastMessage({
              visible: true,
              message: e.data.message,
              type: 'error',
            });
          } else {
            setSkip(e.data.userAccount?.setupCompleted);
            sessionStorage.setItem(
              'skip',
              _.toString(e.data.userAccount?.setupCompleted),
            );
          }
          setFetchingProfile(false);
        })
        .catch((e) => {
          setToastMessage({
            visible: true,
            message: getErrorMessage(e),
            type: 'error',
          });
          setFetchingProfile(false);
        });
    }
  }, []);

  function setToastClass() {
    // ignore in coverage report because toastMessage?.type can not be null
    /* istanbul ignore else */
    if (toastMessage?.type) {
      return toastMessage?.type;
    } else {
      return '';
    }
  }

  function dashboardElement() {
    if (_.isUndefined(skip)) {
      if (toastMessage?.visible) {
        return (
          <div className={style['toast']}>
            <ToastMessage
              status={toastMessage}
              className={setToastClass()}
              callback={() => auth.signoutRedirect()}
            />
          </div>
        );
      }
    } else {
      if (skip) {
        return (
          <div className={style['primary-content']}>
            <div className={style['main']}>
              <h2>
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.PageTitle}
                  tooltipKey='Dashboard'
                >
                  <span>Dashboard</span>
                </TooltipWrapper>
              </h2>
              {/* Section */}
              <div className={style['section']}>
                <div className={style['bar']}>
                  <h3>
                    <TooltipWrapper
                      tooltipSection={TOOLTIP_SECTIONS.Common}
                      tooltipKey='Tax Return Process'
                    >
                      <span>Tax Return Process</span>
                    </TooltipWrapper>
                    <span className={style['color']}>Tax Year {taxYear}</span>
                  </h3>
                  <p>
                    Suggest to complete before{' '}
                    {moment
                      .max(_.map(deadlines, (o) => moment(o['deadline'])))
                      .format('MM/DD/YYYY')}{' '}
                    (
                    {Math.abs(
                      moment
                        .max(_.map(deadlines, (o) => moment(o['deadline'])))
                        .diff(moment(), 'days'),
                    )}{' '}
                    days{' '}
                    {moment
                      .max(_.map(deadlines, (o) => moment(o['deadline'])))
                      .diff(moment(), 'days') > 0
                      ? 'left'
                      : 'ago'}
                    )
                  </p>
                </div>
                <PrimaryPanels data={taxReturnProcess} />
              </div>
              {/* Important Dates Section */}
              <div className={style['section']}>
                <div className={classNames(style['bar'], style['dates-bar'])}>
                  <h3>
                    <TooltipWrapper
                      tooltipSection={TOOLTIP_SECTIONS.Common}
                      tooltipKey='Important Dates'
                    >
                      <span>Important Dates</span>
                    </TooltipWrapper>
                  </h3>
                </div>
                <div className={style['content']}>
                  <div className={style['picker']}>
                    <FieldDate
                      labelText=''
                      selectValue={moment().format(ISO_DATE)}
                      disabledDates={_.map(deadlines, (d) => d.deadline)}
                      showCalender
                      hideTextField
                      hideActionBottom
                      hideTodayButton
                      hideWeekdaysLabel
                      hideSelectMonthYear
                      notAllowSelectDateInPicker
                      highlightDisabledDays
                    />
                  </div>
                  {/* Events board section */}
                  <EventsBoard data={deadlines} />
                </div>
              </div>
            </div>
            <Resources />
          </div>
        );
      } else {
        return (
          <div className={style['dashboard-content']}>
            <h2>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageTitle}
                tooltipKey='Dashboard'
              >
                <span>Dashboard</span>
              </TooltipWrapper>
            </h2>
            <ToastMessage
              status={toastMessage}
              className={style['toast2']}
              callback={() => auth.signoutRedirect()}
              ref={toastRef}
            />
            <div className={style['main']}>
              <Steps toastRef={toastRef} data={taxReturnProcess} />
              {/* Left sider */}
              <div className={style['sider']}>
                {/* Progress block */}
                <Prograss data={currentUserPlan} />
                {/* Help List */}
                <Help />
              </div>
            </div>
          </div>
        );
      }
    }
  }

  return (
    <>
      {dashboardElement()}
      <Loader isOpen={fetchingProfile} />
    </>
  );
}

export default Onboarding;
