import {useState, useEffect, useRef} from 'react';
import _ from 'lodash';
import {
  OriginalFileField,
  ExtProperty,
  Company,
  ILegalEntity,
  Assessor,
  State,
} from './../interface/index';
import {getAllLegalEntities, getCompanies, lookupAssessors} from '../services';
import formatProperty from '../utils/formatProperty';
import Fuse from 'fuse.js';

function useFileToProperties(
  mappedFiles: OriginalFileField[],
  states: State[],
) {
  const [middleWare, setMiddleWare] = useState<ExtProperty[]>([]);
  const [properties, setProperties] = useState<ExtProperty[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [legalEntities, setLegalEntities] = useState<ILegalEntity[]>([]);
  const [assessors, setAssessors] = useState<Assessor[]>([]);
  const ref = useRef<string>();
  const [fetchingCompanies, setFetchingCompanies] = useState(false);
  const [fetchingAllLegalEntities, setAllLegalEntities] = useState(false);
  const [fetchingAllAssessors, setFetchingAllAssessors] = useState(false);

  useEffect(() => {
    if (
      !_.isEmpty(middleWare) &&
      !_.isEmpty(states) &&
      !_.isEmpty(companies) &&
      !_.isEmpty(assessors)
    ) {
      (function () {
        const res = _.map(middleWare, (item) => {
          // ignore in coverage report because companies can not be null
          /* istanbul ignore else */
          if (companies) {
            const findCompany = _.find(companies, (c) =>
              _.isEqual(c.number, item.companyNumber),
            );
            if (findCompany) {
              // ignore in coverage report because if legalEntities is null
              // this component will crash in next _.map(...)
              /* istanbul ignore else */
              if (legalEntities) {
                const findLegalEntity = _.find(
                  legalEntities,
                  (l) =>
                    _.isEqual(l.companyId, findCompany.id) &&
                    _.isEqual(l.number, item.legalEntityNumber),
                );
                if (findLegalEntity) {
                  return {
                    ...item,
                    companyId: _.get(findCompany, 'id'),
                    companyName: _.get(findCompany, 'name'),
                    legalEntityId: _.get(findLegalEntity, 'id'),
                    legalEntityName: _.get(findLegalEntity, 'name'),
                    legalEntities: legalEntities,
                  };
                } else {
                  return {
                    ...item,
                    companyId: _.get(findCompany, 'id'),
                    companyName: _.get(findCompany, 'name'),
                  };
                }
              }
            } else {
              return item;
            }
          }
        }) as ExtProperty[];
        const middleResult = _.map(res, (item) => {
          // ignore in coverage report because states can not be null
          /* istanbul ignore else */
          if (states) {
            const state = _.find(states, (state) => {
              return state.abbreviation === item.state || state.name.toLowerCase() === item.state.toLowerCase();
            })
            if (state) {
              return {
                ...item,
                stateId: _.get(state, 'id',),
                stateName: _.get(state, 'abbreviation',),
              };
            }
            else {
              return item;
            }
          } else {
            return item;
          }
        }) as ExtProperty[];
        const result = _.map(middleResult, (item) => {
          // Try literal search on assessor name
          let assessorObj = _.find(
            assessors,
            (obj) =>
              _.isEqual(obj.stateId, item.stateId) &&
              _.isEqual(obj.name, item.assessor),
          );
          // Try fuzzy search on assessor name 
          if (!assessorObj && item.assessor) {
            const filteredAssessors = assessors.filter(assessor => _.isEqual(assessor.stateId, item.stateId));
            const fuse = new Fuse(filteredAssessors, { keys: ['name'], threshold: 0.01, includeScore: true });
            const fuzzyAssessors = fuse.search(item.assessor);
            if (fuzzyAssessors && fuzzyAssessors.length === 1) {
              assessorObj = fuzzyAssessors[0].item;
              item.assessor = fuzzyAssessors[0].item.name;
            }
          }
          return assessorObj ? {...item, assessorId: assessorObj.id} : item;
        });
        setProperties(_.map(result, (item) => formatProperty(item)));
      })();
    }
  }, [middleWare, states, companies, legalEntities, assessors]);
  useEffect(() => {
    if (!_.isEmpty(mappedFiles)) {
      const arr = _.map(mappedFiles, (item) =>
        _.mapKeys(item, (__, k) => _.camelCase(k)),
      );
      if (!_.isEmpty(arr) && !_.isEmpty(states)) {
        const ids = _(arr)
          .map((item) => {
            return _.get(_.find(states, ['abbreviation', item.state]), 'id');
          })
          .uniq()
          .join(',')
          .valueOf();
        if (!_.isEqual(ref.current, ids)) {
          setFetchingAllAssessors(true);
          lookupAssessors(ids).then((d) => {
            setAssessors(d.data.items);
            ref.current = ids;
            setFetchingAllAssessors(false);
          }).catch(() => {
            setFetchingAllAssessors(false);
          });
        }
      }
      // eslint-disable-next-line
      setMiddleWare(arr as any);
    }
  }, [mappedFiles, states]);
  useEffect(() => {
    setFetchingCompanies(true);
    getCompanies().then((d) => {
      setCompanies(d.data.items);
      setFetchingCompanies(false);
    }).catch(() => {
      setFetchingCompanies(false);
    });

    setAllLegalEntities(true);
    getAllLegalEntities().then((d) => {
      setLegalEntities(d.data.items);
      setAllLegalEntities(false);
    }).catch(() => {
      setAllLegalEntities(false);
    });
  }, []);

  return {properties, setProperties, loading: fetchingCompanies || fetchingAllLegalEntities || fetchingAllAssessors};
}

export default useFileToProperties;
