import React, {useState} from 'react';
import style from './PlanUsage.module.scss';
import {IToastMessage} from '../../../../interface';

import ToastMessage from '../../../../components/ToastMessage/ToastMessage';
import UsageTable from '../../../../components/UsageTable/UsageTable';
import cn from 'classnames';
import {useNavigate} from 'react-router-dom';
import TooltipWrapper from '../../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../../enums';

function PlanUsage() {
  const navigate = useNavigate();

  //Toast message
  const [toastMessage] = useState<IToastMessage>();

  return (
    <div className={style['main-content']}>
      <ToastMessage status={toastMessage} />
      <div className={style['main']}>
        <h2>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageTitle}
            tooltipKey='Account Settings'
          >
            <span>Account Settings</span>
          </TooltipWrapper>
        </h2>
        <div className={style['content']}>
          <div className={style['bar']}>
            <div className={style['bar-item-container']}>
              <div className={style['barHeader']}>
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.Common}
                  tooltipKey='Manage Settings'
                >
                  <span>Manage Settings</span>
                </TooltipWrapper>
              </div>
              <div
                className={style['barItem']}
                onClick={() => navigate('/users')}
              >
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.Common}
                  tooltipKey='User Management'
                >
                  <span>User Management</span>
                </TooltipWrapper>
              </div>
              <div className={cn(style['barItem'], style.active)}>
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.Common}
                  tooltipKey='Plan & Usage'
                >
                  <span>Plan &amp; Usage</span>
                </TooltipWrapper>
              </div>
              <div className={style['barItem']}
                onClick={() => navigate('/taxyearselection')}
              >
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.Common}
                  tooltipKey='Tax Year Selection'
                >
                  <span>Tax Year Selection</span>
                </TooltipWrapper>
              </div>
            </div>
          </div>
          <div className={style['rightPart']}>
            <div className={style['section']}>
              <UsageTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanUsage;
