import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {TOOLTIP_SECTIONS} from '../../enums';
import {getHelpCategories} from '../../services';
import Loader from '../Loader/Loader';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import style from './Resources.module.scss';

interface Topic {
  versions: Version[];
}

interface Version {
  id: number;
  title: string;
  shortContent: string;
}

interface Help {
  id: number;
  title: string;
  type?: string;
  topics: Topic[];
  expanded: boolean;
}

function Resources() {
  const [helpCategories, setHelpCategories] = useState<Help[]>([]);
  const [fetchingData, setFetchingData] = useState(false);

  // Get data from JSON
  useEffect(() => {
    setFetchingData(true);
    getHelpCategories({title: ''}).then((d) => {
      const result = d.data.items.map((item: Help) => ({
        ...item,
        expanded: false
      }));
      setHelpCategories(result);
      setFetchingData(false);
    }).catch(() => {
      setFetchingData(false);
    });
  }, []);

  const toggleCategory = (category: Help) => {
    category.expanded = !category.expanded;
    setHelpCategories([...helpCategories]);
  };

  return (
    <div className={style['sider']}>
      <h2 className={style['help-header']}>Help Categories</h2>
      {helpCategories.map((helpCategory, idx) => (
        <div className={style['block']} key={idx}>
          <div className={style['title']}>
            <h4>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.Common}
                tooltipKey={helpCategory.title}
              >
                <span>{helpCategory.title}</span>
              </TooltipWrapper>
            </h4>
            <div
              role='button' 
              className={`${style['topic-toggle']} ${helpCategory.expanded ? style['expanded'] : style['collapsed']}`} 
              onClick={() => {
                toggleCategory(helpCategory);
              }}
            ></div>
          </div>
          {
            helpCategory.expanded &&
            helpCategory.topics.map((topic, idxTopic) => (
              <ul key={idxTopic}>
                {topic.versions.map((version, idxVersion) => (
                  <li key={idxVersion}>
                    <Link to={`/help?category=${helpCategory.id}&topic=${version.id}`}>
                      <span className={style['content']}>
                        <span className={style['topic-title']}>{version.title}</span>
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            ))
          }
        </div>
      ))}

      <Loader isOpen={fetchingData} />
    </div>
  );
}

export default Resources;
