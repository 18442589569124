import _ from 'lodash';
import moment from 'moment';
import {ExtProperty} from './../interface/index';

function formatProperty(property: ExtProperty): ExtProperty {
  const result = _.cloneDeep(property);
 
  //Address
  if (_.get(property, 'address1')) {
    _.set(result, 'address.line1', _.get(property, 'address1'));
  }
  if (_.get(property, 'address2')) {
    _.set(result, 'address.line2', _.get(property, 'address2'));
  }
  if (_.get(property, 'city')) {
    _.set(result, 'address.city', _.get(property, 'city'));
  }
  if (_.get(property, 'stateId')) {
    _.set(result, 'address.stateId', _.get(property, 'stateId'));
    _.set(result, 'address.state', _.get(property, 'state'));
    _.set(result, 'address.stateName', _.get(property, 'stateName'));
  }
  if (_.get(property, 'zipCode')) {
    _.set(result, 'address.postalCode', _.get(property, 'zipCode'));
  }
  if (_.get(property, 'propertyNumber')) {
    _.set(result, 'number', _.get(property, 'propertyNumber'));
  }
  if (_.get(property, 'propertyName')) {
    _.set(result, 'name', _.get(property, 'propertyName'));
  }
  if (_.get(property, 'propertyCostCenter')) {
    _.set(result, 'costCenter', _.get(property, 'propertyCostCenter'));
  }
  if (_.get(property, 'propertyGlAccount')) {
    _.set(result, 'glAccountNumber', _.get(property, 'propertyGlAccount'));
  }
  if (_.get(property, 'propertyDescription')) {
    _.set(result, 'description', _.get(property, 'propertyDescription'));
  }
  if (_.get(property, 'openDate')) {
    _.set(result, 'openDate', _.get(property, 'openDate'));
  }
  if (_.get(property, 'closedDate')) {
    _.set(result, 'closedDate', _.get(property, 'closedDate'));
  }

  //AssessorAccounts
  if (_.get(property, 'accountName')) {
    _.set(result, 'assessorAccounts[0].name', _.get(property, 'accountName'));
  }
  if (_.get(property, 'accountNumber')) {
    _.set(result, 'assessorAccounts[0].number', _.get(property, 'accountNumber'));
  }
  if (_.get(property, 'assessorId')) {
    _.set(result, 'assessorAccounts[0].assessorId', _.get(property, 'assessorId'));
    _.set(result, 'assessorAccounts[0].assessorName', _.get(property, 'assessor'));
  } else {
    _.set(result, 'assessorAccounts[0].assessorId', null);
  }
  if (_.get(property, 'alternateReference')) {
    _.set(
      result,
      'assessorAccounts[0].alternateReference',
      _.get(property, 'alternateReference'),
    );
  }
  if (_.get(property, 'glAccountNumber')) {
    _.set(
      result,
      'assessorAccounts[0].glAccountNumber',
      _.get(property, 'glAccountNumber'),
    );
  }
  if (_.get(property, 'accountCostCenter')) {
    _.set(
      result,
      'assessorAccounts[0].costCenter',
      _.get(property, 'accountCostCenter'),
    );
  }
  if (_.get(property, 'legalDescription')) {
    _.set(
      result,
      'assessorAccounts[0].legalDescription',
      _.get(property, 'legalDescription'),
    );
  }
  if (_.get(property, 'renditionNotes')) {
    _.set(
      result,
      'assessorAccounts[0].renditionNotes',
      _.get(property, 'renditionNotes'),
    );
  }
  _.set(result, 'active', true);
  _.set(result, 'assessorAccounts[0].active', true);
  _.set(result, 'assessorAccounts[0].expanded', true);
  _.set(result, 'assessorAccounts[0].glAccountNumberInheritFromProperty', false);
  _.set(result, 'assessorAccounts[0].costCenterInheritFromProperty', false);

  if (!_.get(property, 'companyId')) {
    _.unset(result, 'companyName');
    _.unset(result, 'companyNumber');
  }
  if (!_.get(property, 'legalEntityId')) {
    _.unset(result, 'legalEntityName');
    _.unset(result, 'legalEntityNumber');
  }
  if (property.openDate && moment(property.openDate).isValid()) {
    _.set(result, 'openDate', moment(property.openDate).format('MM/DD/YYYY'));
  } else {
    _.unset(result, 'openDate');
  }
  if (property.closedDate && moment(property.closedDate).isValid()) {
    _.set(result, 'closedDate', moment(property.closedDate).format('MM/DD/YYYY'));
  } else {
    _.unset(result, 'closedDate');
  }
  //Delete parameter from upload file
  _.set(result, 'checked', false);
  return _.omit(
    result,
    'address1',
    'address2',
    'city',
    'city',
    'state',
    'stateId',
    'stateName',
    'zipCode',
    'accountName',
    'accountNumber',
    'assessorId',
    'assessor',
    'alternateReference',
    'accountCostCenter',
    'legalDescription',
    'renditionNotes',
    'propertyCostCenter',
    'propertyDescription',
    'propertyGlAccount',
    'propertyName',
    'propertyNumber',
  ) as ExtProperty;
}

export default formatProperty;
