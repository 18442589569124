import {
  mappedAssetsFileReducer,
  mappedFileReducer,
  statesReducer,
  propertiesReducer,
  maintenanceNotificationsReducer,
} from './reducer';
import {
  configureStore,
  combineReducers,
  Reducer,
  AnyAction,
  createAction,
} from '@reduxjs/toolkit';
import adminSlice from './features/admin-slice'
import authSlice from './features/auth-slice';
import dashInfo from './features/dashboard-info-slice';
import selectedTaxYearSlice from './features/selected-tax-year-slice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import returnsSlice from './features/returns-slice';

export const clearStore = createAction('root/clear');

const appReducer = combineReducers({
  uploadFile: mappedFileReducer,
  states: statesReducer,
  maintenanceNotifications: maintenanceNotificationsReducer,
  uploadAssetsFile: mappedAssetsFileReducer,
  properties: propertiesReducer,
  admin: adminSlice,
  [authSlice.name]: authSlice.reducer,
  [dashInfo.name]: dashInfo.reducer,
  [selectedTaxYearSlice.name]: selectedTaxYearSlice.reducer,
  [returnsSlice.name]: returnsSlice.reducer,
});

const rootReducer: Reducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === clearStore.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }),
});

export default store;

export type RootState = ReturnType<typeof appReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;