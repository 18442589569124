interface IEnvConfig {
    API_URL: string;
    AUTHORITY: string;
    CLIENT_ID: string;
    // Add other properties as needed
  }

declare global {
    interface Window { envConfig: IEnvConfig; }
}

const envConfig:IEnvConfig = {
    API_URL: window.envConfig.API_URL,
    AUTHORITY: window.envConfig.AUTHORITY,
    CLIENT_ID: window.envConfig.CLIENT_ID,
};
  
export default envConfig;
