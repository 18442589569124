import React, {useState, useEffect, useRef} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import _, {get} from 'lodash';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import StepsBar from '../../../components/StepsBar/StepsBar';
import ToastMessage, {
  ToastMessageRefType,
} from '../../../components/ToastMessage/ToastMessage';
import {
  AssetField,
  IErrorBase,
  IToastMessage,
  OriginalFileField,
  OriginColumns,
  SelectOption,
} from '../../../interface';
import style from './AssetsReview.module.scss';
import {
  cancelUploadFile,
  getErrorMessage,
  getUploadFile,
  updateUploadFile,
  uploadAsset,
  validateAssets,
} from '../../../services';
import ValidateTable from '../../../components/ValidateTable/ValidateTable';
import {
  ASSETFILETYPE,
  FIXEDASSETFILECOLUMNS,
  INVENTORYFILECOLUMNS,
  LEASEEQUIPMENTFILECOLUMNS,
  LEASEVEHICLEFILECOLUMNS,
  VEHICLEFILECOLUMNS,
} from '../../../constants';
import {
  FIXEDASSETFILETOFIELD,
  INVENTORYFILETOFIELD,
  LEASEDEQUIPMENTFILETOFIELD,
  LEASEDVEHICLEFILETOFIELD,
  TOOLTIP_SECTIONS,
  VEHICLEFILETOFIELD,
} from '../../../enums';
import {AxiosResponse} from 'axios';
import removeUselessField from '../../../utils/removeUselessField';
import usePrevious from '../../../hook/usePrevious';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import Loader, {LoaderIndicator} from '../../../components/Loader/Loader';
import {useAppDispatch} from '../../../redux/store';
import {getDashboardInfo} from '../../../redux/features/dashboard-info-slice';

interface ExtFile {
  status: string;
  mappingPriority: string;
  propertyData: string;
  expectData: string;
  mapFileColumn: SelectOption[];
  fileColumnPreview?: string[];
  selectedColumn: string;
}

function AssetsReview() {
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const [type, setType] = useState<string>();
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [fileColumns, setFileColumns] = useState();
  const [returnJSON, setReturnJSON] = useState<OriginalFileField[]>([]);
  const [assets, setAssets] = useState<AssetField[]>([]);
  const [fileName, setFileName] = useState<string>('');
  const [assetsWithStatus, setAssetsWithStatus] = useState<AssetField[]>([]);
  const [file, setFile] = useState<ExtFile[]>([]);
  const [validate, setValidate] = useState<boolean>(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [deletingItem, setDeletingItem] = useState(false);
  const [validatingData, setValidatingData] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [cancellingUploadFile, setCancellingUploadFile] =
    useState<boolean>(false);
  const toastRef = useRef<ToastMessageRefType | null>(null);
  // Breadcrumb, dashboard depend on user skip or not, property link depend where is the page from
  const breadcrumb = [
    {
      value: 'Assets',
      href: '/assets',
    },
    {value: 'Add Asset', href: '/assets/assetsUpload'},
    {value: 'File Mapping', href: `/assets/assetsMapFile/${id}`},
    {value: 'File Review'},
  ];

  type TypeColumnsKeys =
    | typeof FIXEDASSETFILETOFIELD
    | typeof INVENTORYFILETOFIELD
    | typeof VEHICLEFILETOFIELD
    | typeof LEASEDVEHICLEFILETOFIELD
    | typeof LEASEDEQUIPMENTFILETOFIELD;
  const [columns, setColumns] = useState<OriginColumns[]>(
    FIXEDASSETFILECOLUMNS,
  );
  const [columnsKeys, setColumnsKeys] = useState<TypeColumnsKeys>(
    FIXEDASSETFILETOFIELD,
  );
  useEffect(() => {
    switch (type) {
      case ASSETFILETYPE[1]:
        setColumns(INVENTORYFILECOLUMNS);
        setColumnsKeys(INVENTORYFILETOFIELD);
        break;
      case ASSETFILETYPE[2]:
        setColumns(VEHICLEFILECOLUMNS);
        setColumnsKeys(VEHICLEFILETOFIELD);
        break;
      case ASSETFILETYPE[3]:
        setColumns(LEASEVEHICLEFILECOLUMNS);
        setColumnsKeys(LEASEDVEHICLEFILETOFIELD);
        break;
      case ASSETFILETYPE[4]:
        setColumns(LEASEEQUIPMENTFILECOLUMNS);
        setColumnsKeys(LEASEDEQUIPMENTFILETOFIELD);
        break;
      default:
        setColumns(FIXEDASSETFILECOLUMNS);
        setColumnsKeys(FIXEDASSETFILETOFIELD);
        break;
    }
  }, [type]);

  const ref = usePrevious(assets);
  useEffect(() => {
    if (!_.isEmpty(assets)) {
      if (!_.isEqual(ref, assets)) {
        if (validate) {
          setValidate(false);
          setValidatingData(true);
          validateAssets(assets)
            .then((d) => {
              const errorMessage = getErrorMessage(d);
              if (_.find(d.data, ['status', 'Action Required'])) {
                setToastMessage({
                  visible: true,
                  message:
                    'All errors must be resolved before the assets can be imported!',
                  type: 'error',
                });
              } else if (_.find(d.data, ['status', 'Duplicate'])) {
                setToastMessage({
                  visible: true,
                  message: 'Duplicate records will not be imported.',
                  type: 'warning',
                });
              } else if (errorMessage) {
                setToastMessage({
                  visible: true,
                  message: errorMessage,
                  type: 'error',
                });
              }
              setAssetsWithStatus(
                _.map(assets, (property, index) => ({
                  ...property,
                  ...d.data[index],
                })),
              );
              setValidatingData(false);
            })
            .catch((e) => {
              toastRef.current?.showErrorToast(getErrorMessage(e));
              setValidatingData(false);
            });
        }
      }
    } else {
      setAssetsWithStatus(assets);
    }
  }, [assets, validate]);

  useEffect(() => {
    if (id) {
      setFetchingData(true);
      getUploadFile(id)
        .then((d) => {
          setFileColumns(d.data.columnMappings[0]);
          setReturnJSON(d.data.columnMappings[1]);
          setFile(d.data.columnMappings[2]);
          setFileName(d.data.document.name);
          setAssets(d.data.items);
          setType(d.data.assetType);
          setValidate(true);
          setFetchingData(false);
        })
        .catch(() => {
          setFetchingData(false);
        });
    }
  }, [id]);

  const deleteItem = (values: AssetField[]) => {
    if (id) {
      setDeletingItem(true);
      updateUploadFile(id, {
        columnMappings: [
          fileColumns,
          _.intersectionBy(returnJSON, values, 'id'),
          file,
        ],
        items: values,
      })
        .then((d) => {
          const errorMessage = getErrorMessage(d);
          if (errorMessage) {
            toastRef.current?.showErrorToast(errorMessage);
          } else {
            if (
              _.isEmpty(d.data.items) ||
              _.every(d.data.items, (o) => _.isEqual(o['status'], 'Ready'))
            ) {
              toastRef.current?.showSuccessToast('Delete successfully');
            }
            setAssets(d.data.items);
            setReturnJSON(d.data.columnMappings[1]);
            setValidate(true);
          }
          setDeletingItem(false);
        })
        .catch((e) => {
          toastRef.current?.showErrorToast(getErrorMessage(e));
          setDeletingItem(false);
        });
    }
  };

  return (
    <div className={style['main-content']}>
      {/* Braedcrumb */}
      <Breadcrumb items={breadcrumb} />
      <ToastMessage
        ref={toastRef}
        status={toastMessage}
        className={style['toast']}
      />
      <div className={style['main']}>
        {/* Steps */}
        <StepsBar step={3} steps={['Upload', 'Map', 'Review']} />
        {/* Add property section */}
        <div className={style['top-section']}>
          <h2>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageTitle}
              tooltipKey='Review & Complete Import'
            >
              <span>Review & Complete Import</span>
            </TooltipWrapper>
          </h2>
          <div className={style['results']}>
            <div className='mappedfile-result'>
              <ul>
                <li className={style['mappedFileColumn1']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Uploaded file'
                  >
                    <span>Uploaded file</span>
                  </TooltipWrapper>
                  <p>
                    <i className={'icon'}></i>
                    <span className={style['mappedFileName']}>{fileName}</span>
                  </p>
                </li>
                <li className={style['mappedFileColumn2']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Errors to address'
                  >
                    <span>Errors to address</span>
                  </TooltipWrapper>
                  <span className='block'>
                    {
                      _.filter(assetsWithStatus, ['status', 'Action Required'])
                        .length
                    }
                  </span>
                </li>
                <li className={style['mappedFileColumn3']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Mapped columns'
                  >
                    <span>Mapped columns</span>
                  </TooltipWrapper>
                  <span className='block'>
                    {_.filter(file, ['status', 'Mapped']).length}
                  </span>
                </li>
                <li className={style['mappedFileColumn4']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Total file columns'
                  >
                    <span>Total file columns</span>
                  </TooltipWrapper>
                  <span className='block'>
                    {get(
                      {
                        Inventory: INVENTORYFILECOLUMNS.length,
                        Vehicle: VEHICLEFILECOLUMNS.length,
                        'Leased Vehicle': LEASEVEHICLEFILECOLUMNS.length,
                        'Leased Equipment': LEASEEQUIPMENTFILECOLUMNS.length,
                      },
                      type ?? '',
                      FIXEDASSETFILECOLUMNS.length,
                    )}
                  </span>
                </li>
                <li className={style['mappedFileColumn5']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Total file rows'
                  >
                    <span>Total file rows</span>
                  </TooltipWrapper>
                  <span className='block'>{returnJSON.length}</span>
                </li>
              </ul>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Remove & Replace file'
              >
                <button
                  onClick={() => {
                    if (id) {
                      setCancellingUploadFile(true);
                      cancelUploadFile(id)
                        .then((d) => {
                          const errorMessage = getErrorMessage(d);
                          if (errorMessage) {
                            toastRef.current?.showErrorToast(errorMessage);
                          } else {
                            toastRef.current?.showSuccessToast(
                              'Remove & Replace file successfully',
                            );
                            navigate('/assets/assetsUpload');
                          }
                        })
                        .catch((e) => {
                          toastRef.current?.showErrorToast(getErrorMessage(e));
                          setCancellingUploadFile(false);
                        });
                    }
                  }}
                  className='primary-link'
                  disabled={cancellingUploadFile}
                >
                  Remove & Replace file
                  {cancellingUploadFile ? (
                    <LoaderIndicator
                      className='button-loading'
                      loading={true}
                    />
                  ) : null}
                </button>
              </TooltipWrapper>
            </div>
          </div>
        </div>
        {/* Added Properties */}
        <div className={style['section']}>
          {type && (
            <ValidateTable
              dataList={assetsWithStatus}
              loading={fetchingData || validatingData}
              standardColumns={_.map(columns, 'propertyData')}
              checkedColumns={_.concat(
                _.map(
                  _.filter(columns, ['mappingPriority', 'Required']),
                  'propertyData',
                ),
                ['Import status'],
              )}
              additionColumn={['Import status']}
              mappedKeys={columnsKeys}
              type={_.kebabCase(type)}
              url={get(
                {
                  Inventory: `editUploadInventorySetup/${id}`,
                  Vehicle: `editUploadVehicleSetup/${id}`,
                  'Leased Vehicle': `editUploadLeasedVehicleSetup/${id}`,
                  'Leased Equipment': `editUploadLeasedEquipmentSetup/${id}`,
                },
                type,
                `editUploadFixedAssetSetup/${id}`,
              )}
              onDeleteItem={deleteItem}
            />
          )}
          <div className={style['buttons']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Import assets'
            >
              <button
                className='primary'
                onClick={() => {
                  if (
                    _.isEqual(
                      _.filter(assetsWithStatus, ['status', 'Action Required'])
                        .length,
                      0,
                    ) &&
                    !_.isEqual(
                      _.filter(assetsWithStatus, ['status', 'Ready']).length,
                      0,
                    )
                  ) {
                    const values = {
                      items: removeUselessField(assetsWithStatus),
                      uploadedFileId: _.toNumber(id),
                    };
                    setUploading(true);
                    uploadAsset(values)
                      .then((e: AxiosResponse<IErrorBase>) => {
                        const errorMessage = getErrorMessage(e);
                        if (errorMessage) {
                          setToastMessage({
                            visible: true,
                            message: errorMessage,
                            type: 'error',
                          });
                        } else {
                          appDispatch(getDashboardInfo());
                          navigate(`/assets?assetTab=${type?.replace(' ', '+')}`);
                          sessionStorage.setItem(
                            'importAssetsLength',
                            _.size(e.data).toString(),
                          );
                        }
                        setUploading(false);
                      })
                      .catch((e) => {
                        toastRef.current?.showErrorToast(getErrorMessage(e));
                        setUploading(false);
                      });
                  } else {
                    setToastMessage({
                      ...(toastMessage as IToastMessage),
                      visible: true,
                    });
                  }
                }}
                disabled={uploading || !assets.length}
              >
                Import assets
                {uploading ? (
                  <LoaderIndicator className='button-loading' loading={true} />
                ) : null}
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Back'
            >
              <button
                className='secondary'
                onClick={() => navigate(`/assets/assetsMapFile/${id}`)}
              >
                Back
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Cancel import'
            >
              <button
                className='secondary'
                onClick={() => {
                  if (id) {
                    setCancellingUploadFile(true);
                    cancelUploadFile(id)
                      .then(() => {
                        navigate('/assets/assetsUpload');
                      })
                      .then(() => {
                        setCancellingUploadFile(false);
                      });
                  }
                }}
                disabled={cancellingUploadFile}
              >
                Cancel import
                {cancellingUploadFile ? (
                  <LoaderIndicator className='button-loading' loading={true} />
                ) : null}
              </button>
            </TooltipWrapper>
          </div>
        </div>
      </div>

      <Loader isOpen={deletingItem} />
    </div>
  );
}

export default AssetsReview;
