import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  strokeWidth?: number;
}

function Divider({
  className,
  strokeWidth = 1
}: Props) {
  return (
    <hr
      style={{
        borderWidth: `${strokeWidth}px 0 0`
      }}
      className={classNames(className, 'Divider')}
    />
  );
}

export default Divider;