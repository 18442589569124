import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import style from './AssessorManagementDetails.module.scss';
import { TOOLTIP_SECTIONS } from '../../../enums';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import Divider from '../../../components/Divider/Divider';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { Assessor, IBackendAddress, IBackendAssessorAddress, IBackendAssessorUpdate, IBackendFormDefault, IBackendTaxYearUpdate, IToastMessage, TaxYear } from '../../../interface';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import { useNavigate, useParams } from 'react-router-dom';
import AssessorNotTaxYearForm from '../../../components/AssessorNotTaxYearForm/AssessorNotTaxYearForm';
import AssessorTaxYearForm, { AssessorTaxYearFormHandle } from '../../../components/AssessorTaxYearForm/AssessorTaxYearForm';
import AssessorMissingInformation from '../../../components/AssessorMissingInformation/AssessorMissingInformation';
import { IAssessorTaxYearFormData, IAssessorTaxYearFormDataItem, createEmptyIAssessorTaxYearFormData, createEmptyIAssessorTaxYearFormDataItem } from '../../../interface/IAssessorTaxYearFormData';
import DiscardChangeConfirmModal from '../../../components/DiscardChangeConfirmModal/DiscardChangeConfirmModal';
import { FormikProps, FormikValues, useFormik } from 'formik';
import { IAssessorNotTaxYearFormData, createEmptyIAssessorNotTaxYearFormData } from '../../../interface/IAssessorNotTaxYearFormData';
import _ from 'lodash';
import Loader from '../../../components/Loader/Loader';
import { createAssessor, getAssessor, getErrorMessage, getSystemTaxYears, updateAssessor } from '../../../services';
import { AxiosResponse } from 'axios';
import { formatDateFromBackend, formatDateToBackend } from '../../../utils/date';
import { IBackendFormMapping } from '../../../interface/IBackendFormMapping';
import validateEmpty from '../../../utils/validateEmpty';
import { IAssessorMissingInformationFormData } from '../../../interface/IAssessorMissingInformationFormData';
import { ratioBackendToFrontend, ratioFrontendToBackend } from '../../../utils/formatNumber';
import { IBackendTaxYear } from '../../../interface/IBackendTaxYear';

interface Props {
  className?: string;
  isInEditMode?: boolean;
  isAddNew?: boolean;
}

const emptyIAssessorTaxYearFormDataItem = createEmptyIAssessorTaxYearFormDataItem()
const emptyIAssessorNotTaxYearFormData = createEmptyIAssessorNotTaxYearFormData()

function AssessorManagementDetails({
  className,
  isInEditMode,
  isAddNew
}: Props) {
  const navigate = useNavigate();
  const assessorTaxYearFormHandleRef = React.useRef<AssessorTaxYearFormHandle>(null)
  const [bkBackendAssessor, setBkBackendAssessor] = useState<Assessor | null>(null);
  const [allAvailableTaxYears, setAllAvailableTaxYears] = useState<IBackendTaxYear[] | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [selectedYearIndex, setSelectedYearIndex] = useState(0);
  const [activeTaxYearIndex, setActiveTaxYearIndex] = useState<number | null>(null);
  const [activeTaxYear, setActiveTaxYear] = useState<number | null>(null);

  const params = useParams();
  const [fetchingAssessorInfo, setFetchingAssessorInfo] = useState(false);
  const [fetchingAllTaxYears, setFetchingAllTaxYears] = useState(false);
  const [savingAssessor, setSavingAssessor] = useState(false);
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [showMissingInformation, setShowMissingInformation] = useState<IAssessorMissingInformationFormData | null>(null);
  const [initialAssessorNotTaxYearFormValues, setInitialAssessorNotTaxYearFormValues] = useState<IAssessorNotTaxYearFormData>(
    createEmptyIAssessorNotTaxYearFormData(),
  );
  const [initialAssessorTaxYearValues, setInitialAssessorTaxYearValues] = useState<IAssessorTaxYearFormData>(
    createEmptyIAssessorTaxYearFormData([])
  );
  const [unsaveformMappingsList, setUnsaveformMappingsList] = useState<IBackendFormMapping[]>([]);
  const showSuccess = () => {
    window.scrollTo(0, 0);
    setToastMessage({
      message: 'Assessor details are saved successfully.',
      visible: true,
      type: 'success',
    });
  }
  const showErrorMessage = (message: string) => {
    window.scrollTo(0, 0);
    setToastMessage({
      message,
      visible: true,
      type: 'error',
    });
  }

  const validateForm = () => {
    setTimeout(() => {
      formikAssessorTaxYearForm.validateForm();
      formikAssessorNotTaxYearForm.validateForm();
    }, 100);
  }

  useEffect(() => {
    setFetchingAllTaxYears(true);
    getSystemTaxYears()
      .then((res) => {
        setFetchingAllTaxYears(false);
        const allActiveTaxYears = _.orderBy(
          res.data,
          ['taxYear'],
          ['desc'],
        );

        setAllAvailableTaxYears(allActiveTaxYears);
        let currentTaxYearIndex = _.findIndex(allActiveTaxYears, {
          isCurrent: true,
        });
        if (currentTaxYearIndex < 0) {
          currentTaxYearIndex = 0;
        }
        setActiveTaxYear(allActiveTaxYears[currentTaxYearIndex].taxYear);

        if (isAddNew) {
          setInitialAssessorTaxYearValues(
            createEmptyIAssessorTaxYearFormData(allActiveTaxYears.map(item => item.taxYear))
          );
          setSelectedYearIndex(currentTaxYearIndex || 0);
          setActiveTaxYearIndex(currentTaxYearIndex || 0);
          validateForm();
        } else {
          setActiveTaxYearIndex(currentTaxYearIndex || 0);
        }

      })
      .catch(() => {
        setFetchingAllTaxYears(false);
      });
  }, [isAddNew]);


  useEffect(() => {
    if (!isAddNew && bkBackendAssessor && activeTaxYearIndex !== null) {
      const missingData: IAssessorMissingInformationFormData = {
        // section 1
        name: initialAssessorNotTaxYearFormValues.name,
        title: initialAssessorNotTaxYearFormValues.title,
        state: initialAssessorNotTaxYearFormValues.state,
        mailingAddressLine1: initialAssessorNotTaxYearFormValues.mailingAddressLine1,
        mailingAddressCity: initialAssessorNotTaxYearFormValues.mailingAddressCity,
        mailingAddressState: initialAssessorNotTaxYearFormValues.mailingAddressState,
        mailingAddressZip: initialAssessorNotTaxYearFormValues.mailingAddressZip,
        // section 2
        assessmentDateDate: initialAssessorTaxYearValues.taxYears?.[activeTaxYearIndex].assessmentDateDate,
        assessmentDateMonth: initialAssessorTaxYearValues.taxYears?.[activeTaxYearIndex].assessmentDateMonth,
        inventoryOriginalCostRule: initialAssessorTaxYearValues.taxYears?.[activeTaxYearIndex].inventoryOriginalCostRule,
        assessmentRatio: initialAssessorTaxYearValues.taxYears?.[activeTaxYearIndex].assessmentRatio,
        returnDeadline: initialAssessorTaxYearValues.taxYears?.[activeTaxYearIndex].returnDeadline,
        extensionOffered: initialAssessorTaxYearValues.taxYears?.[activeTaxYearIndex].extensionOffered,
        acquisitionYearType: initialAssessorTaxYearValues.taxYears?.[activeTaxYearIndex].acquisitionYearType,
      };
      if (missingData.extensionOffered === 'Yes') {
        missingData.extensionFillingDeadline = initialAssessorTaxYearValues.taxYears?.[activeTaxYearIndex].extensionFillingDeadline;
        missingData.returnDeadlineIfExtended = initialAssessorTaxYearValues.taxYears?.[activeTaxYearIndex].returnDeadlineIfExtended;
      }
      let haveMissingData = false;
      _.forOwn(missingData, (v) => {
        if (!v) {
          haveMissingData = true;
          return false;
        }
      });
      if (haveMissingData) {
        setShowMissingInformation(missingData);
      } else {
        setShowMissingInformation(null);
      }
    }
  }, [
    isAddNew,
    bkBackendAssessor,
    initialAssessorNotTaxYearFormValues,
    initialAssessorTaxYearValues,
    activeTaxYearIndex
  ]);


  const formikAssessorNotTaxYearForm: FormikProps<IAssessorNotTaxYearFormData> = useFormik<FormikValues>({
    initialValues: initialAssessorNotTaxYearFormValues,
    enableReinitialize: true,
    validate: (values) => {
      const requiredErrors = validateEmpty(_.pick({
        ...emptyIAssessorNotTaxYearFormData,
        ...values,
      }, [
        'name',
        'title',
        'state',
        'mailingAddressLine1',
        'mailingAddressCity',
        'mailingAddressState',
        'mailingAddressZip'
      ]));
      return requiredErrors;
    },
    onSubmit: (values) => {
      if (!isAssessorNotTaxYearFormChanged) {
        return;
      }
      const bkFirstTaxYear = _.get(bkBackendAssessor, `taxYears[${activeTaxYearIndex}]`) as TaxYear;
      const newData: IBackendAssessorUpdate = {
        ...createNonTaxYearSaveData(),
        taxYears: [
          {
            taxYear: bkFirstTaxYear.taxYear as number,
            id: bkFirstTaxYear.id,
            website: formikAssessorNotTaxYearForm.values.websiteLink
          }
        ]
      };
      setSavingAssessor(true);
      updateAssessor(bkBackendAssessor?.id ?? 0, newData)
        .then(() => {
          setInitialAssessorNotTaxYearFormValues(values);
          setSavingAssessor(false);
          showSuccess();
        })
        .catch((e) => {
          showErrorMessage(getErrorMessage(e));
          setSavingAssessor(false);
        });
    },
  });

  const createNonTaxYearSaveData = (): IBackendAssessorUpdate => {
    const bkAddress = _.get(bkBackendAssessor, 'mailingAddresses[0]') as IBackendAssessorAddress;
    const newData: IBackendAssessorUpdate = {
      name: formikAssessorNotTaxYearForm.values.name ?? '',
      stateId: formikAssessorNotTaxYearForm.values.state ?? null,
      mailingTitle: formikAssessorNotTaxYearForm.values.title,
      mailingAddresses: [
        {
          id: bkAddress?.id,
          addressType: 'Returns',
          address: {
            line1: formikAssessorNotTaxYearForm.values.mailingAddressLine1 ?? '',
            line2: formikAssessorNotTaxYearForm.values.mailingAddressLine2 ?? '',
            city: formikAssessorNotTaxYearForm.values.mailingAddressCity ?? '',
            stateId: formikAssessorNotTaxYearForm.values.mailingAddressState ?? null,
            postalCode: formikAssessorNotTaxYearForm.values.mailingAddressZip ?? '',
          }
        }
      ]
    };
    if (bkAddress && newData.mailingAddresses) {
      newData.mailingAddresses[0].id = bkAddress.id;
    }
    return newData;
  }

  const createTaxYearMappingSaveData = (
    item: IAssessorTaxYearFormDataItem,
    result: IBackendTaxYearUpdate
  ) => {
    const mappings = _.filter(unsaveformMappingsList, { taxYear: item.taxYear });
    if (item.defaultForm || mappings.length > 0) {
      result.formDefaults = [
        {
          formId: item.defaultForm ?? null,
          isDefaultForm: true,
          defaultDepreciationScheduleCollectionId: item.defaultDepreciationCollection ?? null,
          defaultIndexTableCollectionId: item.defaultIndexCollection ?? null,
          mappings: mappings.map((mappingItem) => {
            const mappingObject: IBackendFormMapping = {
              ..._.omit(mappingItem, ['assessorId']),
              formId: item.defaultForm,
              depreciationScheduleCollectionId: item.defaultDepreciationCollection ?? null,
              indexTableCollectionId: item.defaultIndexCollection ?? null,
              taxYear: item.taxYear,
              stateId: formikAssessorNotTaxYearForm.values.state ?? null,
            };
            if (bkBackendAssessor) {
              mappingObject.assessorId = bkBackendAssessor.id;
            }
            return mappingObject;
          })
        }
      ];
    }
  }

  const getUnsaveTaxYearList = () => {
    const unsaveTaxYearsList: IAssessorTaxYearFormDataItem[] = _.filter(formikAssessorTaxYearForm.values.taxYears ?? [], (taxYear, index) => {
      if (activeTaxYear && taxYear.taxYear === activeTaxYear) {
        return true;
      }
      const mappings = _.filter(unsaveformMappingsList, { taxYear: taxYear.taxYear });
      return (!_.isEqual(taxYear, initialAssessorTaxYearValues.taxYears?.[index])) || mappings.length > 0;
    });
    return unsaveTaxYearsList;
  }

  const removeTaxYearNullValue = (result: IBackendTaxYearUpdate) => {
    if (!result.assessmentRatio) {
      delete result.assessmentRatio;
    }
    if (!result.extensionsDeadline) {
      delete result.extensionsDeadline;
    }
    if (!result.returnsDeadline) {
      delete result.returnsDeadline;
    }
    if (!result.returnsDeadlineIfExtended) {
      delete result.returnsDeadlineIfExtended;
    }
  }

  const createTaxYearSaveData = (shouldSaveWeblink: boolean): IBackendTaxYearUpdate[] => {
    const taxYearsData: IBackendTaxYearUpdate[] = getUnsaveTaxYearList().map((item) => {

      let inventoryOriginalCostRule = item.inventoryOriginalCostRule;
      if (inventoryOriginalCostRule === 'Monthly Average') {
        inventoryOriginalCostRule = null;
      }

      const result: IBackendTaxYearUpdate = {
        taxYear: item.taxYear ?? null,
        assessmentDateMonth: item.assessmentDateMonth ?? null,
        assessmentDateDay: item.assessmentDateDate ?? null,
        inventoryOriginalCostRule: inventoryOriginalCostRule ?? null,
        assessmentRatio: ratioFrontendToBackend(item.assessmentRatio ?? '0'),
        extensionsOffered: item.extensionOffered === 'Yes',
        extensionsDeadline: formatDateToBackend(item.extensionFillingDeadline, 'M/D/YYYY'),
        returnsDeadline: formatDateToBackend(item.returnDeadline, 'M/D/YYYY'),
        returnsDeadlineIfExtended: formatDateToBackend(item.returnDeadlineIfExtended, 'M/D/YYYY'),
        acquisitionYearType: item.acquisitionYearType ?? null,
      };
      if (shouldSaveWeblink && item.taxYear === activeTaxYear) {
        result.website = formikAssessorNotTaxYearForm.values.websiteLink;
      }
      if (item.taxYear !== activeTaxYear) {
        removeTaxYearNullValue(result);
      }

      createTaxYearMappingSaveData(
        item,
        result
      );

      return result;
    });
    return taxYearsData;
  }

  const formikAssessorTaxYearForm: FormikProps<IAssessorTaxYearFormData> = useFormik<FormikValues>({
    initialValues: initialAssessorTaxYearValues,
    enableReinitialize: true,
    validate: (values: IAssessorTaxYearFormData) => {
      if (activeTaxYearIndex === null) {
        return;
      }
      const validateValues = {
        ...emptyIAssessorTaxYearFormDataItem,
        ...(values.taxYears?.[activeTaxYearIndex] ?? {})
      }
      const requiredErrors = validateEmpty(_.pick(validateValues, [
        'assessmentDateMonth',
        'assessmentDateDate',
        'inventoryOriginalCostRule',
        'assessmentRatio',
        'returnDeadline',
        'extensionOffered',
        'acquisitionYearType'
      ]));

      let extraRequireErrors
      if (
        values.taxYears?.[activeTaxYearIndex].extensionOffered === 'Yes'
      ) {
        extraRequireErrors = validateEmpty(_.pick(validateValues, [
          'extensionFillingDeadline',
          'returnDeadlineIfExtended',
        ]));
      }

      return _.defaults(
        requiredErrors,
        extraRequireErrors,
        parseFloat(values.taxYears?.[activeTaxYearIndex].assessmentRatio ?? '0') > 0 ? null : {
          assessmentRatio: 'Must be a positive number',
        }
      );
    },
    onSubmit: (values: IAssessorTaxYearFormData) => {
      if (
        !isAssessorTaxYearFormChanged &&
        !unsaveformMappingsList.length
      ) {
        return;
      }
      const newData: IBackendAssessorUpdate = {
        taxYears: createTaxYearSaveData(false),
      };
      setSavingAssessor(true);
      updateAssessor(bkBackendAssessor?.id ?? 0, newData)
        .then(() => {
          setUnsaveformMappingsList([]);
          setInitialAssessorTaxYearValues(values);
          setSavingAssessor(false);
          showSuccess();
        })
        .catch((e) => {
          showErrorMessage(getErrorMessage(e));
          setSavingAssessor(false);
        });
    },
  });

  const saveAll = () => {
    if (activeTaxYearIndex === null) {
      return;
    }
    setIsSubmitted(true);
    if (!formikAssessorTaxYearForm.isValid) {
      setSelectedYearIndex(activeTaxYearIndex);
    }
    if (
      !formikAssessorTaxYearForm.isValid ||
      !formikAssessorNotTaxYearForm.isValid
    ) {
      return;
    }

    const newData: IBackendAssessorUpdate = {
      ...createNonTaxYearSaveData(),
      taxYears: createTaxYearSaveData(true),
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let queryAccessor: Promise<AxiosResponse<any, any>>
    if (isAddNew) {
      queryAccessor = createAssessor(newData);
    } else {
      queryAccessor = updateAssessor(bkBackendAssessor?.id ?? 0, newData);
    }

    setSavingAssessor(true);
    queryAccessor
      .then(() => {
        setUnsaveformMappingsList([]);
        setInitialAssessorNotTaxYearFormValues(formikAssessorNotTaxYearForm.values);
        setInitialAssessorTaxYearValues(formikAssessorTaxYearForm.values);
        setTimeout(() => {
          navigate('/admin/assessor-management');
        }, 100);
        setSavingAssessor(false);
      })
      .catch((e) => {
        showErrorMessage(getErrorMessage(e));
        setSavingAssessor(false);
      });
  }

  const saveMissingField = (
    newValues: IAssessorMissingInformationFormData,
    requiredFields: {
      [field: string]: 'Section1' | 'Section2'
    }
  ) => {
    if (activeTaxYearIndex === null) {
      return;
    }
    const bkAddress = _.get(bkBackendAssessor, 'mailingAddresses[0]') as IBackendAssessorAddress;
    const lastTaxYear = formikAssessorTaxYearForm.values.taxYears?.[activeTaxYearIndex];

    const getTaxYearValue = (field: keyof IAssessorMissingInformationFormData): string | undefined | null | number => {
      return newValues[field]
    }
    const getNoTaxYearValue = (field: keyof IAssessorMissingInformationFormData): string | undefined | null | number => {
      return newValues[field]
    }

    const extensionsOffered = getTaxYearValue('extensionOffered') as string
    const extensionsDeadline = getTaxYearValue('extensionFillingDeadline') as string
    const returnsDeadline = getTaxYearValue('returnDeadline') as string
    const returnsDeadlineIfExtended = getTaxYearValue('returnDeadlineIfExtended') as string
    const acquisitionYearType = getTaxYearValue('acquisitionYearType') as string
    let inventoryOriginalCostRule = getTaxYearValue('inventoryOriginalCostRule') as (string | null)
    if (inventoryOriginalCostRule === 'Monthly Average') {
      inventoryOriginalCostRule = null;
    }
    const newData: IBackendAssessorUpdate = {
      name: getNoTaxYearValue('name') as string,
      stateId: getNoTaxYearValue('state') as number,
      mailingTitle: getNoTaxYearValue('title') as string,
      mailingAddresses: [
        {
          id: bkAddress?.id,
          addressType: 'Returns',
          address: {
            line1: getNoTaxYearValue('mailingAddressLine1') as string,
            line2: initialAssessorNotTaxYearFormValues.mailingAddressLine2 ?? '',
            city: getNoTaxYearValue('mailingAddressCity') as string,
            stateId: getNoTaxYearValue('mailingAddressState') as number,
            postalCode: initialAssessorNotTaxYearFormValues.mailingAddressZip ?? '',
          }
        }
      ],
      taxYears: [
        {
          taxYear: lastTaxYear?.taxYear ?? null,
          assessmentDateMonth: getTaxYearValue('assessmentDateMonth') as number,
          assessmentDateDay: getTaxYearValue('assessmentDateDate') as number,
          inventoryOriginalCostRule,
          assessmentRatio: ratioFrontendToBackend(getTaxYearValue('assessmentRatio') as string),
          extensionsOffered: extensionsOffered === 'Yes',
          extensionsDeadline: formatDateToBackend(extensionsDeadline, 'M/D/YYYY'),
          returnsDeadline: formatDateToBackend(returnsDeadline, 'M/D/YYYY'),
          returnsDeadlineIfExtended: formatDateToBackend(returnsDeadlineIfExtended, 'M/D/YYYY'),
          acquisitionYearType,
        }
      ]
    };

    setSavingAssessor(true);
    updateAssessor(bkBackendAssessor?.id ?? 0, newData)
      .then(() => {
        _.forOwn(newValues, (v, k) => {
          if (requiredFields[k] === 'Section1') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (initialAssessorNotTaxYearFormValues as any)[k] = v;
            formikAssessorNotTaxYearForm.setFieldValue(k, v);
          } else if (
            requiredFields[k] === 'Section2' &&
            initialAssessorTaxYearValues.taxYears?.[activeTaxYearIndex]
          ) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (initialAssessorTaxYearValues.taxYears[activeTaxYearIndex] as any)[k] = v;
            formikAssessorTaxYearForm.setFieldValue(`taxYears[${activeTaxYearIndex}].${k}`, v);
          }
        });

        setShowMissingInformation(null);
        showSuccess()
        setSavingAssessor(false);
      })
      .catch((e) => {
        showErrorMessage(getErrorMessage(e));
        setSavingAssessor(false);
      });
  }

  useEffect(() => {
    if (
      params.id &&
      !bkBackendAssessor &&
      !fetchingAssessorInfo &&
      allAvailableTaxYears &&
      activeTaxYearIndex !== null
    ) {
      setFetchingAssessorInfo(true);
      getAssessor(_.toNumber(params.id))
        .then((d) => {
          const assessorData = d.data;
          if (!_.isEmpty(assessorData)) {
            const address = _.get(assessorData, 'mailingAddresses[0].address') as IBackendAddress;
            setInitialAssessorNotTaxYearFormValues({
              name: assessorData.name,
              id: assessorData.id,
              state: assessorData.stateId,
              title: assessorData.mailingTitle,
              mailingAddressLine1: address?.line1,
              mailingAddressLine2: address?.line2,
              mailingAddressCity: address?.city,
              mailingAddressState: address?.stateId,
              mailingAddressZip: address?.postalCode,
              websiteLink: _.get(assessorData, `taxYears[${activeTaxYearIndex}].website`) ?? '',
            });
            const assessorTaxYears = assessorData.taxYears.map((taxYear) => {
              const formDefault: IBackendFormDefault | undefined = _.find(taxYear.formDefaults, { isDefaultForm: true });

              let inventoryOriginalCostRule = taxYear.inventoryOriginalCostRule;
              if (inventoryOriginalCostRule === null) {
                inventoryOriginalCostRule = 'Monthly Average'
              }

              return {
                taxYear: taxYear.taxYear as number,
                assessmentDateMonth: taxYear.assessmentDateMonth,
                assessmentDateDate: taxYear.assessmentDateDay,
                inventoryOriginalCostRule,
                assessmentRatio: ratioBackendToFrontend(taxYear.assessmentRatio),
                extensionOffered: taxYear.extensionsOffered ? 'Yes' : 'No',
                extensionFillingDeadline: formatDateFromBackend(taxYear.extensionsDeadline, 'M/D/YYYY'),
                returnDeadline: formatDateFromBackend(taxYear.returnsDeadline, 'M/D/YYYY'),
                returnDeadlineIfExtended: formatDateFromBackend(taxYear.returnsDeadlineIfExtended, 'M/D/YYYY'),
                acquisitionYearType: taxYear.acquisitionYearType,
                defaultDepreciationCollection: formDefault?.defaultDepreciationScheduleCollectionId,
                defaultIndexCollection: formDefault?.defaultIndexTableCollectionId,
                defaultForm: formDefault?.formId,
              }
            });
            const missingTaxYears = _.filter(allAvailableTaxYears, (tx) => !_.find(assessorTaxYears, { taxYear: tx.taxYear }));
            const taxYears = _.orderBy(
              [
                ...assessorTaxYears,
                ...missingTaxYears.map((tx) => ({
                  taxYear: tx.taxYear
                }))
              ],
              ['taxYear'],
              ['desc'],
            );

            setInitialAssessorTaxYearValues({
              taxYears,
            });
            setSelectedYearIndex(activeTaxYearIndex || 0);

            validateForm();

            setBkBackendAssessor(assessorData);
          }
          setFetchingAssessorInfo(false);
        })
        .catch(() => {
          setFetchingAssessorInfo(false);
        });
    }
  }, [
    params,
    allAvailableTaxYears,
    activeTaxYearIndex
  ])


  const isAssessorNotTaxYearFormChanged = useMemo(() => !_.isEqual(
    initialAssessorNotTaxYearFormValues,
    formikAssessorNotTaxYearForm.values
  ), [
    formikAssessorNotTaxYearForm.values,
    initialAssessorNotTaxYearFormValues
  ]);
  const isAssessorTaxYearFormChanged = useMemo(() => !_.isEqual(
    initialAssessorTaxYearValues,
    formikAssessorTaxYearForm.values
  ), [
    formikAssessorTaxYearForm.values,
    initialAssessorTaxYearValues
  ]);

  const isAnyChanges = useMemo(() => {
    return isAssessorNotTaxYearFormChanged || isAssessorTaxYearFormChanged || unsaveformMappingsList.length > 0;
  }, [
    isAssessorNotTaxYearFormChanged,
    isAssessorTaxYearFormChanged,
    unsaveformMappingsList
  ])

  const resetFormDefault = () => {
    _.forEach(formikAssessorTaxYearForm.values.taxYears, (taxYear, index) => {
      formikAssessorTaxYearForm.setFieldValue(
        `taxYears[${index}].defaultForm`,
        null,
      )
      formikAssessorTaxYearForm.setFieldValue(
        `taxYears[${index}].defaultDepreciationCollection`,
        null,
      )
      formikAssessorTaxYearForm.setFieldValue(
        `taxYears[${index}].defaultIndexCollection`,
        null,
      )
    });
    setInitialAssessorTaxYearValues((previousValue) => {
      return {
        ...previousValue,
        taxYears: (previousValue.taxYears || []).map((ty) => {
          return {
            ...ty,
            defaultForm: null,
            defaultDepreciationCollection: null,
            defaultIndexCollection: null,
          };
        })
      };
    });
    assessorTaxYearFormHandleRef.current?.resetMappingValue();
  }

  const pageTitle = useMemo(() => {
    if (isAddNew) {
      return 'New Assessor';
    }
    if (isInEditMode) {
      return 'Edit Assessor Data';
    }
    return 'Assessor Details';
  }, [isAddNew, isInEditMode])

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'AssessorManagementDetails d-flex')
    }>
      <div className={classNames('d-flex flex-column flex-1', style.blockLeft)}>
        <Breadcrumb
          items={[
            {
              value: 'Assessor Management',
              href: '/admin/assessor-management',
            },
            { value: pageTitle },
          ]}
          className={style.Breadcrumb}
        />
        {toastMessage?.visible && (
          <div className={style.toast}>
            <ToastMessage
              className='successful'
              status={toastMessage}
              visiableHandler={(value) =>
                setToastMessage({ ...toastMessage, visible: value })
              }
            />
          </div>
        )}
        <span className={style.textTitle}>{pageTitle}</span>

        <Divider className={style.divider1} />

        <AssessorNotTaxYearForm
          isInEditMode={isInEditMode}
          formik={formikAssessorNotTaxYearForm}
          isSubmitted={isSubmitted}
          setIsUpdatedState={resetFormDefault}
        />
        <AssessorTaxYearForm
          ref={assessorTaxYearFormHandleRef}
          isInEditMode={isInEditMode}
          className={style.AssessorTaxYearForm}
          formik={formikAssessorTaxYearForm}
          unsaveformMappingsList={unsaveformMappingsList}
          setUnsaveformMappingsList={setUnsaveformMappingsList}
          formikAssessorNotTaxYearForm={formikAssessorNotTaxYearForm}
          bkBackendAssessor={bkBackendAssessor}
          isSubmitted={isSubmitted}
          selectedYearIndex={selectedYearIndex}
          activeTaxYearIndex={activeTaxYearIndex}
          setSelectedYearIndex={setSelectedYearIndex}
          isAddNew={isAddNew}
          setInitialAssessorTaxYearValues={setInitialAssessorTaxYearValues}
        />

        <div className='d-flex gap-20'>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageAction}
            tooltipKey='Save & Exit'
          >
            <button
              className='primary'
              disabled={
                !isAnyChanges &&
                !isAddNew
              }
              onClick={saveAll}
            >
              Save & Exit
            </button>
          </TooltipWrapper>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageAction}
            tooltipKey='Cancel'
          >
            <button
              className='secondary'
              onClick={() => {
                navigate("/admin/assessor-management");
              }}
            >
              Cancel
            </button>
          </TooltipWrapper>
        </div>
      </div>

      {showMissingInformation ? (
        <AssessorMissingInformation
          onSave={(value, requiredFields) => {
            saveMissingField(value, requiredFields);
          }}
          onDiscard={() => setShowMissingInformation(null)}
          initialMissingFormData={showMissingInformation}
          year={formikAssessorTaxYearForm.values.taxYears?.[activeTaxYearIndex || 0]?.taxYear}
        />
      ) : null}

      <DiscardChangeConfirmModal
        initialData={1}
        inputData={isAnyChanges ? 2 : 1}
      />

      <Loader
        isOpen={
          fetchingAllTaxYears ||
          fetchingAssessorInfo ||
          savingAssessor
        }
      />
    </div>
  );
}

export default AssessorManagementDetails;