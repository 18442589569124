import {PROPERTYLIMIT} from './../constants/index';
import {useEffect, useState} from 'react';
import _ from 'lodash';
import {read, utils} from 'xlsx';
import {OriginalFileField} from '../interface';
import limitChars from '../utils/limitChars';

function useReturnJSON(file: File) {
  const [fileJSON, setfileJSON] = useState<OriginalFileField[]>([]);
  const handleFile = async (targetFile: File) => {
    // ignore in coverage report because targetFile.name can not be null
    /* istanbul ignore else */
    if (targetFile.name) {
      const fileBuffer = await targetFile.arrayBuffer();
      const workbook = read(fileBuffer, {cellDates: true, dateNF: 'mm/dd/yyyy'});
      const firstSheet = workbook.SheetNames[0];
      setfileJSON(
        _.map(
          utils.sheet_to_json(workbook.Sheets[firstSheet], {
            raw: false,
            dateNF: 'mm/dd/yyyy',
          }),
          (item, index) => {
            // eslint-disable-next-line
            const res = _.mapValues(_.mapKeys(item as any, (__v, k) => {
              return _.trim(k);
              // eslint-disable-next-line
            }), (v) => _.trim(v)) as any;
            return {
              ...limitChars(res as OriginalFileField, PROPERTYLIMIT),
              id: index + 1,
            };
          },
        ) as OriginalFileField[],
      );
    }
  };
  useEffect(() => {
    !_.isEmpty(file?.name) && handleFile(file);
  }, [file]);
  return {fileJSON, setfileJSON};
}

export default useReturnJSON;
