import React from 'react';
import { TOOLTIP_SECTIONS } from '../../enums';
import {CurrentUserPlan} from '../../interface';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import style from './Prograss.module.scss';

interface Props {
  data: CurrentUserPlan | undefined;
}

function Prograss(props: Props) {
  const {data} = props;
  return (
    <div className={style['progress']}>
      {/* Progress title */}

      <h3>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.PlanSummary}
          tooltipKey='Section Title'
        >
          <span>Plan Summary</span>
        </TooltipWrapper>
      </h3>
      <div className={style['detail']}>
        {data?.isFreeTrial ? (
          <dl>
            <dt>Plan Type:</dt>
            <dd>Free Trial</dd>
          </dl>
        ) : (
          <dl>
            <dt>Plan Type:</dt>
            <dd>Paid</dd>
          </dl>
        )}
        <dl>
          <dt>Expiration: </dt>
          <dd>{data?.expiration}</dd>
        </dl>
      </div>
      {/* Prograss bar */}
      <TooltipWrapper
        tooltipSection={TOOLTIP_SECTIONS.PlanSummary}
        tooltipKey='Progress Bar'
      >
        <div className={style['bar']}>
          {data?.isFreeTrial ? (
            <>
              {data?.numberOfAssetsCreated}/{data?.numberOfAssetsAllowed}{' '}
              allowed assets
              <div className='progress-bar'>
                <div
                  className='inner'
                  style={{
                    width: `${
                      data?.numberOfAssetsAllowed
                         ? (data?.numberOfAssetsCreated /
                            data?.numberOfAssetsAllowed) *
                          100
                        : 0
                    }%`,
                  }}
                ></div>
              </div>
            </>
          ) : (
            <>
              {data?.numberOfReturnsFiled}/{data?.numberOfReturnsPurchased}{' '}
              purchased returns filed
              <div className='progress-bar'>
                <div
                  className='inner'
                  style={{
                    width: `${
                      data?.numberOfReturnsPurchased
                        ? (data?.numberOfReturnsFiled /
                            data?.numberOfReturnsPurchased) *
                          100
                        : 0
                    }%`,
                  }}
                ></div>
              </div>
            </>
          )}
        </div>
      </TooltipWrapper>
    </div>
  );
}

export default Prograss;
