import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import style from './Tabs.module.scss';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../enums';
import {Tab} from '../../interface';

interface Props {
  tabs: string[] | Tab[];
  selected: string;
  onSelect: (value: string) => void;
  children?: JSX.Element;
}

function Tabs(props: Props) {
  const {tabs, selected, onSelect, children} = props;
  const changeTab = (tab: string | Tab) => {
    if (typeof tab === 'string') {
      onSelect(tab);
    } else {
      if (!tab.disabled) {
        onSelect(tab.name);
      }
    }
  };
  return (
    <>
      <div className={style['tabs']}>
        <ul>
          {tabs.map((tab, idx) =>
            typeof tab === 'string' ? (
              <li
                key={idx}
                role='button'
                className={classNames({
                  [style['active']]: _.isEqual(tab, selected),
                })}
                onClick={() => changeTab(tab)}
              >
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.Common}
                  tooltipKey={tab}
                >
                  <span>{tab}</span>
                </TooltipWrapper>
              </li>
            ) : (
              <li
                key={idx}
                role='button'
                className={classNames({
                  [style['active']]: _.isEqual(tab.name, selected),
                  [style['disabled']]: tab.disabled,
                })}
                onClick={() => changeTab(tab)}
              >
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.Common}
                  tooltipKey={tab.name}
                >
                  <span>{tab.name}</span>
                </TooltipWrapper>
              </li>
            ),
          )}
        </ul>
      </div>
      {children}
    </>
  );
}

export default Tabs;
