import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DepreciationSchedule, DepreciationScheduleFactor, IBackendDepreciationScheduleUpdate, IToastMessage, SelectOption } from '../../../interface';
import { bulkUpdateDepreciationFactors, createDepreciationSchedule, getDepreciationSchedule, getDepreciationScheduleCollections, getErrorMessage, updateDepreciationSchedule, } from '../../../services';
import { TOOLTIP_SECTIONS } from '../../../enums';
import _ from 'lodash';
import { FormikProps, useFormik } from 'formik';
import validateEmpty from '../../../utils/validateEmpty';
import Loader from '../../../components/Loader/Loader';
import DiscardChangeConfirmModal from '../../../components/DiscardChangeConfirmModal/DiscardChangeConfirmModal';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import Divider from '../../../components/Divider/Divider';
import style from './DepreciationScheduleManagementDetails.module.scss';
import classNames from 'classnames';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import FieldInput from '../../../components/FieldInput/FieldInput';
import FieldSelect from '../../../components/FieldSelect/FieldSelect';
import DepreciationFactorManagementTable from '../../../components/DepreciationFactorManagementTable/DepreciationFactorManagementTable';
interface Props {
  className?: string;
  isAddNew?: boolean;
}

interface DepreciationScheduleFormData {
  id: number | null;
  depreciationScheduleCollectionId: number;
  floorFactor: number;
  name: string;
}

const newInit: DepreciationScheduleFormData = {
  id: null,
  depreciationScheduleCollectionId: 0,
  floorFactor: 0,
  name: '',
};

function DepreciationScheduleManagementDetails({
  className,
  isAddNew
}: Props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [backendDepreciationSchedule, setBackendDepreciationSchedule] = useState<DepreciationSchedule | null>(null);
  const [initialDepreciationScheduleValues, setInitialDepreciationScheduleValues] = useState<IBackendDepreciationScheduleUpdate>(newInit);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const params = useParams();
  const [fetchingDepreciationCollections, setFetchingDepreciationCollections] = useState(false);
  const [depreciationCollectionOptions, setDepreciationCollectionOptions] = useState<SelectOption[]>([]);
  const [fetchingDepreciationScheduleInfo, setFetchingDepreciationScheduleInfo] = useState(false);
  const [savingDepreciationSchedule, setSavingDepreciationSchedule] = useState(false);

  const [factorsToSave, setFactorsToSave] = useState<DepreciationScheduleFactor[]>([]);
  const [savingFactors, setSavingFactors] = useState(false);

  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const shouldShowError = useMemo(() => isSubmitted, [isSubmitted]);
  const getFormErrorMessage = (key: keyof IBackendDepreciationScheduleUpdate) => {
    return shouldShowError ? formikDepreciationScheduleForm?.errors?.[key] : undefined;
  }
  const showSuccess = () => {
    window.scrollTo(0, 0);
    setToastMessage({
      message: 'Depreciation Schedule details saved successfully',
      visible: true,
      type: 'success',
    });
  }
  const showErrorMessage = (message: string) => {
    window.scrollTo(0, 0);
    setToastMessage({
      message,
      visible: true,
      type: 'error',
    });
  }

  useEffect(() => {
    fetchDepreciationCollections();
  }, [params, searchParams, isAddNew]);

  const fetchDepreciationCollections = () => {
    setFetchingDepreciationCollections(true);
    getDepreciationScheduleCollections({
      page: 0,
      stateIds: ''
    })
      .then((res) => {
        if (res.data?.items?.length > 0) {
          const depreciationCollectionOptions = res.data.items.map((depreciationCollection) => ({
            value: depreciationCollection.id.toString(),
            label:`${depreciationCollection.name}  |  ${depreciationCollection.taxYear}`,
            name: `${depreciationCollection.name}  |  ${depreciationCollection.taxYear}`,
          }));
          setDepreciationCollectionOptions(depreciationCollectionOptions);
        }
        setFetchingDepreciationCollections(false);

        if (isAddNew) {
          setInitialDepreciationScheduleValues(newInit);
          const depreciationCollectionId = searchParams.get('depreciationScheduleCollectionId')
          if (depreciationCollectionId) {
            formikDepreciationScheduleForm?.setFieldValue(
              'depreciationScheduleCollectionId',
              _.toNumber(depreciationCollectionId)
            );
          }
        }
        else {
          fetchDepreciationSchedule();
        }
      })
      .catch((e) => {
        showErrorMessage(getErrorMessage(e));
        setFetchingDepreciationCollections(false);
      });
  }

  const fetchDepreciationSchedule = () => {
    if (
      params.id &&
      !fetchingDepreciationScheduleInfo
    ) {
      setFetchingDepreciationScheduleInfo(true);
      getDepreciationSchedule(_.toNumber(params.id))
        .then((d) => {
          const depreciationScheduleData = d.data;
          if (!_.isEmpty(depreciationScheduleData)) {
            setInitialDepreciationScheduleValues(depreciationScheduleData);
            setBackendDepreciationSchedule(depreciationScheduleData);
            formikDepreciationScheduleForm.validateForm();
          }
          setFetchingDepreciationScheduleInfo(false);
        })
        .catch((e) => {
          showErrorMessage(getErrorMessage(e));
          setFetchingDepreciationScheduleInfo(false);
        });
      }
  };

  const formikDepreciationScheduleForm: FormikProps<IBackendDepreciationScheduleUpdate> = useFormik<IBackendDepreciationScheduleUpdate>({
    initialValues: initialDepreciationScheduleValues,
    enableReinitialize: true,
    validate: (values) => {
      const requiredErrors = validateEmpty(_.pick({
        ...values,
      }, [
        'name',
        'depreciationScheduleCollectionId',
        'floorFactor',
      ]));
      return requiredErrors;
    },
    onSubmit: (values) => {
      if (!isDepreciationScheduleFormChanged && factorsToSave.length === 0) {
        return;
      }

      setSavingDepreciationSchedule(true);

      if (isAddNew) {
        createDepreciationSchedule(values)
          .then(() => {
            showSuccess();
            setInitialDepreciationScheduleValues(formikDepreciationScheduleForm.values);
            setSavingDepreciationSchedule(false);
            setIsSubmitted(false);
            setTimeout(() => {
              navigate(-1);
            }, 100);
          })
          .catch((e) => {
            showErrorMessage(getErrorMessage(e));
            setSavingDepreciationSchedule(false);
            setIsSubmitted(false);
          });
      }
      else {
        updateDepreciationSchedule(backendDepreciationSchedule?.id ?? 0, formikDepreciationScheduleForm.values)
          .then(() => {
            setInitialDepreciationScheduleValues(values);
            setSavingDepreciationSchedule(false);
            
            if (factorsToSave.length > 0) {
              setSavingFactors(true);
              bulkUpdateDepreciationFactors(backendDepreciationSchedule?.id ?? 0, factorsToSave)
                .then(() => {
                  setInitialDepreciationScheduleValues(values);
                  showSuccess();
                  setSavingFactors(false);
                  setTimeout(() => {
                    navigate(-1);
                  }, 100);
                })
                .catch((e) => {
                  showErrorMessage(getErrorMessage(e));
                  setSavingFactors(false);
                });
            }
            else {
              showSuccess();
              setTimeout(() => {
                navigate(-1);
              }, 100);
            }
          })
          .catch((e) => {
            showErrorMessage(getErrorMessage(e));
            setSavingDepreciationSchedule(false);
          });
      }
    },
  });

  const isDepreciationScheduleFormChanged = useMemo(() => !_.isEqual(
    initialDepreciationScheduleValues,
    formikDepreciationScheduleForm.values
  ), [
    formikDepreciationScheduleForm.values,
    initialDepreciationScheduleValues
  ]);

  const pageTitle = useMemo(() => {
    if (isAddNew) {
      return 'New Depreciation Schedule';
    }
    return 'Depreciation Schedule Details';
  }, [isAddNew])

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'DepreciationScheduleManagementDetails d-flex')
    }>
      <div className={classNames('d-flex flex-column flex-1', style.blockLeft)}>
        <Breadcrumb
          items={[
            {
              value: 'Depreciation Schedule Collection Management',
              href: '/admin/schedule-collection-management',
            },
            { value: pageTitle },
          ]}
          className={style.Breadcrumb}
        />
        {toastMessage?.visible && (
          <div className={style.toast}>
            <ToastMessage
              className='successful'
              status={toastMessage}
              visiableHandler={(value) =>
                setToastMessage({ ...toastMessage, visible: value })
              }
            />
          </div>
        )}
        <span className={style.textTitle}>{pageTitle}</span>

        <Divider className={style.divider1} />

        <div className='d-grid grid-4-columns gap-column-25 gap-row-20'>
          <FieldSelect
            labelText='Depreciation Schedule Collection'
            options={depreciationCollectionOptions}
            selectId={depreciationCollectionOptions?.find(option => option.value == formikDepreciationScheduleForm?.values?.depreciationScheduleCollectionId)?.value ?? 0}
            onSelect={(value) => {
                formikDepreciationScheduleForm?.setFieldValue(
                'depreciationScheduleCollectionId',
                value?.value,
              )
            }}
            hideNullOption
            error={getFormErrorMessage('depreciationScheduleCollectionId')}
            isInReadOnlyMode={true}
          />
          <FieldInput
            labelText='Depreciation Schedule Name'
            name='name'
            value={formikDepreciationScheduleForm?.values?.name ?? ''}
            onChange={(value) => {
                formikDepreciationScheduleForm?.setFieldValue('name', value);
            }}
            error={getFormErrorMessage('name')}
            isInReadOnlyMode={true}
          />
          <FieldInput
            type='number'
            labelText='Floor Factor'
            name='floorFactor'
            value={formikDepreciationScheduleForm?.values?.floorFactor ?? 0}
            onChange={(value) => {
                formikDepreciationScheduleForm?.setFieldValue('floorFactor', value);
            }}
            error={getFormErrorMessage('floorFactor')}
            isInReadOnlyMode={true}
          />
        </div>

        {
          !isAddNew && (
            <div>
              <Divider className={style.divider1} strokeWidth={1} />

              <span className={style.textTitle}>Depreciation Factors</span>
              <DepreciationFactorManagementTable 
                depreciationScheduleId={_.toNumber(params.id)}
              />
            </div>
          )
        }
        

        <Divider className={style.divider1} strokeWidth={2} />

        <div className='d-flex gap-20'>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageAction}
            tooltipKey='Back'
          >
            <button
              className='secondary'
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
          </TooltipWrapper>
        </div>
      </div>

      <DiscardChangeConfirmModal
        initialData={1}
        inputData={isDepreciationScheduleFormChanged ? 2 : 1}
      />

      <Loader
        isOpen={
          fetchingDepreciationScheduleInfo ||
          fetchingDepreciationCollections ||
          savingDepreciationSchedule ||
          savingFactors
        }
      />
    </div>
  );
}

export default DepreciationScheduleManagementDetails;