import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import _, {isEqual} from 'lodash';
import FieldInput from '../FieldInput/FieldInput';
import style from './HelpCategoryForm.module.scss';
import {FormikValues, useFormik} from 'formik';
import FieldRichText from '../FieldRichText/FieldRichText';
import FieldTagInput from '../FieldTagInput/FieldTagInput';
import validateEmpty from '../../utils/validateEmpty';
import FieldToggleCheckbox from '../FieldToggleCheckbox/FieldToggleCheckbox';
import DiscardChangeConfirmModal from '../../components/DiscardChangeConfirmModal/DiscardChangeConfirmModal';

// Define the required fields
const requiredField = ['categoryTitle', 'topicTitle', 'topicContent'];

export interface HelpCategoryFormData {
  categoryId?: number;
  categoryTitle: string;
  topicId?: number;
  topicVersionId?: number;
  topicTitle: string;
  topicContent?: string;
  tags: string[];
  topicCount: number;
  hiddenFromPublic: boolean;
}

interface Props {
  data: HelpCategoryFormData;
  onSubmit: (isPublish: boolean, formData: HelpCategoryFormData) => void;
  onEditing: (data: boolean) => void;
  onReset: () => void;
  onDeleteCategory: (id?: number) => void;
  onDeleteTopic: (id?: number) => void;
}

function HelpCategoryForm({
  data,
  onSubmit,
  onEditing,
  onDeleteCategory,
  onDeleteTopic,
}: Props) {
  const [editingCategory, setEditingCategory] = useState(false);
  const [editingTopic, setEditingTopic] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [published, setPublished] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleCategoryDelete = (id?: number) => {
    onDeleteCategory(id);
  };
  const handleTopicDelete = (id?: number) => {
    onDeleteTopic(id);
  };

  const formik = useFormik<HelpCategoryFormData>({
    initialValues: data,
    enableReinitialize: true,
    validate: (values) => {
      if (isSubmitted) {
        const error = validateEmpty(
          _.pick(values as FormikValues, requiredField),
        );
        onEditing(!isEqual(values, formik.initialValues));
        return error;
      }
    },
    onSubmit: (values) => {
      onSubmit(published, values);
      setIsSubmitted(false);
    },
  });

  useEffect(() => {
    // ignore in coverage report because formik can not be null
    /* istanbul ignore else */
    if (formik) {
      if (
        data.categoryId !== formik.initialValues.categoryId ||
        data.topicId !== formik.initialValues.topicId
      ) {
        setIsNew(true);
      } else {
        setIsNew(false);
      }
    }
  }, [data, formik]);

  useEffect(() => {
    if (isNew) {
      if (!data.categoryId || !data.topicId) {
        formik.setFieldValue('topicTitle', `${data.topicTitle} `);
      }
    }
  }, [isNew]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={style['headerRow']}>
          <h3>Help Content</h3>
          <FieldToggleCheckbox
            label='Hide from Public'
            value={formik.values.hiddenFromPublic}
            onChange={(val) => formik.setFieldValue('hiddenFromPublic', val)}
          />
        </div>
        <div className={style['section']}>
          <div className={style['form']}>
            <div className={style['row']}>
              {editingCategory ? (
                <div className={classNames(style['column'])}>
                  <FieldInput
                    classnames={style['field']}
                    labelText='Help Category'
                    name='categoryTitle'
                    value={formik.values.categoryTitle}
                    onChange={(val) =>
                      formik.setFieldValue('categoryTitle', val)
                    }
                    error={formik.errors && formik.errors.categoryTitle}
                    required
                  />
                  <div className={style['operate']}>
                    {!(formik.errors && formik.errors.categoryTitle) && (
                      <i
                        role='button'
                        className={style['close']}
                        onClick={() => {
                          setEditingCategory(false);
                        }}
                      ></i>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <label>Help Category</label>
                  <div
                    className={classNames(
                      style['column'],
                      style['bordered-column'],
                    )}
                  >
                    <div className={style['text']}>
                      {formik.values.categoryTitle}
                    </div>
                    <div className={style['operate']}>
                      <i
                        role='button'
                        className={style['edit']}
                        onClick={() => {
                          setEditingCategory(true);
                        }}
                      ></i>
                      <i
                        role='button'
                        className={style['delete']}
                        onClick={() =>
                          handleCategoryDelete(formik.values.categoryId || 0)
                        }
                      ></i>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={classNames(style['row'], 'padding-0')}>
              {editingTopic ? (
                <div className={classNames(style['column'])}>
                  <FieldInput
                    classnames={style['field']}
                    labelText='Help Topic'
                    name={`topicTitle`}
                    value={formik.values.topicTitle}
                    onChange={(val) => formik.setFieldValue(`topicTitle`, val)}
                    error={formik.errors && formik.errors.topicTitle}
                    required
                  />
                  <div className={style['operate']}>
                    {!(formik.errors && formik.errors.topicTitle) && (
                      <i
                        role='button'
                        className={style['close']}
                        onClick={() => {
                          setEditingTopic(false);
                        }}
                      ></i>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <label>Help Topic</label>
                  <div className={classNames(style['column'])}>
                    <div className={style['text']}>
                      {formik.values.topicTitle}
                    </div>
                    <div className={style['operate']}>
                      <i
                        role='button'
                        className={style['edit']}
                        onClick={() => {
                          setEditingTopic(true);
                        }}
                      ></i>
                      {data.topicCount > 1 && (
                        <i
                          role='button'
                          className={style['delete']}
                          onClick={() =>
                            handleTopicDelete(formik.values.topicId || 0)
                          }
                        ></i>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={style['row']}>
              <FieldRichText
                required
                value={formik.values.topicContent || ''}
                onChange={(val) => {
                  setTimeout(() => {
                    formik.setFieldValue('topicContent', val);
                  }, 0);
                }}
                error={formik.errors && formik.errors.topicContent}
              />
            </div>
            <div className={style['row']}>
              <div className={classNames(style['column'])}>
                <FieldTagInput
                  labelText='Tags'
                  value={formik.values.tags}
                  onChange={(val) => formik.setFieldValue('tags', val)}
                />
              </div>
            </div>
            <div className={style['footer']}>
              {/* Submit link,it change the flag of submit */}
              <div className={style['buttons']}>
              <button
                  type='submit'
                  className='primary-button'
                  onClick={() => {
                    setIsSubmitted(true);
                    setPublished(true);
                  }}
                >
                  Save &amp; Publish
                </button>
                <button
                  type='submit'
                  className='default-button'
                  onClick={() => {
                    setIsSubmitted(true);
                    setPublished(false);
                  }}
                >
                  Save Draft
                </button>
                <button
                  className='default-button'
                  onClick={(e) => {
                    e.preventDefault();
                    formik.resetForm();
                    setIsSubmitted(false);
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <DiscardChangeConfirmModal
        initialData={data}
        inputData={formik.values}
        onConfirm={() => {
          formik.resetForm();
          setIsSubmitted(false);
        }}
      />
    </>
  );
}

export default HelpCategoryForm;
