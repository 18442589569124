import {
  FIXEDASSETFILECOLUMNS,
  INVENTORYFILECOLUMNS,
  VEHICLEFILECOLUMNS,
  LEASEVEHICLEFILECOLUMNS,
  LEASEEQUIPMENTFILECOLUMNS,
} from "../constants";

const chooseTemplate = (value: string) => {
  switch (value) {
    case "Fixed Asset":
      return FIXEDASSETFILECOLUMNS;
    case "Inventory":
      return INVENTORYFILECOLUMNS;
    case "Vehicle":
      return VEHICLEFILECOLUMNS;
    case "Leased Vehicle":
      return LEASEVEHICLEFILECOLUMNS;
    default:
      return LEASEEQUIPMENTFILECOLUMNS;
  }
};

export default chooseTemplate;
