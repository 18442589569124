import classNames from "classnames";
import {
  camelCase,
  debounce,
  Dictionary,
  entries,
  flatMap,
  Function1,
  get,
  isArray,
  isEmpty,
  isEqual,
  isFunction,
  isString,
  isUndefined,
  last,
  map,
  mapValues,
  omit,
  omitBy,
  orderBy,
  some,
  transform,
  uniqueId,
  updateWith,
} from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TOOLTIP_COMMON_KEYS, TOOLTIP_SECTIONS } from "../../enums";
import Chips from "../Chips/Chips";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import SearchInput from "../SearchInput/SearchInput";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";
import Column, {
  AccessorColumnProps,
  CellValue,
  ColumnConfig,
  ColumnFilters,
  ColumnOrder,
  ColumnProps,
  ColumnPropsBase,
  PropertyColumnProps,
} from "./components/Column";
import Editor from "./components/Editor/Editor";
import FilterPanel from "./components/FilterPanel/FilterPanel";
import style from "./Table.module.scss";
import { getColKey, getRowId, IdAccessor } from "./utils";
import {
  ColumnItem,
  ColumnManager,
  Icon,
  Loader,
  Pagination,
} from "@avalara/skylab-react";

export interface ColumnSettings {
  label: string;
  order: number;
  visible: boolean;
}

export type TableSettings = { [colKey: string]: ColumnSettings };

const SearchDebounce = 500;

export interface PaginationOptions {
  page: number;
  pageSize: number;
}

export interface Query {
  pagination?: PaginationOptions;
  order?: ColumnOrder;
  filter?: {
    [keyOrProp: string]: unknown;
  };
  search?: string;
}

interface TableProps<T> {
  id: string;
  rowId?: IdAccessor<T>;
  sortable?: boolean;
  editable?: true;
  filterable?: boolean;
  children:
    | React.ReactNode
    | (({
        renderFilterButton,
      }: {
        renderFilterButton: () => React.ReactNode;
      }) => React.ReactNode);
  rows: T[];
  totalRows?: number;
  onQueryChanged?: (query: Query) => void;
  paginate?: boolean;
  selectable?: {
    selected: T[];
    onChange: (selected: T[]) => void;
  };
  searchable?: true | string;
  dynamicColumns?: boolean;
  defaultSort?: ColumnOrder;
  loading: boolean;
  // eslint-disable-next-line
  updatingTableTopbar?: any;
  showTopBar?: boolean;
  forceVisiblePanel?: "filter" | null;
  closeVisiblePanel?: () => void;
  showAddButton?: string;
  onAddButtonClick?: () => void;
}

interface TableQuery {
  filters: string | null;
  search: string | null;
}

function getAccessor<T>(col: ColumnPropsBase<T>) {
  let { accessor } = col as AccessorColumnProps<T, CellValue>;
  const { prop } = col as PropertyColumnProps<T>;

  if (!accessor && prop) {
    accessor = (row: T) => get(row, prop);
  }

  return (row: T | null) => (row && accessor ? accessor(row) : undefined);
}

function renderCell<T>(
  row: T,
  rowId: string | number,
  col: ColumnProps<T>,
  table: TableProps<T>
) {
  const { children: renderer } = col;
  const value = getAccessor(col)(row);

  if (!(col.editable ?? table.editable)) {
    if (renderer) {
      return isUndefined(value)
        ? (renderer as Function1<T, React.ReactNode>)(row)
        : renderer(value, row);
    } else return value;
  }

  return (
    <Editor
      value={value ?? null}
      config={col.editor}
      onChange={(updated) => col.onChange?.(updated, row)}
      error={col.editor?.error?.(row, rowId)}
      isCellEditField
      disabled={col.editor?.disabled}
    />
  );
}

function Table<T>(props: TableProps<T>) {
  const {
    id,
    rows,
    children,
    sortable,
    paginate,
    selectable,
    searchable,
    filterable,
    totalRows,
    dynamicColumns,
    onQueryChanged,
    defaultSort,
    loading = false,
    updatingTableTopbar = null,
    showTopBar = true,
    forceVisiblePanel,
    closeVisiblePanel,
    showAddButton,
    onAddButtonClick,
  } = props;

  const renderFilterButton = () => (
    <TooltipWrapper
      tooltipSection={TOOLTIP_SECTIONS.TableAction}
      tooltipKey={TOOLTIP_COMMON_KEYS.TableFilter}
    >
      <button
        className="default-button filter-button"
        onClick={() => setVisiblePanel("filter")}
      >
        <i></i>Filter
      </button>
    </TooltipWrapper>
  );

  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const settingsId = useMemo(() => `table_${camelCase(id)}_settings`, [id]);

  const childrenNode = isFunction(children)
    ? children({ renderFilterButton })
    : children;

  // Column list
  const content = useMemo(() => {
    const arr = flatMap(
      isArray(childrenNode) ? childrenNode : [childrenNode]
    );
    const getEntries = (...types: React.ElementType[]) =>
      arr.filter((x) => types.includes(x.type));

    return {
      columns: getEntries(Column) as ColumnConfig<T>[],
      topBar: getEntries(TopBar)?.[0],
    };
  }, [childrenNode, updatingTableTopbar]);

  // Column settings
  const [settings, setSettings] = useState(undefined as TableSettings | void);

  const settingsList = useMemo(
    () =>
      orderBy(entries(settings ?? {}), ([, col]) => col.order).map(
        ([colId, col]) =>
          ({
            id: colId,
            label: col.label,
            hidden: !col.visible,
          } as ColumnItem)
      ),
    [settings]
  );

  // Load settings
  useEffect(() => {
    if (dynamicColumns) {
      const json = localStorage.getItem(settingsId);
      setSettings(json ? JSON.parse(json) : {});
    }
    setSettingsLoaded(true);
  }, [settingsId, dynamicColumns]);

  // Default settings from column definitions
  useEffect(() => {
    if (settings) {
      const ordered = orderBy(
        content.columns.filter((x) => x.props.hidden !== "always"),
        (col) => settings[getColKey(col.props)]?.order
      );
      const updatedSettings = transform(
        ordered,
        (acc, col, idx) => {
          const key = getColKey(col.props);

          acc[key] = {
            // Grouped columns not supported for now
            label: isArray(col.props.label)
              ? (last(col.props.label) as string)
              : col.props.label,
            order: idx + 1,
            visible: settings[key]?.visible ?? !col.props.hidden,
          };
        },
        {} as TableSettings
      );

      if (!isEqual(settings, updatedSettings)) {
        setSettings(updatedSettings);
      }
    }
  }, [content.columns, settings]);

  // Column render list
  const displayedColumns = useMemo(
    () =>
      orderBy(
        content.columns.filter(
          (col) =>
            col.props.hidden !== "always" &&
            settings?.[getColKey(col.props)]?.visible !== false
        ),
        (col) => settings?.[getColKey(col.props)]?.order
      ),
    [content.columns, settings]
  );

  const numDisplayedColumns = useMemo(
    () => displayedColumns.length + (selectable ? 1 : 0),
    [displayedColumns.length, selectable]
  );

  // Save settings
  useEffect(() => {
    if (settings) {
      localStorage.setItem(settingsId, JSON.stringify(settings));
    }
  }, [settings, settingsId]);

  // Pagination data
  const [pagination, setPagination] = useState<PaginationOptions>({
    page: 1,
    pageSize: 10,
  });

  const [paginationStart, setPaginationStart] = useState(0);

  // Opened panel
  const [visiblePanel, setVisiblePanel] = useState(null as "filter" | null);

  // Sort data
  const [order, setOrder] = useState(defaultSort || ({} as ColumnOrder));

  // Selections state
  const [selected, setSelected] = useState(new Set<string | number>());

  // Filters
  const [filters, setFilters] = useState(undefined as ColumnFilters | undefined);
  const lastFiltersRef = useRef<Dictionary<unknown>>();
  const filtersId = useMemo(() => `table_${camelCase(id)}_filters`, [id]);

  const filterChips = useMemo(() => {
    const paths = [] as (string | [string, number])[];

    const labels = transform(
      filters ?? {},
      (acc, filter, key) => {
        if (isArray(filter.label)) {
          filter.label.forEach((x, idx) => {
            acc.push(x);
            paths.push([key, idx]);
          });
        } else if (filter.label) {
          acc.push(filter.label);
          paths.push(key);
        }
      },
      [] as string[]
    );

    return { paths, labels };
  }, [filters]);

  const removeFilter = (target?: string | [string, number]) => {
    const updated = target ? { ...filters } : {};

    if (isString(target)) {
      delete updated[target];
    } else if (isArray(target)) {
      updateWith(updated, target[0], (obj) =>
        mapValues(obj, (arr: unknown[]) =>
          arr.filter((_, i) => i !== target[1])
        )
      );
    }

    setFilters(updated);
  };

  // Search
  const [searchInput, setSearchInput] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const lastSearchRef = useRef<string>();

  // Load filters, search
  useEffect(() => {
    const json = localStorage.getItem(filtersId);
    if (json) {
      const parsedJson = JSON.parse(json);
      const parsedFilters = parsedJson?.filters ? JSON.parse(parsedJson.filters): '';
      setFilters(parsedFilters);
      setSearchInput(parsedJson.search);
    }
    setFiltersLoaded(true);
  }, [filtersId]);

  // Save filters, search
  useEffect(() => {
    const query: TableQuery = { filters: null, search: null };
    if (filters && !isEqual(filters, lastFiltersRef.current)) {
      lastFiltersRef.current = filters;
      query.filters = JSON.stringify(filters);
    }
    else {
      query.filters = JSON.stringify(lastFiltersRef.current);
    }

    if (search !== null && !isEqual(search, lastSearchRef.current)) {
      lastSearchRef.current = search;
      query.search = search;
    }
    else {
      query.search = lastSearchRef.current ?? '';
    }

    if (query.filters || query.search) {
      localStorage.setItem(filtersId, JSON.stringify(query));
    }

  }, [filters, search]);

  // Query change
  useEffect(() => {
    if (settingsLoaded && filtersLoaded) {
      onQueryChanged?.(
        omitBy(
          {
            filter: mapValues(filters, (x) => x.value),
            pagination,
            order,
            search,
          },
          isEmpty
        )
      );
    }
  }, [pagination, order, filters, search, settingsLoaded, filtersLoaded]);

  const onSearchChange = useCallback(
    debounce((x) => setSearch(x), SearchDebounce),
    []
  );

  // Debounce search
  useEffect(() => onSearchChange(searchInput), [searchInput]);

  const rowId = (row: T) => props.rowId && getRowId(row, props.rowId);

  useEffect(() => {
    if (selectable?.onChange) {
      const byKey = transform(
        rows,
        (acc, row, idx) => {
          acc[rowId(row) ?? idx] = row;
        },
        {} as Dictionary<T>
      );

      selectable.onChange(Array.from(selected).map((x) => byKey[x]));
    }
  }, [selected]);

  useEffect(() => {
    const newSet = new Set(
      map(selectable?.selected, (row) => rowId(row) ?? rows?.indexOf(row))
    );

    if (!isEqual(newSet, selected)) {
      setSelected(newSet);
    }
  }, [selectable?.selected]);

  /**
   * Toggle sort (single column for now)
   * @param key column key
   */
  const onSort = (key: string | number) => {
    switch (order[key]) {
      case "asc":
        setOrder({ [key]: "desc" as const });
        break;
      case "desc":
        setOrder(omit(order, key));
        break;
      default:
        setOrder({ [key]: "asc" as const });
    }
  };

  useEffect(() => {
    paginationStart > 0 && setPaginationStart(0);
  }, [totalRows ?? rows?.length]);

  return (
    <>
      {/* Top bar above table */}
      {showTopBar ? (
        <div className={style.bar}>
          {/* Search input */}
          {searchable && (
            <SearchInput
              className={style.searchInput}
              placeholder={isString(searchable) ? searchable : "Search..."}
              value={searchInput}
              onChange={setSearchInput}
            />
          )}

          {content.topBar}

          {/* Filter button */}
          {(filterable ?? some(content.columns, (x) => x.props.filterable)) &&
            renderFilterButton()}

          {/* Settings expand button */}
          {dynamicColumns && (
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.TableAction}
              tooltipKey={TOOLTIP_COMMON_KEYS.TableColumnSettings}
            >
              <ColumnManager
                className={style["col-manage"]}
                id={settingsId}
                columnItems={settingsList}
                onSSave={(e) =>
                  setSettings(
                    transform(
                      e.detail.columnItems as ColumnItem[],
                      (acc, col, idx) => {
                        acc[col.id] = {
                          label: col.label,
                          order: idx + 1,
                          visible: !col.hidden,
                        };
                      },
                      {} as TableSettings
                    )
                  )
                }
              />
            </TooltipWrapper>
          )}

          {showAddButton ? (
            <button
              className='button primary add-button'
              onClick={onAddButtonClick}
            >
              <i></i>{showAddButton}
            </button>
          ) : null}
        </div>
      ) : null}

      {/* Active filters */}
      {filterChips.labels.length > 0 && (
        <Chips
          className={style.chips}
          values={filterChips.labels}
          onRemove={(_, idx) => removeFilter(filterChips.paths[idx])}
          onRemoveAll={() => removeFilter()}
          removeAllLabel="Clear Filter"
        ></Chips>
      )}

      <div className="table-layout">
        <div className={style.tableWrapper}>
          <table>
            <thead>
              <tr>
                {/* Check all rows */}
                {selectable && (
                  <th className={style["select-col"]}>
                    <div>
                      <CustomCheckbox
                        label=""
                        value={rows?.length > 0 && selected.size === rows.length}
                        onChange={(checked) =>
                          setSelected(
                            new Set(
                              checked
                                ? rows.map((row, idx) => rowId(row) ?? idx)
                                : []
                            )
                          )
                        }
                        classnames={"no-space"}
                      />
                      <TooltipWrapper
                        tooltipSection={TOOLTIP_SECTIONS.TableHeader}
                        tooltipKey='Select All'
                        className={style["tooltip-wrapper"]}
                      >
                        <i role='button' className={style['icon-info']}></i>
                        {/* <Icon name="info-circle" />  */}
                      </TooltipWrapper>
                    </div>
                  </th>
                )}

                {map(displayedColumns, (col) => {
                  const colKey = getColKey(col.props);

                  return (
                    <th key={uniqueId(colKey as string)} data-col={colKey} className={col.props.cssClasses || ''}>
                      <TooltipWrapper
                        tooltipSection={TOOLTIP_SECTIONS.TableHeader}
                        // Grouped columns not supported for now
                        tooltipKey={
                          isArray(col.props.label)
                            ? (last(col.props.label) as string)
                            : col.props.label
                        }
                      >
                        <span
                          className={
                            col.props.sortable ?? sortable
                            ? classNames("sort", order[col.props.sortKey || getColKey(col.props)])
                              : undefined
                          }
                          onClick={
                            col.props.sortable ?? sortable
                            ? () => onSort(col.props.sortKey || getColKey(col.props))
                              : undefined
                          }
                        >
                          {/* Grouped columns not supported for now */}
                          {isArray(col.props.label)
                            ? (last(col.props.label) as string)
                            : col.props.label}
                        </span>
                      </TooltipWrapper>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                map(rows, (row, idx) => {
                  const rowKey = rowId(row) ?? idx;

                  return (
                    <tr
                      key={rowKey}
                      className={selected.has(rowKey) ? "selected" : undefined}
                    >
                      {selectable && (
                        <td className={style["select-col"]}>
                          <CustomCheckbox
                            label=""
                            classnames={"no-space"}
                            value={selected.has(rowKey)}
                            onChange={(checked) => {
                              const updated = new Set(selected);

                              checked
                                ? updated.add(rowKey)
                                : updated.delete(rowKey);

                              setSelected(updated);
                            }}
                          />
                        </td>
                      )}

                      {map(displayedColumns, (col) => {
                        const colKey = getColKey(col.props);

                        return (
                          <td key={colKey} data-col={colKey} className={col.props.cssClasses || ''}>
                            {renderCell(row, rowKey, col.props, props)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    className={style.loadingRow}
                    colSpan={numDisplayedColumns}
                  >
                    <Loader loading={true} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        {paginate && (
          <div className={style["pagination"]}>
            <Pagination
              totalRecords={totalRows ?? rows?.length}
              rowsPerPage={pagination.pageSize}
              startIndex={paginationStart}
              onSPaginate={(e) => {
                const updated = {
                  pageSize: e.detail.rowsPerPage,
                  page: e.detail.currentPage,
                };

                if (!isEqual(updated, pagination) && updated.page) {
                  setPagination(updated);
                }

                if (e.detail.startIndex !== paginationStart) {
                  setPaginationStart(e.detail.startIndex);
                }
              }}
            />
          </div>
        )}

        {/* Filters */}
        <FilterPanel
          id={id}
          columns={content.columns}
          defaultFilterable={filterable ?? false}
          filters={filters ?? {}}
          onChange={setFilters}
          onClose={() => {
            setVisiblePanel(null)
            closeVisiblePanel?.()
          }}
          open={
            visiblePanel === "filter" ||
            forceVisiblePanel === "filter"
          }
        />
      </div>
    </>
  );
}

const TopBar = ({ children }: { children: React.ReactNode }) => <>{children}</>;

export default function InitTable<T>() {
  return {
    Column: Column as (props: ColumnProps<T>) => null,
    TopBar,
    Table: Table as (props: TableProps<T>) => JSX.Element,
  };
}
