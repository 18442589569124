import classNames from "classnames";
import { includes, keyBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../components/Loader/Loader';
import RightNavigationPanel from "../../../components/RightNavigationPanel/RightNavigationPanel";
import ToastMessage from "../../../components/ToastMessage/ToastMessage";
import TooltipForm from "../../../components/TooltipForm/TooltipForm";
import { IToastMessage, PageTooltip, TooltipItem } from "../../../interface";
import {
  AsyncStatus,
  getPagesAction,
  getTooltipsAction,
  resetStatus,
  updateTooltipsAction,
} from "../../../redux/features/admin-slice";
import { RootState, useAppDispatch } from "../../../redux/store";
import { DEFAULT_ERROR_MESSAGE } from '../../../services';
import style from "./Tooltip.module.scss";

interface Props {
  updateTooltipsStatus: AsyncStatus;
  fetchPagesStatus: AsyncStatus;
  fetchTooltipsStatus: AsyncStatus;
  pages: string[];
  tooltips: PageTooltip[];
}

function Tooltip({ updateTooltipsStatus, pages, tooltips, fetchPagesStatus, fetchTooltipsStatus }: Props) {
  const dispatch = useAppDispatch();
  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  const { page } = useParams();
  const navigate = useNavigate();

  const goToPage = (target: string) => navigate(target, { replace: true });

  const pagesByTitle = useMemo(
    () => keyBy(tooltips, (x) => x.page),
    [tooltips]
  );

  useEffect(() => {
    dispatch(getPagesAction());
    dispatch(getTooltipsAction({page: ''}));
  }, []);

  // Default to first page
  useEffect(() => {
    if (pages?.length && !includes(pages, page)) {
      goToPage(pages[0]);
    }
  }, [pages]);

  useEffect(() => {
    if (updateTooltipsStatus === "fulfilled") {
      dispatch(resetStatus());
      setToastMessage({
        message: "The tooltips are saved successfully.",
        visible: true,
        type: "success",
      });
    } else if (updateTooltipsStatus === 'rejected') {
      dispatch(resetStatus());
      setToastMessage({
        message: DEFAULT_ERROR_MESSAGE,
        visible: true,
        type: "error",
      });
    }
  }, [updateTooltipsStatus]);

  return (
    <div className={style["main-content"]}>
      {page && (
        <div className={style["main-wrapper"]}>
          <ToastMessage
            status={toastMessage}
            className="successful"
            visiableHandler={(value) => {
              if (toastMessage) {
                setToastMessage({ ...toastMessage, visible: value });
              }
            }}
          />
          <TooltipForm
            tooltips={pagesByTitle[page]?.items}
            onSubmit={(data: TooltipItem[]) => {
              dispatch(updateTooltipsAction({ data }));
            }}
          />
        </div>
      )}
      <RightNavigationPanel
        icon={<i className={style["icon"]}></i>}
        title="Page"
      >
        <h4>Select Page To Manage Tooltip</h4>
        <ul>
          {pages.map((item, index) => (
            <li
              className={classNames(item === page && style["selected"])}
              key={index}
              onClick={() => {
                goToPage(item);
              }}
            >
              {item}
            </li>
          ))}
        </ul>
      </RightNavigationPanel>

      <Loader
        isOpen={
          fetchPagesStatus === 'pending' ||
          fetchTooltipsStatus === 'pending' ||
          updateTooltipsStatus === 'pending'
        }
      />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  updateTooltipsStatus: state.admin.updateTooltipsStatus,
  pages: state.admin.pages,
  fetchPagesStatus: state.admin.fetchPagesStatus,
  tooltips: state.admin.tooltips,
  fetchTooltipsStatus: state.admin.fetchTooltipsStatus,
});

export default connect(mapStateToProps)(Tooltip);
