import classNames from 'classnames';
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import style from './AssessorManagementTable.module.scss';
import InitTable, { Query } from '../Table/Table';
import { InputType } from '../Table/components/Editor/Editor';
import { Link, useNavigate } from 'react-router-dom';
import { deleteAssessor, getAssessors, getErrorMessage, getSystemTaxYears } from '../../services';
import { useAppSelector } from '../../redux/store';
import { Assessor, IBackendAddress, IBackendFormDefault, IToastMessage, SelectOption, State } from '../../interface';
import _ from 'lodash';
import { convertSelectObjectToMapping, getInventoryOriginalCostRuleOptions, getMonthsOptions } from '../../utils/selectionOptions';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { formatDateFromBackend } from '../../utils/date';

interface ITableData {
  id: number;
  name: string;
  title: string;
  state: string;
  mailingAddress: string;
  websiteLink: string;
  assessmentDateMonth?: number;
  assessmentDateDay?: string;
  inventoryOriginalCostRule: string | null;
  returnDeadline?: string;
  extensionOffered?: string;
  extensionFillingDeadline?: string;
  returnDeadlineIfExtended?: string;
  defaultReturnForm?: string;
  defaultDepreciation?: string;
  defaultIndex?: string;
}

const { Table, Column } = InitTable<ITableData>();


const monthOptions: SelectOption[] = getMonthsOptions();
const monthMapping: { [key: number]: string } = _.reduce(
  monthOptions,
  (r, n) => {
    return {
      ...r,
      [n.value as number]: n.name
    };
  },
  {}
);

interface Props {
  className?: string;
  setToastMessage?: React.Dispatch<React.SetStateAction<IToastMessage | undefined>>;
}

const inventoryOriginalCostRuleMapping = convertSelectObjectToMapping(getInventoryOriginalCostRuleOptions())

const getMailingAddress = (
  item: Assessor,
  stateMap: { [key: number]: string }
) => {
  let mailingAddress = '';
  const address = _.get(item, 'mailingAddresses[0].address') as IBackendAddress;
  if (address) {
    mailingAddress = _.compact([address.line1, address.line2, address.city, address.stateId ? stateMap[address.stateId] : null]).join(', ');
  }
  return mailingAddress;
}

function AssessorManagementTable({
  className,
  setToastMessage
}: Props) {
  const stateInCorporations = useAppSelector(state => (state.states ?? []).map((o: State) => ({
    value: o.id,
    label: o.abbreviation,
    name: o.abbreviation,
  })));
  const [fetchingAllTaxYears, setFetchingAllTaxYears] = useState(false);
  const [activeTaxYear, setActiveTaxYear] = useState<number | null>(null);
  const [assessorItems, setAssessorItems] = useState<Assessor[]>([])
  const stateMap: { [key: number]: string } = useAppSelector(state => {
    return _.reduce(
      state.states ?? [],
      (r, n) => {
        return {
          ...r,
          [n.id]: n.abbreviation
        };
      },
      {}
    );
  });

  useEffect(() => {
    setFetchingAllTaxYears(true);
    getSystemTaxYears()
      .then((res) => {
        setFetchingAllTaxYears(false);
        const allActiveTaxYears = _.orderBy(
          res.data,
          ['taxYear'],
          ['desc'],
        );

        let currentTaxYearIndex = _.findIndex(allActiveTaxYears, {
          isCurrent: true,
        });
        if (currentTaxYearIndex < 0) {
          currentTaxYearIndex = 0;
        }
        setActiveTaxYear(allActiveTaxYears[currentTaxYearIndex].taxYear);
      })
      .catch(() => {
        setFetchingAllTaxYears(false);
      });
  }, []);
  const items = useMemo(() => {
    if (activeTaxYear === null) {
      return [];
    }
    return assessorItems.map((item) => {
      const mailingAddress = getMailingAddress(item, stateMap);
      let currentTaxYearIndex = _.findIndex(item.taxYears, {
        taxYear: activeTaxYear,
      });
      if (currentTaxYearIndex < 0) {
        currentTaxYearIndex = 0;
      }
      const extensionsOffered = _.get(item, `taxYears[${currentTaxYearIndex}].extensionsOffered`);
      let formDefault: IBackendFormDefault | null = null;
      for (let i = 0; i < item.taxYears?.length; i++) {
        formDefault = _.find(item.taxYears[i].formDefaults, { isDefaultForm: true }) ?? null;
        if (formDefault) {
          break;
        }
      }

      let inventoryOriginalCostRule = _.get(item, `taxYears[${currentTaxYearIndex}].inventoryOriginalCostRule`);
      if (inventoryOriginalCostRule === null) {
        inventoryOriginalCostRule = 'Monthly Average';
      }
      
      return {
        id: item.id,
        name: item.name,
        title: item.mailingTitle ?? '',
        state: stateMap[item.stateId] ?? '',
        mailingAddress,
        websiteLink: _.get(item, `taxYears[${currentTaxYearIndex}].website`) ?? '',
        assessmentDateMonth: _.get(item, `taxYears[${currentTaxYearIndex}].assessmentDateMonth`),
        assessmentDateDay: _.get(item, `taxYears[${currentTaxYearIndex}].assessmentDateDay`),
        inventoryOriginalCostRule,
        returnDeadline: _.get(item, `taxYears[${currentTaxYearIndex}].returnsDeadline`),
        extensionOffered: extensionsOffered ? 'true' : 'false',
        extensionFillingDeadline: _.get(item, `taxYears[${currentTaxYearIndex}].extensionsDeadline`),
        returnDeadlineIfExtended: _.get(item, `taxYears[${currentTaxYearIndex}].returnsDeadlineIfExtended`),
        defaultReturnForm: (formDefault as IBackendFormDefault)?.form?.name,
        defaultDepreciation: (formDefault as IBackendFormDefault)?.defaultDepreciationScheduleCollection?.name,
        defaultIndex: (formDefault as IBackendFormDefault)?.defaultIndexTableCollection?.name,
      } as ITableData;
    });
  }, [assessorItems, stateMap, activeTaxYear])
  //Pagination
  const [total, setTotal] = useState<number>(0);
  const [confirmationDeleteAssetOpen, setConfirmationDeleteAssetOpen] = useState<ITableData | null>(null)
  const [fetchingData, setFetchingData] = useState(false);
  const [deletingAssessor, setDeletingAssessor] = useState(false);
  const previousQuery = useRef<Query | undefined>()
  const navigate = useNavigate();
  const showSuccess = () => {
    window.scrollTo(0, 0);
    setToastMessage?.({
      message: 'Delete assessor successfully.',
      visible: true,
      type: 'success',
    });
  }
  const showErrorMessage = (message: string) => {
    window.scrollTo(0, 0);
    setToastMessage?.({
      message,
      visible: true,
      type: 'error',
    });
  }

  const getData = useCallback((query?: Query) => {
    previousQuery.current = query;
    setFetchingData(true);
    getAssessors({
      stateId: query?.filter?.state ?? null,
      name: query?.filter?.searchName ?? null,
      full: true,
      text: query?.search,
      sortBy: _.last(_.keys(query?.order)),
      sortDirection: _.last(_.values(query?.order)),
      page: query?.pagination?.page ?? 1,
      perPage: query?.pagination?.pageSize ?? 10,
    })
      .then((res) => {
        setAssessorItems(res.data.items);
        setTotal(res.data.total);
        setFetchingData(false);
      })
      .catch(() => {
        setFetchingData(false);
      });

  }, []);

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'FormManagementSearch d-flex flex-column'
      )
    }>
      <Table
        id='AssessorManagementTable'
        rows={items}
        onQueryChanged={getData}
        paginate
        sortable
        totalRows={total}
        loading={fetchingData || fetchingAllTaxYears}
        searchable='Search assessor...'
        dynamicColumns
        showAddButton='Add Assessor'
        onAddButtonClick={() => navigate('/admin/assessor-management/new')}
      >
        <Column
          label='Assessor Name'
          prop='searchName'
          filterable={{
            type: InputType.Text,
            label: 'Assessor Name'
          }}
          hidden='always'
        />
        <Column
          label='Assessor Name and ID Number'
          id='name'
          filterable={false}
        >
          {
            (row: ITableData) => (
              <span><strong>{row.name}</strong> | #{row.id}</span>
            )
          }
        </Column>
        <Column
          label='Assessor Title'
          sortKey='mailingTitle'
          prop='title'
        >{
            (value: string) => {
              return (
                <span className='ellipsis'>{value}</span>
              );
            }
          }</Column>
        <Column
          label='Assessor State'
          prop='state'
          sortKey='state.abbreviation'
          filterable={{
            type: InputType.Select,
            getValue: (x) => (x as SelectOption).value,
            getLabel: (x) => (x as SelectOption).label,
            options: stateInCorporations,
            multi: false,
            searchable: true,
          }}
        />
        <Column
          label='Assessor Mailing Address'
          prop='mailingAddress'
          sortable={false}
        >
          {
            (value: string) => {
              return (
                <span className='ellipsis'>{value}</span>
              );
            }
          }</Column>
        <Column
          label='Website Link'
          prop='websiteLink'
          sortable={false}
        >
          {
            (value: string) => {
              return (
                <a className={style.textLink} href={value} target='_blank' rel="noreferrer">{value}</a>
              );
            }
          }
        </Column>
        <Column
          label='Assessment Date - Month'
          prop='assessmentDateMonth'
          sortable={false}
          hidden
          accessor={(rowInfo) => {
            return rowInfo.assessmentDateMonth ? monthMapping[rowInfo.assessmentDateMonth as number] : '';
          }}
        />
        <Column
          label='Assessment Date - Day'
          prop='assessmentDateDay'
          sortable={false}
          hidden
        />
        <Column
          label='Inventory Original Cost Rule'
          prop='inventoryOriginalCostRule'
          sortable={false}
          hidden
        >
        {
          (value: string) => (
            <span>{inventoryOriginalCostRuleMapping[value]}</span>
          )
        }</Column>
        <Column
          label='Return Deadline'
          prop='returnDeadline'
          sortable={false}
          hidden
          accessor={(rowInfo) => {
            return formatDateFromBackend(rowInfo.returnDeadline, 'MM/DD/YYYY');
          }}
        />
        <Column
          label='Extension Offered'
          prop='extensionOffered'
          sortable={false}
          hidden
        />
        <Column
          label='Extension Filling Deadline'
          prop='extensionFillingDeadline'
          sortable={false}
          hidden
          accessor={(rowInfo) => {
            return formatDateFromBackend(rowInfo.extensionFillingDeadline, 'MM/DD/YYYY');
          }}
        />
        <Column
          label='Return Deadline if Extended'
          prop='returnDeadlineIfExtended'
          sortable={false}
          hidden
          accessor={(rowInfo) => {
            return formatDateFromBackend(rowInfo.returnDeadlineIfExtended, 'MM/DD/YYYY');
          }}
        />
        <Column
          label='Default Return Form'
          prop='defaultReturnForm'
          sortable={false}
          hidden
        />
        <Column
          label='Default Depreciation'
          prop='defaultDepreciation'
          sortable={false}
          hidden
        />
        <Column
          label='Default Index'
          prop='defaultIndex'
          sortable={false}
          hidden
        />

        {/* Actions column  */}
        <Column
          id='actions'
          label='Actions'
          sortable={false}
        >
          {(data: ITableData) => (
            <div className={classNames(style['operate'], 'd-flex')}>
              <Link
                to={`/admin/assessor-management/${data.id}`}
                className='view'
              />
              <Link
                to={`/admin/assessor-management/${data.id}/edit`}
                className='edit'
              />
              <button
                role='button'
                className='delete'
                onClick={() => {
                  setConfirmationDeleteAssetOpen(data);
                }}
              ></button>
            </div>
          )}
        </Column>


      </Table>

      <ConfirmModal
        isOpen={!!confirmationDeleteAssetOpen}
        onRequestClose={() => setConfirmationDeleteAssetOpen(null)}
        title="Confirmation"
        content="Are you sure you want to delete this assessor?"
        confirming={deletingAssessor}
        onConfirm={() => {
          setDeletingAssessor(true);
          deleteAssessor(confirmationDeleteAssetOpen?.id ?? 0)
            .then(() => {
              setConfirmationDeleteAssetOpen(null);
              showSuccess();
              getData(previousQuery.current);
              setDeletingAssessor(false);
            })
            .catch((e) => {
              setConfirmationDeleteAssetOpen(null);
              showErrorMessage(getErrorMessage(e));
              setDeletingAssessor(false);
            });
        }}
      />
    </div>
  );
}

export default AssessorManagementTable;