import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import actions from '../../../redux/actions';
import _ from 'lodash';
import {IToastMessage, ReduxState, UploadFile} from '../../../interface';
import {ASSETFILETYPE, ASSETFILETYPEINFORMATION} from '../../../constants';
import ModalContext from '../../../context/ModalContext';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import CustomRadio from '../../../components/CustomRadio/CustomRadio';
import Modal from '../../../components/Modal/Modal';
import AddAsset from '../../../components/AddAsset/AddAsset';
import style from './AssetsUpload.module.scss';
import ToastMessage, {
  ToastMessageRefType,
} from '../../../components/ToastMessage/ToastMessage';
import UploadedFilesTable from '../../../components/UploadedFilesTable/UploadedFilesTable';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../enums';

interface Props {
  setUploadAssetsFile: (value: File | undefined) => void;
}

function AssetsUpload(props: Props) {
  const {setUploadAssetsFile} = props;
  // Breadcrumb, dashboard depend on user skip or not, property link depend where is the page from
  const breadcrumb = [
    {
      value: 'Assets',
      href: '/assets',
    },
    {value: 'Add Asset'},
  ];
  const {state, dispatch} = useContext(ModalContext);
  const [type, setType] = useState<string>('');

  useEffect(() => {
    setUploadAssetsFile(undefined);
    if (sessionStorage.getItem('assetType')) {
      setType(sessionStorage.getItem('assetType') as string);
    } else {
      dispatch({type: 'OPEN', href: 'selectAssetType'});
    }
  }, []);

  const [uploadedFile, setUploadedFile] = useState<UploadFile[]>([]);
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [refresh, setRefresh] = useState<boolean>(true);
  const [shouldShowCancelFileWarning, setShouldShowCancelFileWarning] =
    useState(true);
  const toastRef = useRef<ToastMessageRefType | null>(null);

  useEffect(() => {
    if (
      _.map(uploadedFile, 'status').includes('In Progress') &&
      shouldShowCancelFileWarning
    ) {
      setShouldShowCancelFileWarning(false);
      setToastMessage({
        visible: true,
        message: `The In Progress file will be cancelled at 72 hours`,
        type: 'warning',
      });
    }
  }, [uploadedFile]);

  const showError = (value: string) => {
    setToastMessage({
      visible: true,
      message: value,
      type: 'error',
    });
  };

  const refreshUploaded = () => {
    setRefresh(true);
  };

  const uploadedSuccess = () => {
    setRefresh(true);
    setToastMessage({
      visible: true,
      message: `Upload successfully`,
      type: 'success',
    });
  };

  return (
    <>
      <div className={style['main-content']}>
        {/* Braedcrumb */}
        <Breadcrumb items={breadcrumb} />
        <ToastMessage
          ref={toastRef}
          status={toastMessage}
          className={style['toast']}
        />
        <div className={style['main']}>
          {/* Steps */}
          {/* Add property section */}
          <div className={style['section']}>
            <div className={style['title']}>
              <h2>
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.PageTitle}
                  tooltipKey={
                    sessionStorage.getItem('assetType')
                      ? _.capitalize(
                          sessionStorage.getItem('assetType') as string,
                        )
                      : `Add Asset`
                  }
                >
                  <span>
                    {sessionStorage.getItem('assetType')
                      ? _.capitalize(
                          sessionStorage.getItem('assetType') as string,
                        )
                      : `Add Asset`}
                  </span>
                </TooltipWrapper>
              </h2>
              {sessionStorage.getItem('assetType') && (
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.PageAction}
                  tooltipKey='Change asset type'
                >
                  <button
                    className='primary'
                    onClick={() => {
                      dispatch({type: 'OPEN', href: 'selectAssetType'});
                      setType(sessionStorage.getItem('assetType') as string);
                    }}
                  >
                    Change asset type
                  </button>
                </TooltipWrapper>
              )}
            </div>
            <AddAsset
              toastRef={toastRef}
              uploadError={showError}
              uploadSuccess={uploadedSuccess}
            />
          </div>
          {/* Added Properties */}
          <div className={style['section']}>
            <h2>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageTitle}
                tooltipKey='Files Uploaded'
              >
                <span>Files Uploaded</span>
              </TooltipWrapper>
            </h2>
            <div className={style['refresh']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Refresh uploaded files'
            >
              <Link
                to='#'
                onClick={refreshUploaded}
                className='button secondary'
              >
                Refresh uploaded files
              </Link>
            </TooltipWrapper>
            </div>
            <UploadedFilesTable
              toastRef={toastRef}
              type='Asset'
              dataOnload={(value) => setUploadedFile(value)}
              isLoaded={!!sessionStorage.getItem('assetType')}
              refresh={refresh}
              refreshSuccess={() => setRefresh(false)}
              showError={showError}
            />
            <div className={style['buttons']}>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Return to dashboard'
              >
                <Link to='/dashboard' className='button primary'>
                  Return to dashboard
                </Link>
              </TooltipWrapper>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className='selectAssetType'
        title='Add Assets'
        body={
          <>
            <h4>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.Modal}
                tooltipKey='Select type of asset'
              >
                <span>Select type of asset</span>
              </TooltipWrapper>
            </h4>
            <ul className={style['list']}>
              {ASSETFILETYPE &&
                ASSETFILETYPE.map((item, index) => (
                  <li key={index}>
                    <CustomRadio
                      label={item}
                      name='type'
                      value={item}
                      checked={_.isEqual(type, item)}
                      onChange={() => {
                        setType(item);
                      }}
                    />
                  </li>
                ))}
            </ul>
            {_.get(ASSETFILETYPEINFORMATION, type) && (
              <div className={style['description']}>
                <h5>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Modal}
                    tooltipKey='Select Type'
                  >
                    <span>{type}</span>
                  </TooltipWrapper>
                </h5>
                <p className={style['info']}>
                  {_.get(ASSETFILETYPEINFORMATION, type)}
                </p>
              </div>
            )}
          </>
        }
        isOpen={state.isOpen && state.href === 'selectAssetType'}
        footer={
          <div className='buttons'>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Save and Continue'
            >
              <button
                className='primary'
                onClick={() => {
                  sessionStorage.setItem('assetType', type);
                  dispatch({type: 'CLOSE'});
                }}
              >
                Save and Continue
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Cancel'
            >
              <button
                className='secondary'
                onClick={() => {
                  dispatch({type: 'CLOSE'});
                }}
              >
                Cancel
              </button>
            </TooltipWrapper>
          </div>
        }
      />
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  states: state.states,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setUploadAssetsFile: (value: File | undefined) =>
    dispatch(actions.setUploadAssetsFile(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetsUpload);
