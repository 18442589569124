import {createContext, Dispatch} from 'react';
import { INITIALMODAL } from '../constants';
import { ModalAction, ModalState } from '../interface';

const ModalContext = createContext<{state: ModalState; dispatch: Dispatch<ModalAction>}>({
  state: INITIALMODAL,
  dispatch: () => {
      // do nothing.
  },
});

export default ModalContext;
