import React, {useRef, useEffect} from 'react';
import {TOOLTIP_SECTIONS} from '../../enums';
import BaseModal from '../BaseModal/BaseModal';
import {LoaderIndicator} from '../Loader/Loader';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import style from './ConfirmModal.module.scss';

export interface Props {
  title: string;
  content: string;
  onConfirm: () => void;
  onCancel?: () => void;
  isOpen: boolean;
  onRequestClose: () => void;
  confirming?: boolean;
}

function ConfirmModal({
  isOpen,
  title,
  content,
  onConfirm,
  onCancel,
  onRequestClose,
  confirming,
}: Props) {
  const ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    ref.current && ref.current.blur();
  }, [isOpen]);
  return (
    <BaseModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className={style['modal']}>
        <div className={style['modal-header']}>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.Modal}
            tooltipKey={title}
          >
            <span>{title}</span>
          </TooltipWrapper>
        </div>
        <div className={style['modal-content']}>{content}</div>
        <div className={style['modal-footer']}>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.Modal}
            tooltipKey='Confirm'
          >
            <button
              type='button'
              className='primary'
              onClick={onConfirm}
              disabled={confirming}
              ref={ref}
            >
              Confirm
              {confirming ? (
                <LoaderIndicator className='button-loading' loading={true} />
              ) : null}
            </button>
          </TooltipWrapper>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.Modal}
            tooltipKey='Cancel'
          >
            <button
              type='button'
              className='secondary'
              onClick={onCancel || onRequestClose}
            >
              Cancel
            </button>
          </TooltipWrapper>
        </div>
      </div>
    </BaseModal>
  );
}

export default ConfirmModal;
