import { IFormManagementDefaultCollection } from "./IFormManagementDefaultCollection";

export interface IAssessorTaxYearFormDataItem extends IFormManagementDefaultCollection {
  taxYear?: number | null;
  assessmentDateMonth?: number | null;
  assessmentDateDate?: number | null;
  inventoryOriginalCostRule?: string | null;
  assessmentRatio?: string | null;
  extensionOffered?: string;
  extensionFillingDeadline?: string | null;
  returnDeadline?: string | null;
  returnDeadlineIfExtended?: string | null;
  acquisitionYearType?: string | null;
}
export interface IAssessorTaxYearFormData {
  taxYears?: IAssessorTaxYearFormDataItem[]
}

export const createEmptyIAssessorTaxYearFormDataItem = (): IAssessorTaxYearFormDataItem => {
  return {
    taxYear: null,
    assessmentDateMonth: null,
    assessmentDateDate: null,
    inventoryOriginalCostRule: 'Monthly Average',
    assessmentRatio: null,
    extensionOffered: '',
    extensionFillingDeadline: null,
    returnDeadline: null,
    returnDeadlineIfExtended: null,
    defaultDepreciationCollection: null,
    defaultIndexCollection: null,
    defaultForm: null,
  } as IAssessorTaxYearFormDataItem;
}

export const createEmptyIAssessorTaxYearFormData = (taxYears: number[]): IAssessorTaxYearFormData => {
  return {
    taxYears: taxYears.map(taxYear => (
      {
        taxYear,
        inventoryOriginalCostRule: 'Monthly Average'
      }
    ))
  } as IAssessorTaxYearFormData;
}