import { getHealthcheck } from '../../services';
import React, { useEffect, useState } from "react";

interface SystemStatuses {
  Healthy: boolean;
  TimeStamp: string;
}

function Healthcheck() {
  const [systemStatuses, setSystemStatuses] = useState<SystemStatuses>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  useEffect(() => {
    getHealthcheck()
      .then((result) => {
        const statuses = result.data as SystemStatuses;
        setSystemStatuses(statuses);
        setIsLoading(false);
      })
      .catch(() => {
        const statuses: SystemStatuses = {
          Healthy: false,
          TimeStamp: (new Date()).toDateString()
        };
        setSystemStatuses(statuses);
        setIsLoading(false);
      });
  }, []);
  

  return (
    <div>
      {
        isLoading && 
        <div className="loading-content">
          <h2>Loading</h2>
        </div>
      }
      {
        !isLoading &&
        <div className="healthcheck-content pad-left-lg">
          <h4>System Status:&nbsp;
            <span id="system-status">{ systemStatuses?.Healthy ? 'Healthy' : 'Unhealthy' }</span>
          </h4>
          <ul>
            <li>Frontend: Up</li>
            <li>API: {systemStatuses?.Healthy ? 'Up' : 'Down'}</li>
          </ul>
        </div>
      }
      
    </div>
    
  );
}

export default Healthcheck;
