import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import style from './customRadio.module.scss';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import { TOOLTIP_SECTIONS } from '../../enums';

interface Props {
  label: string;
  value: string;
  name: string;
  onChange: (value: string) => void;
  classnames?: string;
  defaultChecked?: boolean;
  tooltipText?: string;
  checked?: boolean;
}

/* Same as custom checkbox, but need the parameters of name & defaultChecked to group the radio */
function CustomRadio(props: Props) {
  const {label, value, name, onChange, classnames, defaultChecked, tooltipText, checked} = props;
  const radioId = _.uniqueId(`radio`);
  return (
    <div className={classNames(style['s-radio'], classnames)}>
      <fieldset role='group'>
        <input
          type='radio'
          id={radioId}
          value={value}
          name={name}
          defaultChecked={defaultChecked}
          onChange={() => {
            onChange(value);
          }}
          checked={checked}
        />
        <label
          className={style['label']}
          htmlFor={radioId}
        >
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.Common}
            tooltipKey={tooltipText ? tooltipText : label}
          >
            <span>{label}</span>
          </TooltipWrapper>
        </label>
      </fieldset>
    </div>
  );
}

export default CustomRadio;
