import { map } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import FieldSelect from "../../../components/FieldSelect/FieldSelect";
import Loader from '../../../components/Loader/Loader';
import PdfViewer, { PdfFile } from "../../../components/PdfViewer/PdfViewer";
import TooltipWrapper from "../../../components/TooltipWrapper/TooltipWrapper";
import { TOOLTIP_SECTIONS } from "../../../enums";
import { getErrorMessage, getPreviewForm, getReturnCustomerForm } from "../../../services";
import getFileName from "../../../utils/getFileName";
import style from "./PreviewReturnForm.module.scss";
import ToastMessage, { ToastMessageRefType } from "../../../components/ToastMessage/ToastMessage";

enum Mode {
  EditFields = "Edit Form Fields",
  AccountFields = "Account form fields",
  GlobalFields = "Global form fields",
}

function PreviewReturnForm() {
  const navigate = useNavigate();
  const params = useParams();

  const [pdfFile, setPdfFile] = useState<PdfFile>();
  const [fetchingPreviewForm, setFetchingPreviewForm] = useState(false);
  const [fetchingReturnCustomerForm, setFetchingReturnCustomerForm] = useState(false);
  const toastRef = useRef<ToastMessageRefType | null>(null);

  useEffect(() => {
    setFetchingPreviewForm(true);
    getPreviewForm(Number(params.returnId)).then((res) => {
      setPdfFile({
        source: res.data,
        name: getFileName(res.headers["content-disposition"]),
      });
      setFetchingPreviewForm(false);
    }).catch((e) => {
      toastRef.current?.showErrorToast(getErrorMessage(e));
      setFetchingPreviewForm(false);
    });
  }, [params.returnId]);

  const breadcrumb = [
    {
      value: "Returns",
      href: "/returns?tab=Preview+Forms",
    },
    { value: "Preview Return" },
  ];

  const [selectedMode, setSelectedMode] = useState<Mode>(Mode.EditFields);

  const modeOptions = useMemo(
    () =>
      map(Mode, (label) => ({ label, name: label })).map((x, id) => ({
        ...x,
        value: id,
      })),
    []
  );

  const clickBack = () => {
    navigate("/returns?tab=Preview+Forms");
  };

  return (
    <div className={style["page"]}>
      <div className={style["main-content"]}>
        <div className={style["main-content-top"]}>
          {/* Braedcrumb */}
          <Breadcrumb items={breadcrumb} />
          <ToastMessage ref={toastRef} className={style['toast']} />
          <div className={style["page-header"]}>
            <h2>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageTitle}
                tooltipKey="Preview Return Form"
              >
                <span>Preview Return Form</span>
              </TooltipWrapper>
            </h2>
            <div className={style["page-header-actions"]}>
              <FieldSelect
                classnames={style["mode-select"]}
                labelText=""
                options={modeOptions}
                selectText={selectedMode}
                onSelect={(selectedOption) => {
                  switch (selectedOption?.label) {
                    case Mode.GlobalFields:
                    case Mode.AccountFields: {
                      setFetchingReturnCustomerForm(true);
                      getReturnCustomerForm(Number(params.returnId)).then(
                        (res) => {
                          setFetchingReturnCustomerForm(false);
                          navigate(
                            `/forms/formDetails/${res.data.id}` +
                              (selectedOption.label === Mode.AccountFields
                                ? `/return/${params.returnId}`
                                : ""),
                            {
                              state: { backTrack: true },
                            }
                          );
                        }
                      ).catch(() => {
                        setFetchingReturnCustomerForm(false);
                      });
                    }
                  }

                  setSelectedMode(selectedOption?.label as Mode);
                }}
                force
              />
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey="Back"
              >
                <button
                  type="button"
                  className="primary"
                  onClick={clickBack}
                >
                  Back
                </button>
              </TooltipWrapper>
            </div>
          </div>
        </div>
        <div className={style["page-content"]}>
          {pdfFile && (
            <PdfViewer className={style["pdf-viewer"]} files={pdfFile} />
          )}
        </div>
      </div>

      <Loader isOpen={fetchingPreviewForm || fetchingReturnCustomerForm} />
    </div>
  );
}

export default PreviewReturnForm;
