import _ from 'lodash';
import moment from 'moment';
import {AssetField} from '../interface';

const formatAssets = (values: AssetField[]) => {
  const result = _.map(values, (value) =>
    _.mapValues(value, (v, k) => {
      if (_.isEqual(k, 'taxYear')) {
        return _.isNaN(_.toNumber(v)) ? null : Number(v);
      }
      if (_.isEqual(k, 'acquisitionDate')) {
        return moment(v as string).isValid() ? moment(v as string).format('MM/DD/YY') : null;
      }
      if (_.isObject(v)) {
        return _.mapValues(v, (iv, ik) => {
          if (_.isEqual(ik, 'rebookCost')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'ltdDepreciation')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'obsolescence')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'originalCost')) {
            // Strip any '$' and ',' from string
            let formattedIv = _.replace(iv, new RegExp('\\$','g'), '');
            formattedIv = _.replace(formattedIv, new RegExp(',','g'), '');
            return _.isNaN(_.toNumber(formattedIv)) ? null : Number(formattedIv);
          }
          if (_.isEqual(ik, 'renderedMarketValue')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'januaryAmount')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'februaryAmount')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'marchAmount')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'aprilAmount')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'mayAmount')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'juneAmount')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'julyAmount')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'augustAmount')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'septemberAmount')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'octoberAmount')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'novemberAmount')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'decemberAmount')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'vehicleYear')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (_.isEqual(ik, 'monthlyRent')) {
            return _.isNaN(_.toNumber(iv)) ? null : Number(iv);
          }
          if (
            _.isEqual(ik, 'rebookDate') ||
            _.isEqual(ik, 'leasedEndDate') ||
            _.isEqual(ik, 'leasedStartDate')
          ) {
            return moment(iv).isValid() ? moment(iv).format('MM/DD/YY') : null;
          }
          return iv;
        });
      }
      return v;
    }),
  );
  return result.map((item, index) => ({
    ...item,
    id: index + 1,
  })) as AssetField[];
};

export default formatAssets;
