import React, { PropsWithChildren, ReactElement } from "react";
import { Link } from "react-router-dom";
import { TOOLTIP_SECTIONS } from "../../enums";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";
import style from "./RightNavigationPanel.module.scss";
import classNames from "classnames";

interface Props {
  icon: ReactElement;
  title: string;
  className?: string;
}

function RightNavigationPanel({ icon, title, children, className }: PropsWithChildren<Props>) {
  return (
    <div className={classNames(style["content"], className)}>
      <div className={style["header-container"]}>
        <div className={style["title"]}>
          {icon}
          <h3>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Common}
              tooltipKey={title}
            >
              <span>{title}</span>
            </TooltipWrapper>
          </h3>
        </div>
        <Link role="button" to="#"></Link>
      </div>
      {children}
    </div>
  );
}

export default RightNavigationPanel;
