import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { HelpCategory, HelpCategoryTopic, MaintenanceNotification, PageTooltip, PurchaseReturn, TooltipItem } from '../../interface';
import { editMaintenanceNotifications, editPurchasedReturns, getHelpContents, getMaintenanceNotifications, getPages, getPurchasedReturns, getTooltips, updateTooltips } from '../../services';

export type AsyncStatus = 'idle' | 'pending' | 'fulfilled' | 'rejected';

export interface AdminState {
    fetchStatus: AsyncStatus;
    fetchHelpContentsStatus: AsyncStatus;
    addStatus: AsyncStatus;
    fetchPagesStatus: AsyncStatus;
    updatePageStatus: AsyncStatus;
    purchasedReturn: {
        items: PurchaseReturn[];
        page: number;
        perPage: number;
        total: number;
        totalPages: number;
    };
    updateTooltipsStatus: AsyncStatus;
    pages: string[];
    tooltips: PageTooltip[];
    helpContents: HelpCategory[];
    maintenanceNotifications: {
        items: MaintenanceNotification[];
        page: number;
        perPage: number;
        total: number;
        totalPages: number;
    };
    fetchContentStatus: AsyncStatus;
    updateContentStatus: AsyncStatus;
    deleteContentStatus: AsyncStatus;
    fetchTooltipsStatus: AsyncStatus;
}

// Define the initial state using that type
const getInitialState = (): AdminState => ({
    addStatus: 'idle',
    deleteContentStatus: 'idle',
    fetchContentStatus: 'idle',
    fetchPagesStatus: 'idle',
    fetchStatus: 'idle',
    fetchHelpContentsStatus: 'idle',
    fetchTooltipsStatus: 'idle',
    helpContents: [],
    pages: [],
    purchasedReturn: {
        items: [],
        page: 0,
        perPage: 0,
        total: 0,
        totalPages: 0,
    },
    tooltips: [],
    maintenanceNotifications: {
        items: [],
        page: 0,
        perPage: 0,
        total: 0,
        totalPages: 0,
    },
    updateContentStatus: 'idle',
    updatePageStatus: 'idle',
    updateTooltipsStatus: 'idle'
});

export const getPagesAction = createAsyncThunk(
    'getPagesAction',
    async () => {
        return (await getPages()).data;
    },
    {
        condition: (_args, { getState }) => {
            const { fetchPagesStatus: loadingPurchasedReturns } = getState() as AdminState;
            return loadingPurchasedReturns !== 'pending';
        },
    }
);

export const getTooltipsAction = createAsyncThunk(
    'getTooltipsAction',
    async (props: { page: string }) => {
      return (await getTooltips(props.page)).data;
    },
    {
        condition: (_args, { getState }) => {
            const { updateTooltipsStatus: loadingPurchasedReturns } = getState() as AdminState;
            return loadingPurchasedReturns !== 'pending';
        },
    }
);

export const updateTooltipsAction = createAsyncThunk(
    'updateTooltipsAction',
    async (props: { data: TooltipItem[] }) => {
        return (await updateTooltips(props.data)).data;
    },
    {
        condition: (_args, { getState }) => {
            const { updateTooltipsStatus: loadingPurchasedReturns } = getState() as AdminState;
            return loadingPurchasedReturns !== 'pending';
        },
    }
);

export const getPurchasedReturnList = createAsyncThunk(
    'getPurchasedReturnList',
    async (props: {perPage: number, page: number, sortBy: string, sortDirection: string, text: string}) => {
        return (await getPurchasedReturns(props)).data;
    },
    {
        condition: (_args, { getState }) => {
            const { fetchStatus: loadingPurchasedReturns } = getState() as AdminState;
            return loadingPurchasedReturns !== 'pending';
        },
    }
);

export const editPurchasedReturn = createAsyncThunk(
    'editPurchasedReturn',
    async (props: { data: PurchaseReturn }) => {
        return (await editPurchasedReturns(props?.data)).data;
    },
    {
        condition: (_args, { getState }) => {
            const { addStatus: loadingPurchasedReturns } = getState() as AdminState;
            return loadingPurchasedReturns !== 'pending';
        },
    }
);

export const getHelpContentsAction = createAsyncThunk(
    'getHelpContentsAction',
    async () => {
        return (await getHelpContents()).data.items;
    },
    {
        condition: (_args, { getState }) => {
            const { fetchStatus: loadingPurchasedReturns } = getState() as AdminState;
            return loadingPurchasedReturns !== 'pending';
        },
    }
);

export const getMaintenanceNotificationList = createAsyncThunk(
    'getMaintenanceNotificationList',
    async (props: {perPage: number, page: number, sortBy: string, sortDirection: string, text: string, onlyActive: boolean}) => {
        return (await getMaintenanceNotifications(props)).data;
    },
    {
        condition: (_args, { getState }) => {
            const { fetchStatus: loadingMaintenanceNotifications } = getState() as AdminState;
            return loadingMaintenanceNotifications !== 'pending';
        },
    }
);

export const editMaintenanceNotification = createAsyncThunk(
    'editMaintenanceNotification',
    async (props: { data: MaintenanceNotification }) => {
        return (await editMaintenanceNotifications(props?.data)).data;
    },
    {
        condition: (_args, { getState }) => {
            const { addStatus: loadingMaintenanceNotifications } = getState() as AdminState;
            return loadingMaintenanceNotifications !== 'pending';
        },
    }
);

export const adminSlice = createSlice({
    name: 'adminSlice',
    initialState: getInitialState,
    reducers: {
        deletePurchasedReturn: (state: AdminState, action: PayloadAction<PurchaseReturn>) => {
            state.purchasedReturn.items = [...state.purchasedReturn.items ].filter(x => x.id !== action.payload.id);
        },
        addPurchasedReturn: (state: AdminState, action: PayloadAction<PurchaseReturn>) => {
            state.purchasedReturn.items  = [...state.purchasedReturn.items ].filter(x => x.id !== action.payload.id);
        },
        updatePurchasedReturnToStore: (state: AdminState, action: PayloadAction<PurchaseReturn>) => {
            const temp = [...state.purchasedReturn.items ];
            const index = temp.findIndex(x => x.id === action.payload.id);
            if(index >= 0){
                temp[index] = action.payload;
            }
            state.purchasedReturn.items  = temp;
        },
        updateMaintenanceNotificationToStore: (state: AdminState, action: PayloadAction<MaintenanceNotification>) => {
            const temp = [...state.maintenanceNotifications.items ];
            const index = temp.findIndex(x => x.id === action.payload.id);
            if(index >= 0){
                temp[index] = action.payload;
            }
            state.maintenanceNotifications.items = temp;
        },
        resetStatus: (state: AdminState) => {
            state.fetchPagesStatus = 'idle';
            state.updateTooltipsStatus = 'idle';
            state.updateContentStatus = 'idle';
            state.fetchStatus = 'idle';
        },
        deleteHelpContent: (state: AdminState, action: PayloadAction<number>) => {
            state.helpContents = [...state.helpContents].filter(x => x.id !== action.payload);
        },
        updateHelpCategoryToStore: (state: AdminState, action: PayloadAction<HelpCategory>) => {
            const index = state.helpContents.findIndex(x => x.id === action.payload.id);
            if (index >= 0) {
                const temp = [...state.helpContents];
                temp[index] = action.payload;
                state.helpContents = temp;
            }
        },
        addHelpCategoryToStore: (state: AdminState, action: PayloadAction<HelpCategory>) => {
            const temp = [...state.helpContents];
            temp.push(action.payload);
            state.helpContents = temp;
        },
        updateHelpTopicToStore: (state: AdminState, action: PayloadAction<HelpCategoryTopic>) => {
            const tempHelpContent = [...state.helpContents];
            const index = tempHelpContent.findIndex(x => x.id === action.payload.helpCategoryId);
            if (index >= 0) {
                const tempCategory = tempHelpContent[index];
                const topicIndex = tempCategory.topics.findIndex(x => x.id === action.payload.id);
                if (topicIndex >= 0) {
                    tempCategory.topics[topicIndex] = action.payload;
                    tempHelpContent[index] = tempCategory;
                    state.helpContents = tempHelpContent;
                }

            }

        }
    },
    extraReducers: (builder) => {
        builder
            // purchaseReturn
            .addCase(getPurchasedReturnList.pending, (state) => {
                state.fetchStatus = 'pending';
            })
            .addCase(getPurchasedReturnList.fulfilled, (state, action) => {
                state.purchasedReturn = action.payload;
                state.fetchStatus = 'fulfilled';
            })
            .addCase(getPurchasedReturnList.rejected, (state) => {
                state.fetchStatus = 'rejected';
            })
            .addCase(editPurchasedReturn.pending, (state) => {
                state.addStatus = 'pending';
            })
            .addCase(editPurchasedReturn.fulfilled, (state, action) => {
                const items = [...state.purchasedReturn.items ];
                const index = items.findIndex(x => x.id === action.payload.id);
                if (index >= 0) {
                    items[index] = action.payload;
                    state.purchasedReturn.items  = items;
                }
                state.addStatus = 'fulfilled';
            })
            .addCase(editPurchasedReturn.rejected, (state) => {
                state.addStatus = 'rejected';
            })

            // maintenanceNotification
            .addCase(getMaintenanceNotificationList.pending, (state) => {
                state.fetchStatus = 'pending';
            })
            .addCase(getMaintenanceNotificationList.fulfilled, (state, action) => {
                state.maintenanceNotifications  = action.payload;
                state.fetchStatus = 'fulfilled';
            })
            .addCase(getMaintenanceNotificationList.rejected, (state) => {
                state.fetchStatus = 'rejected';
            })
            .addCase(editMaintenanceNotification.pending, (state) => {
                state.addStatus = 'pending';
            })
            .addCase(editMaintenanceNotification.fulfilled, (state, action) => {
                const items = [...state.maintenanceNotifications.items];
                const index = items.findIndex(x => x.id === action.payload.id);
                if (index >= 0) {
                    items[index] = action.payload;
                    state.maintenanceNotifications.items  = items;
                }
                state.addStatus = 'fulfilled';
            })
            .addCase(editMaintenanceNotification.rejected, (state) => {
                state.addStatus = 'rejected';
            })

            // tooltip
            .addCase(updateTooltipsAction.pending, (state) => {
                state.updateTooltipsStatus = 'pending';
            })
            .addCase(updateTooltipsAction.fulfilled, (state, action) => {
                const saved = keyBy(action.payload, x => x.id);
                state.updateTooltipsStatus = 'fulfilled';
                state.tooltips = state.tooltips?.map(x => saved[x.id] ?? x);
            })
            .addCase(updateTooltipsAction.rejected, (state) => {
                state.updateTooltipsStatus = 'rejected';
            })
            .addCase(getPagesAction.pending, (state) => {
                state.fetchPagesStatus = 'pending';
            })
            .addCase(getPagesAction.fulfilled, (state, action) => {
                state.pages = action.payload;
                state.fetchPagesStatus = 'fulfilled';
            })
            .addCase(getPagesAction.rejected, (state) => {
                state.fetchPagesStatus = 'rejected';
            })
            .addCase(getTooltipsAction.pending, (state) => {
                state.fetchTooltipsStatus = 'pending';
            })
            .addCase(getTooltipsAction.fulfilled, (state, action) => {
                state.tooltips = action.payload;
                state.fetchTooltipsStatus = 'fulfilled';
            })
            .addCase(getTooltipsAction.rejected, (state) => {
                state.fetchTooltipsStatus = 'rejected';
            })

            // helpContent
            .addCase(getHelpContentsAction.pending, (state) => {
                state.fetchHelpContentsStatus = 'pending';
            })
            .addCase(getHelpContentsAction.fulfilled, (state, action) => {
                state.helpContents = action.payload;
                state.fetchHelpContentsStatus = 'fulfilled';
            })
            .addCase(getHelpContentsAction.rejected, (state) => {
                state.fetchHelpContentsStatus = 'rejected';
            });
    },
});

export const {
    deletePurchasedReturn,
    resetStatus,
    deleteHelpContent,
    updateHelpCategoryToStore,
    updateHelpTopicToStore,
    addHelpCategoryToStore,
    updatePurchasedReturnToStore,
    updateMaintenanceNotificationToStore,
} = adminSlice.actions;

export default adminSlice.reducer;