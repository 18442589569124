/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useRef} from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import {useFormik} from 'formik';
import {MaintenanceNotification} from '../../interface';
import style from './MaintenanceNotificationForm.module.scss';
import {LoaderIndicator} from '../Loader/Loader';
import DiscardChangeConfirmModal, {
  DiscardChangeConfirmModalMethods,
} from '../../components/DiscardChangeConfirmModal/DiscardChangeConfirmModal';
import FieldDate from '../FieldDate/FieldDate';
import FieldRichText from '../FieldRichText/FieldRichText';
import validateEmpty from '../../utils/validateEmpty';

// Define the required fields
const requiredField = [
  'startDate',
  'endDate',
  'content'
];

interface Props {
  inEditMode?: boolean;
  data: MaintenanceNotification;
  onSubmit: (data: MaintenanceNotification) => void;
  onClear: () => void;
  sectionClassName?: string;
  formClassName?: string;
  submitting: boolean;
  innerModal?: boolean;
  errorMessage?: string;
}

function MaintenanceNotificationForm({
  inEditMode = false,
  data,
  onSubmit,
  sectionClassName,
  formClassName,
  onClear,
  submitting,
  innerModal = false,
  errorMessage,
}: Props) {
  const confirmModalRef = useRef<DiscardChangeConfirmModalMethods>();
  const [navBlock, setNavBlock] = useState<boolean>();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const formik = useFormik<MaintenanceNotification>({
    initialValues: {
      ...data
    },
    enableReinitialize: true,
    validate: (values) => {
      if (isSubmit) {
        const requiredErrors = validateEmpty(_.pick(values, requiredField));
        return _.defaults(requiredErrors);
      }
    },
    onSubmit: _.noop,
  });


  return (
    <>
      <form
        className={style['main']}
        onSubmit={(e) => {
          e.preventDefault();
          formik.setTouched({
            startDate: true,
            endDate: true,
            content: true,
          });
          formik.validateForm(formik.values).then((error) => {
            if (!(Object.keys(error).length > 0)) {
              setNavBlock(false);

              const updated = _.cloneDeep(formik.values);
              onSubmit(updated);
            }
          });
        }}
      >
        <div className={classNames(sectionClassName, style['section'])}>
          <div className={classNames(formClassName, style['form'])}>
            <div className={style['row']}>
              <div className={classNames(style['column'])}>
                <FieldDate
                  classnames={style.fieldDate}
                  key='notification-start-date-time'
                  labelText='Notification Start'
                  selectValue={formik.values.startDate}
                  onSelect={(updatedValue) => {
                    formik.setFieldValue('startDate', updatedValue);
                  }}
                  required
                  error={formik.errors && formik.errors.startDate}
                />
              </div>
            </div>
            <div className={style['row']}>
              <div className={style['column']}>
                <FieldDate
                  classnames={style.fieldDate}
                  key='notification-end-date-time'
                  labelText='Notification End'
                  selectValue={formik.values.endDate}
                  onSelect={(updatedValue) => {
                    formik.setFieldValue('endDate', updatedValue);
                  }}
                  required
                  error={formik.errors && formik.errors.endDate}
                />
              </div>
            </div>
            <div className={style['row']}>
              <div className={style['column']}>
                <FieldRichText
                    required
                    value={formik.values.content || ''}
                    onChange={(val) => {
                      setTimeout(() => {
                        formik.setFieldValue('content', val);
                      }, 0);
                    }}
                    error={formik.errors && formik.errors.content}
                  />
              </div>
            </div>
          </div>
        </div>
        {errorMessage && (
          <div className={style.error}>
            <span>{errorMessage}</span>
          </div>
        )}
        <div className={style['footer']}>
          {/* Submit link,it change the flag of submit */}
          <div className={style['buttons']}>
            <button
              type='submit'
              className='primary-button'
              disabled={submitting}
              onClick={() => {
                setIsSubmit(true);
              }}
            >
              Save
              {submitting ? (
                <LoaderIndicator className='button-loading' loading={true} />
              ) : null}
            </button>
            <button
              type='button'
              onClick={() => {
                if (inEditMode) {
                  if (confirmModalRef.current) {
                    confirmModalRef.current.openConfirmModal();
                  }
                } else {
                  formik.resetForm();
                }
              }}
              className='default-button'
              disabled={submitting}
            >
              {inEditMode ? 'Cancel' : 'Reset'}
            </button>
          </div>
        </div>
      </form>
      <DiscardChangeConfirmModal
        initialData={formik.initialValues}
        inputData={formik.values}
        onConfirm={() => {
          formik.resetForm();
          onClear();
        }}
        className={classNames(
          style['confirmModal'],
          innerModal && style['innerModal'],
        )}
        ref={confirmModalRef}
        navBlock={navBlock}
        innerModal={innerModal}
      />
    </>
  );
}

export default MaintenanceNotificationForm;