import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  assign,
  compact,
  debounce,
  find,
  get,
  isArray,
  isBoolean,
  isString,
  noop,
  range,
  some,
  without,
  map,
  unionBy,
} from "lodash";
import FieldDate from "../../../FieldDate/FieldDate";
import FieldTextarea from "../../../FieldTextarea/FieldTextarea";
import FieldToggleCheckbox from "../../../FieldToggleCheckbox/FieldToggleCheckbox";
import FieldInput from "../../../FieldInput/FieldInput";
import CustomCheckbox from "../../../CustomCheckbox/CustomCheckbox";
import style from "./Editor.module.scss";
import FieldSelect from "../../../FieldSelect/FieldSelect";
import { SelectOption } from "../../../../interface";
import FieldSelectTable from "../../../FieldSelectTable";

const SearchDebounce = 500;

type Option = { value: unknown; label: string };

export enum InputType {
  Text,
  TextArea,
  Select,
  DateRange,
  Toggle,
  Checkbox,
}

const DefaultPlaceholder = {
  [InputType.Text]: "Enter",
  [InputType.TextArea]: "Enter",
  [InputType.Select]: "Select",
  [InputType.DateRange]: "MM/DD/YY",
};

export interface EditConfigBase {
  type: InputType;
  placeholder?: string;
  label?: string;
}

type Generator<TOpt> = (query: string) => Promise<TOpt[]>;

interface OptionSelectBase<TOpt> extends EditConfigBase {
  options: readonly TOpt[] | Generator<TOpt>;
  getLabel?: (option: TOpt) => string;
  getValue?: (option: TOpt) => unknown;
}

export interface SelectEditConfig<TOpt> extends OptionSelectBase<TOpt> {
  type: InputType.Select;
  searchable?: boolean;
  multi?: boolean;
  nullOption?: true | TOpt;
}

export interface CheckboxEditConfig<TOpt>
  extends Omit<OptionSelectBase<TOpt>, "options"> {
  type: InputType.Checkbox;
  options?: readonly TOpt[];
}

export interface EditTextConfig extends EditConfigBase {
  type: InputType.Text;
}

export interface EditTextAreaConfig extends EditConfigBase {
  type: InputType.TextArea;
}

export interface EditToggleConfig extends EditConfigBase {
  type: InputType.Toggle;
}

export interface DateRangeConfig extends Omit<EditConfigBase, "label"> {
  type: InputType.DateRange;
  label: [string, string];
}

export type EditConfig =
  | EditTextConfig
  | SelectEditConfig<unknown>
  | CheckboxEditConfig<unknown>
  | DateRangeConfig
  | EditTextAreaConfig
  | EditToggleConfig;

interface Props {
  selectedOptionLabels?: string | string[];
  value: unknown;
  config?: EditConfig;
  overrides?: Partial<EditConfig>;
  onChange?: (value: unknown, label: string | string[]) => void;
  label?: string | string[];
  error?: string | null;
  isCellEditField?: boolean;
  disabled?: boolean;
}

export default function Editor(props: Props) {
  const {
    selectedOptionLabels,
    value,
    config: baseConfig,
    overrides,
    onChange,
    label,
    error,
    isCellEditField = false,
  } = props;

  const [asyncOptions, setAsyncOptions] = useState([] as unknown[]);
  const [mappedOptions, setMappedOptions] = useState<SelectOption[]>([]);

  const callbackRef = useRef<(arr: Option[]) => void>();

  /**
   * Config with overrides
   */
  const config = useMemo(
    () => assign({}, ...compact([baseConfig, overrides])),
    [baseConfig, overrides]
  ) as EditConfig;

  const optionSource = (config as OptionSelectBase<unknown>)?.options;

  const placeholder = useMemo(
    () => config.placeholder ?? get(DefaultPlaceholder, config.type),
    [config.placeholder]
  );

  /**
   * Current options
   */
  const resolvedOptions = useMemo(
    () => (isArray(optionSource) ? optionSource : asyncOptions),
    [asyncOptions, optionSource]
  );

  /**
   * Raw data mapped to option objects
   */
  useEffect(() => {
    if (![InputType.Select, InputType.Checkbox].includes(config.type)) {
      setMappedOptions([]);
    }

    const { getLabel, getValue } = config as OptionSelectBase<unknown>;
    const { nullOption, multi } = config as SelectEditConfig<unknown>;

    let options: SelectOption[] = (resolvedOptions ?? []).map((option) => ({
      value: getValue ? getValue(option) : option?.value ?? option,
      label: getLabel ? getLabel(option) : option?.label ?? option,
    }));

    if (nullOption) {
      options.unshift({
        value: null,
        label: isString(nullOption) ? nullOption : placeholder,
      });
    }

    if (config.type === InputType.Select && selectedOptionLabels) {
      let asyncSelectedOptions = multi
        ? map(selectedOptionLabels, (l, idx) => ({
            value: (value as unknown[])[idx],
            label: l,
          }))
        : [{ value, label: selectedOptionLabels }];

      asyncSelectedOptions = asyncSelectedOptions.filter((x) => x.label);

      options = unionBy(
        options,
        asyncSelectedOptions,
        "value"
      ) as SelectOption[];
    }

    setMappedOptions(options);
  }, [resolvedOptions, selectedOptionLabels]);

  useEffect(() => {
    callbackRef.current?.(mappedOptions);
    callbackRef.current = undefined;
  }, [mappedOptions]);

  /**
   * Selected option(s)
   */
  const selected = useMemo(() => {
    if (config.type === InputType.Select) {
      return config.multi
        ? ((isArray(value) && value) || [value]).map(
            (entry) => mappedOptions.find((x) => x.value === entry) ?? null
          )
        : mappedOptions.find((x) => x.value === value) ?? null;
    }

    return null;
  }, [mappedOptions, value]);

  const fetchOptions = useCallback(
    debounce((text: string, callback: (x: Option[]) => void) => {
      const fetch = (config as SelectEditConfig<unknown>)
        .options as Generator<unknown>;

      callbackRef.current = callback;
      fetch(text).then(setAsyncOptions);
    }, SearchDebounce),
    []
  );

  const search = useCallback(
    (text: string, callback: (x: Option[]) => void) => {
      text ? fetchOptions(text, callback) : callback([]);
    },
    []
  );

  const onOptionSelect = useCallback(
    (option?: SelectOption) => {
      onChange?.(option?.value ?? null, option?.label ?? "");
    },
    [onChange]
  );

  switch (config?.type) {
    case InputType.Select: {
      if (isCellEditField) {
        return (
          <FieldSelectTable
            labelText={label as string}
            selectId={
              selected ? ((selected as SelectOption).value as number) : null
            }
            options={mappedOptions}
            onSelect={onOptionSelect}
          />
        );
      }

      const { options, searchable, multi } = config;
      const commonProps = {
        classnames: style.selectField,
        labelText: label as string,
        placeholder,
        noSearch: !searchable,
        selectId: selected ? (selected as SelectOption).value : null,
        ...(multi
          ? {
              selectIds: selected
                ? (selected as SelectOption[]).map((x) => (x ? x.value : null))
                : // ignore in coverage report because selected always have valid value
                  /* istanbul ignore next */ [],
            }
          : {}),
        multiple: multi,
        onSelect: onOptionSelect,
        onMultipleSelect: (selectedOptions: SelectOption[]) => {
          onChange?.(
            selectedOptions.map((x) => x.value),
            selectedOptions.map((x) => x.label)
          );
        },
        options: mappedOptions,
      };

      return isArray(options) ? (
        <FieldSelect {...commonProps} />
      ) : (
        <FieldSelect
          {...commonProps}
          noSearch={false}
          async
          onSInput={(key) => {
            search(key, noop);
          }}
        />
      );
    }

    case InputType.DateRange: {
      const labels = isArray(label) ? label : [label];

      return (
        <>
          {range(2).map((idx) => (
            <FieldDate
              classnames={style.fieldDate}
              key={idx}
              labelText={labels[idx] ?? ""}
              selectValue={(value as string[])?.[idx]}
              onSelect={(updated) => {
                let newValue = range(2).map((i) =>
                  i === idx ? updated : (value as string[])?.[i]
                );
                if (compact(newValue).length === 0) newValue = [];

                onChange?.(newValue, compact(newValue).join(" - "));
              }}
            />
          ))}
        </>
      );
    }

    case InputType.TextArea: {
      return (
        <>
          {label && <label>{label}</label>}
          <FieldTextarea
            value={value as string}
            onChange={(updated) => onChange?.(updated, updated)}
            error={error || undefined}
          />
        </>
      );
    }

    case InputType.Toggle: {
      return (
        <>
          {label && <label>{label}</label>}
          <FieldToggleCheckbox
            label=""
            value={value as boolean}
            onChange={(updated) => onChange?.(updated, String(updated))}
          />
        </>
      );
    }

    case InputType.Checkbox: {
      const checkboxes = optionSource
        ? mappedOptions.map((opt) => ({
            ...opt,
            checked: some(value as unknown[], (x) => x === opt.value),
          }))
        : [{ label: "", value: undefined, checked: Boolean(value) }];

      return (
        <>
          {label && <label>{label}</label>}
          {checkboxes.map((opt) => (
            <CustomCheckbox
              classnames={style.checkbox}
              key={opt.label}
              label={opt.label}
              value={opt.checked}
              onChange={(checked) => {
                let updated: boolean | unknown[] = checked;

                if (optionSource) {
                  const currentArr = (value as unknown[]) ?? [];

                  updated = checked
                    ? [...currentArr, opt.value]
                    : without(currentArr, opt.value);
                }

                onChange?.(
                  updated,
                  isBoolean(updated)
                    ? (label as string)
                    : updated
                        .map((entry) => {
                          const option = find(mappedOptions, {
                            value: entry,
                          }) as SelectOption;
                          return option?.label;
                        })
                        .join(", ")
                );
              }}
            />
          ))}
        </>
      );
    }

    default:
      return (
        <>
          {label && <label>{label}</label>}
          <FieldInput
            labelText=""
            value={value as string}
            onChange={(updated) => onChange?.(updated, updated)}
          />
        </>
      );
  }
}
