import {createSlice} from '@reduxjs/toolkit';

interface IReturnsSlice {
    includeReturnsWithNoAssets: boolean;
}

const initialState: Partial<IReturnsSlice> = { includeReturnsWithNoAssets: false };

const returnsSlice = createSlice({
  name: 'returns',
  initialState,
  reducers: {
    setIncludeReturnsWithNoAssets: (state, action) => {
        state.includeReturnsWithNoAssets = action.payload;
    }
  },
});

export const { setIncludeReturnsWithNoAssets } = returnsSlice.actions;
export default returnsSlice;
