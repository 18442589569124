import classNames from 'classnames';
import _, {keys, last, values} from 'lodash';
import moment from 'moment';
import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Dispatch} from 'redux';
import ModalContext from '../../context/ModalContext';
import usePrevious from '../../hook/usePrevious';
import {UploadFile} from '../../interface';
import actions from '../../redux/actions';
import {
  deleteUploadedFile,
  downloadUploadedFile,
  getErrorMessage,
  getUploadedFile,
} from '../../services';
import bytesToSize from '../../utils/bytesToSize';
import exportXLSX from '../../utils/exportFile';
import readUploadedFile from '../../utils/readFile';
import {LoaderIndicator} from '../Loader/Loader';
import Modal from '../Modal/Modal';
import InitTable, {Query} from '../Table/Table';
import {ToastMessageRefType} from '../ToastMessage/ToastMessage';
import style from './UploadedFilesTable.module.scss';

const {Table, Column} = InitTable<UploadFile>();

interface Props {
  dataOnload: (value: UploadFile[]) => void;
  isLoaded?: boolean;
  type: 'Asset' | 'Property';
  toastRef: MutableRefObject<ToastMessageRefType | null>;
  refresh: boolean;
  refreshSuccess: () => void;
  showError: (value: string) => void;
}

interface ButtonProps {
  fileId: number;
}

function DownloadButton(props: ButtonProps) {
  const {fileId} = props;
  const [downloading, setDownloading] = useState(false);
  const download = (fileId: number) => {
    const res = downloadUploadedFile(fileId);
    setDownloading(true);
    exportXLSX(res, () => {
      setDownloading(false);
    });
  };
  return (
    <button
      role='button'
      className='download'
      onClick={() => download(fileId)}
      disabled={downloading}
    >
      {downloading ? (
        <LoaderIndicator
          className='button-loading icon-button'
          loading={true}
        />
      ) : null}
    </button>
  );
}

interface EditButtonProps {
  file: UploadFile;
  type: string;
  setUploadAssetsFile: (value: File | undefined) => void;
  setUploadFile: (value: File | undefined) => void;
  showError?: (value: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setUploadAssetsFile: (value: File | undefined) =>
    dispatch(actions.setUploadAssetsFile(value)),
  setUploadFile: (value: File | undefined) =>
    dispatch(actions.setUploadFile(value)),
});

const EditButton = connect(
  null,
  mapDispatchToProps,
)(function (props: EditButtonProps) {
  const {file, type, setUploadAssetsFile, setUploadFile, showError} = props;
  const [downloading, setDownloading] = useState(false);
  const navigate = useNavigate();
  return (
    <i
      role='button'
      className='edit'
      onClick={() => {
        if (!_.isEqual(file.step, 'Review')) {
          setDownloading(true);
          downloadUploadedFile(_.toNumber(file.id))
            .then((d) => {
              setDownloading(false);
              if (_.isEqual(type, 'Asset')) {
                navigate(`../assetsMapFile/${file.id}`);
                setUploadAssetsFile(readUploadedFile(d));
              }
              if (_.isEqual(type, 'Property')) {
                navigate(`../propertyMapFile/${file.id}`);
                setUploadFile(readUploadedFile(d));
              }
            })
            .catch(() => {
              setDownloading(false);
              showError && showError(`Error occurs, please try again later.`);
            });
        } else {
          if (_.isEqual(type, 'Asset')) {
            navigate(`../assetsReview/${file.id}`);
          }
          if (_.isEqual(type, 'Property')) {
            navigate(`../propertyReview/${file.id}`);
          }
        }
      }}
    >
      {downloading ? (
        <LoaderIndicator
          className='button-loading icon-button'
          loading={true}
        />
      ) : null}
    </i>
  );
});

function UploadedFilesTable(props: Props) {
  const {
    dataOnload,
    isLoaded,
    type,
    toastRef,
    refresh,
    refreshSuccess,
    showError,
  } = props;
  const [files, setfiles] = useState<UploadFile[]>([]);
  const [query, setQuery] = useState<Query>();
  const [total, setTotal] = useState<number>();
  const [fetchingData, setFetchingData] = useState(false);

  const fetchData = () => {
    if (isLoaded !== false && query) {
      setFetchingData(true);
      getUploadedFile({
        type,
        perPage: query.pagination?.pageSize ?? 10,
        sortBy: last(keys(query.order)),
        sortDirection: last(values(query.order)),
        page: query.pagination?.page ?? 1,
      })
        .then((d) => {
          setfiles(d.data.items);
          setTotal(d.data.total);
          dataOnload(d.data.items);
          setFetchingData(false);
          refreshSuccess();
        })
        .catch(() => {
          setFetchingData(false);
          refreshSuccess();
        });
    }
  };

  const refQuery = usePrevious(query ? query : {})
  useEffect(() => {
    if (refresh || !_.isEqual(refQuery, query)) {
      fetchData();
    }
  }, [query, refresh]);

  const deleteFile = (fileId: number) => {
    setid(fileId);
    dispatch({type: 'OPEN', href: 'comfirm'});
  };

  const {state, dispatch} = useContext(ModalContext);
  const [id, setid] = useState<number>();

  const confirm = (e: React.MouseEvent) => {
    dispatch({type: 'CLOSE'});
    if (id) {
      deleteUploadedFile(id)
        .then((d) => {
          const errorMessage = getErrorMessage(d);
          if (errorMessage) {
            toastRef.current?.showErrorToast(errorMessage);
          } else {
            toastRef.current?.showSuccessToast('Delete file successfully');
          }
          return fetchData();
        })
        .catch((e) => {
          showError(e.data.message);
        });
    }
    e.stopPropagation();
  };

  const ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    ref.current && ref.current.blur();
  }, [state.isOpen]);

  return (
    <div className={style['main']}>
      <Table
        id='filesUploaded'
        rows={files}
        onQueryChanged={setQuery}
        paginate
        totalRows={total}
        sortable
        defaultSort={{createdAt: 'desc'}}
        loading={fetchingData}
      >
        <Column label='Status' prop='status'>
          {(status: string) => (
            <span className={classNames('badge-status', _.kebabCase(status))}>
              {status}
            </span>
          )}
        </Column>
        <Column label='File Name' prop='document.name'>
          {(name: string) => (
            <p className={style.name}>
              <i className={style.icon}></i>
              <span>{name}</span>
            </p>
          )}
        </Column>
        {type === 'Asset' && <Column label='Type of asset' prop='assetType' />}
        <Column label='File Size' prop='document.lengthInBytes'>
          {(bytes: number) => bytesToSize(bytes)}
        </Column>
        <Column label='Imported Date & Time' prop='createdAt'>
          {(timestamp: string) =>
            moment(timestamp).format('MM/DD/YYYY hh:mm A')
          }
        </Column>
        <Column id='actions' label='Actions' sortable={false}>
          {(file: UploadFile) => (
            <div className='operate'>
              {(_.isEqual(file['status'], 'Ready to Map') ||
                _.isEqual(file['status'], 'In Progress')) && (
                <EditButton file={file} type={type} showError={showError} />
              )}
              {(!_.isEqual(file['status'], 'Scanning File') &&
                !_.isEqual(file['status'], 'File Rejected')) && (
                <DownloadButton fileId={file['id']} />
              )}
              {!_.isEqual(file['status'], 'Scanning File') && (
                <i
                  role='button'
                  className='delete'
                  onClick={() => deleteFile(file['id'])}
                ></i>
              )}
            </div>
          )}
        </Column>
      </Table>

      <Modal
        title='Confirmation'
        body={<p>Are you sure you want to delete?</p>}
        isOpen={state.isOpen && state.href === 'comfirm'}
        footer={
          <div className='buttons'>
            <button
              className='primary-button'
              onClick={(e) => confirm(e)}
              ref={ref}
            >
              Confirm
            </button>
            <button
              className='default-button'
              onClick={() => dispatch({type: 'CLOSE'})}
            >
              Cancel
            </button>
          </div>
        }
      />
    </div>
  );
}

export default UploadedFilesTable;
