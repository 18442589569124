import classNames from 'classnames';
import React, { useRef, useState, useEffect } from 'react';
import style from './DepreciationCollectionManagementTable.module.scss';
import InitTable, { Query } from '../Table/Table';
import { InputType } from '../Table/components/Editor/Editor';
import { Link } from 'react-router-dom';
import { getDepreciationScheduleCollections, getSystemTaxYears } from '../../services';
import { useAppSelector } from '../../redux/store';
import { DepreciationScheduleCollection, SelectOption, State } from '../../interface';
import _ from 'lodash';
import FieldToggleCheckbox from '../FieldToggleCheckbox/FieldToggleCheckbox';
import { DepreciationScheduleType } from '../../enums';


const { Table, Column, TopBar } = InitTable<DepreciationScheduleCollection>();

interface Props {
  className?: string;
}


function DepreciationCollectionManagementTable({
  className
}: Props) {
  const stateOptions = useAppSelector(state => (state.states ?? []).map((o: State) => ({
    value: o.id,
    label: o.abbreviation,
    name: o.abbreviation,
  })));
  const [fetchingAllTaxYears, setFetchingAllTaxYears] = useState(false);
  const [activeTaxYears, setActiveTaxYears] = useState<SelectOption[]>([]);
  const [currentTaxYear, setCurrentTaxYear] = useState<number | null>(null);
  const [depreciationCollections, setDepreciationCollections] = useState<DepreciationScheduleCollection[]>([]);
  const [showOnlyCurrentTaxYear, setShowOnlyCurrentTaxYear] = useState<boolean>(true);
  const depreciationTypeOptions = Object.values(DepreciationScheduleType).map(depreciationType => ({
    value: depreciationType.toString(),
    label: depreciationType.toString(),
    name: depreciationType.toString(),
  }));

  useEffect(() => {
    setFetchingAllTaxYears(true);
    getSystemTaxYears()
      .then((res) => {
        setFetchingAllTaxYears(false);
        const allActiveTaxYears = _.orderBy(
          res.data,
          ['taxYear'],
          ['desc'],
        );

        setActiveTaxYears(allActiveTaxYears.map(taxYear => { 
          return { 
            item: taxYear, 
            label: taxYear.taxYear.toString(), 
            name: taxYear.taxYear.toString(), 
            value: taxYear.taxYear 
          } as SelectOption; 
        }));

        let currentTaxYearIndex = _.findIndex(allActiveTaxYears, {
          isCurrent: true,
        });
        if (currentTaxYearIndex < 0) {
          currentTaxYearIndex = 0;
        }
        setCurrentTaxYear(allActiveTaxYears[currentTaxYearIndex].taxYear);
      })
      .catch(() => {
        setFetchingAllTaxYears(false);
      });
  }, []);

  useEffect(() => {
    fetchData(previousQuery.current);
  }, [showOnlyCurrentTaxYear, currentTaxYear]);
  
  //Pagination
  const [total, setTotal] = useState<number>(0);
  const [fetchingData, setFetchingData] = useState(false);
  const previousQuery = useRef<Query | undefined>()
  const fetchData = (query?: Query) => {
    if (!currentTaxYear){
      return;
    }

    const queryTaxYear = showOnlyCurrentTaxYear ? currentTaxYear : (query?.filter?.taxYear as number)
    previousQuery.current = query;
    setFetchingData(true);
    getDepreciationScheduleCollections({
      stateIds: (query?.filter?.stateIds as number[])?.join(','),
      text: query?.search,
      taxYear: queryTaxYear ?? null,
      type: (query?.filter?.type as string) ?? '',
      sortBy: _.last(_.keys(query?.order)),
      sortDirection: _.last(_.values(query?.order)),
      page: query?.pagination?.page ?? 1,
      perPage: query?.pagination?.pageSize ?? 10,
    })
      .then((res) => {
        setDepreciationCollections(res.data.items);
        setTotal(res.data.total);
        setFetchingData(false);
      })
      .catch(() => {
        setFetchingData(false);
      });

  };

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'FormManagementSearch d-flex flex-column'
      )
    }>
      <Table
        id='DepreciationCollectionManagementTable'
        rows={depreciationCollections}
        onQueryChanged={fetchData}
        paginate
        sortable
        totalRows={total}
        loading={fetchingData || fetchingAllTaxYears}
        searchable='Search Depreciation Collection...'
        dynamicColumns
      >
        <TopBar>
          <FieldToggleCheckbox
            label='Show Only Current Tax Year'
            value={showOnlyCurrentTaxYear}
            onChange={(value) => setShowOnlyCurrentTaxYear(value)}
            classnames={style['float-right']}
          />
        </TopBar>
        

        {/* Collection Name */}
        <Column
          label='Collection Name'
          id='name'
        >
          {
            (row: DepreciationScheduleCollection) => (
              <span><strong>{row.name}</strong></span>
            )
          }
        </Column>
        
        {/* Collection State */}
        <Column
          label='Collection State'
          id="stateIds"
          prop="state.abbreviation"
          sortKey='state.abbreviation'
          filterable={{
            type: InputType.Select,
            getValue: (x) => (x as SelectOption).value,
            getLabel: (x) => (x as SelectOption).label,
            options: stateOptions,
            multi: true,
            searchable: true,
          }}
        />

        {/* Depreciation Type */}
        <Column
          label='Type'
          prop='type'
          sortKey='type'
          filterable={{
            type: InputType.Select,
            getValue: (x) => (x as SelectOption).value,
            getLabel: (x) => (x as SelectOption).label,
            options: depreciationTypeOptions,
            multi: false,
            searchable: false,
          }}
        />

        {/* Tax Year */}
        <Column
          label='Tax Year'
          prop='taxYear'
          sortKey='taxYear'
          filterable={{
            type: InputType.Select,
            getValue: (x) => (x as SelectOption).value,
            getLabel: (x) => (x as SelectOption).label,
            options: activeTaxYears,
            multi: false,
            searchable: true,
          }}
        />

        {/* Actions column  */}
        <Column
          id='actions'
          label='Actions'
          sortable={false}
        >
          {(data: DepreciationScheduleCollection) => (
            <div className={classNames(style['operate'], 'd-flex')}>
              <Link
                to={`/admin/depreciation-collection-management/${data.id}/view`}
                className='view'
              />
            </div>
          )}
        </Column>
      </Table>
    </div>
  );
}

export default DepreciationCollectionManagementTable;