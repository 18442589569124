import React from 'react';
import _ from 'lodash';
import style from './StepsBar.module.scss';
import { Stepper } from '@avalara/skylab-react';

interface Props {
  step: number;
  steps: string[];
}

function StepsBar(props: Props) {
  const {step, steps} = props;
  const result = _.map(steps, (item, i) => {
    if(_.isEqual(step, i + 1)) {
      return ({label: item, active: true})
    } else if(_.gt(step, i + 1)) {
      return ({label: item, completed: true})
    } else {
      return ({label: item})
    }
  });
  return (
    <div className={style['steps']}>
      <Stepper stepItems={result} />
    </div>
  );
}

export default StepsBar;
