import { AssetField } from "../interface";
import _ from "lodash";

const removeUselessField = (values: AssetField[]) =>
  values.map((value) =>
    _.omit(value, [
      "id",
      "assessor",
      "assessorAccountNumber",
      "companyName",
      "companyNumber",
      "legalEntityId",
      "legalEntityName",
      "legalEntityNumber",
      "propertyId",
      "propertyName",
      "propertyNumber",
      "errors",
    ])
  );

export default removeUselessField;
