import React, {useEffect} from 'react';
import {useAuth} from 'react-oidc-context';

function Logout() {
  const auth = useAuth();
  useEffect(() => {
    auth.signoutRedirect();
    sessionStorage.removeItem('skip');
    sessionStorage.removeItem('user-account-id');
    sessionStorage.removeItem('selected-tax-year');

    // Clear stored table filters
    Object.keys(localStorage)
      .filter(x => x.endsWith("_filters"))
      .forEach(x => localStorage.removeItem(x));

  }, []);
  return <></>;
}

export default Logout;
