import React from 'react';
import classNames from 'classnames';
import style from './FieldTextarea.module.scss';

interface Props {
  labelText?: string;
  value: string;
  onChange: (value: string) => void;
  classnames?: string;
  required?: boolean;
  tooltipText?: string;
  disabled?: boolean;
  error?: string;
  name?: string;
  placeholder?: string;
  resizable?: boolean;
  rows?: number;
}

function FieldTextarea(props: Props) {
  const {
    labelText,
    classnames,
    required,
    tooltipText,
    value,
    onChange,
    disabled,
    error,
    name,
    placeholder,
    resizable,
    rows = 3,
  } = props;
  return (
    // If validate false, pass the error to here to show error style
    <label
      className={classNames(style['form-control'], classnames, {
        error: error,
      })}
    >
      {labelText && <div className={style['label']}>
        {labelText}
        {required && <i className={style['required']}>*</i>}
        {tooltipText && (
          <i className={style['tooltip']}>
            <span className={style['tip']}>{tooltipText}</span>
          </i>
        )}
      </div>}
      {/* If disabled the input, add disabled parameter here */}
      <div
        className={classNames(
          style['input-control'],
          {
            disabled: disabled,
          },
          resizable && style['resizable']
        )}
      >
        <textarea
          name={name}
          value={value ? value : ''}
          onChange={(evt) => onChange(evt.target.value)}
          disabled={disabled}
          placeholder={placeholder}
          rows={rows}
        />
      </div>
      {/* Error message if do not enter any text and if field is required */}
      {error && (
        <span
          className={classNames(style['error-message'], {
            [style['long-message']]: !/Empty/.test(error),
          })}
        >
          {error}
        </span>
      )}
    </label>
  );
}

export default FieldTextarea;
