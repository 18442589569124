import {values} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { LoaderIndicator } from '../../../components/Loader/Loader';
import Tabs from '../../../components/Tabs/Tabs';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../enums';
import {downloadCommonClasses} from '../../../services';
import exportXLSX from '../../../utils/exportFile';
import style from './Forms.module.scss';
import FormsFormDetails from './FormsFormDetails/FormsFormDetails';
import FormsGlobalAssetMapping from './FormsGlobalAssetMapping/FormsGlobalAssetMapping';
import { State, ReduxState } from "../../../interface";
import {connect} from 'react-redux';

enum Tab {
  Mapping = 'Global Asset Mapping',
  Details = 'Form Details',
  Package = 'Package Contents',
}

const tabList = values(Tab);

interface Props {
  states: State[];
}

function Forms(props : Props) {
  const {states} = props;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<string>(
    searchParams.get('tab') as Tab,
  );

  const breadcrumb = [
    {
      value: 'Forms',
      href: '/forms',
    },
    {value: 'Manage Forms'},
  ];

  const clickReturnToDashboard = () => {
    navigate('/dashboard');
  };

  const setTab = useCallback(
    (tab: Tab) =>
      setSearchParams(new URLSearchParams({tab}), {
        replace: true,
      }),
    [],
  );

  useEffect(() => {
    const tabParam = searchParams.get('tab');

    if (!tabParam) {
      setTab(Tab.Mapping);
    } else if (tabParam !== selectedTab) {
      setSelectedTab(tabParam);
    }
  }, [searchParams, selectedTab]);

  const [downloading, setDownloading] = useState(false);
  const download = () => {
    setDownloading(true);
    exportXLSX(downloadCommonClasses(), () => {
      setDownloading(false);
    });
  };

  return (
    <div className={style['main-content']}>
      {/* Braedcrumb */}
      <Breadcrumb items={breadcrumb} />
      <h2>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.PageTitle}
          tooltipKey='Manage Forms'
        >
          <span>Manage Forms</span>
        </TooltipWrapper>
      </h2>
      <div className={style['main']}>
        {selectedTab && (
          <div>
            <Tabs
              selected={selectedTab}
              onSelect={(x) => setTab(x as Tab)}
              tabs={tabList}
            >
              <div className={style['tab-content']}>
                {selectedTab === Tab.Mapping && <FormsGlobalAssetMapping />}
                {selectedTab === Tab.Details && (
                  <FormsFormDetails variant='form-details' states={states} />
                )}
                {selectedTab === Tab.Package && (
                  <FormsFormDetails variant='package-content' states={states}/>
                )}
              </div>
            </Tabs>
          </div>
        )}
        <hr />
        <div className={style['buttons']}>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageAction}
            tooltipKey='Return to dashboard'
          >
            <button
              type='button'
              className='primary'
              onClick={clickReturnToDashboard}
            >
              Return to dashboard
            </button>
          </TooltipWrapper>

          {selectedTab === Tab.Mapping && (
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Download Avalara standardized asset classes and descriptions'
            >
              <button className='secondary' onClick={download} disabled={downloading}>
                Download Avalara standardized asset classes and descriptions{downloading ? (<LoaderIndicator className='button-loading' loading={true} />) : null}
              </button>
            </TooltipWrapper>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  states: state.states,
});

export default connect(mapStateToProps)(Forms);