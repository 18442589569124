import Axios from "axios";
import classNames from "classnames";
import {
  isArray,
  isFunction,
  isString,
  isUndefined,
  times,
  toNumber,
} from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import pdfFileIcon from "../../images/ic-pdf-file.svg";
import { responseToFile } from "../../utils/exportFile";
import FieldInput from "../FieldInput/FieldInput";
import FieldSelect from "../FieldSelect/FieldSelect";
import style from "./PdfViewer.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type Source<T = ArrayBuffer> = string | T | (() => Promise<T>);

export interface PdfFile {
  name: string;
  source: Source;
  thumbnail?: Source<File>;
}

export interface Props {
  className?: string;
  files: PdfFile | PdfFile[];
}

interface PdfContext {
  name: string;
  data?: ArrayBuffer | Blob | null;
  thumbnailBlobUri?: string | null;
  pageNumbers: number[];
}

const defaultPdfContext: PdfContext = {
  name: "",
  pageNumbers: [],
};

function resolveSource(
  source: Source<unknown>,
  isFile = false
): Promise<ArrayBuffer | File> {
  if (isString(source)) {
    // ignore in coverage report because isFile is always false
    /* istanbul ignore next */
    return Axios.get<ArrayBuffer>(source, {
      responseType: "arraybuffer",
    }).then((res) => (isFile ? responseToFile(res) : res.data));
  } else return Promise.resolve(isFunction(source) ? source() : source);
}

function PdfViewer({ className, files }: Props) {
  const multiple = isArray(files);
  const fileArr = useMemo(() => (isArray(files) ? files : [files]), [files]);
  const [pdfIndex, setPdfIndex] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageText, setCurrentPageText] = useState(String(currentPage));
  const [currentZoom, setCurrentZoom] = useState(1);
  const [pdfContexts, setPdfContexts] = useState<PdfContext[]>([]);
  const cleanupRef = useRef<PdfContext[]>();
  const wrapper = useRef<HTMLDivElement>(null);
  const pageIntersectionRef = useRef<IntersectionObserver>();
  const [pageRotation, setPageRotation] = useState<number>(0);

  useEffect(() => {
    cleanupRef.current = pdfContexts;
  }, [pdfContexts]);

  useEffect(
    () => () =>
      cleanupRef.current?.forEach(
        (x) => x.thumbnailBlobUri && URL.revokeObjectURL(x.thumbnailBlobUri)
      ),
    []
  );

  const zoomOptions = useMemo(
    () =>
      [0.5, 1, 1.5, 2, 4].map((scale) => ({
        value: scale,
        label: `${scale * 100}%`,
        name: `${scale * 100}%`,
      })),
    []
  );

  useEffect(() => {
    setPdfContexts(
      (multiple ? files : [files]).map(() => ({
        ...defaultPdfContext,
      }))
    );

    setPdfIndex(0);
  }, [files]);

  const resolveDocument = (idx: number) => {
    if (isUndefined(pdfContexts[idx].data)) {
      pdfContexts[idx].data = null;

      return resolveSource(fileArr[idx].source)
        .then((x) => x as ArrayBuffer)
        .then((buffer) => {
          const newBuffer = copyArrayBuffer(buffer);
          pdfjs
            .getDocument(buffer)
            .promise.then((pdfDoc) => {
              setPdfContexts((current) =>
                current.map((ctx, idxCtx) => {
                  return idxCtx === idx
                  ? {
                      ...ctx,
                      data: new Blob([newBuffer]),
                      pdfDoc,
                      pageNumbers: times(pdfDoc.numPages, (item) => item + 1),
                    }
                  : ctx
                })
              );
            })
        });
    }
  }

  const resolveThumbnail = (idx: number) => {
    if (isUndefined(pdfContexts[idx].thumbnailBlobUri)) {
      const source = fileArr[idx].thumbnail;
      pdfContexts[idx].thumbnailBlobUri = null;

      if (source) {
        return resolveSource(source).then((file) =>
          setPdfContexts((current) =>
            current.map((ctx, idxCtx) =>
              idxCtx === idx
                ? {
                    ...ctx,
                    thumbnailBlobUri: URL.createObjectURL(file as File),
                  }
                : ctx
            )
          )
        );
      }
    }
  };

  useEffect(() => {
    if (pdfContexts.length) {
      // Populate current document
      resolveDocument(pdfIndex)?.catch(() => resolveThumbnail(pdfIndex));

      // Populate thumbnails or documents for other entries
      pdfContexts.forEach((ctx, idx) => {
        if (isUndefined(ctx.data)) {
          resolveThumbnail(idx) || resolveDocument(idx);
        }
      });
    }
  }, [pdfContexts, pdfIndex]);

  const getPdfContext = (index: number) => {
    // ignore in coverage report because pdfContexts[index] can not be null
    /* istanbul ignore else */
    if (pdfContexts[index]) {
      return pdfContexts[index];
    } else {
      return defaultPdfContext;
    }
  };

  const setIndex = useCallback(
    (index: number) => {
      if (multiple) {
        setCurrentPage(1);
        setCurrentPageText('1');
        setPdfIndex(index);
      } else {
        setCurrentPage(index + 1);
        setCurrentPageText(String(index + 1));
      }
    },
    [multiple]
  );

  const observePageScroll = useCallback(
    (page: number) => {
      const pageEl = wrapper.current?.querySelector(
        `[data-page-number="${page}"]`
      );

      // ignore in coverage report because pageEl can not be null
      /* istanbul ignore else */
      if (pageEl) {
        let observer = pageIntersectionRef.current;

        if (!observer) {
          observer = pageIntersectionRef.current = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  const newPage = Number(
                    entry.target.getAttribute("data-page-number")
                  );

                  setCurrentPageText(String(newPage));
                  setCurrentPage(newPage);
                }
              });
            },
            {
              root: wrapper.current,
              rootMargin: "0px",
              threshold: 0.5,
            }
          );
        }

        observer.observe(pageEl);
      }

      return () => {
        pageIntersectionRef.current?.disconnect();
        pageIntersectionRef.current = undefined;
      };
    },
    [pdfIndex]
  );

  const copyArrayBuffer = (originalBuffer: ArrayBuffer) => {
    // Create a new ArrayBuffer with the same byte length as the original
    const newBuffer = new ArrayBuffer(originalBuffer.byteLength);
  
    // Create TypedArray views for both the original and new buffers
    const originalView = new Uint8Array(originalBuffer);
    const newView = new Uint8Array(newBuffer);
  
    // Copy the data from the original buffer to the new buffer
    newView.set(originalView);
  
    return newBuffer;
  }

  return (
    <div className={classNames(style["root"], className)}>
      {!!pdfContexts.length && (
        <>
          <div className={style["pdf-preview-container"]} role="list">
            {(multiple ? pdfContexts : getPdfContext(0).pageNumbers).map(
              (item, index) => {
                const ctx: PdfContext = pdfContexts[multiple ? index : 0];
                return (
                  <div
                    key={index}
                    className={classNames(
                      style["pdf-preview-item"],
                      (multiple ? pdfIndex === index : currentPage === item) &&
                        style["active"]
                    )}
                    role="listitem"
                  >
                    {ctx.thumbnailBlobUri ? (
                      <img
                        className={style["pdf-preview-page-wrapper"]}
                        src={ctx.thumbnailBlobUri}
                        onClick={() => {
                          setPageRotation(0);
                          setIndex(index);
                        }}
                      />
                    ) : (
                      <Document
                        className={style["pdf-preview-page-wrapper"]}
                        file={ctx.data}
                      >
                        <Page
                          pageNumber={(multiple && 1) || index + 1}
                          width={170}
                          onClick={() => {
                            setPageRotation(0);
                            setIndex(index);
                          }}
                        />
                      </Document>
                    )}

                    <div
                      className={style["pdf-preview-name"]}
                      title={
                        multiple ? fileArr[index].name : `Page ${index + 1}`
                      }
                    >
                      {multiple ? fileArr[index].name : `Page ${index + 1}`}
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <div className={style["pdf-main"]}>
            <div className={style["pdf-main-header"]}>
              <div
                className={style["pdf-file-name"]}
                title={fileArr[pdfIndex].name}
              >
                <img src={pdfFileIcon} alt="" />
                <div className={style["pdf-file-name-text"]}>
                  {fileArr[pdfIndex].name}
                </div>
              </div>
              <div className={style["pdf-actions"]}>
                <div className={style["pdf-pagination"]}>
                  Page{" "}
                  <FieldInput
                    classnames={style["pdf-pagination-input"]}
                    labelText=""
                    value={currentPageText}
                    onChange={(val) => setCurrentPageText(val)}
                    onKeyDown={(evt) => {
                      if (evt.key === "Enter") {
                        const ctx = pdfContexts[pdfIndex];
                        let num =
                          currentPageText.match(/^\d*$/) &&
                          toNumber(currentPageText);

                        if (num) {
                          num = Math.max(
                            1,
                            Math.min(num, ctx.pageNumbers.length)
                          );

                          if (multiple && wrapper.current) {
                            wrapper.current
                              .querySelector(`[data-page-number="${num}"]`)
                              ?.scrollIntoView();
                          } else {
                            setCurrentPage(num);
                          }
                        } else {
                          setCurrentPageText(String(currentPage));
                        }
                      }
                    }}
                  />{" "}
                  of {getPdfContext(pdfIndex).pageNumbers.length}
                </div>
                <div className={style["pdf-zoom"]}>
                  Zoom
                  <FieldSelect
                    classnames={style["pdf-zoom-select"]}
                    labelText=""
                    options={zoomOptions}
                    selectId={currentZoom}
                    onSelect={(selectedOption) => {
                      setCurrentZoom((selectedOption?.value as number) || 1);
                    }}
                    force
                  />
                </div>
                <div className={style["pdf-rotate"]}>
                  <button
                    type={'button'}
                    className={classNames('primary', style['rotate'])}
                    onClick={() => {
                      setPageRotation((pageRotation + 90) % 360);
                    }}
                  >
                    <i></i>
                  </button>
                </div>
              </div>
              <div className={style["header-counter-name-placeholder"]}></div>
            </div>
            <div className={style["pdf-main-page-container"]} ref={wrapper}>
              <div className={style["pdf-main-page-wrapper"]}>
                <Document file={getPdfContext(pdfIndex).data}>
                  {(multiple
                    ? getPdfContext(pdfIndex).pageNumbers
                    : [currentPage]
                  ).map((page) => (
                    <Page
                      onRenderSuccess={() =>
                        multiple && observePageScroll(page)
                      }
                      key={page}
                      className={style["pdf-main-page"]}
                      pageNumber={page}
                      scale={currentZoom}
                      rotate={pageRotation}
                    />
                  ))}
                </Document>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PdfViewer;
