import {Limit, OriginalFileField} from '../interface';
import _ from 'lodash';
import moment from 'moment';

function limitChars(obj: OriginalFileField, limit: Limit) {
  return _.mapValues(obj, (v, k) => {
    if (!_.isNumber(v)) {
      if(!_.isDate(v)) {
        return _.get(limit, k)
        ? _.truncate(v, {length: _.get(limit, k), omission: ''})
        : v;
      } else {
        return moment(v).format('MM/DD/YY');
      }
    } else {
      return Number(v)
        .toLocaleString('fullwide', {useGrouping: false})
        .slice(0, _.get(limit, k));
    }
  });
}

export default limitChars;
