import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Landing from "./pages/Landing/Landing";
import Onboarding from "./pages/Landing/Onboarding/Onboarding";
import CompanySetup from "./pages/Landing/CompanySetup/CompanySetup";
import LegalEntitySetup from "./pages/Landing/LegalEntitySetup/LegalEntitySetup";
import Properties from "./pages/Landing/Properties/Properties";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import PropertySetup from "./pages/Landing/PropertySetup/PropertySetup";
import PropertyUpload from "./pages/Landing/PropertyUpload/PropertyUpload";
import PropertyReview from "./pages/Landing/PropertyReview/PropertyReview";
import PropertyMapFile from "./pages/Landing/PropertyMapFile/PropertyMapFile";
import AssetsUpload from "./pages/Landing/AssetsUpload/AssetsUpload";
import AssetsMapFile from "./pages/Landing/AssetsMapFile/AssetsMapFile";
import AssetsReview from "./pages/Landing/AssetsReview/AssetsReview";
import Assets from "./pages/Landing/Assets/Assets";
import { AuthProvider } from "react-oidc-context";
import {
  POST_LOGOUT_REDIRECT_URI,
  REDIRECT_URI,
  RESPONSE_TYPE,
  SCOPE,
} from "./config";
import Logout from "./pages/Logout";
import getBaseURL from "./utils/getBaseURL";
import FixedAssetSetup from "./pages/Landing/FixedAssetSetup/FixedAssetSetup";
import InventorySetup from "./pages/Landing/InventorySetup/InventorySetup";
import VehicleSetup from "./pages/Landing/VehicleSetup/VehicleSetup";
import LeasedVehicleSetup from "./pages/Landing/LeasedVehicleSetup/LeasedVehicleSetup";
import LeasedEquipmentSetup from "./pages/Landing/LeasedEquipmentSetup/LeasedEquipmentSetup";
import Admin from "./pages/Admin/Admin";
import Tooltip from "./pages/Admin/Tooltip/Tooltip";
import NewAccount from "./pages/Admin/NewAccount/NewAccount";
import HelpContent from "./pages/Admin/HelpContent/HelpContent";
import PurchasedReturn from "./pages/Admin/PurchasedReturn/PurchasedReturn";
import Returns from "./pages/Landing/Returns/Returns";
import ManageFormDetails from "./pages/Landing/ManageFormDetails/ManageFormDetails";
import Forms from "./pages/Landing/Forms/Forms";
import ManagePackageContent from "./pages/Landing/ManagePackageContent/ManagePackageContent";
import UserManagement from "./pages/Landing/AccountSetting/UserManagement/UserManagement";
import PlanUsage from "./pages/Landing/AccountSetting/PlanUsage/PlanUsage";
import Help from "./pages/Help/Help";
import EditAccountMappingTaxability from "./pages/Landing/EditAccountMappingTaxability/EditAccountMappingTaxability";
import PreviewReturnForm from "./pages/Landing/PreviewReturnForm/PreviewReturnForm";
import PreviewPackage from "./pages/Landing/PreviewPackage/PreviewPackage";
import Reports from './pages/Landing/Reports/Reports';
import GenerateReport from './pages/Landing/GenerateReport/GenerateReport';
import RoleGuard from "./components/RoleGuard/RoleGuard";
import Healthcheck from "./pages/Healthcheck/Healthcheck";
import TaxYearSelection from "./pages/Landing/AccountSetting/TaxYearSelection/TaxYearSelection";
import MaintenanceNotifications from "./pages/Admin/MaintenanceNotifications/MaintenanceNotifications";
import AssessorManagement from "./pages/Admin/AssessorManagement/AssessorManagement";
import AssessorManagementDetails from "./pages/Admin/AssessorManagementDetails/AssessorManagementDetails";
import FormManagement from "./pages/Admin/FormManagement/FormManagement";
import envConfig from "./envConfig"
import ScheduleCollectionManagement from "./pages/Admin/ScheduleCollectionManagement/ScheduleCollectionManagement";
import DepreciationCollectionManagementDetails from "./pages/Admin/DepreciationCollectionManagementDetails/DepreciationCollectionManagementDetails";
import DepreciationScheduleManagementDetails from "./pages/Admin/DepreciationScheduleManagementDetails/DepreciationScheduleManagementDetails";
import IndexCollectionManagementDetails from "./pages/Admin/IndexCollectionManagementDetails/IndexCollectionManagementDetails";
import IndexTableManagementDetails from "./pages/Admin/IndexTableManagementDetails/IndexTableManagementDetails";

function App() {
  const oidcConfig = {
    authority: envConfig.AUTHORITY,
    client_id: envConfig.CLIENT_ID,
    redirect_uri: getBaseURL() + REDIRECT_URI,
    response_type: RESPONSE_TYPE as string,
    scope: SCOPE,
    post_logout_redirect_uri: getBaseURL() + POST_LOGOUT_REDIRECT_URI,
    onSigninCallback: () => {
      window.history.replaceState(
        {},
        document.title,
        window.location.pathname
      )
    }
  };

  return (
    <AuthProvider {...oidcConfig}>
      <Router>
        <Routes>
          <Route path="/logout" element={<Logout />} />
          <Route path="/healthcheck" element={<Healthcheck />} />

          <Route element={<RequireAuth />}>
            <Route
              path="dashboard"
              element={
                <RoleGuard route="dashboard">
                  <Landing />
                </RoleGuard>
              }
            >
              <Route index element={<Onboarding />} />
              <Route path="*" element={<Navigate replace to="/dashboard" />} />
            </Route>

            <Route
              path="properties"
              element={
                <RoleGuard route="properties">
                  <Landing />
                </RoleGuard>
              }
            >
              <Route index element={<Properties />} />
              <Route path="companySetup" element={<CompanySetup />} />
              <Route path="editCompany/:id" element={<CompanySetup />} />
              <Route path="propertySetup" element={<PropertySetup />} />
              <Route path="editProperty/:id" element={<PropertySetup />} />
              <Route path="legalEntitySetup" element={<LegalEntitySetup />} />
              <Route path="editLegalEntity/:id" element={<LegalEntitySetup />} />
              <Route path="properties" element={<Properties />} />
              <Route path="propertyUpload" element={<PropertyUpload />} />
              <Route path="propertyMapFile/:id" element={<PropertyMapFile />} />
              <Route path="propertyReview/:id" element={<PropertyReview />} />
              <Route path="editUploadProperty/:id" element={<PropertySetup />} />
            </Route>

            <Route
              path="assets"
              element={
                <RoleGuard route="assets">
                  <Landing />
                </RoleGuard>
              }
            >
              <Route index element={<Assets />} />
              {/* Assets */}
              <Route path="assets" element={<Assets />} />
              <Route path="rollover" element={<Assets />} />
              <Route path="assetsUpload" element={<AssetsUpload />} />
              <Route path="assetsMapFile/:id" element={<AssetsMapFile />} />
              <Route path="assetsReview/:id" element={<AssetsReview />} />
              <Route path="fixedAssetSetup" element={<FixedAssetSetup />} />
              <Route
                path="editFixedAssetSetup/:id"
                element={<FixedAssetSetup />}
              />
              <Route
                path="editUploadFixedAssetSetup/:id"
                element={<FixedAssetSetup />}
              />
              <Route path="inventorySetup" element={<InventorySetup />} />
              <Route path="editInventorySetup/:id" element={<InventorySetup />} />
              <Route
                path="editUploadInventorySetup/:id"
                element={<InventorySetup />}
              />
              <Route path="vehicleSetup" element={<VehicleSetup />} />
              <Route path="editVehicleSetup/:id" element={<VehicleSetup />} />
              <Route
                path="editUploadVehicleSetup/:id"
                element={<VehicleSetup />}
              />
              <Route path="leasedVehicleSetup" element={<LeasedVehicleSetup />} />
              <Route
                path="editLeasedVehicleSetup/:id"
                element={<LeasedVehicleSetup />}
              />
              <Route
                path="editUploadLeasedVehicleSetup/:id"
                element={<LeasedVehicleSetup />}
              />
              <Route
                path="leasedEquipmentSetup"
                element={<LeasedEquipmentSetup />}
              />
              <Route
                path="editLeasedEquipmentSetup/:id"
                element={<LeasedEquipmentSetup />}
              />
              <Route
                path="editUploadLeasedEquipmentSetup/:id"
                element={<LeasedEquipmentSetup />}
              />
            </Route>

            <Route
              path="users"
              element={
                <RoleGuard route="users">
                  <Landing />
                </RoleGuard>
              }
            >
              <Route index element={<UserManagement />} />
            </Route>

            <Route
              path="plans"
              element={
                <RoleGuard route="plans">
                  <Landing />
                </RoleGuard>
              }
            >
              <Route index element={<PlanUsage />} />
            </Route>

            <Route
              path="taxyearselection"
              element={
                <RoleGuard route="taxyearselection">
                  <Landing />
                </RoleGuard>
              }
            >
              <Route index element={<TaxYearSelection />} />
            </Route>

            <Route
              path="forms"
              element={
                <RoleGuard route="forms">
                  <Landing />
                </RoleGuard>
              }
            >
              <Route index element={<Forms />} />
              <Route path="formDetails/:formId" element={<ManageFormDetails />}>
                <Route path="return/:returnId" element={null}></Route>
              </Route>
              <Route
                path="packageContents/:formId"
                element={<ManagePackageContent />}
              >
                <Route
                  path="return/:returnId"
                  element={<ManagePackageContent />}
                />
              </Route>
            </Route>

            <Route
              path="returns"
              element={
                <RoleGuard route="returns">
                  <Landing />
                </RoleGuard>
              }
            >
              <Route index element={<Returns />} />
              <Route
                path="editAccountMappingAndTaxability/:returnId"
                element={<EditAccountMappingTaxability />}
              />
              <Route
                path="previewReturnForm/:returnId"
                element={<PreviewReturnForm />}
              />
              <Route
                path="previewPackage/:returnId"
                element={<PreviewPackage />}
              />
            </Route>

            <Route
              path="reports"
              element={
                <RoleGuard route="reports">
                  <Landing />
                </RoleGuard>
              }
            >
              <Route index element={<Reports />} />

              <Route
                path="generate"
                element={<GenerateReport />}
              />
            </Route>

            <Route
              path="admin"
              element={
                <RoleGuard route="admin">
                  <Landing />
                </RoleGuard>
              }
            >
              <Route element={<Admin />}>
                <Route index element={<Navigate replace to="tooltip" />} />
                <Route path="tooltip" element={<Tooltip />}>
                  <Route path=":page" element={null}></Route>
                </Route>
                <Route path="new-account" element={<NewAccount />} />
                <Route path="help-content" element={<HelpContent />} />
                <Route path="purchase-returns" element={<PurchasedReturn />} />
                <Route path="maintenance-notifications" element={<MaintenanceNotifications />} />
                <Route path="assessor-management" element={<AssessorManagement />} />
                <Route path="assessor-management/new" element={<AssessorManagementDetails isInEditMode isAddNew />} />
                <Route path="assessor-management/:id" element={<AssessorManagementDetails />} />
                <Route path="assessor-management/:id/edit" element={<AssessorManagementDetails isInEditMode />} />
                <Route path="schedule-collection-management" element={<ScheduleCollectionManagement />} />
                <Route path="depreciation-collection-management/new" element={<DepreciationCollectionManagementDetails isAddNew />} />
                <Route path="depreciation-collection-management/:id/view" element={<DepreciationCollectionManagementDetails />} />
                <Route path="depreciation-schedule-management/new" element={<DepreciationScheduleManagementDetails isAddNew />} />
                <Route path="depreciation-schedule-management/:id/view" element={<DepreciationScheduleManagementDetails />} />
                <Route path="index-collection-management/new" element={<IndexCollectionManagementDetails isAddNew />} />
                <Route path="index-collection-management/:id/view" element={<IndexCollectionManagementDetails />} />
                <Route path="index-table-management/new" element={<IndexTableManagementDetails isAddNew />} />
                <Route path="index-table-management/:id/view" element={<IndexTableManagementDetails />} />
                <Route path="form-management" element={<FormManagement />} />
              </Route>
            </Route>

            <Route
              path="help"
              element={
                <RoleGuard route="help">
                  <Landing />
                </RoleGuard>
              }
            >
              <Route index element={<Help />} />
            </Route>
          </Route>

          <Route path="*" element={<Navigate replace to="/dashboard" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
