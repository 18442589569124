import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import actions from '../../../redux/actions';
import _ from 'lodash';
import {IToastMessage, UploadFile} from '../../../interface';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import AddProperty from '../../../components/AddProperty/AddProperty';
import ToastMessage, {
  ToastMessageRefType,
} from '../../../components/ToastMessage/ToastMessage';
import style from './PropertyUpload.module.scss';
import UploadedFilesTable from '../../../components/UploadedFilesTable/UploadedFilesTable';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../enums';

interface Props {
  setUploadFile: (value: File | undefined) => void;
}

function PropertyUpload(props: Props) {
  const {setUploadFile} = props;
  // Breadcrumb, dashboard depend on user skip or not, property link depend where is the page from
  const breadcrumb = [
    {
      value: 'Properties',
      href: '/properties?tab=Properties+and+Assessor+Accounts',
    },
    {value: 'Property Setup'},
  ];
  useEffect(() => {
    setUploadFile(undefined);
  }, []);

  const [uploadedFile, setUploadedFile] = useState<UploadFile[]>([]);
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [refresh, setRefresh] = useState<boolean>(true);
  const [shouldShowCancelFileWarning, setShouldShowCancelFileWarning] =
    useState(true);
  const toastRef = useRef<ToastMessageRefType | null>(null);

  useEffect(() => {
    if (
      shouldShowCancelFileWarning &&
      _.map(uploadedFile, 'status').includes('In Progress')
    ) {
      setShouldShowCancelFileWarning(false);
      setToastMessage({
        visible: true,
        message: `The In Progress file will be cancelled at 72 hours`,
        type: 'warning',
      });
    }
  }, [uploadedFile]);

  const showError = (value: string) => {
    setToastMessage({
      visible: true,
      message: value,
      type: 'error',
    });
  };

  const refreshUploaded = () => {
    setRefresh(true);
  };

  const uploadedSuccess = () => {
    setRefresh(true);
    setToastMessage({
      visible: true,
      message: `Upload successfully`,
      type: 'success',
    });
  };

  return (
    <div className={style['main-content']}>
      {/* Braedcrumb */}
      <Breadcrumb items={breadcrumb} />
      <ToastMessage
        ref={toastRef}
        status={toastMessage}
        className={style['toast']}
      />
      <div className={style['main']}>
        {/* Add property section */}
        <div className={style['section']}>
          <h2>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageTitle}
              tooltipKey='Add Property'
            >
              <span>Add Property</span>
            </TooltipWrapper>
          </h2>
          <AddProperty
            toastRef={toastRef}
            uploadError={showError}
            uploadSuccess={uploadedSuccess}
          />
        </div>
        {/* Added Properties */}
        <div className={style['section']}>
          <h2>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageTitle}
              tooltipKey='Files Uploaded'
            >
              <span>Files Uploaded</span>
            </TooltipWrapper>
          </h2>
          <div className={style['refresh']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Refresh uploaded files'
            >
              <Link
                to='#'
                onClick={refreshUploaded}
                className='button secondary'
              >
                Refresh uploaded files
              </Link>
            </TooltipWrapper>
          </div>
          <UploadedFilesTable
            toastRef={toastRef}
            type='Property'
            dataOnload={(value) => setUploadedFile(value)}
            refresh={refresh}
            refreshSuccess={() => setRefresh(false)}
            showError={showError}
          />
          <div className={style['buttons']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Return to dashboard'
            >
              <Link to='/dashboard' className='button primary'>
                Return to dashboard
              </Link>
            </TooltipWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setUploadFile: (value: File | undefined) =>
    dispatch(actions.setUploadFile(value)),
});

export default connect(null, mapDispatchToProps)(PropertyUpload);
