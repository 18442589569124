import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import style from './CustomCheckbox.module.scss';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import { TOOLTIP_SECTIONS } from '../../enums';

interface Props {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  classnames?: string;
  tooltipText?: string;
}

/** Custom checkbox, it receice label & value & onChange and a optional parameter classnames */
function CustomCheckbox(props: Props) {
  const {label, value, onChange, classnames, tooltipText} = props;
  const labelId = _.uniqueId(`label`);
  const checkboxId = _.uniqueId(`checkbox`);
  return (
    <div className={classNames(style['s-checkbox'], classnames)}>
      <fieldset role='group' aria-labelledby={labelId}>
        <legend id={labelId}></legend>
        <input
          type='checkbox'
          id={checkboxId}
          value={label}
          checked={value ?? false}
          onChange={() => onChange(!value)}
        />

        <label htmlFor={checkboxId} className={style['check-label']}>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.Common}
            tooltipKey={tooltipText ? tooltipText : label}
          >
            <span>{label}</span>
          </TooltipWrapper>
        </label>
      </fieldset>
    </div>
  );
}

export default CustomCheckbox;
