import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IBackendIndexTableUpdate, IndexTable, IndexTableFactor, IToastMessage, SelectOption } from '../../../interface';
import { bulkUpdateIndexFactors, createIndexTable, getErrorMessage, getIndexTable, getIndexTableCollections, updateIndexTable, } from '../../../services';
import { TOOLTIP_SECTIONS } from '../../../enums';
import _ from 'lodash';
import { FormikProps, useFormik } from 'formik';
import validateEmpty from '../../../utils/validateEmpty';
import Loader from '../../../components/Loader/Loader';
import DiscardChangeConfirmModal from '../../../components/DiscardChangeConfirmModal/DiscardChangeConfirmModal';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import Divider from '../../../components/Divider/Divider';
import style from './IndexTableManagementDetails.module.scss';
import classNames from 'classnames';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import FieldInput from '../../../components/FieldInput/FieldInput';
import FieldSelect from '../../../components/FieldSelect/FieldSelect';
import IndexFactorManagementTable from '../../../components/IndexFactorManagementTable/IndexFactorManagementTable';

interface Props {
  className?: string;
  isAddNew?: boolean;
}

interface IndexTableFormData {
  id: number | null;
  indexTableCollectionId: number;
  floorFactor: number;
  name: string;
}

const newInit: IndexTableFormData = {
  id: null,
  indexTableCollectionId: 0,
  floorFactor: 0,
  name: '',
};

function IndexTableManagementDetails({
  className,
  isAddNew
}: Props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [backendIndexTable, setBackendIndexTable] = useState<IndexTable | null>(null);
  const [initialIndexTableValues, setInitialIndexTableValues] = useState<IBackendIndexTableUpdate>(newInit);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const params = useParams();
  const [fetchingIndexCollections, setFetchingIndexCollections] = useState(false);
  const [indexCollectionOptions, setIndexCollectionOptions] = useState<SelectOption[]>([]);
  const [fetchingIndexTableInfo, setFetchingIndexTableInfo] = useState(false);
  const [savingIndexTable, setSavingIndexTable] = useState(false);

  const [factorsToSave, setFactorsToSave] = useState<IndexTableFactor[]>([]);
  const [savingFactors, setSavingFactors] = useState(false);

  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const shouldShowError = useMemo(() => isSubmitted, [isSubmitted]);
  const getFormErrorMessage = (key: keyof IBackendIndexTableUpdate) => {
    return shouldShowError ? formikIndexTableForm?.errors?.[key] : undefined;
  }
  const showSuccess = () => {
    window.scrollTo(0, 0);
    setToastMessage({
      message: 'Index Table details saved successfully',
      visible: true,
      type: 'success',
    });
  }
  const showErrorMessage = (message: string) => {
    window.scrollTo(0, 0);
    setToastMessage({
      message,
      visible: true,
      type: 'error',
    });
  }

  useEffect(() => {
    fetchIndexCollections();
  }, [params, searchParams, isAddNew]);

  const fetchIndexCollections = () => {
    setFetchingIndexCollections(true);
    getIndexTableCollections({
      page: 0,
      stateIds: ''
    })
      .then((res) => {
        if (res.data?.items?.length > 0) {
          const indexCollectionOptions = res.data.items.map((indexCollection) => ({
            value: indexCollection.id.toString(),
            label:`${indexCollection.name}  |  ${indexCollection.taxYear}`,
            name: `${indexCollection.name}  |  ${indexCollection.taxYear}`,
          }));
          setIndexCollectionOptions(indexCollectionOptions);
        }
        setFetchingIndexCollections(false);

        if (isAddNew) {
          setInitialIndexTableValues(newInit);
          const indexCollectionId = searchParams.get('indexTableCollectionId')
          if (indexCollectionId) {
            formikIndexTableForm?.setFieldValue(
              'indexTableCollectionId',
              _.toNumber(indexCollectionId)
            );
          }
        }
        else {
          fetchIndexTable();
        }
      })
      .catch((e) => {
        showErrorMessage(getErrorMessage(e));
        setFetchingIndexCollections(false);
      });
  }

  const fetchIndexTable = () => {
    if (
      params.id &&
      !fetchingIndexTableInfo
    ) {
      setFetchingIndexTableInfo(true);
      getIndexTable(_.toNumber(params.id))
        .then((d) => {
          const indexTableData = d.data;
          if (!_.isEmpty(indexTableData)) {
            setInitialIndexTableValues(indexTableData);
            setBackendIndexTable(indexTableData);
            formikIndexTableForm.validateForm();
          }
          setFetchingIndexTableInfo(false);
        })
        .catch((e) => {
          showErrorMessage(getErrorMessage(e));
          setFetchingIndexTableInfo(false);
        });
      }
  };

  const formikIndexTableForm: FormikProps<IBackendIndexTableUpdate> = useFormik<IBackendIndexTableUpdate>({
    initialValues: initialIndexTableValues,
    enableReinitialize: true,
    validate: (values) => {
      const requiredErrors = validateEmpty(_.pick({
        ...values,
      }, [
        'name',
        'indexTableCollectionId',
        'floorFactor',
      ]));
      return requiredErrors;
    },
    onSubmit: (values) => {
      if (!isIndexTableFormChanged && factorsToSave.length === 0) {
        return;
      }

      setSavingIndexTable(true);

      if (isAddNew) {
        createIndexTable(values)
          .then(() => {
            showSuccess();
            setInitialIndexTableValues(formikIndexTableForm.values);
            setSavingIndexTable(false);
            setIsSubmitted(false);
            setTimeout(() => {
              navigate(-1);
            }, 100);
          })
          .catch((e) => {
            showErrorMessage(getErrorMessage(e));
            setSavingIndexTable(false);
            setIsSubmitted(false);
          });
      }
      else {
        updateIndexTable(backendIndexTable?.id ?? 0, formikIndexTableForm.values)
          .then(() => {
            setInitialIndexTableValues(values);
            setSavingIndexTable(false);
            
            if (factorsToSave.length > 0) {
              setSavingFactors(true);
              bulkUpdateIndexFactors(backendIndexTable?.id ?? 0, factorsToSave)
                .then(() => {
                  setInitialIndexTableValues(values);
                  showSuccess();
                  setSavingFactors(false);
                  setTimeout(() => {
                    navigate(-1);
                  }, 100);
                })
                .catch((e) => {
                  showErrorMessage(getErrorMessage(e));
                  setSavingFactors(false);
                });
            }
            else {
              showSuccess();
              setTimeout(() => {
                navigate(-1);
              }, 100);
            }
          })
          .catch((e) => {
            showErrorMessage(getErrorMessage(e));
            setSavingIndexTable(false);
          });
      }
    },
  });

  const isIndexTableFormChanged = useMemo(() => !_.isEqual(
    initialIndexTableValues,
    formikIndexTableForm.values
  ), [
    formikIndexTableForm.values,
    initialIndexTableValues
  ]);

  const pageTitle = useMemo(() => {
    if (isAddNew) {
      return 'New Index Table';
    }
    return 'Index Table Details';
  }, [isAddNew])

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'IndexTableManagementDetails d-flex')
    }>
      <div className={classNames('d-flex flex-column flex-1', style.blockLeft)}>
        <Breadcrumb
          items={[
            {
              value: 'Index Table Collection Management',
              href: '/admin/schedule-collection-management?tab=Index+Table+Collections',
            },
            { value: pageTitle },
          ]}
          className={style.Breadcrumb}
        />
        {toastMessage?.visible && (
          <div className={style.toast}>
            <ToastMessage
              className='successful'
              status={toastMessage}
              visiableHandler={(value) =>
                setToastMessage({ ...toastMessage, visible: value })
              }
            />
          </div>
        )}
        <span className={style.textTitle}>{pageTitle}</span>

        <Divider className={style.divider1} />

        <div className='d-grid grid-4-columns gap-column-25 gap-row-20'>
          <FieldSelect
            labelText='Index Table Collection'
            options={indexCollectionOptions}
            selectId={indexCollectionOptions?.find(option => option.value == formikIndexTableForm?.values?.indexTableCollectionId)?.value ?? 0}
            onSelect={(value) => {
                formikIndexTableForm?.setFieldValue(
                'indexTableCollectionId',
                value?.value,
              )
            }}
            hideNullOption
            error={getFormErrorMessage('indexTableCollectionId')}
            isInReadOnlyMode={true}
          />
          <FieldInput
            labelText='Index Table Name'
            name='name'
            value={formikIndexTableForm?.values?.name ?? ''}
            onChange={(value) => {
              formikIndexTableForm?.setFieldValue('name', value);
            }}
            error={getFormErrorMessage('name')}
            isInReadOnlyMode={true}
          />
          <FieldInput
            type='number'
            labelText='Floor Factor'
            name='floorFactor'
            value={formikIndexTableForm?.values?.floorFactor ?? 0}
            onChange={(value) => {
              formikIndexTableForm?.setFieldValue('floorFactor', value);
            }}
            error={getFormErrorMessage('floorFactor')}
            isInReadOnlyMode={true}
          />
        </div>

        {
          !isAddNew && (
            <div>
              <Divider className={style.divider1} strokeWidth={1} />

              <span className={style.textTitle}>Index Factors</span>
              <IndexFactorManagementTable 
                indexTableId={_.toNumber(params.id)}
              />
            </div>
          )
        }
        

        <Divider className={style.divider1} strokeWidth={2} />

        <div className='d-flex gap-20'>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageAction}
            tooltipKey='Back'
          >
            <button
              className='secondary'
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
          </TooltipWrapper>
        </div>
      </div>

      <DiscardChangeConfirmModal
        initialData={1}
        inputData={isIndexTableFormChanged ? 2 : 1}
      />

      <Loader
        isOpen={
          fetchingIndexTableInfo ||
          fetchingIndexCollections ||
          savingIndexTable ||
          savingFactors
        }
      />
    </div>
  );
}

export default IndexTableManagementDetails;