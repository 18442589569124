import React, { useEffect } from "react";
import classNames from "classnames";
import style from "./FieldTagInput.module.scss";
import { uniq } from "lodash";

interface Props {
  labelText?: string;
  value: string[];
  onChange: (value: string[]) => void;
  classnames?: string;
  required?: boolean;
  tooltipText?: string;
  error?: string;
}

function FieldTagInput(props: Props) {
  const {
    labelText,
    classnames,
    required,
    tooltipText,
    value,
    onChange,
    error,
  } = props;

  const [tags, setTags] = React.useState<string[]>(value);
  const [inputValue, setInputValue] = React.useState<string>("");

  useEffect(() => {
    if (value) {
      setTags(value);
    }
  }, [value]);

  const handleDelete = (i: number) => {
    onChange([...tags].filter((_, index) => index !== i));
  };

  const handleAddition = (updated: string) => {
    const splittedValue = updated.split(",").map((x) => x.trim()).filter((x) => x.length > 0);
    onChange(uniq([...tags, ...splittedValue]));
  };

  return (
    <div className={classNames(style["form-control-container"])}>
      <label
        className={classNames(style["form-control"], classnames, {
          error: error,
        })}
      >
        {/* Lable of input,accept required and tooltip parameters */}
        <div className={classNames(style["label"])}>
          {labelText}
          {required && <i className={style["required"]}>*</i>}
          {tooltipText && (
            <i className={style["tooltip"]}>
              <span className={style["tip"]}>{tooltipText}</span>
            </i>
          )}
        </div>
        <div className={style["tag-container"]}>
          {tags.map((tag, index) => (
            <span className={style["tag"]} key={index}>
              {tag}
              <i className={style["close-icon"]} onClick={() => handleDelete(index)}>
              </i>
            </span>
          ))}
        </div>
        {error && (
          <span
            className={classNames(style["error-message"], {
              [style["long-message"]]: !/Empty/.test(error),
            })}
          >
            {error}
          </span>
        )}
      </label>
      <label
        className={classNames(style["form-control"], classnames, {
          error: error,
        })}
      >
        <div className={classNames(style["label"])}>Add Tag</div>
        <div className={style["input-control-wrapper"]}>
          <div className={classNames(style["input-control"])}>
            <input
              value={inputValue}
              onChange={(evt) => {
                setInputValue(evt.target.value);
              }}
              placeholder="Enter tag label, use comma to separate"
              onKeyUp={(e) => {
                if (inputValue && e.key === "Enter") {
                  setInputValue("");
                  handleAddition(inputValue);
                }
              }}
            />
          </div>
          <button
            type="button"
            className="default-button"
            disabled={!inputValue}
            onClick={() => {
              // ignore in coverage report because inputValue can not be null
              /* istanbul ignore else */
              if (inputValue) {
                setInputValue("");
                handleAddition(inputValue);
              }
            }}
          >
            Add
          </button>
        </div>
        {/* Error message if do not enter any text and if field is required */}
        {error && (
          <span
            className={classNames(style["error-message"], {
              [style["long-message"]]: !/Empty/.test(error),
            })}
          >
            {error}
          </span>
        )}
      </label>
    </div>
  );
}

export default FieldTagInput;
