import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import style from './FormManagementInfo.module.scss';
import { useAppSelector } from '../../redux/store';
import { SelectOption, State } from '../../interface';
import { FormikProps} from 'formik';
import FieldSelect from '../FieldSelect/FieldSelect';
import _ from 'lodash';
import Divider from '../Divider/Divider';
import FieldInput from '../FieldInput/FieldInput';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import { IBackendFormInfo } from '../../interface/IBackendFormInfo';
import { IFormManagementSearchData } from '../../interface/IFormManagementSearchData';
import { IFormManagementData } from '../../interface/IFormManagementData';
import { getSystemTaxYears } from '../../services';

interface Props {
  className?: string;
  formList: IBackendFormInfo[];
  formikEditForm?: FormikProps<IFormManagementData>;
  formikSearchForm?: FormikProps<IFormManagementSearchData>;
}

function FormManagementInfo(
  {
    className,
    formList,
    formikEditForm,
    formikSearchForm
  }: Props
) {
  const [taxYearOptions, setTaxYearOptions] = useState<SelectOption[]>([]);
  const formListOptions: SelectOption[] = useMemo(() => {
    const newOptions = (formList ?? []).map(item => ({
      value: item.id,
      label: item.name,
      name: item.name
    }))

    const selectedOption = _.find(newOptions, { value: formikSearchForm?.values.form });
    if (formikSearchForm?.values.form && !selectedOption) {
      formikSearchForm.setFieldValue(
        'form',
        null,
      )
    }

    return newOptions;
  }, [formList]);
  const stateInCorporations = useAppSelector(state => (state.states ?? []).map((o: State) => ({
    value: o.id,
    label: o.abbreviation,
    name: o.name,
  })));

  useEffect(() => {
    getSystemTaxYears()
      .then((res) => {
        setTaxYearOptions(res.data.map(item => ({
          value: item.taxYear,
          label: `${item.taxYear}`,
          name: `${item.taxYear}`
        })));
      })
      .catch(() => {
        console.log('fetchTaxYearFail');
      });
  }, []);

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'FormManagementSearch d-flex flex-column'
      )
    }>
      <div className='d-flex align-items-end gap-10 full-width'>
        <FieldSelect
          labelText='Tax Year'
          options={taxYearOptions}
          selectId={formikSearchForm?.values.taxYear}
          onSelect={(value) => {
            formikSearchForm?.setFieldValue(
              'taxYear',
              value?.value,
            )
          }}
          classnames='flex-1'
          hideNullOption
        />

        <FieldSelect
          labelText='State'
          options={stateInCorporations}
          selectId={formikSearchForm?.values.state}
          onSelect={(value) => {
            formikSearchForm?.setFieldValue(
              'state',
              value?.value,
            )
          }}
          classnames='flex-1'
          hideNullOption
        />

        <FieldSelect
          labelText='Form (form list based on tax year and state selected)'
          options={formListOptions}
          selectId={formikSearchForm?.values.form}
          onSelect={(value) => {
            formikSearchForm?.setFieldValue(
              'form',
              value?.value,
            )
          }}
          classnames={style.blockColumn3}
          hideNullOption
        />
      </div>

      <Divider className={style.divider1} />

      {formikSearchForm?.values.form && (
        <div className='d-flex align-items-end gap-10 full-width'>
          <FieldInput
            labelText='Form Name'
            name='formName'
            value={formikEditForm?.values.formName ?? ''}
            onChange={(value) => {
              formikEditForm?.setFieldValue('formName', value);
            }}
            classnames='flex-1'
          />
          <FieldInput
            labelText='Tax Form Catalog ID'
            name='taxFormCatalogId'
            placeholder='6 digits'
            value={formikEditForm?.values.taxFormCatalogId ?? ''}
            onChange={(value) => {
              formikEditForm?.setFieldValue('taxFormCatalogId', value);
            }}
            classnames='flex-1'
          />

          <CustomCheckbox
            label='Set Form as Default for State'
            value={formikEditForm?.values.setFormAsDefaultForState ?? false}
            onChange={(value) =>
              formikEditForm?.setFieldValue(
                'setFormAsDefaultForState',
                value,
              )
            }
            classnames={classNames(style.blockColumn3, style.CustomCheckbox)}
          />
        </div>
      )}
    </div>
  );
}

export default FormManagementInfo;