import classNames from 'classnames';
import React, {Fragment, useMemo, useState} from 'react';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../enums';
import style from './TableMenu.module.scss';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingOverlay,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import {flatMap} from 'lodash';
import {LoaderIndicator} from '../Loader/Loader';

export interface MenuItem {
  variant: 'default' | 'primary' | 'secondary';
  tooltip?: {
    section: TOOLTIP_SECTIONS;
    keyword: string;
  };
  label: string;
  disabled?: boolean;
  onClick?: (helpers: {changeShowState: (show: boolean) => void}) => void;
  loading?: boolean;
}

export interface Props {
  className?: string;
  toggle: React.ReactElement;
  menuItems: MenuItem[][];
}

function TableMenu({className, toggle, menuItems}: Props) {
  const [open, setOpen] = useState(false);

  const {x, y, reference, floating, strategy, context} = useFloating({
    open,
    strategy: 'fixed',
    placement: 'bottom-end',
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip({padding: 8}),
      size({
        apply({availableHeight, elements}) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          });
        },
      }),
      offset(({rects}) => ({
        mainAxis: -rects.reference.height,
      })),
    ],
  });

  const {getReferenceProps, getFloatingProps} = useInteractions([
    useClick(context),
    useRole(context, {role: 'menu'}),
    useDismiss(context),
  ]);

  const isEmpty = useMemo(() => !flatMap(menuItems).length, [menuItems]);

  return (
    <div
      className={classNames(
        style['table-menu'],
        className,
        isEmpty && 'is-empty',
      )}
    >
      {React.cloneElement(toggle, {
        ...getReferenceProps({
          ref: reference,
        }),
        onClick: () => {
          setOpen(true);
        },
      })}
      {open && (
        <FloatingOverlay lockScroll className={style['floating-overlay']}>
          <FloatingFocusManager context={context} preventTabbing>
            <div
              {...getFloatingProps({
                ref: floating,
                className: style['menu'],
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  overflow: 'auto',
                },
              })}
            >
              {menuItems.map((group, groupIndex) => (
                <Fragment key={groupIndex}>
                  {groupIndex > 0 && <hr className={style['divider']} />}
                  <div className={style['menu-item-group']}>
                    {group.map((item, itemIndex) => {
                      const btn = (
                        <button
                          key={itemIndex}
                          role='menu-item'
                          type='button'
                          className={classNames(
                            style['menu-item'],
                            style[item.variant],
                          )}
                          disabled={item.loading || item.disabled}
                          onClick={() => {
                            setOpen(false);
                            item.onClick?.call(undefined, {
                              changeShowState: setOpen,
                            });
                          }}
                        >
                          {item.label}
                          {item.loading && (
                            <LoaderIndicator
                              className='button-loading'
                              loading={true}
                            />
                          )}
                        </button>
                      );

                      return item.tooltip ? (
                        <div key={itemIndex}>
                          <TooltipWrapper
                            key={itemIndex}
                            tooltipSection={item.tooltip?.section}
                            tooltipKey={item.tooltip?.keyword}
                          >
                            {btn}
                          </TooltipWrapper>
                        </div>
                      ) : (
                        btn
                      );
                    })}
                  </div>
                </Fragment>
              ))}
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </div>
  );
}

export default TableMenu;
