import React, {useState, useRef} from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import OutsideClickHandler from 'react-outside-click-handler';
import style from './FieldInputWithOptions.module.scss';
import {SelectOption} from '../../interface';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import { TOOLTIP_SECTIONS } from '../../enums';

interface Props {
  labelText: string;
  value: string;
  onChange: (value: string) => void;
  classnames?: string;
  required?: boolean;
  tooltipText?: string;
  disabled?: boolean;
  error?: string;
  name?: string;
  maxLength?: number;
  recommended?: boolean;
  decimal?: boolean;
  placeholder?: string;
  options: SelectOption[];
}

function FieldInputWithOptions(props: Props) {
  const {
    labelText,
    classnames,
    required,
    tooltipText,
    value,
    onChange,
    disabled,
    error,
    name,
    maxLength,
    recommended,
    decimal,
    placeholder,
    options,
  } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const ref = useRef(null);
  return (
    // If validate false, pass the error to here to show error style
    <label
      className={classNames(style['form-control'], classnames, {error: error})}
    >
      {/* Lable of input,accept required and tooltip parameters */}
      <div
        className={classNames(style['label'], {
          [style['label-recommended']]: recommended,
        })}
      >
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.Common}
          tooltipKey={tooltipText ? tooltipText : labelText}
        >
          <span>
            {labelText}
            {required && <i className={style['required']}>*</i>}
          </span>
        </TooltipWrapper>
        {recommended && <small>Recommended</small>}
      </div>
      <OutsideClickHandler
        onOutsideClick={() => {
          setVisible(false);
        }}
      >
        {/* If disabled the input, add disabled parameter here */}
        <div
          className={classNames(style['input-control'], {
            disabled: disabled,
          })}
        >
          <input
            type='text'
            name={name}
            value={value ? value : ''}
            onChange={(evt) => {
              if (decimal) {
                if (evt.target.value) {
                  if (/^\d+(\.\d*)?$/.test(evt.target.value)) {
                    onChange(evt.target.value);
                  }
                } else {
                  onChange(evt.target.value);
                }
              } else {
                onChange(evt.target.value);
              }
            }}
            disabled={disabled}
            placeholder={placeholder ? placeholder : ''}
            maxLength={maxLength}
            onFocus={() => setVisible(true)}
            ref={ref}
          />
          {visible &&
            !!_.filter(options, (option) =>
              _.includes(_.lowerCase(option.label), _.lowerCase(value)),
            ).length && (
              <div className={style['options']}>
                <ul>
                  {_.filter(options, (option) =>
                    _.includes(_.lowerCase(option.label), _.lowerCase(value)),
                  ).map((item, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        onChange(item.label);
                        setTimeout(() => {
                          ref.current && (ref.current as HTMLElement).blur();
                          setVisible(false);
                        }, 0);
                      }}
                    >
                      {item.label}
                    </li>
                  ))}
                </ul>
              </div>
            )}
        </div>
      </OutsideClickHandler>
      {/* Error message if do not enter any text and if field is required */}
      {error && (
        <span
          className={classNames(style['error-message'], {
            [style['long-message']]: !/Empty/.test(error),
          })}
        >
          {error}
        </span>
      )}
    </label>
  );
}

export default FieldInputWithOptions;
