import { ReactElement } from "react";
import { EditConfig } from "./Editor/Editor";

export type ColumnOrder = { [keyOrProp: string]: "asc" | "desc" };

export type ColumnFilters = {
  [keyOrProp: string]: { label: string | string[]; value: unknown };
};

export type CellValue = string | number | null;

export interface ColumnPropsBase<T> {
  label: string | string[];
  id?: string;
  sortable?: boolean;
  sortKey?: string;
  editable?: boolean;
  editor?: EditConfig & {
    error?: (row: T, rowId: string | number) => string | null;
    disabled?: boolean;
  };
  filterable?: boolean | Partial<EditConfig>;
  // eslint-disable-next-line
  onChange?: (newValue: any, row: T) => void;
  // eslint-disable-next-line
  children?: (value: any, row: T) => React.ReactNode;
  hidden?: boolean | "always";
  cssClasses?: string;
}

export interface PropertyColumnProps<T, K extends keyof T = keyof T>
  extends ColumnPropsBase<T> {
  prop: K | string;
}

export interface AccessorColumnProps<T, TRes> extends ColumnPropsBase<T> {
  accessor: (row: T) => TRes;
}

export interface DisplayColumnProps<T>
  extends Omit<ColumnPropsBase<T>, "children"> {
  id: string;
  children?: (row: T) => React.ReactNode;
}

export type ColumnProps<T> =
  | PropertyColumnProps<T>
  | AccessorColumnProps<T, CellValue>
  | DisplayColumnProps<T>;

export type ColumnConfig<T> =
  | ReactElement<PropertyColumnProps<T>>
  | ReactElement<AccessorColumnProps<T, CellValue>>
  | ReactElement<DisplayColumnProps<T>>;

// eslint-disable-next-line
export default function Column<T>(_props: ColumnProps<T>) {
  return null; // Just for config, no content
}
