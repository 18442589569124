import { FormikValues } from "formik";
import _ from "lodash";

function removeEmpty<T>(v: T) {
  if (_.isString(v)) {
    return _.isEmpty(v);
  }
  if (_.isNull(v)) {
    return true;
  }
  if (_.isUndefined(v)) {
    return true;
  }
}

const removeEmptyField = (values: FormikValues) => {
  return _.map(values, (value) => {
    const result = _.omitBy(value, (v) => {
      return removeEmpty(v);
    });
    return _.mapValues(result, (v) => {
      if (_.isPlainObject(v)) {
        return _.omitBy(v, (a) => {
          return removeEmpty(a);
        });
      }
      if (_.isArray(v)) {
        return _.map(v, (entry) =>
          _.omitBy(entry, (entryVal) => {
            return removeEmpty(entryVal);
          })
        );
      }
      return v;
    });
  });
};

export default removeEmptyField;
