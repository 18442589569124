import classNames from 'classnames';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import style from './IndexTableManagementTable.module.scss';
import InitTable, { Query } from '../Table/Table';
import { Link, useParams } from 'react-router-dom';
import { getIndexTables } from '../../services';
import { IndexTable, IToastMessage } from '../../interface';
import _ from 'lodash';


const { Table, Column, TopBar } = InitTable<IndexTable>();

interface Props {
  className?: string;
  setToastMessage?: React.Dispatch<React.SetStateAction<IToastMessage | undefined>>;
}


function IndexTableManagementTable(props: Props) {
  const params = useParams();
  const [indexTables, setIndexTables] = useState<IndexTable[]>([])
  
  //Pagination
  const [total, setTotal] = useState<number>(0);
  const [fetchingData, setFetchingData] = useState(false);
  const previousQuery = useRef<Query | undefined>();

  useEffect(() => {
    fetchData(previousQuery.current);
  }, [params.id]);

  const fetchData = useCallback((query?: Query) => {
    if (!params.id) {
      return;
    }

    previousQuery.current = query;
    setFetchingData(true);
    getIndexTables({
      indexTableCollectionId: _.toNumber(params.id),
      sortBy: _.last(_.keys(query?.order)),
      sortDirection: _.last(_.values(query?.order)),
      page: query?.pagination?.page ?? 1,
      perPage: query?.pagination?.pageSize ?? 10,
    })
      .then((res) => {
        setIndexTables(res.data.items);
        setTotal(res.data.total);
        setFetchingData(false);
      })
      .catch(() => {
        setFetchingData(false);
      });

  }, []);

  return (
    <div className={
      classNames(
        props.className,
        style['main-content'],
        'd-flex flex-column'
      )
    }>
      <Table
        id='IndexTableManagementTable'
        rows={indexTables}
        onQueryChanged={fetchData}
        paginate
        sortable
        totalRows={total}
        loading={fetchingData}
      >
        <TopBar>
          <h3 className={style.textTitle}>Index Tables</h3>
        </TopBar>
        {/* Schedule Name */}
        <Column
          label='Schedule Name'
          id='name'
        >
          {
            (row: IndexTable) => (
              <span><strong>{row.name}</strong></span>
            )
          }
        </Column>

        {/* Actions column  */}
        <Column
          id='actions'
          label='Actions'
          sortable={false}
        >
          {(data: IndexTable) => (
            <div className={classNames(style['operate'], 'd-flex')}>
              <Link
                to={`/admin/index-table-management/${data.id}/view`}
                className='view'
              />
            </div>
          )}
        </Column>
      </Table>
    </div>
  );
}

export default IndexTableManagementTable;