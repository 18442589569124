import { getDaysArray, getMonths } from './date';
import { SelectOption } from '../interface';
import _ from 'lodash';
import moment from 'moment';

export const getMonthsOptions = (): SelectOption[] => {
  return getMonths().map(month => {
    const name = moment().month(month - 1).format('MMMM');
    return ({
      value: month,
      label: name,
      name: name
    } as SelectOption)
  })
}

export const getInventoryOriginalCostRuleOptions = (): SelectOption[] => {
  return [
    {
      value: 'Monthly Average',
      label: 'Monthly Average',
      name: 'Monthly Average'
    } as SelectOption,
    ...getMonths().map(month => {
      const name = moment().month(month - 1).format('MMMM');
      const value = `${name.toLowerCase()}Amount`;

      return ({
        value: value,
        label: name,
        name
      } as SelectOption)
    })
  ]
}

export const getAcquisitionYearTypeOptions = (): SelectOption[] => {
  return [
    {
      value: 'Calendar Year',
      label: 'Calendar Year',
      name: 'Calendar Year'
    } as SelectOption,
    {
      value: 'Fiscal Year',
      label: 'Fiscal Year',
      name: 'Fiscal Year'
    } as SelectOption,
  ]
}

export const getDaysOptions = function (month: number, year: number | null | undefined) {
  return getDaysArray(month, year).map(date => ({
    value: date,
    label: `${date}`,
    name: `${date}`
  }))
}

export const getYesNoOptions = function (): SelectOption[] {
  return [
    {
      value: 'Yes',
      label: 'Yes',
      name: 'Yes',
    } as SelectOption,
    {
      value: 'No',
      label: 'No',
      name: 'No',
    } as SelectOption,
  ];
}

export const convertSelectObjectToMapping = (options: SelectOption[]): { [key: number | string]: string } => {
  return _.reduce(
    options,
    (r, n) => ({
      ...r,
      [n.value as (number | string)]: n.name
    }),
    {}
  );
}