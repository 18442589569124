import React from 'react';
import { Navigate } from 'react-router-dom';
import * as _ from 'lodash';

import { EUserRoles, USER_ROLES } from '../../enums';
import useUserProfile from '../../hook/useUserProfile';
import { BaseRoutes } from '../../interface';

interface Props {
  children: JSX.Element;
  route: BaseRoutes;
}

function RoleGuard({ children, route }: Props) {
  const userRoles = useUserProfile()?.roles;

  if (!userRoles?.length) {
    return <></>;
  }

  // is user is only system admin, they can only access admin route
  if (userRoles.length === 1 && userRoles[0] === EUserRoles.SystemAdministrator && route !== 'admin') {
    return <Navigate replace to="/admin" />;
  }

  // if the role is invalid, navigate to dashboard else render children
  const validateRoute = (validRole: EUserRoles | EUserRoles[]) => {
    const validRoles = _.isArray(validRole) ? validRole : [validRole];

    if (_.intersection(userRoles, validRoles).length > 0) {
      return children;
    } else {
      return <Navigate replace to="/dashboard" />;
    }
  }

  switch (route) {
    case 'properties':
      return validateRoute(EUserRoles.AccountPropertyModerator);

    case 'assets':
      return validateRoute(EUserRoles.AccountAssetModerator);

    case 'forms':
      return validateRoute(EUserRoles.AccountFormModerator);

    case 'returns':
      return validateRoute(EUserRoles.AccountReturnModerator);

    case 'reports':
      return validateRoute(EUserRoles.AccountReportModerator);

    case 'users':
    case 'plans':
    case 'taxyearselection':
      return validateRoute(EUserRoles.AccountAdministrator);

    case 'admin':
      return validateRoute(EUserRoles.SystemAdministrator);

    case 'dashboard':
    case 'help':
      return validateRoute(_.filter(USER_ROLES, role => role !== EUserRoles.SystemAdministrator));
  }
}

export default RoleGuard;
