import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ToastMessage, {
  ToastMessageRefType,
} from '../../../components/ToastMessage/ToastMessage';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../enums';
import {State, ReduxState} from '../../../interface';
import style from './Returns.module.scss';
import {connect} from 'react-redux';
import Tabs from '../../../components/Tabs/Tabs';
import ApproveAssets from '../../../components/ApproveAssets/ApproveAssets';
import FieldToggleCheckbox from '../../../components/FieldToggleCheckbox/FieldToggleCheckbox';
import { useAppDispatch } from '../../../redux/store';
import { setIncludeReturnsWithNoAssets } from '../../../redux/features/returns-slice';
import useIncludeReturnsWithNoAssets from '../../../hook/useIncludeReturnsWithNoAssets';

interface Props {
  states: State[];
}

function Returns(props: Props) {
  const {states} = props;
  const navigate = useNavigate();
  const toastRef = useRef<ToastMessageRefType | null>(null);

  const breadcrumb = [
    {
      value: 'Returns',
      href: '/returns',
    },
    {value: 'Manage Returns'},
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const stateIncludeReturnsWithNoAssets = useIncludeReturnsWithNoAssets();
  const RETURNSTYPE = ['Approve Assets', 'Preview Forms', 'File Packages'];
  const [type, setType] = useState<string>(RETURNSTYPE[0]);
  const [deadline, setDeadline] = useState<string>('');
  const [includeReturnsWithNoAsset, setIncludeReturnsWithNoAsset] = useState<boolean>(stateIncludeReturnsWithNoAssets);
  const dispatch = useAppDispatch();
  

  const setTab = (v: string) => {
    setSearchParams({tab: v});
  };

  useEffect(() => {
    if (searchParams.get('tab')) {
      setType(searchParams.get('tab') as string);
    } else {
      setType(RETURNSTYPE[0]);
    }

    if (searchParams.get('deadline')) {
      setDeadline(searchParams.get('deadline') as string);
    }
    else {
      setDeadline('');
    }
  }, [searchParams]);

  useEffect(() => {
    if (includeReturnsWithNoAsset !== stateIncludeReturnsWithNoAssets) {
      dispatch(setIncludeReturnsWithNoAssets(includeReturnsWithNoAsset));
    }
  }, [includeReturnsWithNoAsset]);

  return (
    <div className={style['main-content']}>
      {/* Braedcrumb */}
      <Breadcrumb items={breadcrumb} />
      <ToastMessage ref={toastRef} />
      <h2>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.PageTitle}
          tooltipKey='Manage Returns'
        >
          <span>Manage Returns</span>
        </TooltipWrapper>
      </h2>
      <div className={style['main']}>
        <FieldToggleCheckbox
          label='Include Returns with no assets'
          value={includeReturnsWithNoAsset}
          onChange={(value) => setIncludeReturnsWithNoAsset(value)}
          classnames={style['float-right']}
        />
        <Tabs
          tabs={RETURNSTYPE}
          selected={type}
          onSelect={(v) => {
            setTab(v);
          }}
        >
          <div className={style['tableContainer']}>
            <ApproveAssets states={states} type={type} toastRef={toastRef} key={type} deadline={deadline} includeReturnsWithNoAssets={includeReturnsWithNoAsset} />
          </div>
        </Tabs>
        <hr />
        <div className={style['buttons']}>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageAction}
            tooltipKey='Return to dashboard'
          >
            <button
              type='button'
              className='primary'
              onClick={() => navigate('/dashboard')}
            >
              Return to dashboard
            </button>
          </TooltipWrapper>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  states: state.states,
});

export default connect(mapStateToProps)(Returns);
