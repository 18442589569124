import React, { MutableRefObject } from 'react';
import {Link} from 'react-router-dom';
import { TOOLTIP_SECTIONS } from '../../enums';
import DropZone from '../Dropzone/Dropzone';
import { ToastMessageRefType } from '../ToastMessage/ToastMessage';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import style from './AddProperty.module.scss';

interface Props {
  uploadError: (value: string) => void;
  uploadSuccess: () => void;
  toastRef: MutableRefObject<ToastMessageRefType | null>;
}

function AddProperty(props: Props) {
  const {uploadError, toastRef, uploadSuccess} = props;
  return (
    <div className={style['blocks']}>
      <Link
        role='button'
        className={style['add-property-block']}
        to='/properties/propertySetup'
      >
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.PageAction}
          tooltipKey='Click here to add property manually'
        >
          <span>Click here to add property manually</span>
        </TooltipWrapper>
      </Link>
      <div className={style['column']}>
        <DropZone toastRef={toastRef} uploadSuccess={uploadSuccess} uploadError={uploadError} />
      </div>
    </div>
  );
}

export default AddProperty;
