import { FormikValues } from "formik";
import { MaintenanceNotificationResponse, State } from '../interface';

const actionTypes = {
  SET_UPLOADFILE: 'SET_UPLOADFILE',
  SET_STATES: 'SET_STATES',
  SET_MAINTENANCENOTIFICATIONS: 'SET_MAINTENANCENOTIFICATIONS',
  SET_UPLOADASSETSFILE: 'SET_UPLOADASSETSFILE',
  SET_PROPERTIES: 'SET_PROPERTIES',
};

const actions = {
  setUploadFile: (uploadFile: File | undefined) => {
    return {type: 'SET_UPLOADFILE', uploadFile};
  },
  setStates: (states: State[]) => {
    return {type: 'SET_STATES', states};
  },
  setMaintenanceNotifications: (maintenanceNotifications: MaintenanceNotificationResponse) => {
    return {type: 'SET_MAINTENANCENOTIFICATIONS', maintenanceNotifications};
  },
  setUploadAssetsFile: (uploadAssetsFile: File | undefined) => {
    return {type: 'SET_UPLOADASSETSFILE', uploadAssetsFile};
  },
  setProperties: (properties: FormikValues | undefined) => {
    return {type: 'SET_PROPERTIES', properties}
  }
};

export {actionTypes};
export default actions;
