import { useEffect } from 'react';
import { getTooltipsAction, AsyncStatus } from "../redux/features/admin-slice";
import { RootState, useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";

function useTooltips() {
  const dispatch = useAppDispatch();

  const fetchTooltipsStatus: AsyncStatus = useSelector(
    (state: RootState) => state.admin.fetchTooltipsStatus
  );

  useEffect(() => {
    if (fetchTooltipsStatus === 'idle' || fetchTooltipsStatus === 'rejected') {
      dispatch(getTooltipsAction({page: ''}));
    }
  }, []);
}

export default useTooltips;
