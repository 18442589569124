import { Dialog } from "@avalara/skylab-react";
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import styles from "./BaseModal.module.scss";

export interface BaseModalProps {
  className?: string;
  isOpen: boolean;
  onRequestClose?: () => void;
}

function BaseModal({
  className,
  isOpen,
  onRequestClose,
  children,
}: PropsWithChildren<BaseModalProps>) {
  return (
    <>
      <Dialog
        open={isOpen}
        className={classNames(className, styles.container)}
        onSDismiss={onRequestClose}
        noScroll={false}
      >
        <div slot="body" className={classNames("modal-body")}>
          {children}
        </div>
      </Dialog>
      {isOpen && <div className={styles.backdrop}></div>}
    </>
  );
}

export default BaseModal;
