import React, { useEffect, useMemo, useState } from 'react';
import style from './AssessorMissingInformation.module.scss';
import RightNavigationPanel from '../RightNavigationPanel/RightNavigationPanel';
import IconAlert from '../../images/icon-alert-triangle-grayscaleDarkest-16x16.svg';
import Divider from '../Divider/Divider';
import FieldInput from '../FieldInput/FieldInput';
import { FormikProps, FormikValues, useFormik } from 'formik';
import classNames from 'classnames';
import { TOOLTIP_SECTIONS } from '../../enums';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import _ from 'lodash';
import FieldSelect from '../FieldSelect/FieldSelect';
import { useAppSelector } from '../../redux/store';
import { SelectOption, State } from '../../interface';
import FieldDate from '../FieldDate/FieldDate';
import { getMonthsOptions, getInventoryOriginalCostRuleOptions, getYesNoOptions, getDaysOptions, getAcquisitionYearTypeOptions } from '../../utils/selectionOptions';
import { IAssessorMissingInformationFormData } from '../../interface/IAssessorMissingInformationFormData';
import validateEmpty from '../../utils/validateEmpty';

const monthOptions: SelectOption[] = getMonthsOptions();
const inventoryOriginalCostRuleOptions: SelectOption[] = getInventoryOriginalCostRuleOptions();
const acquisitionYearTypeOptions: SelectOption[] = getAcquisitionYearTypeOptions();
const yesNoOptions = getYesNoOptions();

interface Props {
  className?: string;
  onSave?: (
    newValue: IAssessorMissingInformationFormData,
    requiredFields: {
      [field: string]: 'Section1' | 'Section2'
    }
  ) => void;
  onDiscard?: () => void;
  initialMissingFormData: IAssessorMissingInformationFormData;
  year: number | null | undefined;
}

const Section1Fields = [
  'name',
  'title',
  'state',
  'mailingAddressLine1',
  'mailingAddressCity',
  'mailingAddressState',
  'mailingAddressZip'
];

function AssessorMissingInformation({
  className,
  onSave,
  onDiscard,
  initialMissingFormData,
  year,
}: Props) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const stateInCorporations = useAppSelector(state => (state.states ?? []).map((o: State) => ({
    value: o.id,
    label: o.abbreviation,
    name: o.name,
  })));
  const [initialValues, setInitialValues] = useState<IAssessorMissingInformationFormData>({});
  const formik: FormikProps<IAssessorMissingInformationFormData> = useFormik<FormikValues>({
    initialValues,
    enableReinitialize: true,
    validate: (values: IAssessorMissingInformationFormData) => {
      const requiredErrors = validateEmpty(_.pick(values, [
        'name',
        'title',
        'state',
        'mailingAddressLine1',
        'mailingAddressCity',
        'mailingAddressState',
        'mailingAddressZip',
        'assessmentDateMonth',
        'assessmentDateDate',
        'assessmentRatio',
        'returnDeadline',
        'extensionOffered',
        'acquisitionYearType',
      ]));

      let extraRequireErrors
      if (
        values.extensionOffered === 'Yes'
      ) {
        extraRequireErrors = validateEmpty(_.pick(values, [
          'extensionFillingDeadline',
          'returnDeadlineIfExtended',
        ]));
      }
      return _.defaults(requiredErrors, extraRequireErrors, parseFloat(values.assessmentRatio ?? '0') > 0 ? null : {
        assessmentRatio: 'Must be a positive number',
      });
    },
    onSubmit: (values) => {
      onSave?.(
        values,
        requiredFields
      );
    },
  });
  const [requiredFields, setRequiredFields] = useState<{
    [field: string]: 'Section1' | 'Section2'
  }>({});
  const [shouldShowSection1, setShouldShowSection1] = useState(false)
  const [shouldShowSection2, setShouldShowSection2] = useState(false)

  const isFormChanged = useMemo(() => !_.isEqual(
    initialValues,
    formik.values
  ), [formik.values, initialValues]);

  const dateOptions = useMemo(() => {
    const month = formik.values.assessmentDateMonth;
    const options = getDaysOptions(month ?? 2, year);
    if (formik.values.assessmentDateDate) {
      const selectedOption = _.find(options, { value: formik.values.assessmentDateDate });
      if (!selectedOption) {
        formik?.setFieldValue(
          'assessmentDateDate',
          null,
        );
      }
    }
    return options;
  }, [
    formik.values.assessmentDateMonth,
    year
  ]);

  useEffect(() => {
    setInitialValues(initialMissingFormData);
    const newRequireFields: {
      [field: string]: 'Section1' | 'Section2'
    } = {};
    _.forOwn(initialMissingFormData, (v, k) => {
      if (!v) {
        newRequireFields[k] = Section1Fields.indexOf(k) >= 0 ? 'Section1' : 'Section2';
        if (!shouldShowSection1 && newRequireFields[k] === 'Section1') {
          setShouldShowSection1(true);
        }
        if (!shouldShowSection2 && newRequireFields[k] === 'Section2') {
          setShouldShowSection2(true);
        }
      }
    });
    setRequiredFields(newRequireFields);
  }, [
    initialMissingFormData,
  ]);

  const getErrorMessage = (field: keyof IAssessorMissingInformationFormData) => {
    return isSubmitted ? formik.errors?.[field] : undefined;
  }

  const section1UI = () => {
    return (<div className='d-flex flex-column'>
      <span className={style.textTitle}>SECTION 1</span>
      <div className='d-flex flex-column gap-20'>
        {requiredFields.name && (<FieldInput
          labelText='Assessor Name'
          name='name'
          value={formik.values.name ?? ''}
          onChange={(value) => {
            formik.setFieldValue('name', value);
          }}
          error={getErrorMessage('name')}
        />)}
        {requiredFields.title && (<FieldInput
          labelText='Assessor Title'
          name='title'
          value={formik.values.title ?? ''}
          onChange={(value) => {
            formik.setFieldValue('title', value);
          }}
          error={getErrorMessage('title')}
        />)}
        {requiredFields.state && (<FieldSelect
          labelText='State'
          options={stateInCorporations}
          selectId={formik.values.state}
          onSelect={(value) => {
            formik.setFieldValue(
              'state',
              value?.value,
            )
          }}
          hideNullOption
          error={getErrorMessage('state')}
        />)}
        {requiredFields.mailingAddressLine1 && (<FieldInput
          labelText='Mailing Address - Line 1'
          name='mailingAddressLine1'
          value={formik.values.mailingAddressLine1 ?? ''}
          onChange={(value) => {
            formik.setFieldValue('mailingAddressLine1', value);
          }}
          error={getErrorMessage('mailingAddressLine1')}
        />)}
        {requiredFields.mailingAddressCity && (<FieldInput
          labelText='Mailing Address - City'
          name='mailingAddressCity'
          value={formik.values.mailingAddressCity ?? ''}
          onChange={(value) => {
            formik.setFieldValue('mailingAddressCity', value);
          }}
          error={getErrorMessage('mailingAddressCity')}
        />)}
        {requiredFields.mailingAddressState ? (<FieldSelect
          labelText='Mailing Address - State'
          options={stateInCorporations}
          selectId={formik.values.mailingAddressState}
          onSelect={(value) => {
            formik.setFieldValue(
              'mailingAddressState',
              value?.value,
            )
          }}
          hideNullOption
          error={getErrorMessage('mailingAddressState')}
        />) : null}
        {requiredFields.mailingAddressZip && (<FieldInput
          labelText='Mailing Address - ZIP'
          name='mailingAddressZip'
          value={formik.values.mailingAddressZip ?? ''}
          onChange={(value) => {
            formik.setFieldValue('mailingAddressZip', value);
          }}
          error={getErrorMessage('mailingAddressZip')}
        />)}
      </div>
    </div>);
  }

  const section2UI = () => {
    return (<div className='d-flex flex-column'>
      <span className={style.textTitle}>SECTION 2</span>
      <div className='d-flex flex-column gap-20'>
        {requiredFields.assessmentDateMonth && (<FieldSelect
          labelText='Assessment Date - Month'
          options={monthOptions}
          selectId={formik.values.assessmentDateMonth}
          onSelect={(value) => {
            const newMonth = value?.value as number;
            formik.setFieldValue(
              `assessmentDateMonth`,
              newMonth,
            );
          }}
          hideNullOption
          error={getErrorMessage('assessmentDateMonth')}
        />)}
        {requiredFields.assessmentDateDate && (<FieldSelect
          labelText='Assessment Date - Day'
          options={dateOptions}
          selectId={formik.values.assessmentDateDate}
          onSelect={(value) => {
            formik.setFieldValue(
              `assessmentDateDate`,
              value?.value,
            )
          }}
          hideNullOption
          error={getErrorMessage('assessmentDateDate')}
        />)}
        {requiredFields.inventoryOriginalCostRule && (<FieldSelect
          labelText='Inventory Original Cost Rule'
          options={inventoryOriginalCostRuleOptions}
          selectId={formik.values.inventoryOriginalCostRule}
          onSelect={(value) => {
            formik.setFieldValue(
              `inventoryOriginalCostRule`,
              value?.value,
            )
          }}
          hideNullOption
          error={getErrorMessage('inventoryOriginalCostRule')}
        />)}
        {requiredFields.assessmentRatio && (<FieldInput
          labelText='Assessment Ratio %'
          name='assessmentRatio'
          value={formik.values.assessmentRatio ?? ''}
          onChange={(value) => {
            formik.setFieldValue(`assessmentRatio`, parseFloat(value));
          }}
          decimal
          error={getErrorMessage('assessmentRatio')}
        />)}
        {requiredFields.returnDeadline && (<FieldDate
          labelText='Return Deadline'
          primaryFormat='M/D/YYYY'
          selectValue={formik.values.returnDeadline ?? ''}
          onSelect={(value) => {
            if (`${value}`.indexOf('Invalid') >= 0) {
              formik.setFieldValue(`returnDeadline`, '')
            } else {
              formik.setFieldValue(`returnDeadline`, value)
            }
          }}
          formatOutput
          error={getErrorMessage('returnDeadline')}
        />)}
        {requiredFields.extensionOffered && (<FieldSelect
          labelText='Extension Offered'
          options={yesNoOptions}
          selectId={formik.values.extensionOffered}
          onSelect={(value) => {
            formik.setFieldValue(
              `extensionOffered`,
              value?.value,
            )
          }}
          hideNullOption
          error={getErrorMessage('extensionOffered')}
        />)}
        {formik.values.extensionOffered === 'Yes' && (<>
          {requiredFields.extensionFillingDeadline && (<FieldDate
            labelText='Extension Filling Deadline'
            primaryFormat='M/D/YYYY'
            selectValue={formik.values.extensionFillingDeadline ?? ''}
            onSelect={(value) => {
              if (`${value}`.indexOf('Invalid') >= 0) {
                formik.setFieldValue(`extensionFillingDeadline`, '')
              } else {
                formik.setFieldValue(`extensionFillingDeadline`, value)
              }
            }}
            formatOutput
            error={getErrorMessage('extensionFillingDeadline')}
          />)}
          {requiredFields.returnDeadlineIfExtended && (<FieldDate
            labelText='Return Deadline If Extended'
            primaryFormat='M/D/YYYY'
            selectValue={formik.values.returnDeadlineIfExtended ?? ''}
            onSelect={(value) => {
              if (`${value}`.indexOf('Invalid') >= 0) {
                formik.setFieldValue(`returnDeadlineIfExtended`, '')
              } else {
                formik.setFieldValue(`returnDeadlineIfExtended`, value)
              }
            }}
            formatOutput
            error={getErrorMessage('returnDeadlineIfExtended')}
          />)}
          {requiredFields.acquisitionYearType && (<FieldSelect
          labelText='Acquisition Year Type'
          options={acquisitionYearTypeOptions}
          selectText={formik.values.acquisitionYearType ?? ''}
          onSelect={(value) => {
            formik.setFieldValue(
              `acquisitionYearType`,
              value?.value,
            )
          }}
          hideNullOption
          error={getErrorMessage('acquisitionYearType')}
        />)}
        </>)}
      </div>
    </div>);
  }

  return (
    <RightNavigationPanel
      icon={<div
        className={style.blockIconWarning}
      >
        <img src={IconAlert} alt="" />
      </div>}
      title="Missing Information"
      className={classNames(style['main-content'], className)}
    >
      {shouldShowSection1 ? section1UI() : null}

      {shouldShowSection2 ? section2UI() : null}

      <Divider className={style.divider1} />

      <div className='d-flex gap-20'>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.PageAction}
          tooltipKey='Save'
        >
          <button
            className='primary'
            disabled={!isFormChanged}
            onClick={() => {
              setIsSubmitted(true);
              formik.submitForm();
            }}
          >
            Save
          </button>
        </TooltipWrapper>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.PageAction}
          tooltipKey='Discard'
        >
          <button
            className='secondary'
            onClick={onDiscard}
          >
            Discard
          </button>
        </TooltipWrapper>
      </div>

    </RightNavigationPanel >
  );
}

export default AssessorMissingInformation;