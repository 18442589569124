import { FormikValues } from 'formik';
import _ from 'lodash';

const validateEmail = (values: FormikValues) => {
  const errors = {};
  _.each(values, (v, k) => {
    if (_.isString(v)) {

      if (!String(v)
        .toLowerCase()
        .match(
          /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
        )) {
        _.assign(errors, { [k]: `Invalid email` });
      }
    }
  });
  if (!_.isEmpty(errors)) {
    return errors;
  } else {
    return;
  }
};

export default validateEmail;
