/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useImperativeHandle, useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { isEqual, isBoolean } from "lodash";
import { useBlocker } from '../../hook/useBlocker';
import ModalContext from '../../context/ModalContext';
import { NextLocation } from "../../interface";
import Modal from '../../components/Modal/Modal';
import TooltipWrapper from '../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../enums';

export interface DiscardChangeConfirmModalProps {
  initialData: any;
  inputData: any;
  className?: string;
  onConfirm?: () => void;
  navBlock?: boolean;
  innerModal?: boolean;
}

export interface DiscardChangeConfirmModalMethods {
  openConfirmModal: () => void;
}

const DiscardChangeConfirmModal = forwardRef(({
  initialData,
  inputData,
  className,
  onConfirm,
  navBlock,
  innerModal = false,
}: DiscardChangeConfirmModalProps, ref) => {
  const navigate = useNavigate();
  const location = useLocation();

  //Leave Guard
  const [nextPathName, setNextPathName] = useState<string | null>();
  const {state, dispatch} = useContext(ModalContext);
  const [block, setBlock] = useState(false);
  const [quitConfirm, setQuitConfirm] = useState(false);
  const nav = useCallback((nextLocation: NextLocation) => {
    if (!isEqual(nextLocation.location.pathname, location.pathname)) {
      setNextPathName(nextLocation.location?.pathname + nextLocation.location?.search);
      dispatch({type: 'OPEN', href: 'confirm-leave'});
    }
  }, []);

  useEffect(() => {
    setBlock(!isEqual(initialData, inputData));
  }, [initialData, inputData]);

  useBlocker(nav, !quitConfirm && (isBoolean(navBlock) ? navBlock : block));

  useImperativeHandle(ref, () => ({
    openConfirmModal: () => {
      if (block) {
        dispatch({type: 'OPEN', href: 'confirm-leave'});
      } else {
        onConfirm && onConfirm();
      }
    },
  }), [block]);

  const confirmRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    confirmRef.current && confirmRef.current.blur();
  }, [state]);


  return (
    <Modal
      title='Confirmation'
      className={className}
      body={<p>There are unsaved changes, do you want to discard?</p>}
      isOpen={state.isOpen && state.href === 'confirm-leave'}
      innerModal={innerModal}
      footer={
        <div className='buttons'>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.Modal}
            tooltipKey='Confirmation Confirm'
          >
            <button
              className='primary'
              ref={confirmRef}
              onClick={() => {
                setQuitConfirm(true);
                dispatch({type: 'CLOSE'});
                setTimeout(() => {
                  onConfirm && onConfirm();
                  nextPathName && navigate(nextPathName);
                }, 100);
              }}
            >
              Confirm
            </button>
          </TooltipWrapper>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.Modal}
            tooltipKey='Confirmation Cancel'
          >
            <button
              className='secondary'
              onClick={() => dispatch({type: 'CLOSE'})}
            >
              Cancel
            </button>
          </TooltipWrapper>
        </div>
      }
    />
  );
})

DiscardChangeConfirmModal.displayName = 'DiscardChangeConfirmModal';

export default DiscardChangeConfirmModal;
