import {last, keys, values, Dictionary} from 'lodash';
import React, {useContext, useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {InputType} from '../../../components/Table/components/Editor/Editor';
import InitTable, {Query} from '../../../components/Table/Table';
import {IToastMessage, ReportItem} from '../../../interface';
import {deleteReport, deleteReports, downloadGeneralReport, getErrorMessage, getGeneralReports} from '../../../services';
import {formatDate} from '../../../utils/stringUtil';
import exportXLSX from '../../../utils/exportFile';
import style from './Reports.module.scss';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../enums';
import { LoaderIndicator } from '../../../components/Loader/Loader';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ModalContext from '../../../context/ModalContext';
import Modal from '../../../components/Modal/Modal';
import ToastMessage, { ToastMessageRefType } from '../../../components/ToastMessage/ToastMessage';

const {Table, Column, TopBar} = InitTable<ReportItem>();

const breadcrumbs = [{
  value: "Reports",
  href: "#",
}];

function Reports() {
  const navigate = useNavigate();

  const [items, setItems] = useState<ReportItem[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [fetchingData, setFetchingData] = useState(false);
  const [downloading, setDownloading] = useState<{[key: number]: boolean}>({});
  const [reportToDelete, setReportToDelete] = useState<ReportItem>();
  const [deletingReport, setDeletingReport] = useState<boolean>(false);
  const {state, dispatch} = useContext(ModalContext);
  const ref = useRef<HTMLButtonElement>(null);
  const toastRef = useRef<ToastMessageRefType | null>(null);
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [query, setQuery] = useState<Query>({});
  const [selected, setSelected] = useState<ReportItem[]>([]);

  const fetchData = (query?: Query) => {
    setFetchingData(true);
    setQuery(query ?? {});
    getGeneralReports({
      page: query?.pagination?.page ?? 1,
      perPage: query?.pagination?.pageSize ?? 10,
      sortBy: last(keys(query?.order)),
      sortDirection: last(values(query?.order)),
      text: query?.search,
      dateRange: query?.filter?.createdAt as string[],
      // eslint-disable-next-line
    }).then((res: any) => {
      setItems(res.data.items);
      setTotal(res.data.total);
      setSelected([]);
      setFetchingData(false);
    }).catch(() => {
      setFetchingData(false);
    });
  };

  const openConfirmDeleteModal = () => {
    dispatch({type: 'OPEN', href: 'comfirm'});
  };

  const confirmDelete = (e: React.MouseEvent) => {
    setDeletingReport(true);

    (reportToDelete
      ? deleteReport(reportToDelete.id)
      : deleteReports(selected.map((x) => x.id).join(','))
    )
      .then((response) => {
        setDeletingReport(false);
        dispatch({type: 'CLOSE'});
        const errorMessage = getErrorMessage(response);
        if (errorMessage) {
          toastRef.current?.showErrorToast(errorMessage);
        } else {
          toastRef.current?.showSuccessToast('Delete successful');
          return fetchData(query);
        }
      })
      .catch((err) => {
        toastRef.current?.showErrorToast(`An error occurred, please try again later.`);
        setDeletingReport(false);
        dispatch({type: 'CLOSE'});
      });

    e.stopPropagation();
  };

  return (
    <div className={style['main-content']}>
      <Breadcrumb items={breadcrumbs} />
      <ToastMessage
          ref={toastRef}
          status={toastMessage}
        />
      <div className={style['title-container']}>
        <h2>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageTitle}
            tooltipKey='Reports'
          >
            <span>Reports</span>
          </TooltipWrapper>
        </h2>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.PageAction}
          tooltipKey='Generate New Report'
        >
          <button
            type='button'
            className='primary'
            onClick={() => {
              navigate('/reports/generate');
            }}
          >
            Generate New Report
          </button>
        </TooltipWrapper>
      </div>
      <div className={style['main']}>
        <Table
          id='manageReports'
          paginate
          sortable
          searchable='Search reports...'
          onQueryChanged={fetchData}
          rows={items}
          totalRows={total}
          selectable={{selected, onChange: setSelected}}
          defaultSort={{
            createdAt: 'desc',
          }}
          loading={fetchingData}
        >
          <TopBar><div className={style.operate}>
            {selected.length > 0 && (
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.TableAction}
                tooltipKey='Delete'
              >
                <button
                  className='button secondary'
                  onClick={openConfirmDeleteModal}
                >
                  Delete ({selected.length})
                </button>
              </TooltipWrapper>
            )}
          </div></TopBar>

          <Column label='Report Name' prop='reportName' />
          <Column label='Report ID' prop='reportId' />

          <Column
            id='createdAt'
            label='Generation Date'
            accessor={(item) => item.createdAt}
            filterable
            editor={{
              type: InputType.DateRange,
              label: ['Generate From Date', 'Generate To Date'],
            }}
          >
            {formatDate}
          </Column>
          <Column id='actions' label='Actions' sortable={false}>
            {(item: ReportItem) => (
              <div className={style['operate']}>
                <button
                  role='button'
                  className='download'
                  onClick={() => {
                    const res = downloadGeneralReport('' + item.id);
                    setDownloading({
                      ...downloading,
                      [item.id]: true,
                    });
                    exportXLSX(res, () => {
                      setDownloading({
                        ...downloading,
                        [item.id]: false,
                      });
                    });
                  }}
                  disabled={!!downloading[item.id]}
                >{downloading[item.id] ? (<LoaderIndicator className='button-loading icon-button' loading={true} />) : null}</button>
                <button
                  role='button'
                  className='delete'
                  onClick={() => {
                    setReportToDelete(item);
                    openConfirmDeleteModal();
                  }}
                ></button>
              </div>
            )}
          </Column>
        </Table>

        <hr />
        <div className={style['buttons']}>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageAction}
            tooltipKey='Return to dashboard'
          >
            <Link to='/dashboard' className='button primary'>
              Return to dashboard
            </Link>
          </TooltipWrapper>
        </div>
      </div>
      
      <Modal
        title='Confirmation'
        body={<p>Are you sure you want to delete?</p>}
        isOpen={state.isOpen && state.href === 'comfirm'}
        onClose={() => {
          setDeletingReport(false);
        }}
        footer={
          <div className='buttons'>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Confirmation Confirm'
            >
              <button
                className='primary'
                onClick={(e) => confirmDelete(e)}
                disabled={deletingReport}
                ref={ref}
              >
                Confirm
                {deletingReport ? (
                  <LoaderIndicator className='button-loading' loading={true} />
                ) : null}
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Confirmation Cancel'
            >
              <button
                className='secondary'
                onClick={() => {
                  setDeletingReport(false);
                  dispatch({type: 'CLOSE'});
                }}
              >
                Cancel
              </button>
            </TooltipWrapper>
          </div>
        }
      />
    </div>
  );
}

export default Reports;
