import { get, isArray, isFunction } from "lodash";
import { ColumnProps, PropertyColumnProps } from "./components/Column";

export type IdAccessor<T> = keyof T | ((row: T) => number | string);

export function getColKey<T>(col: ColumnProps<T>) {
  return (
    col.id ??
    ((col as PropertyColumnProps<T>).prop as string | number) ??
    (isArray(col.label) ? col.label.join("/") : col.label)
  );
}

/**
 * Row ID accessor
 */
export function getRowId<T>(row: T, accessor: IdAccessor<T>) {
  return isFunction(accessor)
    ? accessor(row)
    : (get(row, accessor) as string | number);
}
