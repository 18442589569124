import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import classNames from 'classnames';
import style from './AssessorTaxYearForm.module.scss';
import { FormikProps } from 'formik';
import Tabs from '../Tabs/Tabs';
import _ from 'lodash';
import Divider from '../Divider/Divider';

import IconEdit from '../../images/icon-edit.svg';
import IconSave from '../../images/icon-save-grayscaleDark-18x18.svg';
import FieldSelect from '../FieldSelect/FieldSelect';
import { Assessor, DepreciationScheduleCollection, IndexTableCollection, SelectOption } from '../../interface';
import FieldInput from '../FieldInput/FieldInput';
import { getAcquisitionYearTypeOptions, getDaysOptions, getInventoryOriginalCostRuleOptions, getMonthsOptions, getYesNoOptions } from '../../utils/selectionOptions';
import FieldDate from '../FieldDate/FieldDate';
import { IAssessorTaxYearFormData } from '../../interface/IAssessorTaxYearFormData';
import FormManagementSearch from '../FormManagementSearch/FormManagementSearch';
import AssessorManagementDetailsTable from '../AssessorManagementDetailsTable/AssessorManagementDetailsTable';
import { getCommonAssetClass, getDepreciationScheduleCollections, getErrorMessage, getIndexTableCollections, getMappingInfos, getReturnForms, updateMappingInfo } from '../../services';
import Loader from '../Loader/Loader';
import { IBackendFormInfo } from '../../interface/IBackendFormInfo';
import { IBackendFormMapping } from '../../interface/IBackendFormMapping';
import { IAssessorNotTaxYearFormData } from '../../interface/IAssessorNotTaxYearFormData';

interface Props {
  className?: string;
  isInEditMode?: boolean;
  formik?: FormikProps<IAssessorTaxYearFormData>;
  formikAssessorNotTaxYearForm?: FormikProps<IAssessorNotTaxYearFormData>;
  unsaveformMappingsList?: IBackendFormMapping[];
  setUnsaveformMappingsList?: React.Dispatch<React.SetStateAction<IBackendFormMapping[]>>;
  selectedYearIndex: number;
  activeTaxYearIndex: number | null;
  setSelectedYearIndex: React.Dispatch<React.SetStateAction<number>>;
  showSuccessMessage?: () => void;
  showErrorMessage?: (message: string) => void;
  bkBackendAssessor?: Assessor | null;
  isSubmitted?: boolean;
  isAddNew?: boolean;
  setInitialAssessorTaxYearValues: React.Dispatch<React.SetStateAction<IAssessorTaxYearFormData>>;
}

const monthOptions: SelectOption[] = getMonthsOptions();
const inventoryOriginalCostRuleOptions: SelectOption[] = getInventoryOriginalCostRuleOptions();
const acquisitionYearTypeOptions: SelectOption[] = getAcquisitionYearTypeOptions();
const yesNoOptions = getYesNoOptions();

interface IErrorType {
  assessmentDateMonth: string;
  assessmentDateDate: string;
  inventoryOriginalCostRule: string;
  assessmentRatio: string;
  returnDeadline: string;
  extensionOffered: string;
  extensionFillingDeadline: string;
  returnDeadlineIfExtended: string;
  acquisitionYearType: string;
  defaultForm?: string;
}

export type AssessorTaxYearFormHandle = {
  resetMappingValue: () => void;
};

const AssessorTaxYearForm = forwardRef<AssessorTaxYearFormHandle, Props>(({
  className,
  isInEditMode,
  formik,
  unsaveformMappingsList = [],
  setUnsaveformMappingsList,
  showSuccessMessage,
  showErrorMessage,
  formikAssessorNotTaxYearForm,
  bkBackendAssessor,
  isSubmitted,
  activeTaxYearIndex,
  selectedYearIndex,
  setSelectedYearIndex,
  isAddNew,
  setInitialAssessorTaxYearValues
}: Props, ref) => {
  const [commonAssetClassOptions, setCommonAssetClassesOptions] = useState<SelectOption[]>([]);
  const [searchStandardAssetClass, setSearchStandardAssetClass] = useState('');
  const [visibleFilterPanel, setVisibleFilterPanel] = useState(null as "filter" | null);
  const [isEditing, setIsEditing] = useState(isInEditMode);
  const [depreciationScheduleCollections, setDepreciationScheduleCollections] = useState<DepreciationScheduleCollection[]>([]);
  const [formList, setFormList] = useState<IBackendFormInfo[]>([]);
  const [indexTableCollections, setIndexTableCollections] = useState<IndexTableCollection[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [
    fetchingDepreciationScheduleCollections,
    setFetchingDepreciationScheduleCollections,
  ] = useState(false);
  const [fetchingReturnForms, setFetchingReturnForms] = useState(false);
  const [fetchingIndexTableCollections, setFetchingIndexTableCollections] =
    useState(false);
  const [fetchingCommonAssetClass, setFetchingCommonAssetClass] =
    useState(false);
  const [fetchingMappingInfos, setFetchingMappingInfos] =
    useState(false);
  const [taxYearMappingInfos, setTaxYearMappingInfos] = useState<{ [taxYear: number]: IBackendFormMapping[] }>({});
  const [isTaxSubmitted, setIsTaxSubmitted] = useState<boolean>(false);

  const defaultTaxYearMappingInfo = useMemo(() => {
    return commonAssetClassOptions.map(item => ({
      commonClassId: item.value,
      formGroupId: null,
      reportable: false,
      depreciationScheduleId: null,
      indexTableId: null,
      taxable: false
    }));
  }, [commonAssetClassOptions]);

  const shouldShowError = useMemo(() => (isTaxSubmitted || isSubmitted) && selectedYearIndex === activeTaxYearIndex, [isTaxSubmitted, isSubmitted, selectedYearIndex, activeTaxYearIndex]);

  const dateOptions = useMemo(() => {
    const year = formik?.values.taxYears?.[selectedYearIndex]?.taxYear;
    const month = formik?.values.taxYears?.[selectedYearIndex]?.assessmentDateMonth;
    const options = getDaysOptions(month ?? 2, year);
    if (formik?.values.taxYears?.[selectedYearIndex]?.assessmentDateDate) {
      const selectedOption = _.find(options, { value: formik?.values.taxYears?.[selectedYearIndex]?.assessmentDateDate });
      if (!selectedOption) {
        formik?.setFieldValue(
          `taxYears[${selectedYearIndex}].assessmentDateDate`,
          null,
        );
      }
    }
    return options;
  }, [
    formik?.values.taxYears?.[selectedYearIndex]?.assessmentDateMonth,
    selectedYearIndex
  ]);
  
  useImperativeHandle(ref, () => ({
    resetMappingValue() {
      const taxYear = formik?.values.taxYears?.[selectedYearIndex];
      if (taxYear && taxYear.taxYear) {
        setTaxYearMappingInfos({});
        setUnsaveformMappingsList?.([]);
      }
    },
  }));

  useEffect(() => {
    const taxYear = formik?.values.taxYears?.[selectedYearIndex];
    const formsQuery = {
      stateId: formikAssessorNotTaxYearForm?.values.state ?? null,
      taxYear: taxYear?.taxYear ?? null,
    }
    if (
      formsQuery.stateId &&
      formsQuery.taxYear
    ) {

      setFetchingDepreciationScheduleCollections(true);
      getDepreciationScheduleCollections({
        page: 0,
        stateIds: formsQuery.stateId?.toString(),
        taxYear: formsQuery.taxYear
      })
        .then((res) => {
          setDepreciationScheduleCollections(
            _.get(res, 'data.items') as DepreciationScheduleCollection[]
          );
          setFetchingDepreciationScheduleCollections(false);
        })
        .catch(() => {
          setFetchingDepreciationScheduleCollections(false);
        });

      setFetchingReturnForms(true);
      getReturnForms({
        page: 0,
        ...formsQuery,
      })
        .then((res) => {
          const items: IBackendFormInfo[] = _.get(res, 'data.items');
          setFormList(items);

          setFetchingReturnForms(false);
        })
        .catch(() => {
          setFetchingReturnForms(false);
        });

      setFetchingIndexTableCollections(true);
      getIndexTableCollections({
        page: 0,
        stateIds: formsQuery.stateId?.toString(),
        taxYear: formsQuery.taxYear
      })
        .then((res) => {
          setIndexTableCollections(
            _.get(res, 'data.items') as IndexTableCollection[]
          );
          setFetchingIndexTableCollections(false);
        })
        .catch(() => {
          setFetchingIndexTableCollections(false);
        });
    }
  }, [
    selectedYearIndex,
    formik?.values.taxYears?.[selectedYearIndex]?.taxYear,
    formikAssessorNotTaxYearForm?.values.state
  ]);

  useEffect(() => {
    const taxYear = formik?.values.taxYears?.[selectedYearIndex];

    if (
      taxYear &&
      formikAssessorNotTaxYearForm &&
      taxYear.defaultForm &&
      taxYear.taxYear &&
      formikAssessorNotTaxYearForm.values.state &&
      !taxYearMappingInfos[taxYear.taxYear as number]
    ) {
      setFetchingMappingInfos(true);
      getMappingInfos({
        assessorId: bkBackendAssessor?.id,
        stateId: formikAssessorNotTaxYearForm?.values.state,
        taxYear: taxYear.taxYear,
        formId: taxYear.defaultForm,
        depreciationScheduleCollectionId: taxYear.defaultDepreciationCollection,
        indexTableCollectionId: taxYear.defaultIndexCollection
      })
        .then((res) => {
          setTaxYearMappingInfos({
            ...taxYearMappingInfos,
            [taxYear.taxYear as number]: res.data.map(item => ({
              ...item,
              taxable: item.taxable ?? false,
              reportable: item.reportable ?? false,
            }))
          })
          setFetchingMappingInfos(false);
        })
        .catch(() => {
          setFetchingMappingInfos(false);
        });
    }

  }, [
    bkBackendAssessor,
    selectedYearIndex,
    formik?.values.taxYears?.[selectedYearIndex]?.taxYear,
    formik?.values.taxYears?.[selectedYearIndex]?.defaultForm,
    formikAssessorNotTaxYearForm?.values.state
  ]);

  useEffect(() => {
    const taxYear = formik?.values.taxYears?.[selectedYearIndex];
    if (
      taxYear &&
      taxYear.taxYear &&
      !taxYear.defaultForm &&
      formikAssessorNotTaxYearForm &&
      formikAssessorNotTaxYearForm.values.state &&
      formList
    ) {
      const defaultForm = _.find(formList, {
        isStateDefaultForm: true,
      });
      if (
        defaultForm &&
        defaultForm.stateId === formikAssessorNotTaxYearForm.values.state &&
        defaultForm.taxYear === taxYear.taxYear
      ) {
        formik.setFieldValue(
          `taxYears[${selectedYearIndex}].defaultForm`,
          defaultForm.id,
        )
        formik.setFieldValue(
          `taxYears[${selectedYearIndex}].defaultDepreciationCollection`,
          defaultForm.defaultDepreciationScheduleCollectionId,
        )
        formik.setFieldValue(
          `taxYears[${selectedYearIndex}].defaultIndexCollection`,
          defaultForm.defaultIndexTableCollectionId,
        )
        setInitialAssessorTaxYearValues((previousValue) => {
          return {
            ...previousValue,
            taxYears: (previousValue.taxYears || []).map((ty) => {
              if (ty.taxYear === taxYear.taxYear) {
                return {
                  ...ty,
                  defaultForm: defaultForm.id,
                  defaultDepreciationCollection: defaultForm.defaultDepreciationScheduleCollectionId,
                  defaultIndexCollection: defaultForm.defaultIndexTableCollectionId,
                };
              }
              return ty;
            })
          };
        });
      }
    }

  }, [
    bkBackendAssessor,
    selectedYearIndex,
    formikAssessorNotTaxYearForm?.values.state,
    formList
  ]);

  useEffect(() => {
    setFetchingCommonAssetClass(true);
    getCommonAssetClass()
      .then((res) => {
        setCommonAssetClassesOptions(res.data.map(item => ({
          value: item.id,
          label: item.name,
          name: item.name
        })));
        setFetchingCommonAssetClass(false);
      })
      .catch(() => {
        setFetchingCommonAssetClass(false);
      });
  }, []);


  const updateMappingInfos = (newValues: IBackendFormMapping[]) => {
    const taxYear = formik?.values.taxYears?.[selectedYearIndex].taxYear;
    setTaxYearMappingInfos({
      ...taxYearMappingInfos,
      [taxYear as number]: (taxYearMappingInfos[taxYear as number] ?? [...defaultTaxYearMappingInfo]).map(item => {
        const matchItem = _.find(newValues, {
          commonClassId: item.commonClassId
        });
        if (matchItem) {
          return {
            ...item,
            ...matchItem
          };
        } else {
          return item;
        }
      })
    });
  };
  const updateUnsavedFormMappingsList = (newValues: IBackendFormMapping[]) => {
    updateMappingInfos(newValues);
    const newUnsaveformMappingsList = unsaveformMappingsList.map(item => {
      const matchIndex = _.findIndex(newValues, {
        commonClassId: item.commonClassId,
        taxYear: item.taxYear
      });
      if (matchIndex >= 0) {
        const newValue = newValues[matchIndex];
        newValues.splice(matchIndex, 1);
        return {
          ...item,
          ...newValue
        };
      } else {
        return item;
      }
    });
    const results = [
      ...newUnsaveformMappingsList,
      ...newValues
    ];
    setUnsaveformMappingsList?.(results);
  }
  const removeUnsavedFormMappingsList = (newValues: IBackendFormMapping[]) => {
    updateMappingInfos(newValues);
    setUnsaveformMappingsList?.(_.filter(unsaveformMappingsList, (item) => {
      return !_.find(newValues, {
        commonClassId: item.commonClassId,
        taxYear: item.taxYear
      })
    }));
  }

  const saveMappingInfo = (newValues: IBackendFormMapping[]) => {
    if (isAddNew) {
      updateUnsavedFormMappingsList(newValues);
    } else {
      setIsSaving(true);
      const taxYear = formik?.values.taxYears?.[selectedYearIndex];
      updateMappingInfo(newValues.map((item) => {
        const result = {
          ...item,
          formId: taxYear?.defaultForm,
          depreciationScheduleCollectionId: taxYear?.defaultDepreciationCollection ?? null,
          indexTableCollectionId: taxYear?.defaultIndexCollection ?? null,
          taxYear: item.taxYear,
          stateId: formikAssessorNotTaxYearForm?.values.state ?? null,
        };
        if (bkBackendAssessor) {
          result.assessorId = bkBackendAssessor.id;
        }
        return result;
      }))
        .then(
          () => {
            removeUnsavedFormMappingsList(newValues);
            setIsSaving(false);
            showSuccessMessage?.();
          },
        )
        .catch((e) => {
          setIsSaving(false);
          showErrorMessage?.(getErrorMessage(e));
        });
    }
  }

  const getFieldErrorMessage = (key: keyof IErrorType) => {
    return shouldShowError ? (formik?.errors as IErrorType)?.[key] : undefined;
  }

  return (
    <div className={classNames(className, style['main-content'], 'AssessorTaxYearForm')}>
      <div className='d-flex align-items-center justify-content-between'>
        <span className={style.textTitle}>SECTION 2: Tax Year Specific</span>
        {(!isInEditMode) && (
          <button
            onClick={() => {
              if (isEditing) {
                setIsTaxSubmitted(true);
                if (formik?.isValid) {
                  setIsEditing(false);
                  formik?.submitForm();
                } else {
                  // switch to current year to show error
                  setSelectedYearIndex(activeTaxYearIndex || 0);
                }
              } else {
                setIsEditing(true);
              }
            }}
            className={style.btnEdit}
          >
            <img src={isEditing ? IconSave : IconEdit} alt="" />
          </button>
        )}
      </div>

      <Divider className={style.divider1} strokeWidth={2} />

      <Tabs
        tabs={formik?.values.taxYears?.map(tx => `${tx.taxYear}`) ?? []}
        selected={`${formik?.values.taxYears?.[selectedYearIndex]?.taxYear}`}
        onSelect={(taxYear) => {
          setSelectedYearIndex(_.findIndex(formik?.values.taxYears, {
            taxYear: parseInt(taxYear)
          }))
        }}
      ></Tabs>

      <div className={classNames('d-grid grid-4-columns gap-column-25 gap-row-20', style.blockFieldContainer)}>
        <FieldSelect
          labelText='Assessment Date - Month'
          options={monthOptions}
          selectId={formik?.values.taxYears?.[selectedYearIndex]?.assessmentDateMonth}
          onSelect={(value) => {
            const newMonth = value?.value as number;
            formik?.setFieldValue(
              `taxYears[${selectedYearIndex}].assessmentDateMonth`,
              newMonth,
            );
          }}
          isInReadOnlyMode={!isEditing}
          hideNullOption
          error={getFieldErrorMessage('assessmentDateMonth')}
        />
        <FieldSelect
          labelText='Assessment Date - Day'
          options={dateOptions}
          selectId={formik?.values.taxYears?.[selectedYearIndex]?.assessmentDateDate}
          onSelect={(value) => {
            formik?.setFieldValue(
              `taxYears[${selectedYearIndex}].assessmentDateDate`,
              value?.value,
            )
          }}
          isInReadOnlyMode={!isEditing}
          hideNullOption
          error={getFieldErrorMessage('assessmentDateDate')}
        />
        <FieldSelect
          labelText='Inventory Original Cost Rule'
          options={inventoryOriginalCostRuleOptions}
          selectId={formik?.values.taxYears?.[selectedYearIndex]?.inventoryOriginalCostRule}
          onSelect={(value) => {
            formik?.setFieldValue(
              `taxYears[${selectedYearIndex}].inventoryOriginalCostRule`,
              value?.value,
            )
          }}
          isInReadOnlyMode={!isEditing}
          hideNullOption
          error={getFieldErrorMessage('inventoryOriginalCostRule')}
        />
        <FieldInput
          labelText='Assessment Ratio %'
          name='assessmentRatio'
          value={formik?.values.taxYears?.[selectedYearIndex]?.assessmentRatio ?? ''}
          onChange={(value) => {
            formik?.setFieldValue(`taxYears[${selectedYearIndex}].assessmentRatio`, value);
          }}
          isInReadOnlyMode={!isEditing}
          decimal
          error={getFieldErrorMessage('assessmentRatio')}
        />
        <FieldDate
          labelText='Return Deadline'
          primaryFormat='M/D/YYYY'
          selectValue={formik?.values.taxYears?.[selectedYearIndex]?.returnDeadline ?? ''}
          onSelect={(value) => {
            if (
              `${value}`.indexOf('Invalid') >= 0 ||
              !value
            ) {
              formik?.setFieldValue(`taxYears[${selectedYearIndex}].returnDeadline`, '')
            } else {
              formik?.setFieldValue(`taxYears[${selectedYearIndex}].returnDeadline`, value)
            }
          }}
          formatOutput
          isInReadOnlyMode={!isEditing}
          error={getFieldErrorMessage('returnDeadline')}
        />
        <FieldSelect
          labelText='Extension Offered'
          options={yesNoOptions}
          selectId={formik?.values.taxYears?.[selectedYearIndex]?.extensionOffered ?? ''}
          onSelect={(value) => {
            const newExtensionOffered = value?.value;
            if (newExtensionOffered === 'No') {
              formik?.setFieldValue(`taxYears[${selectedYearIndex}].extensionFillingDeadline`, null);
              formik?.setFieldValue(`taxYears[${selectedYearIndex}].returnDeadlineIfExtended`, null);
            }
            formik?.setFieldValue(
              `taxYears[${selectedYearIndex}].extensionOffered`,
              newExtensionOffered,
            )
          }}
          isInReadOnlyMode={!isEditing}
          hideNullOption
          error={getFieldErrorMessage('extensionOffered')}
        />
        <FieldDate
          labelText='Extension Filling Deadline'
          primaryFormat='M/D/YYYY'
          selectValue={formik?.values.taxYears?.[selectedYearIndex]?.extensionFillingDeadline ?? ''}
          onSelect={(value) => {
            if (
              `${value}`.indexOf('Invalid') >= 0 ||
              !value
            ) {
              formik?.setFieldValue(`taxYears[${selectedYearIndex}].extensionFillingDeadline`, null);
            } else {
              formik?.setFieldValue(`taxYears[${selectedYearIndex}].extensionFillingDeadline`, value);
            }
          }}
          formatOutput
          isInReadOnlyMode={!isEditing}
          error={getFieldErrorMessage('extensionFillingDeadline')}
          disabled={formik?.values.taxYears?.[selectedYearIndex]?.extensionOffered === 'No'}
        />
        <FieldDate
          labelText='Return Deadline If Extended'
          primaryFormat='M/D/YYYY'
          selectValue={formik?.values.taxYears?.[selectedYearIndex]?.returnDeadlineIfExtended ?? ''}
          onSelect={(value) => {
            if (
              `${value}`.indexOf('Invalid') >= 0 ||
              !value
            ) {
              formik?.setFieldValue(`taxYears[${selectedYearIndex}].returnDeadlineIfExtended`, null);
            } else {
              formik?.setFieldValue(`taxYears[${selectedYearIndex}].returnDeadlineIfExtended`, value);
            }
          }}
          formatOutput
          isInReadOnlyMode={!isEditing}
          error={getFieldErrorMessage('returnDeadlineIfExtended')}
          disabled={formik?.values.taxYears?.[selectedYearIndex]?.extensionOffered === 'No'}
        />
        <FieldSelect
          labelText='Acquisition Year Type'
          options={acquisitionYearTypeOptions}
          selectText={formik?.values.taxYears?.[selectedYearIndex]?.acquisitionYearType ?? 'Fiscal Year'}
          onSelect={(value) => {
            formik?.setFieldValue(
              `taxYears[${selectedYearIndex}].acquisitionYearType`,
              value?.value,
            )
          }}
          isInReadOnlyMode={!isEditing}
          hideNullOption
          error={getFieldErrorMessage('acquisitionYearType')}
        />

      </div>

      <Divider className={style.divider2} />

      <FormManagementSearch
        setVisibleFilterPanel={setVisibleFilterPanel}
        className={style.FormManagementSearch}
        shouldShowDefaultForm
        isInReadOnlyMode={!isEditing}
        depreciationScheduleCollections={depreciationScheduleCollections}
        formList={formList}
        indexTableCollections={indexTableCollections}
        formikEditFormValues={formik?.values.taxYears?.[selectedYearIndex]}
        searchStandardAssetClass={searchStandardAssetClass}
        setSearchStandardAssetClass={setSearchStandardAssetClass}
        updateFormValue={(key, value) => formik?.setFieldValue(
          `taxYears[${selectedYearIndex}].${key}`,
          value,
        )}
        updateUnsavedFormMappingsList={updateUnsavedFormMappingsList}
      />

      <AssessorManagementDetailsTable
        visibleFilterPanel={visibleFilterPanel}
        setVisibleFilterPanel={setVisibleFilterPanel}
        searchStandardAssetClass={searchStandardAssetClass}
        isInEditMode={isEditing}
        formId={formik?.values.taxYears?.[selectedYearIndex]?.defaultForm}
        taxYear={formik?.values.taxYears?.[selectedYearIndex]?.taxYear}
        depreciationScheduleCollectionId={formik?.values.taxYears?.[selectedYearIndex]?.defaultDepreciationCollection}
        indexTableCollectionId={formik?.values.taxYears?.[selectedYearIndex]?.defaultIndexCollection}
        commonAssetClassOptions={commonAssetClassOptions}
        formMappingsList={taxYearMappingInfos[formik?.values.taxYears?.[selectedYearIndex]?.taxYear as number] ?? defaultTaxYearMappingInfo}
        updateUnsavedFormMappingsList={updateUnsavedFormMappingsList}
        saveMappingInfo={saveMappingInfo}
      />

      <Divider className={style.divider3} />

      <Loader
        isOpen={
          fetchingDepreciationScheduleCollections ||
          fetchingReturnForms ||
          fetchingIndexTableCollections ||
          fetchingCommonAssetClass ||
          fetchingMappingInfos ||
          isSaving
        }
      />
    </div>
  );
});

AssessorTaxYearForm.displayName = 'AssessorTaxYearForm';

export default AssessorTaxYearForm;