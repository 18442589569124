import _ from 'lodash';
import {PICKACCOUNTFILED, PICKPROPERTYFIELD} from '../constants';
import {ExtProperty} from '../interface';

function clearProperty(property: ExtProperty): ExtProperty {
  const withoutAccount = _.pick(property, PICKPROPERTYFIELD);
  const assessorAccounts = property.assessorAccounts.map((account) =>
    _.pick(account, PICKACCOUNTFILED),
  );
  const result = {...withoutAccount, assessorAccounts};
  return result as ExtProperty;
}

export default clearProperty;
