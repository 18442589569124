import React, {useState, useEffect, useRef} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import _, {get} from 'lodash';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import StepsBar from '../../../components/StepsBar/StepsBar';
import {Order, ReduxState, SelectOption, State} from '../../../interface';
import style from './AssetsMapFile.module.scss';
import {
  cancelUploadFile,
  getAssetsTemplate,
  getErrorMessage,
  getUploadFile,
  updateUploadFile,
} from '../../../services';
import exportXLSX from '../../../utils/exportFile';
import {connect} from 'react-redux';
import useMappedResult from '../../../hook/useMappedResult';
import useReturnJSON from '../../../hook/useReturnJSON';
import classNames from 'classnames';
import FieldSelect from '../../../components/FieldSelect/FieldSelect';
import chooseTemplate from '../../../utils/chooseTemplate';
import {
  FIXEDASSETFILECOLUMNS,
  INVENTORYFILECOLUMNS,
  LEASEEQUIPMENTFILECOLUMNS,
  LEASEVEHICLEFILECOLUMNS,
  VEHICLEFILECOLUMNS,
} from '../../../constants';
import usePrevious from '../../../hook/usePrevious';
import useFileToAssets from '../../../hook/useFileToAssets';
import formatAssets from '../../../utils/formatAssets';
import {Dispatch} from 'redux';
import actions from '../../../redux/actions';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../enums';
import Loader, {LoaderIndicator} from '../../../components/Loader/Loader';
import ToastMessage, {
  ToastMessageRefType,
} from '../../../components/ToastMessage/ToastMessage';

interface Props {
  states: State[];
  uploadAssetsFile: File;
  setUploadAssetsFile: (value: File | undefined) => void;
}

interface ExtFile {
  status: string;
  mappingPriority: string;
  propertyData: string;
  expectData: string;
  mapFileColumn: SelectOption[];
  fileColumnPreview?: string[];
  selectedColumn: string;
}

function AssetsMapFile(props: Props) {
  const {uploadAssetsFile, states, setUploadAssetsFile} = props;
  const navigate = useNavigate();
  const {id} = useParams();
  const [type, setType] = useState<string>(
    sessionStorage.getItem('assetType') as string,
  );
  const {fileColumns, setFileColumns} = useMappedResult(uploadAssetsFile);
  const {fileJSON: returnJSON, setfileJSON} = useReturnJSON(uploadAssetsFile);
  const {assets, loading: loadingFileAssets} = useFileToAssets(
    returnJSON,
    type,
  );
  const [file, setfile] = useState<ExtFile[]>([]);
  const [order, setorder] = useState<Order>(false);
  const [orderkey, setorderkey] = useState<string>('');
  const [validate, setValidate] = useState<boolean>(false);
  const [cancellingUploadFile, setCancellingUploadFile] =
    useState<boolean>(false);

  // Breadcrumb, dashboard depend on user skip or not, property link depend where is the page from
  const breadcrumb = [
    {
      value: 'Assets',
      href: '/assets',
    },
    {value: 'Add Asset', href: '/assets/assetsUpload'},
    {value: 'File Mapping'},
  ];

  useEffect(() => {
    if (!_.isEmpty(uploadAssetsFile.name) && !_.isEmpty(fileColumns)) {
      const result = _.map(chooseTemplate(type), (item) => ({
        ...item,
        status: _.includes(fileColumns, item['propertyData'])
          ? 'Mapped'
          : 'Unmapped',
        selectedColumn: _.includes(fileColumns, item['propertyData'])
          ? item['propertyData']
          : undefined,
      })) as ExtFile[];
      setfile(result);
      uploadAssetsFile.name && setFileName(uploadAssetsFile.name);
      setUploadAssetsFile(undefined);
    }
  }, [uploadAssetsFile, fileColumns]);

  useEffect(() => {
    setValidate(
      _.every(
        _.filter(file, (f) => {
          return _.includes(
            _.filter(chooseTemplate(type), ['mappingPriority', 'Required']).map(
              (item) => item.propertyData,
            ),
            f['propertyData'],
          );
        }),
        (o) => _.isEqual(o['status'], 'Mapped'),
      ),
    );
  }, [file]);

  // Order the properties list
  useEffect(() => {
    setfile(_.orderBy(file, [orderkey], [order]));
  }, [order, orderkey]);
  // Change order when click on the table header
  const changeOrderHanele = (key: string) => {
    if (_.isEqual(key, orderkey)) {
      switch (order) {
        case false:
          setorder('asc');
          break;
        case 'asc':
          setorder('desc');
          break;
        default:
          setorder(false);
          break;
      }
    } else {
      setorderkey(key);
      setorder('asc');
    }
  };

  const [downloading, setDownloading] = useState(false);
  const download = () => {
    const res = getAssetsTemplate(type);
    setDownloading(true);
    exportXLSX(res, () => {
      setDownloading(false);
    });
  };

  const ref = usePrevious(file);
  const refReturnJSON = usePrevious(returnJSON);
  const [initial, setInitial] = useState<boolean>(true);
  const [updatingUploadFile, setUpdatingUploadFile] = useState<boolean>(false);
  const [movingNextStep, setMovingNextStep] = useState<boolean>(false);
  const toastRef = useRef<ToastMessageRefType | null>(null);
  const [first, setFirst] = useState<boolean>(true);
  useEffect(() => {
    if (id) {
      if (initial) {
        if (
          !_.isEmpty(fileColumns) &&
          !_.isEmpty(returnJSON) &&
          !_.isEmpty(file)
        ) {
          if (!_.isEqual(ref, file) || !_.isEqual(refReturnJSON, returnJSON)) {
            setUpdatingUploadFile(true);
            updateUploadFile(id, {
              columnMappings: [fileColumns, returnJSON, file],
              status: 'In Progress',
            })
              .then((d) => {
                const errorMessage = getErrorMessage(d);
                if (errorMessage) {
                  toastRef.current?.showErrorToast(errorMessage);
                } else {
                  if (!first) {
                    toastRef.current?.showSuccessToast('Update successfully');
                  }
                  setFirst(false);
                }
                setUpdatingUploadFile(false);
              })
              .catch((e) => {
                toastRef.current?.showErrorToast(getErrorMessage(e));
                setUpdatingUploadFile(false);
              });
          }
        }
      } else {
        setInitial(true);
      }
    }
  }, [id, file, returnJSON, fileColumns, initial]);

  const [fileName, setFileName] = useState<string>('');
  const [fetchingUploadFile, setFetchingUploadFile] = useState(false);
  useEffect(() => {
    if (id) {
      if (_.isEmpty(uploadAssetsFile.name)) {
        setFetchingUploadFile(true);
        getUploadFile(id)
          .then((d) => {
            setFileColumns(d.data.columnMappings[0]);
            setfileJSON(d.data.columnMappings[1]);
            setfile(d.data.columnMappings[2]);
            setFileName(d.data.document.name);
            setType(d.data.assetType);
            setInitial(false);
            setFirst(false);
            setFetchingUploadFile(false);
          })
          .catch(() => {
            setFetchingUploadFile(false);
          });
      }
    }
  }, [id]);

  const next = () => {
    if (!id || _.isEmpty(states)) {
      return;
    }

    const result = _.map(assets, (asset) => ({
      ...asset,
      stateId:
        _.find(states, (state) => {
          return state.abbreviation === _.get(asset, 'state') || state.name.toLowerCase() === _.get(asset, 'state')?.toLowerCase();
        })?.id ?? null,
      state:
        _.find(states, (state) => {
          return state.abbreviation === _.get(asset, 'state') || state.name.toLowerCase() === _.get(asset, 'state')?.toLowerCase();
        })?.abbreviation ??
        '',
    }));

    setMovingNextStep(true);
    updateUploadFile(id, {items: formatAssets(result)})
      .then((d) => {
        const errorMessage = getErrorMessage(d);
        if (errorMessage) {
          toastRef.current?.showErrorToast(errorMessage);
        } else {
          toastRef.current?.showSuccessToast('Update successfully');
          navigate(`/assets/assetsReview/${id}`);
        }
        setMovingNextStep(false);
      })
      .catch((e) => {
        toastRef.current?.showErrorToast(getErrorMessage(e));
        setMovingNextStep(false);
      });
  };

  return (
    <div className={style['main-content']}>
      {/* Braedcrumb */}
      <Breadcrumb items={breadcrumb} />
      <ToastMessage ref={toastRef} className={style['toast']} />
      <div className={style['main']}>
        {/* Steps */}
        <StepsBar step={2} steps={['Upload', 'Map', 'Review']} />
        {/* Add property section */}
        <div className={style['section']}>
          <h2>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageTitle}
              tooltipKey='Map Asset File'
            >
              <span>
                {sessionStorage.getItem('assetType')
                  ? `Map ${type} File`
                  : `Map Asset File`}
              </span>
            </TooltipWrapper>
          </h2>
          <div className={style['results']}>
            <div className={style['buttons']}>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Download template and formatting guide'
              >
                <button
                  className='secondary'
                  onClick={download}
                  disabled={downloading}
                >
                  Download template and formatting guide
                  {downloading ? (
                    <LoaderIndicator
                      className='button-loading'
                      loading={true}
                    />
                  ) : null}
                </button>
              </TooltipWrapper>
            </div>
            <div className='mappedfile-result'>
              <ul>
                <li className={style['mappedFileColumn1']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Uploaded file'
                  >
                    <span>Uploaded file</span>
                  </TooltipWrapper>
                  <p>
                    <i className={'icon'}></i>
                    <span className={style['mappedFileName']}>{fileName}</span>
                  </p>
                </li>
                <li className={style['mappedFileColumn2']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Mapped columns'
                  >
                    <span>Mapped columns</span>
                  </TooltipWrapper>
                  <span className='block'>
                    {_.filter(file, ['status', 'Mapped']).length}
                  </span>
                </li>
                <li className={style['mappedFileColumn3']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Total file columns'
                  >
                    <span>Total file columns</span>
                  </TooltipWrapper>
                  <span className='block'>
                    {get(
                      {
                        Inventory: INVENTORYFILECOLUMNS.length,
                        Vehicle: VEHICLEFILECOLUMNS.length,
                        'Leased Vehicle': LEASEVEHICLEFILECOLUMNS.length,
                        'Leased Equipment': LEASEEQUIPMENTFILECOLUMNS.length,
                      },
                      type,
                      FIXEDASSETFILECOLUMNS.length,
                    )}
                  </span>
                </li>
                <li className={style['mappedFileColumn4']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Total file rows'
                  >
                    <span>Total file rows</span>
                  </TooltipWrapper>
                  <span className='block'>{returnJSON.length}</span>
                </li>
              </ul>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Remove & Replace file'
              >
                <button
                  onClick={() => {
                    if (id) {
                      setCancellingUploadFile(true);
                      cancelUploadFile(id)
                        .then((d) => {
                          const errorMessage = getErrorMessage(d);
                          if (errorMessage) {
                            toastRef.current?.showErrorToast(errorMessage);
                          } else {
                            toastRef.current?.showSuccessToast(
                              'Remove & Replace file successfully',
                            );
                            navigate('/assets/assetsUpload');
                          }
                          setCancellingUploadFile(false);
                        })
                        .catch((e) => {
                          toastRef.current?.showErrorToast(getErrorMessage(e));
                          setCancellingUploadFile(false);
                        });
                    }
                  }}
                  className='primary-link'
                  disabled={cancellingUploadFile}
                >
                  Remove & Replace file
                  {cancellingUploadFile ? (
                    <LoaderIndicator
                      className='button-loading'
                      loading={true}
                    />
                  ) : null}
                </button>
              </TooltipWrapper>
            </div>
          </div>
        </div>
        {/* Added Properties */}
        <div className={style['section']}>
          <div className={classNames('table-layout', style['table-layout'])}>
            <table>
              {/* Table header */}
              <thead>
                <tr>
                  <th>
                    <TooltipWrapper
                      tooltipSection={TOOLTIP_SECTIONS.TableHeader}
                      tooltipKey='Status'
                    >
                      <span
                        className={classNames(
                          'sort',
                          _.isEqual(orderkey, 'status') && order,
                        )}
                        onClick={() => changeOrderHanele('status')}
                      >
                        Status
                      </span>
                    </TooltipWrapper>
                  </th>
                  <th>
                    <TooltipWrapper
                      tooltipSection={TOOLTIP_SECTIONS.TableHeader}
                      tooltipKey='Mapping priority'
                    >
                      <span
                        className={classNames(
                          'sort',
                          _.isEqual(orderkey, 'mappingPriority') && order,
                        )}
                        onClick={() => changeOrderHanele('mappingPriority')}
                      >
                        Mapping priority
                      </span>
                    </TooltipWrapper>
                  </th>
                  <th>
                    <TooltipWrapper
                      tooltipSection={TOOLTIP_SECTIONS.TableHeader}
                      tooltipKey='Avalara field name'
                    >
                      <span
                        className={classNames(
                          'sort',
                          _.isEqual(orderkey, 'propertyData') && order,
                        )}
                        onClick={() => changeOrderHanele('propertyData')}
                      >
                        Avalara field name
                      </span>
                    </TooltipWrapper>
                  </th>
                  <th>
                    <TooltipWrapper
                      tooltipSection={TOOLTIP_SECTIONS.TableHeader}
                      tooltipKey='Column name in file'
                    >
                      <span>Column name in file</span>
                    </TooltipWrapper>
                  </th>
                  <th>
                    <TooltipWrapper
                      tooltipSection={TOOLTIP_SECTIONS.TableHeader}
                      tooltipKey='Expected format'
                    >
                      <span
                        className={classNames(
                          'sort',
                          _.isEqual(orderkey, 'expectData') && order,
                        )}
                        onClick={() => changeOrderHanele('expectData')}
                      >
                        Expected format
                      </span>
                    </TooltipWrapper>
                  </th>
                  <th>
                    <div className={style['tip-column']}>
                      <TooltipWrapper
                        tooltipSection={TOOLTIP_SECTIONS.TableHeader}
                        tooltipKey='Column data preview'
                      >
                        <span>Column data preview</span>
                      </TooltipWrapper>{' '}
                      <i className={style['tooltip']}>
                        <span className={style['tip']}>
                          Invalid values will be removed/truncated
                        </span>
                      </i>
                    </div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody>
                {file.map((column, index) => (
                  <tr key={index}>
                    <td>
                      <span
                        className={classNames(
                          'badge-status',
                          _.isEqual(column['status'], 'Mapped')
                            ? 'success'
                            : 'default',
                        )}
                      >
                        {column['status']}
                      </span>
                    </td>
                    <td>
                      <span
                        className={classNames(
                          'badge-status',
                          get(
                            {
                              Required: 'error error-required',
                              Recomended: 'warning',
                            },
                            column['mappingPriority'],
                            'normal',
                          ),
                        )}
                      >
                        {column['mappingPriority']}
                      </span>
                    </td>
                    <td>{column['propertyData']}</td>
                    <td>
                      <FieldSelect
                        labelText=''
                        options={fileColumns.map((item) => ({
                          name: item,
                          label: item,
                          value: item,
                        }))}
                        selectText={_.get(column, 'selectedColumn')}
                        onSelect={(value) => {
                          const fileResult = [
                            ..._.slice(file, 0, index),
                            _.assign(column, {
                              selectedColumn: value?.name,
                              status: value?.name ? 'Mapped' : 'Unmapped',
                            }),
                            ..._.slice(file, index + 1),
                          ];
                          setfile(fileResult);
                          const result = _.map(returnJSON, (item, fieldIdx) =>
                            _.assign(
                              item,
                              _.map(returnJSON, (field) => {
                                const obj = _.pick(
                                  field,
                                  value?.name as string,
                                );
                                return _.mapKeys(
                                  obj,
                                  () => column['propertyData'],
                                );
                              })[fieldIdx],
                            ),
                          );
                          setfileJSON(result);
                        }}
                      />
                    </td>
                    <td>{column['expectData']}</td>
                    <td>
                      <div className={style['blocks']}>
                        {_(returnJSON)
                          .filter((item) =>
                            _.has(item, _.get(column, 'selectedColumn')),
                          )
                          .uniqBy(_.get(column, 'selectedColumn'))
                          .take(3)
                          .map((item, fieldIdx) => (
                            <span key={fieldIdx}>
                              {_.get(item, _.get(column, 'selectedColumn'))}
                            </span>
                          ))
                          .value()}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={style['buttons']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Next'
            >
              <button
                className='primary'
                disabled={movingNextStep || !validate}
                onClick={next}
              >
                Next
                {movingNextStep ? (
                  <LoaderIndicator className='button-loading' loading={true} />
                ) : null}
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Cancel import'
            >
              <button
                className='secondary'
                onClick={() => {
                  if (id) {
                    setCancellingUploadFile(true);
                    cancelUploadFile(id)
                      .then(() => {
                        navigate('/assets/assetsUpload');
                      })
                      .then(() => {
                        setCancellingUploadFile(false);
                      });
                  }
                }}
              >
                Cancel import
                {cancellingUploadFile ? (
                  <LoaderIndicator className='button-loading' loading={true} />
                ) : null}
              </button>
            </TooltipWrapper>
          </div>
        </div>
      </div>
      <Loader
        isOpen={loadingFileAssets || updatingUploadFile || fetchingUploadFile}
      />
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  states: state.states,
  uploadAssetsFile: state.uploadAssetsFile,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setUploadAssetsFile: (value: File | undefined) =>
    dispatch(actions.setUploadAssetsFile(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetsMapFile);
