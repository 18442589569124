import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import OutsideClickHandler from 'react-outside-click-handler';
import style from './FieldSelectYear.module.scss';
import {SelectOption} from '../../interface';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import { TOOLTIP_SECTIONS } from '../../enums';

interface Props {
  labelText: string;
  classnames?: string;
  required?: boolean;
  tooltipText?: string;
  disabled?: boolean;
  error?: string;
  options: SelectOption[];
  onSelect: (option: SelectOption | undefined) => void;
  selectId?: number | null;
}

//This component is on add owner page,it is different from other select,user could not des-selected it
function FieldSelectYear(props: Props) {
  const {
    labelText,
    classnames,
    required,
    tooltipText,
    disabled,
    error,
    options,
    onSelect,
    selectId,
  } = props;
  //The selected option
  const [selected, setSelected] = useState<string>('');
  //Status of options flyout
  const [visible, setvisible] = useState<boolean>(false);

  //If options is empty or do not selected,pass empty string to parent
  useEffect(() => {
    if (selectId) {
      if (_.isEmpty(options)) {
        setSelected('');
      } else {
        setSelected(
          _.get(_.find(options, ['value', selectId]), 'label') as string,
        );
      }
    } else {
      setSelected(options[0].label);
    }
  }, [selectId, options]);
  return (
    <label
      className={classNames(
        style['form-control'],
        classnames,
        {error: error},
        {disabled: disabled},
      )}
    >
      {/* Lable of input,accept required and tooltip parameters */}
      <div className={style['label']}>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.Common}
          tooltipKey={tooltipText ? tooltipText : labelText}
        >
          <span>
            {labelText}
            {required && <i className={style['required']}>*</i>}
          </span>
        </TooltipWrapper>
      </div>
      <div
        className={style['input-control']}
        onClick={() => setvisible(!visible)}
      >
        {/* click outside flyout disappear */}
        <OutsideClickHandler
          onOutsideClick={() => {
            setvisible(false);
          }}
        >
          {/* Selected option field */}
          <div className={style['selected']}>
            {selected ? selected : 'Select'}
          </div>
          {visible && (
            <div className={style['options']}>
              <ul>
                {/* Options list */}
                {options.map((option, index) => (
                  <li
                    key={index}
                    onClick={(evt) => {
                      setSelected(option.label);
                      onSelect(option);
                      setvisible(false);
                      evt.stopPropagation();
                    }}
                    className={classNames({
                      [style['active']]: _.isEqual(option, selected),
                    })}
                  >
                    {option['label']}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </OutsideClickHandler>
      </div>
      {/* Error message below the select */}
      {error && <span className={style['error-message']}>{error}</span>}
    </label>
  );
}

export default FieldSelectYear;
