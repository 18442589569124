import classNames from 'classnames';
import DOMPurify from 'dompurify';
import _ from 'lodash';
import React, {RefObject, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Loader from '../../components/Loader/Loader';
import RightNavigationPanel from '../../components/RightNavigationPanel/RightNavigationPanel';
import SearchInput from '../../components/SearchInput/SearchInput';
import Selection, {SelectionItem} from '../../components/Selection/Selection';
import TooltipWrapper from '../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../enums';
import {
  HelpCategory,
  HelpCategoryTopic,
  TopicVersionStatus,
} from '../../interface';
import {getHelpContentsAction} from '../../redux/features/admin-slice';
import {RootState, useAppDispatch} from '../../redux/store';
import {getHelpCategory, getHelpTopicVersionContent} from '../../services';
import style from './Help.module.scss';
import { useSearchParams } from 'react-router-dom';

interface Props {
  helpContents: HelpCategory[];
}

function Help({helpContents}: Props) {
  const dispatch = useAppDispatch();
  const [selectionItems, setSelectionItems] = useState<SelectionItem[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<HelpCategory>();
  const [selectedItem, setSelectedItem] = useState<SelectionItem>();
  const [selectedTopic, setSelectedTopic] = useState<HelpCategoryTopic>();
  const [ref, setRef] = useState<Map<number | undefined, RefObject<HTMLDivElement>>>();
  const [search, setSearch] = useState<string>('');
  const [showLoader, setShowLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCategorySelection = (id: number) => {
    setShowLoader(true);
    getHelpCategory(id)
      .then((resp) => {
        setRef(
          new Map(resp.data.topics.map((item) => [item.id, React.createRef()])),
        );

        // Fetch topic contents
        const contentPromises = resp.data.topics.map(topic => {
          const version = _.defaults(
            topic.versions.find((x) => x.status === TopicVersionStatus.Published),
          );

          return getHelpTopicVersionContent(version.s3PresignedUrl || '')
            .then((response) => {
              version.content = response?.data;
            });
        });

        Promise.all(contentPromises).then(() => {
          setSelectedCategory(resp.data);
        });
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  /**
   * function to get topic version
   * @param topic topic
   */
  const getTopicVersion = (topic: HelpCategoryTopic) => {
    const version = _.defaults(
      topic.versions.find((x) => x.status === TopicVersionStatus.Published),
    );
    
    return version;
  }

  useEffect(() => {
    dispatch(getHelpContentsAction());
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      setSelectedItem(selectionItems.find((x) => x.id === selectedCategory.id));
    }
  }, [selectedCategory]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (helpContents) {
        const data = helpContents.map(
          (x) =>
            ({
              name: x.title,
              callback: (id: number) => {
                if (id) {
                  handleCategorySelection(id);
                }
              },
              id: x.id,
            } as SelectionItem),
        );
        setSelectionItems(
          data.filter((x) =>
            x.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
          ),
        );

        const selectedCategoryId = parseInt(searchParams.get('category') ?? '');
        if (!selectedCategory && helpContents[0]) {
          handleCategorySelection(selectedCategoryId || helpContents[0].id || 0);
        }
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search, helpContents]);

  useEffect(() => {
    if (selectedTopic) {
      ref?.get(selectedTopic.id)?.current?.scrollIntoView({behavior: 'smooth'});
    }
  }, [selectedTopic]);

  useEffect(() => {
    if (selectedCategory) {
      const selectedTopicId = parseInt(searchParams.get('topic') ?? '');
      const selectedTopic = selectedCategory.topics.find(topic => topic.id === selectedTopicId) || selectedCategory.topics[0];
      setSelectedTopic(selectedTopic);
    }
  }, [selectedCategory]);

  return (
    <div className={style['admin-container']}>
      <div className={style['admin-content']}>
        <h2>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageTitle}
            tooltipKey='Help'
          >
            <span>Help</span>
          </TooltipWrapper>
        </h2>
        <div className={style['selection-container']}>
          <SearchInput
            placeholder='Search properties…'
            value={search}
            onChange={(value) => setSearch(value)}
            className={style['search']}
          />
          <div className={style['selection-wrapper']}>
            <Selection
              title='Browse Help'
              selectionList={selectionItems}
              selectedItem={selectedItem}
            />
          </div>
        </div>
      </div>
      <div className={style['main-content']}>
        <div className={style['content-wrapper']}>
          {selectedCategory?.topics.map((topic, index) => {
            const myRef = ref?.get(topic.id);
            return (
              <div ref={myRef} key={index} className={style['topic-wrapper']}>
                <div className={style['topic']}>
                  {getTopicVersion(topic).title}
                </div>
                <div
                  className={style['content']}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(getTopicVersion(topic).content || ''),
                  }}
                ></div>
              </div>
            );
          })}
        </div>
        <RightNavigationPanel
          icon={<i className={style['icon']}></i>}
          title='Help Categories'
        >
          <ul>
            {selectedCategory?.topics.map((topic, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setSelectedTopic(topic);
                    const myRef = ref?.get(topic.id);
                    if (myRef) {
                      myRef.current?.scrollTo();
                    }
                  }}
                >
                  <div
                    className={classNames(
                      style['topic'],
                      selectedTopic &&
                        topic.id === selectedTopic.id &&
                        style['selected'],
                    )}
                  >
                    {getTopicVersion(topic).title}
                  </div>
                  <div
                    className={style['content']}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(_.truncate(getTopicVersion(topic).content || '', { length: 1000 })),
                    }}
                  ></div>
                </li>
              );
            })}
          </ul>
        </RightNavigationPanel>
      </div>
      <Loader isOpen={showLoader} />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  helpContents: state.admin.helpContents,
});

export default connect(mapStateToProps)(Help);
