/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import update from 'immutability-helper';
import _ from 'lodash';
import {connect} from 'react-redux';
import RightNavigationPanel from '../../../components/RightNavigationPanel/RightNavigationPanel';
import {
  HelpCategory,
  HelpCategoryTopic,
  IToastMessage,
  TopicVersionStatus,
} from '../../../interface';
import {
  addHelpCategoryToStore,
  AsyncStatus,
  deleteHelpContent,
  getHelpContentsAction,
  resetStatus,
  updateHelpCategoryToStore,
  updateHelpTopicToStore,
} from '../../../redux/features/admin-slice';
import {RootState, useAppDispatch} from '../../../redux/store';
import style from './HelpContent.module.scss';
import ToastMessage, {
  ToastMessageRefType,
} from '../../../components/ToastMessage/ToastMessage';
import HelpCategoryForm, {
  HelpCategoryFormData,
} from '../../../components/HelpCategoryForm/HelpCategoryForm';
import Modal from '../../../components/Modal/Modal';
import SortableCard from '../../../components/SortableCard/SortableCard';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {
  addHelpTopic,
  createHelpCategory,
  deleteHelpCategory,
  deleteHelpTopic,
  getErrorMessage,
  getTopic,
  reorderHelpCategory,
  reorderHelpTopic,
  updateHelpCategory,
  updateHelpTopic,
  getHelpTopicVersionContent,
} from '../../../services';
import Loader from '../../../components/Loader/Loader';

interface Props {
  helpContents: HelpCategory[];
  updateContentStatus: AsyncStatus;
  fetchStatus: AsyncStatus;
  fetchHelpContentsStatus: AsyncStatus;
}

interface SelectedItem {
  category?: HelpCategory;
  topic?: HelpCategoryTopic;
  addMethod?: (val?: HelpCategory) => void;
}

const emptyFormData = {
  categoryTitle: '',
  hiddenFromPublic: false,
  tags: [],
  topicContent: '',
  topicCount: 0,
  topicTitle: '',
  categoryId: 0,
  topicId: 0,
  topicVersionId: 0,
};

function HelpContent({
  helpContents,
  updateContentStatus,
  fetchStatus,
  fetchHelpContentsStatus,
}: Props) {
  const dispatch = useAppDispatch();
  const [localHelpContents, setLocalHelpContents] = useState(helpContents);

  const [draggingTopicPosition, setDraggingTopicPosition] = useState(-1);
  const handleTopicMoveDragIndex = useRef(-1);
  const handleTopicMoveHoverIndex = useRef(-1);

  const [draggingCategoryPosition, setDraggingCategoryPosition] = useState(-1);
  const handleCategoryMoveDragIndex = useRef(-1);
  const handleCategoryMoveHoverIndex = useRef(-1);

  const [selectedHelpCategory, setSelectedHelpCategory] =
    useState<HelpCategory>();
  const [selectedTopic, setSelectedTopic] = useState<HelpCategoryTopic>();
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [formData, setFormData] = useState<HelpCategoryFormData>();
  const [isEditing, setIsEditing] = useState(false);
  const [toBeDeletedCategory, setToBeDeletedCategory] = useState<number>();
  const [toBeDeletedTopic, setToBeDeletedTopic] = useState<number>();
  const [selectedItem, setSelectedItem] = useState<SelectedItem | undefined>(
    undefined,
  );
  const [showWarning, setShowWarning] = useState(false);
  const [showSortWarning, setShowSortWarning] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const toastRef = useRef<ToastMessageRefType | null>(null);

  /**
   * utility method for category/topic selection.
   * @param category category
   * @param topic topic
   */
  const handleSelection = (
    category: HelpCategory,
    topic: HelpCategoryTopic,
  ) => {
    // set the category & topic
    setSelectedHelpCategory(category);
    setSelectedTopic(topic);
    setFormData(emptyFormData);
  };

  /**
   * Utility function to generate new topic
   * @param category category
   */
  const generateNewTopic = (category: HelpCategory) => {
    return {
      displayOrder: category.topics.length + 1,
      versions: [
        {
          hiddenFromPublic: false,
          status: TopicVersionStatus.Draft,
          title: `${category.title} - New Topic`,
          tags: [],
        },
      ],
    };
  };

  /**
   * Utilitiy to add new topic
   * @param category
   */
  const addNewTopic = (category?: HelpCategory) => {
    if (category) {
      const tempHelpContents = [...localHelpContents];
      const tempCategory = _.cloneDeep(category);
      tempCategory.topics.push(generateNewTopic(tempCategory));

      // update the local version of category list for new topic via category
      const index = tempHelpContents.findIndex((x) => x.id == tempCategory.id);
      if (index >= 0) {
        tempHelpContents[index] = tempCategory;
        setLocalHelpContents(tempHelpContents);
        handleSelection(
          tempCategory,
          tempCategory.topics[tempCategory.topics.length - 1],
        );
      }
      setIsEditing(true);
    }
  };

  const deleteUnsavedTopic = (category?: HelpCategory) => {
    if (category) {
      const tempHelpContents = _.map(localHelpContents, (cat) => {
        if (cat.id === category.id) {
          return {...cat, topics: _.filter(cat.topics, (topic) => !!topic.id)};
        }
        return cat;
      });

      setLocalHelpContents(tempHelpContents);
      handleSelection(category, category.topics[0]);
    }
  };

  /**
   * function to handle confirmation of selection category/topic
   * @param item selected item for category/topic selection
   */
  const handleConfirmation = (item: SelectedItem) => {
    // remove the current selection of category/topic from local state if they are new.
    if (selectedHelpCategory && !selectedHelpCategory.id) {
      let tempLocalHelpContents = [...localHelpContents];
      tempLocalHelpContents = tempLocalHelpContents.filter(
        (x) => x.id !== selectedHelpCategory.id,
      );
      setLocalHelpContents(tempLocalHelpContents);
    } else if (selectedTopic && !selectedTopic.id) {
      const tempLocalHelpContents = [...localHelpContents];
      const tempHelpCategory = _.cloneDeep(selectedHelpCategory);

      const index = tempLocalHelpContents.findIndex(
        (x) => x.id === tempHelpCategory?.id,
      );
      if (index >= 0 && tempHelpCategory) {
        tempHelpCategory.topics = tempHelpCategory?.topics.filter(
          (x) => x.id !== selectedTopic.id,
        );
        tempLocalHelpContents[index] = tempHelpCategory;
        setLocalHelpContents(tempLocalHelpContents);
        setSelectedHelpCategory(tempHelpCategory);
        item.category = tempHelpCategory;
      }
    }

    if (item.addMethod) {
      item.addMethod(item?.category);
    } else if (item.category && item.topic) {
      handleSelection(item?.category, item?.topic);
    }
    setSelectedItem(undefined);
  };

  /**
   * function to add new category into local state.
   */
  const addNewCategory = () => {
    const tempHelpContents = [...localHelpContents];
    const newCategory: HelpCategory = {
      displayOrder: localHelpContents.length + 1,
      title: `New Category`,
      topics: [],
    };
    newCategory.topics.push(generateNewTopic(newCategory));

    tempHelpContents.push(newCategory);
    setLocalHelpContents(tempHelpContents);
    handleSelection(newCategory, newCategory.topics[0]);
  };

  const deleteUnsavedCategory = () => {
    const tempHelpContents = _.filter(
      [...localHelpContents],
      (category) => !!category.id,
    );

    setLocalHelpContents(tempHelpContents);
    handleSelection(tempHelpContents[0], tempHelpContents[0]?.topics[0]);
  };

  const getDisplayOrder = <T extends {id?: number}>(arr: T[]) =>
    arr.map((item, idx) => ({
      id: item.id || 0,
      displayOrder: idx + 1,
    }));

  /**
   * function to handle categories sorting
   * @param dragIndex swap position from
   * @param hoverIndex swap position to
   */
  const handleCategoryMove = (dragIndex: number, hoverIndex: number) => {
    handleCategoryMoveDragIndex.current = dragIndex;
    handleCategoryMoveHoverIndex.current = hoverIndex;
    if (isEditing) {
      setShowSortWarning(true);
    } else {
      setDraggingCategoryPosition(hoverIndex);
    }
  };

  const handleCategoryDrop = () => {
    if (isEditing) {
      setShowSortWarning(true);
    } else if (
      handleCategoryMoveDragIndex.current !==
      handleCategoryMoveHoverIndex.current
    ) {
      const dragIndex = handleCategoryMoveDragIndex.current;
      const hoverIndex = handleCategoryMoveHoverIndex.current;

      const newLocalHelpContents = update(localHelpContents, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, localHelpContents[dragIndex]],
        ],
      });
      setLocalHelpContents(newLocalHelpContents);

      const tempHelpContents = _.cloneDeepWith(newLocalHelpContents);
      reorderHelpCategory(getDisplayOrder(tempHelpContents));
    }
    handleCategoryMoveDragIndex.current = -1;
    handleCategoryMoveHoverIndex.current = -1;
    setDraggingCategoryPosition(-1);
  };

  const handleCategoryCancel = () => {
    handleCategoryMoveDragIndex.current = -1;
    handleCategoryMoveHoverIndex.current = -1;
    setDraggingCategoryPosition(-1);
  };

  /**
   * function to handle topic sorting
   * @param dragIndex swap position from
   * @param hoverIndex swap position to
   * @param categoryIndex category index
   */
  const handleTopicMove = (dragIndex: number, hoverIndex: number) => {
    handleTopicMoveDragIndex.current = dragIndex;
    handleTopicMoveHoverIndex.current = hoverIndex;
    if (isEditing) {
      setShowSortWarning(true);
    } else {
      if (selectedHelpCategory) {
        setDraggingTopicPosition(hoverIndex);
      }
    }
  };

  const handleTopicDrop = (categoryIndex: number) => {
    if (isEditing) {
      setShowSortWarning(true);
    } else if (
      handleTopicMoveDragIndex.current !== handleTopicMoveHoverIndex.current &&
      draggingTopicPosition >= 0
    ) {
      if (selectedHelpCategory) {
        const dragIndex = handleTopicMoveDragIndex.current;
        const hoverIndex = handleTopicMoveHoverIndex.current;

        const newCategory = update(localHelpContents[categoryIndex], {
          topics: (topics) =>
            update(topics || [], {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, topics[dragIndex]],
              ],
            }),
        });
        setLocalHelpContents((prevCards: HelpCategory[]) =>
          update(prevCards, {
            [categoryIndex]: () => {
              return newCategory;
            },
          }),
        );
        setSelectedHelpCategory(newCategory);
        const tempHelpCategory = _.cloneDeep(newCategory);
        reorderHelpTopic(getDisplayOrder(tempHelpCategory.topics));
      }
    }
    handleTopicMoveDragIndex.current = -1;
    handleTopicMoveHoverIndex.current = -1;
    setDraggingTopicPosition(-1);
  };

  const handleTopicCancel = () => {
    handleTopicMoveDragIndex.current = -1;
    handleTopicMoveHoverIndex.current = -1;
    setDraggingTopicPosition(-1);
  };

  /**
   * function to get topic title
   * @param topic topic
   */
  const getTopicTitle = (topic: HelpCategoryTopic) => {
    const displayVersion = _.defaults(
      topic.versions.find((x) => x.status === TopicVersionStatus.Draft),
      topic.versions.find((x) => x.status === TopicVersionStatus.Published),
    );

    return displayVersion.title;
  };

  const updateTopic = (isPublish: boolean, data: HelpCategoryFormData) => {
    setShowLoader(true);
    const helpCategoryId = data.categoryId || 0;
    updateHelpTopic(data.topicId || 0, {
      helpCategoryId,
      versions: [
        {
          id: data.topicVersionId,
          status: isPublish ? 'Published' : 'Draft',
          hiddenFromPublic: data.hiddenFromPublic,
          tags: data.tags,
          title: data.topicTitle,
          content: data.topicContent,
        },
      ],
    })
      .then((resp) => {
        const errorMessage = getErrorMessage(resp);
        if (errorMessage) {
          toastRef.current?.showErrorToast(errorMessage);
        } else {
          toastRef.current?.showSuccessToast('Update successfully');
          setSelectedTopic(resp.data);
          dispatch(updateHelpTopicToStore(resp.data));
        }
        setShowLoader(false);
      })
      .catch((e) => {
        toastRef.current?.showErrorToast(getErrorMessage(e));
        setShowLoader(false);
      });
  };

  const createTopic = (isPublish: boolean, data: HelpCategoryFormData) => {
    setShowLoader(true);
    addHelpTopic({
      helpCategoryId: data.categoryId || 0,
      versions: [
        {
          status: isPublish ? 'Published' : 'Draft',
          hiddenFromPublic: data.hiddenFromPublic,
          tags: data.tags,
          title: data.topicTitle,
          content: data.topicContent,
        },
      ],
    })
      .then((resp) => {
        const errorMessage = getErrorMessage(resp);
        if (errorMessage) {
          toastRef.current?.showErrorToast(errorMessage);
        } else {
          setSelectedTopic(resp.data);
          if (selectedHelpCategory) {
            const selectedCategory = _.cloneDeep(selectedHelpCategory);
            selectedCategory.topics = selectedCategory.topics.filter(
              (x) => x.id,
            );
            selectedCategory.topics.push(resp.data);
            setSelectedHelpCategory(selectedCategory);
            dispatch(updateHelpCategoryToStore(selectedCategory));
          }
          toastRef.current?.showSuccessToast('Update successfully');
        }
        setShowLoader(false);
      })
      .catch((e) => {
        toastRef.current?.showErrorToast(getErrorMessage(e));
        setShowLoader(false);
      });
  };

  const updateCategory = (isPublish: boolean, data: HelpCategoryFormData) => {
    setShowLoader(true);
    updateHelpCategory(data.categoryId || 0, data.categoryTitle)
      .then((resp) => {
        const errorMessage = getErrorMessage(resp);
        if (errorMessage) {
          setToastMessage({
            message: errorMessage,
            visible: true,
            type: 'error',
          });
        } else {
          setSelectedHelpCategory(resp.data);
          dispatch(updateHelpCategoryToStore(resp.data));
          if (data.topicId) {
            updateTopic(isPublish, data);
          } else {
            createTopic(isPublish, data);
          }
        }

        setShowLoader(false);
      })
      .catch((e) => {
        toastRef.current?.showErrorToast(getErrorMessage(e));
        setShowLoader(false);
      });
  };

  const createCategory = (isPublish: boolean, data: HelpCategoryFormData) => {
    setShowLoader(true);
    createHelpCategory({
      title: data.categoryTitle,
      topics: [
        {
          versions: [
            {
              status: isPublish ? 'Published' : 'Draft',
              hiddenFromPublic: data.hiddenFromPublic,
              tags: data.tags,
              title: data.topicTitle,
              content: data.topicContent,
            },
          ],
        },
      ],
    })
      .then((resp) => {
        const errorMessage = getErrorMessage(resp);
        if (errorMessage) {
          setToastMessage({
            message: errorMessage,
            visible: true,
            type: 'error',
          });
        } else {
          setSelectedHelpCategory(resp.data);
          setSelectedTopic(resp.data.topics[0]);
          dispatch(addHelpCategoryToStore(resp.data));
          setToastMessage({
            message: 'Help Content is created successfully.',
            visible: true,
            type: 'success',
          });
        }
        setShowLoader(false);
      })
      .catch((e) => {
        toastRef.current?.showErrorToast(getErrorMessage(e));
        setShowLoader(false);
      });
  };

  const handleFormSubmit = (isPublish: boolean, data: HelpCategoryFormData) => {
    if (data.categoryId) {
      updateCategory(isPublish, data);
    } else {
      createCategory(isPublish, data);
    }
  };

  const handleSelectTopic = (
    category: HelpCategory,
    topic: HelpCategoryTopic,
  ) => {
    if (!isEditing) {
      handleSelection(category, topic);
    } else {
      setSelectedItem({
        category: selectedHelpCategory,
        topic,
      });
    }
  };

  const handleFormSetup = (
    category: HelpCategory,
    topic: HelpCategoryTopic,
  ) => {
    const displayVersion = _.defaults(
      topic.versions.find((x) => x.status === TopicVersionStatus.Draft),
      topic.versions.find((x) => x.status === TopicVersionStatus.Published),
    );

    const formData = {
      categoryTitle: category.title,
      hiddenFromPublic: displayVersion.hiddenFromPublic,
      tags: displayVersion.tags,
      topicContent: '',
      topicCount: category.topics.length,
      topicTitle: displayVersion.title,
      categoryId: category.id,
      topicId: topic.id,
      topicVersionId: displayVersion.id,
    }

    // Fetch content from S3
    if (displayVersion.s3PresignedUrl) {
      getHelpTopicVersionContent(displayVersion.s3PresignedUrl || '')
      .then((response) => {
        formData.topicContent = response?.data;
        setFormData(formData);
      });
    }
    else {
      setFormData(formData);
    }

    // if the topic & category is not new then set editing false.
    if (topic.id) {
      setIsEditing(false);
    }
  };

  const handleTopicDelete = (id: number, category: HelpCategory) => {
    if (id === 0) {
      deleteUnsavedTopic(category);
    } else {
      setShowLoader(true);
      deleteHelpTopic(id)
        .then((d) => {
          const errorMessage = getErrorMessage(d);
          if (errorMessage) {
            toastRef.current?.showErrorToast(errorMessage);
          } else {
            const tempCategory = _.cloneDeep(category);
            const index = tempCategory?.topics.findIndex((x) => x.id === id);
            if (index >= 0) {
              tempCategory.topics.splice(index, 1);
            }

            dispatch(updateHelpCategoryToStore(tempCategory));
            handleSelection(tempCategory, tempCategory.topics[0]);
            toastRef.current?.showSuccessToast('Delete successfully');
          }
        })
        .finally(() => {
          setShowLoader(false);
        });
    }
  };

  const handleCategoryDelete = (id: number) => {
    if (id === 0) {
      deleteUnsavedCategory();
    } else {
      setShowLoader(true);
      deleteHelpCategory(id)
        .then((d) => {
          const errorMessage = getErrorMessage(d);
          if (errorMessage) {
            toastRef.current?.showErrorToast(errorMessage);
          } else {
            toastRef.current?.showSuccessToast('Delete successfully');
            dispatch(deleteHelpContent(id));
          }
        })
        .finally(() => {
          setShowLoader(false);
        });
    }
  };

  useEffect(() => {
    if (
      localHelpContents &&
      localHelpContents.length > 0 &&
      localHelpContents[0].topics.length > 0
    ) {
      if (!localHelpContents.find((x) => x.id === selectedHelpCategory?.id)) {
        handleSelection(localHelpContents[0], localHelpContents[0].topics[0]);
      }
    } else {
      setSelectedHelpCategory(undefined);
      setSelectedTopic(undefined);
      setFormData(emptyFormData);
    }
  }, [localHelpContents]);

  useEffect(() => {
    dispatch(getHelpContentsAction());
  }, []);

  useEffect(() => {
    if (selectedTopic && selectedHelpCategory) {
      if (selectedTopic.id) {
        setShowLoader(true);
        getTopic(selectedTopic.id)
          .then((resp) => {
            setSelectedTopic(resp.data);
            handleFormSetup(selectedHelpCategory, resp.data);
            dispatch(updateHelpTopicToStore(resp.data));
          })
          .finally(() => {
            setShowLoader(false);
          });
      } else {
        handleFormSetup(selectedHelpCategory, selectedTopic);
      }
    }
  }, [selectedTopic?.id]);

  useEffect(() => {
    setLocalHelpContents(helpContents);
  }, [helpContents]);

  useEffect(() => {
    if (updateContentStatus === 'fulfilled') {
      dispatch(resetStatus());
      setToastMessage({
        message: 'Help Content updated successfully.',
        visible: true,
        type: 'success',
      });
      if (
        !selectedHelpCategory &&
        localHelpContents &&
        localHelpContents.length > 0 &&
        localHelpContents[0].topics.length > 0
      ) {
        handleSelection(localHelpContents[0], localHelpContents[0].topics[0]);
      }
    }
  }, [updateContentStatus]);

  useEffect(() => {
    if (
      updateContentStatus === 'fulfilled' ||
      updateContentStatus === 'rejected'
    ) {
      dispatch(resetStatus());
    }
  }, [fetchStatus]);

  const renderCategoryCard = (item: HelpCategory, index: number) => (
    <SortableCard
      key={`${item.id}`}
      id={`${item.id}`}
      index={index}
      onMove={handleCategoryMove}
      onDrop={handleCategoryDrop}
      onCancel={handleCategoryCancel}
      type='Category'
      className={classNames(style.categorySortItem, {
        [style.newPositionDragingTop]:
          draggingCategoryPosition === index &&
          draggingCategoryPosition < handleCategoryMoveDragIndex.current,
        [style.newPositionDragingBottom]:
          draggingCategoryPosition === index &&
          draggingCategoryPosition > handleCategoryMoveDragIndex.current,
      })}
    >
      <div
        className={classNames(
          style['listItemWrapper'],
          item.id === selectedHelpCategory?.id && style['selected'],
        )}
        key={index}
        onClick={() => {
          if (selectedHelpCategory?.id === item.id) {
            return;
          }

          if (!isEditing) {
            handleSelection(item, item.topics[0]);
          } else {
            setSelectedItem({
              category: item,
              topic: item.topics[0],
            });
          }
        }}
      >
        <div className={style['item']}>
          <i className={style['icon-arrow']}></i>
          <span className={style['help-content-title']}>{item.title}</span>
        </div>
        {item.id === selectedHelpCategory?.id && (
          <div className={style['topics']}>
            {item.topics.map((topic, index2) =>
              renderTopicCard(topic, index2, index, item.id || 0),
            )}
            <li>
              <i
                role='button'
                className={classNames('link', style['add-link'])}
                onClick={(e) => {
                  e.stopPropagation();
                  if (selectedHelpCategory) {
                    if (isEditing) {
                      if (
                        (selectedHelpCategory && !selectedHelpCategory.id) ||
                        (selectedTopic && !selectedTopic.id)
                      ) {
                        setShowWarning(true);
                      } else {
                        setSelectedItem({
                          category: selectedHelpCategory,
                          topic: selectedHelpCategory.topics[0],
                          addMethod: addNewTopic,
                        });
                      }
                    } else {
                      addNewTopic(selectedHelpCategory);
                    }
                  }
                }}
              >
                Add Help Topic
              </i>
            </li>
          </div>
        )}
      </div>
    </SortableCard>
  );

  const renderTopicCard = (
    topic: HelpCategoryTopic,
    index: number,
    itemIndex: number,
    itemId: number,
  ) => (
    <SortableCard
      key={`${itemId}-${topic.id}`}
      id={`${itemId}-${topic.id}`}
      index={index}
      onMove={(dragIndex, hoverIndex) => handleTopicMove(dragIndex, hoverIndex)}
      onDrop={() => handleTopicDrop(itemIndex)}
      onCancel={() => handleTopicCancel()}
      type='Topic'
    >
      <div
        key={index}
        className={classNames(
          style['listItemWrapper'],
          topic.id === selectedTopic?.id && style['selected'],
          {
            [style.newPositionDragingTop]:
              draggingTopicPosition === index &&
              draggingTopicPosition < handleTopicMoveDragIndex.current,
            [style.newPositionDragingBottom]:
              draggingTopicPosition === index &&
              draggingTopicPosition > handleTopicMoveDragIndex.current,
          },
        )}
        onClick={(e) => {
          e.stopPropagation();
          if (selectedTopic?.id === topic.id) {
            return;
          }
          if (selectedHelpCategory) {
            handleSelectTopic(selectedHelpCategory, topic);
          }
        }}
      >
        {getTopicTitle(topic)}
      </div>
    </SortableCard>
  );

  return (
    <div className={style['main-content']}>
      {localHelpContents && (
        <div className={style['main-wrapper']}>
          <ToastMessage
            status={toastMessage}
            ref={toastRef}
            className={style['toast']}
            visiableHandler={(value) => {
              if (toastMessage) {
                setToastMessage({...toastMessage, visible: value});
              }
            }}
          />
          <div className={style['form-container']}>
            {formData && (
              <HelpCategoryForm
                data={formData}
                onReset={() => {
                  setIsEditing(false);
                }}
                onSubmit={(isPublish, data) => {
                  setIsEditing(false);
                  handleFormSubmit(isPublish, data);
                }}
                onEditing={setIsEditing}
                onDeleteCategory={setToBeDeletedCategory}
                onDeleteTopic={setToBeDeletedTopic}
              />
            )}
          </div>
        </div>
      )}
      <RightNavigationPanel
        icon={<i className={style['icon']}></i>}
        title='Help Categories'
      >
        <DndProvider backend={HTML5Backend}>
          <div className={style.helpCategoriesWrapper}>
            {localHelpContents &&
              localHelpContents.map((item, index) =>
                renderCategoryCard(item, index),
              )}
            <div>
              <i
                role='button'
                className={classNames('link', style['add-link'])}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isEditing) {
                    if (
                      (selectedHelpCategory && !selectedHelpCategory.id) ||
                      (selectedTopic && !selectedTopic.id)
                    ) {
                      setShowWarning(true);
                    } else {
                      setSelectedItem({
                        category: selectedHelpCategory,
                        addMethod: addNewCategory,
                      });
                    }
                  } else {
                    addNewCategory();
                  }
                }}
              >
                Add Help Category
              </i>
            </div>
          </div>
        </DndProvider>
      </RightNavigationPanel>
      <Modal
        title='Confirmation'
        body={<p>Are you sure you want to delete?</p>}
        isOpen={_.isNumber(toBeDeletedCategory) || _.isNumber(toBeDeletedTopic)}
        footer={
          <div className='buttons'>
            <button
              className='primary-button'
              onClick={() => {
                if (_.isNumber(toBeDeletedCategory)) {
                  handleCategoryDelete(toBeDeletedCategory);
                } else if (_.isNumber(toBeDeletedTopic)) {
                  if (selectedHelpCategory) {
                    handleTopicDelete(toBeDeletedTopic, selectedHelpCategory);
                  }
                }
                setToBeDeletedCategory(undefined);
                setToBeDeletedTopic(undefined);
              }}
            >
              Confirm
            </button>
            <button
              className='default-button'
              onClick={() => {
                setToBeDeletedCategory(undefined);
                setToBeDeletedTopic(undefined);
              }}
            >
              Cancel
            </button>
          </div>
        }
      />
      <Modal
        title='Confirmation'
        body={
          <p>There are unsaved changes, are you sure you want to discard?</p>
        }
        isOpen={!!selectedItem}
        onClose={() => setSelectedItem(undefined)}
        footer={
          <div className='buttons'>
            <button
              className='primary-button'
              onClick={() => {
                if (selectedItem) {
                  handleConfirmation(selectedItem);
                }
              }}
            >
              Confirm
            </button>
            <button
              className='default-button'
              onClick={() => {
                setSelectedItem(undefined);
              }}
            >
              Cancel
            </button>
          </div>
        }
      />

      <Modal
        title='Warning'
        body={
          <p>You need to save the help category before adding new topic.</p>
        }
        isOpen={showWarning}
        onClose={() => setShowWarning(false)}
        footer={
          <div className='buttons'>
            <button
              className='default-button'
              onClick={() => {
                setShowWarning(false);
              }}
            >
              Ok
            </button>
          </div>
        }
      />

      <Modal
        title='Warning'
        body={<p>Please save the editing items before sort.</p>}
        isOpen={showSortWarning}
        onClose={() => setShowSortWarning(false)}
        footer={
          <div className='buttons'>
            <button
              className='default-button'
              onClick={() => {
                setShowSortWarning(false);
              }}
            >
              Ok
            </button>
          </div>
        }
      />
      <Loader
        isOpen={
          fetchStatus === 'pending' ||
          showLoader ||
          fetchHelpContentsStatus === 'pending'
        }
      />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  helpContents: state.admin.helpContents,
  fetchHelpContentsStatus: state.admin.fetchHelpContentsStatus,
  updateContentStatus: state.admin.updateContentStatus,
  fetchStatus: state.admin.fetchStatus,
});

export default connect(mapStateToProps)(HelpContent);
