import React, {MutableRefObject, useState} from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import style from './Dropzone.module.scss';
import {getDownloadTemplate, getErrorMessage, uploadFile} from '../../services';
import exportXLSX from '../../utils/exportFile';
import useMappedResult from '../../hook/useMappedResult';
import {Uploader} from '@avalara/skylab-react';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../enums';
import {LoaderIndicator} from '../Loader/Loader';
import {ToastMessageRefType} from '../ToastMessage/ToastMessage';

interface Props {
  uploadError: (value: string) => void;
  toastRef: MutableRefObject<ToastMessageRefType | null>;
  uploadSuccess: () => void;
}

const maxFileSize = 75 * 1024 * 1024;

function DropZone(props: Props) {
  const {uploadError, toastRef, uploadSuccess} = props;
  const [files, setfiles] = useState<File[]>([]);
  const inputProps = {
    accept:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx,.xls,.csv',
    multiple: false,
    tabIndex: -1,
    type: 'file',
  };

  const onChange = (value: File[]) => {
    if (!value.length || value[0].size > maxFileSize) {
      return;
    }
    setfiles(value);
  };
  const [downloading, setDownloading] = useState(false);
  const download = () => {
    const res = getDownloadTemplate();
    setDownloading(true);
    exportXLSX(res, () => {
      setDownloading(false);
    });
  };
  const {fileRows} = useMappedResult(files[0]);
  const [uploading, setUploading] = useState(false);
  const upload = () => {
    if (_.isEmpty(fileRows)) {
      uploadError(`The uploaded file contains no data.`);
      return;
    }
    setUploading(true);
    uploadFile({file: files[0], type: 'Property'})
      .then((d) => {
        const errorMessage = getErrorMessage(d);
        if (errorMessage) {
          if (toastRef.current) {
            toastRef.current.showErrorToast(errorMessage);
          }
        } else {
          uploadSuccess();
        }
        setUploading(false);
        setfiles([]);
      })
      .catch((e) => {
        toastRef.current?.showErrorToast(getErrorMessage(e));
        setUploading(false);
        setfiles([]);
      });
  };
  const cancel = () => {
    setfiles([]);
  };
  return (
    <div className={classNames(style.container)}>
      {/* Uploader */}
      <Uploader
        onSAttach={(e) => {
          onChange(e.detail.files);
        }}
        className={files.length ? `filled` : 'non-filled'}
        i18n={{
          'uploader.dragDropFileSingle': 'Drag & drop file here',
          'uploader.dragDropFilesMultiple': 'Drag & drop file here',
          'uploader.chooseFileSingle': files.length
            ? 'Change file'
            : 'Choose file',
          'uploader.chooseFilesMultiple': files.length
            ? 'Change file'
            : 'Choose file',
        }}
        {...inputProps}
      >
        <div slot='guidelines'>
          <div className={style['placeholder']}>
            <small>File must be .XLS, .XLSX, or .CSV and under 75MB</small>
          </div>
          {!_.isEmpty(files) && (
            <ul className={style['list']}>
              {files.map((f, i) => (
                <li key={i}>
                  <p>
                    <i className={style['icon']}></i>
                    {f['name']}
                  </p>
                  <i role='button' className='icon-remove' onClick={cancel}></i>
                </li>
              ))}
            </ul>
          )}
          <div className={style['action']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Upload'
            >
              <button
                className='primary'
                onClick={upload}
                disabled={uploading || _.isEmpty(files)}
                type={'button'}
              >
                Upload file
                {uploading ? (
                  <LoaderIndicator className='button-loading' loading={true} />
                ) : null}
              </button>
            </TooltipWrapper>
          </div>
        </div>
      </Uploader>
      {/* Buttons */}
      <div className={style['buttons']}>
        <div className={style['operate']}>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageAction}
            tooltipKey='Download template and formatting guide'
          >
            <button
              className='button secondary'
              onClick={download}
              disabled={downloading}
            >
              Download template and formatting guide
              {downloading ? (
                <LoaderIndicator className='button-loading' loading={true} />
              ) : null}
            </button>
          </TooltipWrapper>
        </div>
      </div>
    </div>
  );
}

export default DropZone;
