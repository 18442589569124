import _ from 'lodash';
import React, {useContext, ReactNode} from 'react';
import classNames from 'classnames';
import {Dialog} from '@avalara/skylab-react';
import styles from './styles.module.scss';
import ModalContext from '../../context/ModalContext';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import { TOOLTIP_SECTIONS } from '../../enums';

interface Props {
  title: string;
  body: ReactNode;
  footer: ReactNode;
  isOpen: boolean;
  className?: string;
  innerModal?: boolean;
  onClose?: () => void;
  tooltipText?: string;
}

const compare = (prev: Props, next: Props) => {
  return _.isEqual(prev, next) ? true : false;
};

const Modal = React.memo(function Modal(props: Props) {
  const { title, body, footer, isOpen, className, onClose, tooltipText, innerModal=false } = props;
  const { dispatch } = useContext(ModalContext);

  return (
    <>
      <Dialog
        open={isOpen}
        className={classNames(className, styles.container)}
        onSDismiss={() => {
          dispatch({ type: "CLOSE" });
          onClose?.();
        }}
        noScroll={false}
      >
        <div
          slot='header'
          className={classNames(styles.header, 'modal-header')}
        >
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.Modal}
            tooltipKey={tooltipText ? tooltipText : title}
          >
            <span>{title}</span>
          </TooltipWrapper>
        </div>
        <div slot='body' className={classNames('modal-body')}>
          {body}
        </div>
        <div
          slot='footer'
          className={classNames(styles.footer, 'modal-footer')}
        >
          {footer}
        </div>
      </Dialog>
      {isOpen && <div className={classNames(styles.backdrop, innerModal && styles.innerModal)}></div>}
    </>
  );
}, compare);

export default Modal;
