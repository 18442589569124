import {useState} from 'react';
import _ from 'lodash';

function useSearch() {
  const [search, setsearch] = useState<string>('');
  // Change order when click on the table header
  // Filter properties by search input,ignored chars case
  function filteredData<T>(data: T[], value: string) {
    return data?.filter((item) => {
      return Object.keys(item).some(
        (key: string) =>
          _.isString(_.get(item, key)) &&
          _.lowerCase(_.get(item, key)).includes(value),
      );
    });
  }
  return {search, setsearch, filteredData};
}

export default useSearch;
