import { range } from 'lodash';
import moment from 'moment';

export const ISO_DATE = 'YYYY-MM-DD'; // 	ISO8601 date string format

export const getFirstDateOfThisYear = (format = ISO_DATE) => {
  return moment().startOf('year').format(format);
}

export const getLastDateOfThisYear = (format = ISO_DATE) => {
  return moment().endOf('year').format(format);
}

export const getToday = (format = ISO_DATE) => {
  return moment().format(format);
}

export const formatDateFromBackend = (backendDate: string | null | undefined, newFormat: string): string | null => {
  if (!backendDate) {
    return null;
  }
  return moment(backendDate, ISO_DATE).format(newFormat);
}

export const formatDateToBackend = (backendDate: string | null | undefined, newFormat: string): string | null => {
  if (!backendDate) {
    return null;
  }
  return moment(backendDate, newFormat).format(ISO_DATE);
}

export const getMonths = (): number[] => {
  return range(1, 13);
}

export const getYearsFrom = (from: number): number[] => {
  const thisYear = new Date().getFullYear();
  return range(thisYear, from - 1);
}

export const getDaysArray = function(
  month: number,
  year: number | null | undefined,
) {
  const yearValue = year || (new Date().getFullYear());
  const monthIndex = month - 1; // 0..11 instead of 1..12
  const date = new Date(yearValue, monthIndex, 1);
  const result = [];
  while (date.getMonth() == monthIndex) {
    result.push(date.getDate());
    date.setDate(date.getDate() + 1);
  }
  return result;
}