import {createSlice} from '@reduxjs/toolkit';

interface ISystemTaxYearSlice {
    taxYear: string;
}

const initialState: Partial<ISystemTaxYearSlice> = { taxYear: sessionStorage.getItem('selected-tax-year') || '' };

const selectedTaxYearSlice = createSlice({
  name: 'selectedTaxYear',
  initialState,
  reducers: {
    setSelectedTaxYear: (state, action) => {
      sessionStorage.setItem('selected-tax-year', action.payload.taxYear?.toString() || '');
      return Object.assign({}, state, action.payload);
    }
  },
});

export const { setSelectedTaxYear } = selectedTaxYearSlice.actions;
export default selectedTaxYearSlice;
