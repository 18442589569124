import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ToastMessage, { ToastMessageRefType } from "../../../components/ToastMessage/ToastMessage";
import { PurchaseReturn, PurchaseReturnUI } from "../../../interface";
import style from "./NewAccount.module.scss";
import PurchaseReturnForm from "../../../components/PurchaseReturnForm/PurchaseReturnForm";
import { RootState } from "../../../redux/store";
import { AsyncStatus } from "../../../redux/features/admin-slice";
import { connect } from "react-redux";
import { addPurchasedReturns, getErrorMessage } from "../../../services";

interface Props {
  addStatus: AsyncStatus;
}

function NewAccount({ addStatus }: Props) {
  const navigate = useNavigate();
  const toastRef = useRef<ToastMessageRefType | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const data: PurchaseReturnUI = {
    businessName: "",
    email: "",
    firstName: "",
    lastName: "",
    userPurchases: [
      {
        taxYear: 0,
        returnsPurchased: 0,
      },
    ],
    roles: undefined
  };

  const handleSubmit = (_data: PurchaseReturn) => {
    setSubmitting(true);
    addPurchasedReturns(_data).then((d) => {
      const errorMessage = getErrorMessage(d);
      if (errorMessage) {
        toastRef.current?.showErrorToast(errorMessage);
      } else {
        navigate("/admin/purchase-returns");
      }
      setSubmitting(false);
    }).catch((e) => {
      toastRef.current?.showErrorToast(getErrorMessage(e));
      setSubmitting(false);
    });
  };

  useEffect(() => {
    if (addStatus === "fulfilled") {
      toastRef.current?.showSuccessToast('Add successful');
    }
  }, [addStatus]);

  return (
    <div className={style["main-content"]}>
      <ToastMessage
        ref={toastRef}
        className={style["toast"]}
      />
      <h3>Setup New Customer Account</h3>
      <PurchaseReturnForm
        data={data}
        onSubmit={(_data) => handleSubmit(_data)}
        formClassName={style["form"]}
        onClear={() => {
          navigate("/admin/purchase-returns");
        }}
        submitting={submitting}
      />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  addStatus: state.admin?.addStatus,
});

export default connect(mapStateToProps)(NewAccount);
