import {useState, useEffect} from 'react';
import _ from 'lodash';
import {AssetField, OriginalFileField} from '../interface';
import {ASSETFILETYPE} from './../constants';
import {
  FIXEDASSETFILETOFIELD,
  INVENTORYFILETOFIELD,
  LEASEDEQUIPMENTFILETOFIELD,
  LEASEDVEHICLEFILETOFIELD,
  VEHICLEFILETOFIELD,
} from '../enums';
import {getAllProperties} from '../services';
import formatAssets from '../utils/formatAssets';
import removeAssetsEmptyFields from '../utils/removeAssetsEmptyFields';
import moment from 'moment';

type FileToField =
  | typeof FIXEDASSETFILETOFIELD
  | typeof INVENTORYFILETOFIELD
  | typeof VEHICLEFILETOFIELD
  | typeof LEASEDVEHICLEFILETOFIELD
  | typeof LEASEDEQUIPMENTFILETOFIELD;

function returnKey(field: FileToField, k: string) {
  if (_.get(field, k)) {
    return _.get(field, k);
  } else {
    if (_.isEqual(k, 'id')) {
      return 'id';
    } else {
      return 'null';
    }
  }
}

function getMiddleware(mappedFiles: OriginalFileField[], type: string) {
  switch (type) {
    case ASSETFILETYPE[1]: {
      return _.map(mappedFiles, (file) => {
        const mappedFile = _.mapKeys(file, (__, k) => returnKey(INVENTORYFILETOFIELD, k));
        if (Object.keys(_.omit(mappedFile, ['null'])).length > 0) {
          _.set(mappedFile, 'acquisitionDate', moment());
        }
        return mappedFile;
      });
    }
    case ASSETFILETYPE[2]:
      return _.map(mappedFiles, (file) => {
        return _.mapKeys(file, (__, k) => returnKey(VEHICLEFILETOFIELD, k));
      });
    case ASSETFILETYPE[3]:
      return _.map(mappedFiles, (file) => {
        return _.mapKeys(file, (__, k) =>
          returnKey(LEASEDVEHICLEFILETOFIELD, k)
        );
      });
    case ASSETFILETYPE[4]:
      return _.map(mappedFiles, (file) => {
        return _.mapKeys(file, (__, k) =>
          returnKey(LEASEDEQUIPMENTFILETOFIELD, k)
        );
      });
    default:
      return _.map(mappedFiles, (file) => {
        return _.mapKeys(file, (__, k) => returnKey(FIXEDASSETFILETOFIELD, k));
      });
  }
}

function useFileToAssets(mappedFiles: OriginalFileField[], type: string) {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState<AssetField[]>([]);
  let middleware;
  useEffect(() => {
    if (_.isEmpty(properties)) {
      setLoading(true);
      getAllProperties()
        .then((d) => {
          setLoading(false);
          setProperties(d.data.items);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [properties]);
  useEffect(() => {
    if (!_.isEmpty(properties)) {
      middleware = _.map(getMiddleware(mappedFiles, type), (file) => {
        return _.omit(file, ['null']);
      });
      const result: AssetField[] = [];
      _.map(middleware, (r, index) => {
        _.forEach(r, (v, k) => {
          _.set(result, `${index}.${k}`, v);
        });
      });
      _.map(result, (r, index) => {
        const findProperty = _.find(properties, [
          'number',
          _.get(r, 'propertyNumber'),
        ]);
        //Get company Id
        if (
          _.isEqual(
            _.get(r, 'companyName'),
            _.get(findProperty, 'company.name'),
          )
        ) {
          _.set(result[index], 'companyId', _.get(findProperty, 'companyId'));
          _.set(
            result[index],
            'companyNumber',
            _.get(findProperty, 'company.number'),
          );
        } else {
          _.unset(result[index], 'companyName');
          _.unset(result[index], 'companyNumber');
        }
        //Update legal entity information
        if (
          _.isEqual(
            _.get(r, 'legalEntityName'),
            _.get(findProperty, 'legalEntity.name'),
          )
        ) {
          _.set(
            result[index],
            'legalEntityNumber',
            _.get(findProperty, 'legalEntity.number'),
          );
          _.set(
            result[index],
            'legalEntityId',
            _.get(findProperty, 'legalEntityId'),
          );
        } else {
          _.unset(result[index], 'legalEntityName');
          _.unset(result[index], 'legalEntityNumber');
        }
        //Update Proper information
        if (_.get(findProperty, 'name')) {
          _.has(result[index], 'propertyName') &&
            _.set(result[index], 'propertyName', _.get(findProperty, 'name'));
          _.set(result[index], 'propertyId', _.get(findProperty, 'id'));
          _.set(
            result[index],
            'propertyStateId',
            _.get(findProperty, 'address.stateId'),
          );
          if (
            !_.isEqual(
              _.get(r, 'legalEntityName'),
              _.get(findProperty, 'legalEntity.name'),
            )
          ) {
            _.set(
              result[index],
              'legalEntityName',
              _.get(findProperty, 'legalEntity.name'),
            );
            _.set(
              result[index],
              'legalEntityNumber',
              _.get(findProperty, 'legalEntity.number'),
            );
            _.set(
              result[index],
              'legalEntityId',
              _.get(findProperty, 'legalEntityId'),
            );
          }
          if (
            !_.isEqual(
              _.get(r, 'companyName'),
              _.get(findProperty, 'company.name'),
            )
          ) {
            _.set(result[index], 'companyId', _.get(findProperty, 'companyId'));
            _.set(
              result[index],
              'companyNumber',
              _.get(findProperty, 'company.number'),
            );
            _.set(
              result[index],
              'companyName',
              _.get(findProperty, 'company.name'),
            );
          }
        } else {
          _.unset(result[index], 'propertyName');
          _.unset(result[index], 'propertyNumber');
        }
        
        //Update Assessor
        if (_.get(r, 'assessorAccountNumber') || _.get(r, 'assessor')) {
          const findAccount = _.find(
            _.get(findProperty, 'assessorAccounts'),
            (assessorAccount) => {
              return ( 
                _.isEqual(_.get(r, 'assessorAccountNumber'), _.get(assessorAccount, 'number'))
                && _.isEqual(_.get(r, 'assessor'), _.get(assessorAccount, 'assessor.name'))
                && _.get(r, 'taxYear') == _.get(assessorAccount, 'taxYear')
              );
            },
          );
          if (!_.isEmpty(findAccount)) {
            _.set(result[index], 'assessorAccountId', _.get(findAccount, 'id'));
            _.set(result[index], 'assessorAccount.name', _.get(r, 'assessor'));
            _.set(result[index], 'assessorAccount.number', _.get(r, 'assessorAccountNumber'));
          } else {
            _.unset(result[index], 'assessorAccountNumber');
            _.unset(result[index], 'assessor');
          }
        } else {
          if (_.isEqual(_.get(findProperty, 'assessorAccounts'), 1)) {
            _.set(result[index], 'assessorAccountId', _.get(findProperty, 'assessorAccounts[0].id'));
            _.set(result[index], 'assessorAccountNumber', _.get(findProperty, 'assessorAccounts[0].number'));
            _.set(result[index], 'assessorAccounts', _.get(findProperty, 'assessorAccounts[0].assessor.name'));
            _.set(result[index], 'assessorAccount.number', _.get(findProperty, 'assessorAccounts[0].number'));
            _.set(result[index], 'assessorAccount.name', _.get(findProperty, 'assessorAccounts[0].name'));
          }
        }
        _.set(result[index], 'type', type);

        // Update disposed and isAddition fields
        if (_.get(r, 'disposed')) {
          const isDisposed = _.includes(['TRUE','True','true','YES','Yes','yes'], _.get(r, 'disposed').toString());
          _.set(result[index], 'disposed', isDisposed);
        } else {
          _.set(result[index], 'disposed', false);
        }
        if (_.get(r, 'isAddition')) {
          const isAddition = _.includes(['TRUE','True','true','YES','Yes','yes'], _.get(r, 'isAddition').toString());
          _.set(result[index], 'isAddition', isAddition);
        } else {
          _.set(result[index], 'isAddition', false);
        }
      });
      setAssets(
        formatAssets(result).map(
          (item) => removeAssetsEmptyFields(item) as AssetField,
        ),
      );
    }
  }, [mappedFiles, properties]);
  return {assets, setAssets, loading};
}

export default useFileToAssets;
